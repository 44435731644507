import React from 'react';
import {HiCheck} from 'react-icons/hi';
import cx from 'classnames';
import {isEqual} from 'lodash';

import {colorPickerColorsFormatted} from '../../models/tags';

export const ColorPicker = ({
  onChange,
  currentColor,
}: {
  onChange?: (colors: string[]) => void;
  currentColor?: string[];
}) => {
  const renderColorSample = (
    bgColor: string,
    fontColor: string,
    selected: boolean,
    onClick?: () => void
  ) => {
    return (
      <div
        className={cx(
          'hover:ring-2 ring-gray-300 flex items-center content-center ring-offset-2 rounded-full w-5 h-5 text-center cursor-pointer',
          [bgColor],
          [fontColor]
        )}
        onClick={onClick}
      >
        {selected ? (
          <HiCheck className="m-auto w-4 h-4" />
        ) : (
          <div className="w-5 h-5 leading-5">a</div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-row flex-nowrap place-items-center gap-x-2 gap-y-4 font-normal text-xs">
      {colorPickerColorsFormatted.map((colors, index) => {
        return (
          <div
            className="hover:ring-2 ring-gray-300 rounded-full ring-offset-2"
            key={index}
            onClick={() => onChange && onChange(colors)}
          >
            {' '}
            {renderColorSample(
              colors[0],
              colors[1],
              isEqual(colors, currentColor)
            )}{' '}
          </div>
        );
      })}
    </div>
  );
};
