import {DateTime} from 'luxon';
import queryString from 'query-string';
import {AxiosInstance, AxiosResponse} from 'axios';
import {map} from 'lodash';

import {
  responseToPresignedDatasetLocation,
  PresignedDatasetLocation,
} from './dataset';

import {saveAs} from 'file-saver';

export interface UserFileInfo {
  locationID: number;
  userID: number;
  firstName: string;
  lastName: string;
  orgName: string;
  location: string;
  filename: string;
  size: number;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface UserFileInfoResp {
  LocationID: number;
  UserID: number;
  FirstName: string;
  LastName: string;
  OrgName: string;
  Location: string;
  Filename: string;
  Size: number;
  CreatedAt: string;
  UpdatedAt: string;
}

export const responseToUserFileInfo = (
  userFileInfoResp: UserFileInfoResp
): UserFileInfo => {
  return {
    userID: userFileInfoResp.UserID,
    firstName: userFileInfoResp.FirstName,
    lastName: userFileInfoResp.LastName,
    orgName: userFileInfoResp.OrgName,
    location: userFileInfoResp.Location,
    locationID: userFileInfoResp.LocationID,
    filename: userFileInfoResp.Filename,
    size: userFileInfoResp.Size,
    createdAt: DateTime.fromISO(userFileInfoResp.CreatedAt),
    updatedAt: DateTime.fromISO(userFileInfoResp.UpdatedAt),
  };
};

export const getAllUploadedFiles = (
  http: AxiosInstance,
  page: number,
  resultsPerPage: number
) => {
  return http
    .get(
      `v1/data_partner/all_files?page[]=${page}&resultsPerPage[]=${resultsPerPage}`
    )
    .then((response: AxiosResponse<UserFileInfoResp[]>) => {
      const {data} = response;
      return data.map((userFileInfo: UserFileInfoResp) => {
        return responseToUserFileInfo(userFileInfo);
      });
    });
};

export const getCountAllUploadedFiles = (http: AxiosInstance) => {
  return http
    .get('v1/data_partner/all_files_count')
    .then((response: AxiosResponse<number>) => {
      const {data} = response;
      return data;
    });
};

export const getPresignedDownloadUrls = (
  http: AxiosInstance,
  locationIDs: number[]
) => {
  return http
    .post(
      'v1/data_partner/download',
      queryString.stringify(
        {
          locationIDs: locationIDs,
        },
        {arrayFormat: 'bracket'}
      )
    )
    .then(response => {
      const {data} = response;
      const presignedDatasetLocations = map(
        data,
        presignedDatasetLocationResponse =>
          responseToPresignedDatasetLocation(presignedDatasetLocationResponse)
      );
      return presignedDatasetLocations;
    });
};

export const downloadFilesWithPresignedLocations = (
  presignedLocations: PresignedDatasetLocation[]
) => {
  presignedLocations.forEach((loc, i) =>
    setTimeout(() => downloadFileFromURL(loc.presignedURL), 500 * i)
  );
};

export const downloadFileFromURL = (URL: string) => {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(URL, '_blank');
};

export const downloadFileFromURLSameTab = async (
  http: AxiosInstance,
  URL: string
) => {
  return http
    .get(URL, {
      responseType: 'blob',
    })
    .then(response => {
      const filename = 'dataset.csv';

      saveAs(response.data, filename); // Use FileSaver to trigger the file download
    })
    .catch(error => {
      console.error('Error downloading file', error);
    });
};
