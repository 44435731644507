import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';

import {fetchPrices, PriceIndex} from '../../models/prices';
import {ApplicationShell} from '../../core/layout/application-shell';
import {Loading} from '../../core/components/loading';
import {useAxios} from 'src/utils/http';

export const PricingPage = () => {
  const api = useAxios();

  const {data: priceIndices, isLoading: priceIndicesLoading} = useQuery(
    ['pricing'],
    () => fetchPrices(api),
    {staleTime: Infinity}
  );

  const renderPriceIndex = (pi: PriceIndex) => {
    return pi.pricePoints.map((pricePoint, index) => (
      <div className="grid grid-cols-2" key={index}>
        <h3 className="text-sm text-gray-500">{pricePoint.label}</h3>
        <h3 className="text-sm text-gray-500 justify-self-end">
          {pricePoint.price}
        </h3>
      </div>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Pricing</title>
      </Helmet>

      <ApplicationShell>
        <div className="grid grid-cols-1 w-full gap-7 auto-rows-min">
          <h1 className="text-3xl">Pricing</h1>
          <h3 className="text-sm text-gray-500 mb-2">
            This pricing list is meant to provide an approximation of dataset
            costs. Certain exams may cost more due to rarity or other
            complexities.
          </h3>
          {priceIndicesLoading ? (
            <Loading />
          ) : (
            <>
              <h2 className="text-lg">
                {priceIndices && priceIndices.modalities.label}
              </h2>
              <div className="grid grid-cols-1 grid-flow-row gap-4">
                {priceIndices && renderPriceIndex(priceIndices.modalities)}
              </div>
            </>
          )}
        </div>
      </ApplicationShell>
    </>
  );
};
