import {AxiosInstance} from 'axios';

export interface StatsData {
  message: string;
  time: string;
  body_parts: StatData[];
  modalities: StatData[];
  total_reports: StatData[];
}

export interface StatData {
  label: string;
  stat: number;
}

export interface StatsDataResponse {
  message: string;
  time: string;
  body_parts: {[key: string]: number};
  modalities: {[key: string]: number};
  total_reports: {[key: string]: number};
}

export const fetchStats = (http: AxiosInstance) => {
  return http.get('/v1/statistics').then(response => {
    const {data} = response;
    return responseToStatsData(data);
  });
};

export const responseToStatsData = (statsDataResp: StatsDataResponse) => {
  const statsData: StatsData = {
    message: statsDataResp.message,
    time: statsDataResp.time,
    body_parts: Object.entries(statsDataResp.body_parts).map(
      ([key, value]): StatData => {
        return {label: key, stat: value};
      }
    ),
    modalities: Object.entries(statsDataResp.modalities).map(
      ([key, value]): StatData => {
        return {label: key, stat: value};
      }
    ),
    total_reports: Object.entries(statsDataResp.total_reports).map(
      ([key, value]): StatData => {
        return {label: key, stat: value};
      }
    ),
  };
  return statsData;
};
