import {Duration} from 'luxon';

const daysInYear = 365.25;
const daysInMonth = 30;
const secondsInDay = 60 * 60 * 24;

const secondsInYear = secondsInDay * daysInYear;
const secondsInMonth = secondsInDay * daysInMonth;

export const SecondsToDuration = (seconds: number): Duration => {
  const years = Math.floor(seconds / secondsInYear);
  const months = Math.floor((seconds % secondsInYear) / secondsInMonth);
  const days = Math.floor(
    ((seconds % secondsInYear) % secondsInDay) / secondsInDay
  );

  return Duration.fromObject({years, months, days});
};

export const DurationToSeconds = (duration: Duration): number => {
  return (
    duration.years * secondsInYear +
    duration.months * secondsInMonth +
    duration.days * secondsInDay
  );
};
