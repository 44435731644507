import React, {useState, Fragment} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {HiOutlineCheck} from 'react-icons/hi';

import {Dropdown} from '../dropdown';

export interface SelectOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

export const Select = <T,>({
  options,
  value,
  text = '',
  onSelect,
  contained = true,
  selectTheme = 'white',
  open,
  hideButton = false,
  dividerIndexes,
}: {
  options: SelectOption<T>[];
  value?: T;
  text: string;
  onSelect: (option: SelectOption<T>) => void;
  contained?: boolean;
  selectTheme?: 'white' | 'primary';
  open?: boolean;
  hideButton?: boolean;
  dividerIndexes?: number[];
}) => {
  const [selectOpened, selectOpenedChange] = useState(false);

  const onDropdownToggle = (opened: boolean) => {
    selectOpenedChange(opened);
  };

  const selected = (option: SelectOption<T>) => {
    selectOpenedChange(false); // Close dropdown
    onSelect(option);
  };

  if (open !== undefined && open !== selectOpened) {
    selectOpenedChange(open);
  }

  return (
    <>
      <Dropdown
        text={text}
        hideButton={hideButton}
        contained={contained}
        open={selectOpened}
        onToggle={(opened: boolean) => onDropdownToggle(opened)}
      >
        <div className="max-h-60 overflow-auto py-1">
          {_.map(options, (option, optionIndex) => {
            const isSelected = _.isEqual(value, option.value);

            return (
              <Fragment key={'fragment-' + optionIndex}>
                {dividerIndexes?.includes(optionIndex) && (
                  <div className="py-1">
                    <hr className="divide-y divide-gray-400"></hr>
                  </div>
                )}
                <div
                  className={cx(
                    'group cursor-default select-none relative py-2 pl-3 pr-9',
                    option.disabled
                      ? {'text-gray-400': true}
                      : {
                          'hover:text-white': selectTheme === 'primary',
                          'hover:bg-blue-600': selectTheme === 'primary',
                          'hover:text-gray-900': selectTheme === 'white',
                          'hover:bg-gray-100': selectTheme === 'white',
                        }
                  )}
                  onClick={() => !option.disabled && selected(option)}
                  key={'option-' + optionIndex}
                  data-cy={
                    !option.disabled
                      ? 'Select_optionDiv'
                      : 'Select_optionDisabledDiv'
                  }
                >
                  <span
                    className={cx('block whitespace-nowrap text-sm', {
                      'font-semibold': isSelected,
                      'font-normal': !isSelected,
                    })}
                  >
                    {option.label}
                  </span>
                  {/* Checkmark, only display for selected option. */}
                  <span
                    className={cx(
                      'text-blue-600 absolute inset-y-0 right-0 flex items-center pr-4',
                      {
                        hidden: !isSelected,
                        'group-hover:text-white': selectTheme === 'primary',
                        'hover:text-blue-600': selectTheme === 'white',
                      }
                    )}
                  >
                    <HiOutlineCheck className="h-5 w-5" />
                  </span>
                </div>
              </Fragment>
            );
          })}
        </div>
      </Dropdown>
    </>
  );
};
