import {Fragment, useState, useMemo} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import {useQuery, useQueries, useQueryClient} from 'react-query';

import {
  ReportSelectionEventType,
  useReportSelection,
} from '../../hooks/report-selection-provider';
import {Tag} from '../tags';
import {
  PatientSearchPatient,
  PatientSearchResult,
  SearchQuery,
} from '../../models/search';
import {
  getMatchedTags,
  getStudiesTags,
  getAvailableTags,
  StudyTag,
  studyTagsBatchSize,
} from '../../models/tags';
import {trackEvent} from '../../utils/tracking';
import {fetchAllDatasets} from '../../models/dataset';
import {encodeURLObject} from '../../utils/router-helper';
import {PatientReportsModal} from '../reports-modal';
import {Report, truncateStudyID} from '../../models/report';
import {useAuth} from '../../hooks/auth';
import {useAxios} from 'src/utils/http';

export const PatientSearchResultsTable = ({
  searchResult,
  searchQuery,
  allSelected,
}: {
  searchResult: PatientSearchResult;
  searchQuery: SearchQuery;
  allSelected: boolean;
}) => {
  const {authState} = useAuth();
  const http = useAxios();
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const [patientReportsModalPatientId, patientReportsModalPatientIdChange] =
    useState<string>();
  const queryClient = useQueryClient();

  const {data: datasets} = useQuery(['dataset'], () => fetchAllDatasets(http), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const studies = _.chain(searchResult.searchResults)
    .flatMap(p => _.concat(p.studies, p.additionalStudies))
    .value();
  const patients = searchResult.searchResults;

  const {data: tags} = useQuery(['tags'], () => getAvailableTags(http), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const batchStudyTags = useQueries(
    _.chunk(studies, studyTagsBatchSize).map(chunkedStudies => {
      return {
        queryKey: [
          'batchStudyTags',
          chunkedStudies.map(study => study.studyId),
          'tags',
        ],
        queryFn: () => {
          return getStudiesTags(
            http,
            chunkedStudies.map(study => study.studyId)
          );
        },
        staleTime: 5 * 60 * 1000, // 5 minutes,
        enabled: !!chunkedStudies,
        onSuccess: (batchTags: StudyTag[]) =>
          batchTags.forEach(tag =>
            queryClient.setQueryData(['report', tag.studyID, 'tag'], tag)
          ),
      };
    })
  ).flatMap(tag => tag.data ?? []);

  const studyTags = useQueries(
    (batchStudyTags ?? []).map(studyTag => {
      return {
        queryKey: ['report', studyTag.studyID, 'tag'],
        queryFn: () => {
          return getStudiesTags(http, [studyTag.studyID]);
        },
        enabled: false,
        staleTime: Infinity,
        initialData: studyTag,
      };
    })
  ).flatMap(tag => tag.data ?? []);

  const ReportRow = ({
    isAdditionalStudy = false,
    rowIndex,
    report,
    onSelect,
  }: {
    isAdditionalStudy?: boolean;
    rowIndex: number;
    report: Report;
    onSelect: (checked: boolean) => void;
  }) => {
    return (
      <tr data-cy="PatientSearchResultsTable_ReportRow">
        {/* Select Checkbox */}
        <td className="px-6 py-4 whitespace-nowrap text-sm w-1">
          <input
            type="checkbox"
            className="checkbox-input mr-2"
            checked={
              allSelected
                ? !reportSelectionState.remove.ids.has(report.studyId)
                : reportSelectionState.add.ids.has(report.studyId)
            }
            onChange={e => {
              const checked = e.target.checked;
              trackEvent(
                checked ? 'SEARCH_SELECT_REPORT' : 'SEARCH_UNSELECT_REPORT'
              );
              onSelect(checked);
            }}
          />
          {isAdditionalStudy && <span className="text-yellow-500">•</span>}
        </td>

        {/* Study ID */}
        <td className="px-6 py-4 whitespace-nowrap text-sm w-1">
          {truncateStudyID(report.studyId)}
        </td>

        {/* Title */}
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          <Link
            to={{
              pathname: '/patient_search/report',
              search: queryString.stringify({
                q: encodeURLObject(searchQuery),
                page: searchResult.page,
                id: report.studyId,
              }),
            }}
            className="text-primary hover:text-primary-active"
            data-tour={`results-study-title-${rowIndex}`}
            target="_blank"
            rel="noreferrer"
          >
            {!_.isEmpty(report.reportTitle)
              ? report.reportTitle
              : truncateStudyID(report.studyId)}
          </Link>
        </td>

        {/* Modality */}
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {report.modality}
        </td>

        {/* Patient ID */}
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {report.patientId}
        </td>

        {/* Exam Date */}
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {report.examDate?.toFormat('LLL dd, yyyy')}
        </td>

        {/* Manufacturer */}
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {report.manufacturer}
        </td>

        {/* Priority tags */}
        <td className="px-6 py-4 whitespace-nowrap overflow-x-scroll max-w-xs w-full text-sm">
          <div className="flex text-xs gap-x-1 font-normal">
            {studyTags &&
              getMatchedTags(report.studyId, studyTags, datasets, userTags)
                .filter(tag => tag.favorite)
                .map((tag, i) => <Tag key={i} tag={tag} />)}
          </div>
        </td>
      </tr>
    );
  };
  const PatientRows = ({patient}: {patient: PatientSearchPatient}) => {
    return (
      <Fragment key={patient.patientId}>
        <tr data-cy="PatientSearchResultsTable_PatientRow">
          <td className="px-6 py-4 whitespace-nowrap text-sm" colSpan={8}>
            {authState.profile?.admin ? (
              <button
                type="button"
                className="link"
                onClick={() =>
                  patientReportsModalPatientIdChange(patient.patientId)
                }
              >
                {patient.patientId}
              </button>
            ) : (
              <>{patient.patientId}</>
            )}
          </td>
        </tr>

        {_.concat(patient.studies, patient.additionalStudies).map(
          (report, i) => {
            return (
              <Fragment key={`${patient.patientId}_${report.studyId}`}>
                <ReportRow
                  report={report}
                  onSelect={checked =>
                    reportSelectionDispatch({
                      type: checked
                        ? ReportSelectionEventType.SELECT
                        : ReportSelectionEventType.UNSELECT,
                      payload: [report.studyId],
                    })
                  }
                  isAdditionalStudy={_.some(patient.additionalStudies, {
                    studyId: report.studyId,
                  })}
                  rowIndex={i}
                />
              </Fragment>
            );
          }
        )}
      </Fragment>
    );
  };

  const renderTable = () => {
    return (
      <div className="overflow-x-scroll">
        <table
          data-cy="PatientSearchResultsTable"
          className="min-w-full divide-y divide-gray-200 border"
        >
          <thead className="bg-gray-50 uppercase text-xs">
            <tr className="text-left text-gray-400">
              <th className="py-4 font-normal whitespace-nowrap w-min"></th>
              <th className="px-6 py-4 font-normal whitespace-nowrap w-min">
                Study ID
              </th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">Title</th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">
                Modality
              </th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">
                Patient ID
              </th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">
                ExamDate
              </th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">
                Manufacturer
              </th>
              <th className="px-6 py-4 font-normal whitespace-nowrap">
                Priority Tags
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {patients.map(patient => {
              return (
                <Fragment key={patient.patientId}>
                  <PatientRows patient={patient} />
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {renderTable()}

      {!_.isNil(patientReportsModalPatientId) && (
        <PatientReportsModal
          patientId={patientReportsModalPatientId}
          onRequestClose={() => patientReportsModalPatientIdChange(undefined)}
        />
      )}
    </>
  );
};
