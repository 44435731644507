import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {useAuth0} from '@auth0/auth0-react';

const axiosConfig = {
  //withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
  headers: {Accept: 'application/json'},
};

//export const http = axios.create(axiosConfig);

// auth0 middleware
export const useAxios = (
  {
    axiosRequestConfig,
    useAuth = true,
  }: {
    axiosRequestConfig?: AxiosRequestConfig;
    useAuth?: boolean;
  } = {
    useAuth: true,
  }
): AxiosInstance => {
  const config = {...axiosConfig, ...(axiosRequestConfig || {})};
  const {getAccessTokenSilently} = useAuth0();
  const instance = axios.create(config);
  if (process.env.REACT_APP_LIVE_TEST === 'true') {
    return axios.create({
      baseURL: axiosConfig.baseURL,
    });
  }

  if (useAuth) {
    instance.interceptors.request.use(async config => {
      const token = await getAccessTokenSilently();

      config.headers = {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      };

      return config;
    });
  }

  return instance;
};
