import {toast} from 'react-toastify';
import {ToastMessage} from '../core/components/toast';
import {Column} from 'react-table';
import {AxiosInstance} from 'axios';

export interface ESColumn {
  name: string;
  type: string;
}

export const fetchSQLResults = (
  http: AxiosInstance,
  query: string,
  isInternal: boolean
) => {
  const request = {query: query, output: 'json'};
  const endpoint = isInternal
    ? '/v1/minerva_internal/sql_search'
    : '/v1/minerva/sql_search';
  return http
    .post(endpoint, request)
    .then(response => {
      const {data: results} = response;
      for (const column of results.columns) {
        column.name = column.name.replace(/\./g, '-');
      }
      return results;
    })
    .catch(err => {
      const message =
        err?.response?.data?.message ??
        'Something went wrong processing the query.';
      toast(<ToastMessage title={message} icon="error" />);
      throw err;
    });
};

export const shapeColumns = (sqlResults: {columns: ESColumn[]}) => {
  const shapedColumns = sqlResults.columns.map((column: ESColumn) => {
    return {
      Header: column.name,
      accessor: column.name,
      filter: 'includes',
      Cell: ({value}: {value: string}) =>
        typeof value === 'string' ? (
          <span
            className="text-gray-500  text-sm leading-5 font-normal"
            title={value}
          >
            {value.length > 120 && sqlResults.columns.length > 2
              ? value.substring(0, 120) + '...'
              : value}
          </span>
        ) : (
          <span className="text-gray-500  text-sm leading-5 font-normal">
            {value}
          </span>
        ),
    } as Column<ESColumn>;
  });
  // Move study_id to the front of the list
  shapedColumns.sort((columnA, columnB) => {
    return columnA.Header === 'study_id'
      ? -1
      : columnB.Header === 'study_id'
      ? 1
      : 0;
  });
  return shapedColumns;
};

export const shapeRows = (sqlResults: {
  columns: ESColumn[];
  rows: Array<Array<{}>>;
}) => {
  return sqlResults.rows.map(row => {
    const shapedRow: {[key: string]: unknown} = {};
    row.forEach((value: unknown, index: number) => {
      /* eslint-disable security/detect-object-injection */
      shapedRow[sqlResults.columns[index].name] = value;
    });
    return shapedRow;
  }) as Array<{}>;
};

export const fetchCompletion = async (
  http: AxiosInstance,
  naturalLanguageQuery: string,
  isInternal: boolean
) => {
  const request = {
    naturalLanguageQuery: naturalLanguageQuery,
    isInternal: isInternal,
  };

  try {
    const response = await http.post('/v1/openai/', request);
    const completion = response.data.choices[0].text.trim();
    return completion;
  } catch (error) {
    console.error('Error fetching completion:', error);
    throw error;
  }
};
