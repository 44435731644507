import React, {Fragment} from 'react';
import cx from 'classnames';

import {DicomHeaderScan} from '../../../models/dataset-review';

export const HeaderScanResults = ({
  headerScan,
}: {
  headerScan: DicomHeaderScan;
}) => {
  return (
    <div>
      <div className="mt-4 mb-2 text-xl flex items-center">
        Header Scan Results
      </div>

      <dl className="shadow grid grid-cols-5 gap-y-1 p-1 rounded">
        {Object.entries(headerScan).map((header, i) => {
          return (
            <Fragment key={header[0]}>
              <dt className="text-md mr-2 col-span-1 break-all">
                {header[0]}:
              </dt>{' '}
              <dd
                className={cx('text-sm col-span-4 break-normal', {
                  'text-red-700': !header[1],
                })}
              >
                {header[1] ?? 'Value missing in Header Scan Results'}
              </dd>
              {Object.keys(headerScan).length - 1 !== i && (
                <hr className="divide-y divide-gray-400 col-span-full" />
              )}
            </Fragment>
          );
        })}
      </dl>
    </div>
  );
};
