import {useState, ReactNode} from 'react';
import cx from 'classnames';

import {Dropdown} from './dropdown';

export const ColumnDropdown = ({
  label,
  children,
  disabled,
}: {
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}) => {
  const [open, openChange] = useState<boolean>(false);

  return (
    <>
      <Dropdown
        open={open}
        onToggle={opened => {
          if (disabled) {
            return;
          }

          openChange(opened);
        }}
        disabled={disabled}
        buttonClassName={cx(
          'text-xs font-medium text-gray-500 uppercase tracking-wider text-gray-400',
          {
            'cursor-pointer hover:text-gray-500': !disabled,
            'cursor-default': disabled,
          }
        )}
        customButton={label}
      >
        <div className="p-4 w-72">{children}</div>
      </Dropdown>
    </>
  );
};
