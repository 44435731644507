import _ from 'lodash';
import {DateTime} from 'luxon';

import {
  CartItem,
  CartItemResponse,
  Cart,
  responseToCartItem,
  CartDiscount,
  CartDiscountResponse,
  responseToCartDiscount,
} from '../models/cart';
import {DatasetOrderStatusEnum} from 'src/enums/datasetorderstatusenum';
import {OrderStudyStatusEnum} from 'src/enums/orderstudystatusenum';
import {AxiosInstance} from 'axios';

export interface DatasetOrder {
  orderId: string;
  datasetId: number;
  datasetName: string;
  orgId: number;
  orderStatus: DatasetOrderStatusEnum;
  amount: number;
  numStudies: number;
  originalAmount: number;
  returnAmount: number;
  numReturnStudies: number;
  subtotalItems?: CartItem[];
  discounts?: CartDiscount[];
  orderedAt?: DateTime;
  paidAt?: DateTime;
  returnWindowAt?: DateTime;
  updatedAt?: DateTime;
  createdAt?: DateTime;
}

interface DatasetOrderResponse {
  order_id: string;
  dataset_id: number;
  dataset_name: string;
  org_id: number;
  order_status: DatasetOrderStatusEnum;
  amount: number;
  num_studies: number;
  original_amount: number;
  return_amount: number;
  num_return_studies: number;
  subtotal_items?: CartItemResponse[];
  discounts?: CartDiscountResponse[];
  ordered_at: string;
  return_window_at: string;
  paid_at: {Time: string; Valid: boolean};
  updated_at: string;
  created_at: string;
}

export interface DatasetOrderStudy {
  orderId: string;
  studyId: string;
  orderStudyStatus: OrderStudyStatusEnum;
}

interface DatasetOrderStudyResponse {
  order_id: string;
  study_id: string;
  order_study_status: OrderStudyStatusEnum;
}

export const responseToDatasetOrder = (ordersResp: DatasetOrderResponse) => {
  const datasetOrder: DatasetOrder = {
    orderId: ordersResp.order_id,
    datasetId: ordersResp.dataset_id,
    datasetName: ordersResp.dataset_name,
    orgId: ordersResp.org_id,
    orderStatus: ordersResp.order_status,
    amount: ordersResp.amount,
    numStudies: ordersResp.num_studies,
    originalAmount: ordersResp.original_amount,
    returnAmount: ordersResp.return_amount,
    numReturnStudies: ordersResp.num_return_studies,
    subtotalItems: ordersResp.subtotal_items?.map(cartItemResp =>
      responseToCartItem(cartItemResp)
    ),
    discounts: ordersResp.discounts?.map(discountResp =>
      responseToCartDiscount(discountResp)
    ),
    orderedAt:
      ordersResp.ordered_at !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(ordersResp.ordered_at)
        : undefined,
    paidAt: ordersResp.paid_at.Valid
      ? DateTime.fromISO(ordersResp.paid_at.Time)
      : undefined,
    returnWindowAt:
      ordersResp.return_window_at !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(ordersResp.return_window_at)
        : undefined,
    updatedAt:
      ordersResp.updated_at !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(ordersResp.updated_at)
        : undefined,
    createdAt:
      ordersResp.created_at !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(ordersResp.created_at)
        : undefined,
  };

  return datasetOrder;
};

export const responseToDatasetOrderStudy = (
  orderStudyResp: DatasetOrderStudyResponse
) => {
  const datasetOrderStudy: DatasetOrderStudy = {
    orderId: orderStudyResp.order_id,
    studyId: orderStudyResp.study_id,
    orderStudyStatus: orderStudyResp.order_study_status,
  };
  return datasetOrderStudy;
};

export const fetchAllDatasetOrders = (http: AxiosInstance) => {
  return http.get('/v1/admin/orders').then(response => {
    const {data} = response;

    const orders = _.map(data, datasetOrderResp =>
      responseToDatasetOrder(datasetOrderResp)
    );

    return orders;
  });
};

export const datasetOrderToCart = (datasetOrder: DatasetOrder) => {
  const subtotal =
    datasetOrder.subtotalItems?.reduce((total, item) => {
      return total + item.total;
    }, 0) ?? datasetOrder.amount;

  const cart: Cart = {
    items: datasetOrder.subtotalItems ?? [],
    subtotal: subtotal,
    numStudies: datasetOrder.numStudies,
    total: datasetOrder.amount,
    discounts: datasetOrder.discounts ?? [],
  };

  return cart;
};

export const fetchDatasetOrder = (
  http: AxiosInstance,
  orderId: string,
  isAdmin?: boolean
) => {
  let url = `/v1/orders/${orderId}`;
  if (isAdmin) {
    url = `/v1/admin/orders/${orderId}`;
  }

  return http.get(url).then(response => {
    const {data} = response;
    return responseToDatasetOrder(data);
  });
};

export const fetchUserAllDatasetOrders = (http: AxiosInstance) => {
  return http.get('/v1/orders').then(response => {
    const {data} = response;

    const orders = _.map(data, datasetOrderResp =>
      responseToDatasetOrder(datasetOrderResp)
    );

    return orders;
  });
};

export const updateDatasetOrdersStatus = (
  http: AxiosInstance,
  orderId: string,
  status: string
) => {
  return http.put(`/v1/admin/orders/${orderId}`, {order_status: status});
};
