import {AxiosInstance} from 'axios';
import _ from 'lodash';

export interface UserSyntheticDataCredit {
  userId: number;
  email: string;
  creditCounter: number;
}

export const updateSyntheticDataCredit = (
  http: AxiosInstance,
  userId: number,
  credit: number
) => {
  return http.put(`/v1/admin/synthetic/credit/${userId}`, {credit: credit});
};

interface UserSyntheticDataCreditResponse {
  userid: number;
  email: string;
  credit_counter: number;
}

export const responseToSyntheticDataCredit = (
  syntheticDataResp: UserSyntheticDataCreditResponse
) => {
  const syntheticDataCredit: UserSyntheticDataCredit = {
    userId: syntheticDataResp.userid,
    email: syntheticDataResp.email,
    creditCounter: syntheticDataResp.credit_counter,
  };
  return syntheticDataCredit;
};

export const fetchAllSyntheticCreditUsersAdmin = (http: AxiosInstance) => {
  return http.get('/v1/admin/synthetic/credit').then(response => {
    const {data} = response;

    const usersCredit = _.map(data, syntheticCreditResp =>
      responseToSyntheticDataCredit(syntheticCreditResp)
    );

    return usersCredit;
  });
};
