import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {ApplicationShell} from '../../../core/layout/application-shell';

export const SyntheticGrid = () => {
  return (
    <>
      <Helmet>
        <title>Segmed Openda - Synthetic Data</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        <h2 className="text-2xl leading-7 font-bold text-gray-900">
          Segmed Synthetic Chest XRAY
        </h2>
        <br />
        <Link to={'/synthetic/01_chest'}>
          <img
            className="h-24 w-auto"
            src="assets/misc/01_chest_min.png"
            alt="Synthetic Chest"
          />
          <br />
        </Link>
      </ApplicationShell>
    </>
  );
};
