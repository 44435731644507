import {useEffect} from 'react';
import {
  Route,
  useNavigate,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import {trackEvent} from '../../utils/tracking';
import {ReportSelectionProvider} from '../../hooks/report-selection-provider';
import {DatasetReviewProvider} from '../../hooks/dataset-review-provider';
import {AuthCheck} from '../../components/auth-check';

import {SearchOldPage} from '../search/search-old';
import {Login} from '../auth/login';
import {Logout} from '../auth/logout';
import {VerifyEmail} from '../auth/verify-email';

import {DatasetList} from '../datasets/list';
import {DatasetDetails} from '../datasets/details';
import {DatasetReportDetails} from '../datasets/report-details';

import {SavedSearchesList} from '../saved-searches/list';

import {
  SearchOldReportDetails,
  SearchOldResultsPage,
} from '../search/search-old';
import {
  PatientSearchPage,
  PatientSearchResultsPage,
} from '../search/patient-search';
import {
  AdvancedSearchPage,
  AdvancedSearchResultsPage,
} from '../search/advanced-search';

import {PricingPage} from '../pricing/pricing-page';

import {TerminalPage} from '../terminal/terminal-page';

import {StatsPage} from '../stats/stats-page';

import {AccountSettings} from '../account/account-settings';

import {ErrorPage} from '../../core/layout/error-page';

import {FileUploadPage} from '../file-upload';

import {SampleDownloadPage} from '../admin/sample-download';

import {DicomViewerPage} from '../dicom-viewer';

import {TagsPage} from '../tags';

import {OrdersPage} from '../orders';

import {AdminOrdersPage} from '../admin/orders';

import {
  AdminDatasetsPage,
  AdminDatasetsReviewPage,
  AdminDatasetsResultsReviewPage,
  AdminDicomRequestStatusPage,
} from '../admin/datasets';
import {AdminUsersPage} from '../admin/users';
import {AdminSyntheticUsersPage} from '../admin/synthetic-credits';
import {AdminReviewReportDetails} from '../admin/datasets/report-details';

import {OrderDetailsPage} from '../orders';
import {AdvancedSearchReportDetails} from '../search/advanced-search/advanced-search-report-details';
import {
  MinervaSearchPage,
  MinervaSearchInternalPage,
} from '../search/minerva-search';
import {StudyDetails} from '../study';
import {PatientSearchReportDetails} from '../search/patient-search/patient-search-report-details';
import {ToolsPage} from '../tools';
import {DicomHeaderSelectorPage} from '../tools/dicom-header-selector';

import {SyntheticGrid} from '../synthetic-data/grid';
import {SyntheticDisplay} from '../synthetic-data/display';

import {DistributionsPage} from '../distributions';
import {DoraPage} from '../dora';
import {Register} from '../auth/register';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <AuthCheck>
            <ReportSelectionProvider>
              <MinervaSearchPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />
      {/* Search Routes */}
      <Route
        path="/internal/search"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <ReportSelectionProvider>
              <MinervaSearchInternalPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/terminal"
        element={
          <AuthCheck>
            <ReportSelectionProvider>
              <TerminalPage internalPage={false} />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/internal/terminal"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <ReportSelectionProvider>
              <TerminalPage internalPage={true} />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/search"
        element={
          <AuthCheck allowed={['internal']}>
            <SearchOldPage />
          </AuthCheck>
        }
      />
      <Route
        path="/search/results"
        element={
          <AuthCheck allowed={['internal']}>
            <ReportSelectionProvider>
              <SearchOldResultsPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/patient_search"
        element={
          <AuthCheck allowed={['internal']}>
            <ReportSelectionProvider>
              <PatientSearchPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/advanced_search"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <ReportSelectionProvider>
              <AdvancedSearchPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/advanced_search/results"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <ReportSelectionProvider>
              <AdvancedSearchResultsPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/advanced_search/report"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <ReportSelectionProvider>
              <AdvancedSearchReportDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/patient_search/results"
        element={
          <AuthCheck allowed={['internal']}>
            <ReportSelectionProvider>
              <PatientSearchResultsPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/search/report"
        element={
          <AuthCheck allowed={['internal']}>
            <ReportSelectionProvider>
              <SearchOldReportDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/patient_search/report"
        element={
          <AuthCheck allowed={['internal']}>
            <ReportSelectionProvider>
              <PatientSearchReportDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/study/:studyID/dicomViewer/:seriesID?/:sopID?"
        element={
          <AuthCheck redirectPath="/">
            <DicomViewerPage
              getImageUrl={({studyID, seriesID, sopID}) => {
                return `/study/${studyID}/dicomViewer${
                  seriesID ? `/${seriesID}` : ''
                }${seriesID && sopID ? `/${sopID}` : ''}`;
              }}
            />
          </AuthCheck>
        }
      />
      <Route
        path="/study/:studyId"
        element={
          <AuthCheck>
            <StudyDetails />
          </AuthCheck>
        }
      />
      {/* Dicom Viewer Routes */}
      <Route
        path="/datasets/:datasetID/report/:studyID/dicomViewer/:seriesID?/:sopID?"
        element={
          <AuthCheck redirectPath="/">
            <DicomViewerPage
              getImageUrl={({datasetID, studyID, seriesID, sopID}) => {
                return `/datasets/${datasetID}/report/${studyID}/dicomViewer${
                  seriesID ? `/${seriesID}` : ''
                }${seriesID && sopID ? `/${sopID}` : ''}`;
              }}
            />
          </AuthCheck>
        }
      />
      {/* Saved Searches Routes */}
      <Route
        path="/saved-searches"
        element={
          <AuthCheck>
            <SavedSearchesList />
          </AuthCheck>
        }
      />
      {/* Datasets Routes */}
      <Route
        path="/datasets"
        element={
          <AuthCheck allowed={['customer']}>
            <DatasetList />
          </AuthCheck>
        }
      />
      <Route
        path="/datasets/:datasetId/report/:studyId"
        element={
          <AuthCheck allowed={['customer']}>
            <ReportSelectionProvider>
              <DatasetReportDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/datasets/:datasetId/*"
        element={
          <AuthCheck allowed={['customer']}>
            <ReportSelectionProvider>
              <DatasetDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      {/* File Upload Routes */}
      <Route
        path="/datapartner/upload"
        element={
          <AuthCheck redirectPath="/" allowed={['data_partner']}>
            <ReportSelectionProvider>
              <FileUploadPage />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      {/* File Download Routes */}
      <Route
        path="/admin/datapartner/download"
        element={
          <AuthCheck allowed={['admin']}>
            <SampleDownloadPage />
          </AuthCheck>
        }
      />
      {/* Pricing Routes */}
      <Route
        path="/pricing"
        element={
          <AuthCheck allowed={['internal']}>
            <PricingPage />
          </AuthCheck>
        }
      />
      {/* Stats Routes */}
      <Route
        path="/stats"
        element={
          <AuthCheck allowed={['customer', 'data_partner']}>
            <StatsPage />
          </AuthCheck>
        }
      />
      {/* Tag Mgmt Routes */}
      <Route
        path="/tags"
        element={
          <AuthCheck allowed={['customer']}>
            <TagsPage />
          </AuthCheck>
        }
      />
      {/* User Dataset Orders */}
      <Route
        path="/orders"
        element={
          <AuthCheck allowed={['internal']}>
            <OrdersPage />
          </AuthCheck>
        }
      />
      <Route
        path="/orders/:orderId"
        element={
          <AuthCheck allowed={['internal']}>
            <OrderDetailsPage />
          </AuthCheck>
        }
      />
      {/* Account Settings */}
      <Route
        path="/settings"
        element={
          <AuthCheck>
            <AccountSettings />
          </AuthCheck>
        }
      />
      {/* Admin routes */}
      <Route
        path="/admin/datasets"
        element={
          <AuthCheck allowed={['admin']}>
            <AdminDatasetsPage />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/dataset/dicom-request-status/:datasetId"
        element={
          <AuthCheck allowed={['admin']} redirectPath="/">
            <AdminDicomRequestStatusPage />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/orders"
        element={
          <AuthCheck allowed={['admin']}>
            <AdminOrdersPage />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/orders/:orderId"
        element={
          <AuthCheck allowed={['admin']}>
            <OrderDetailsPage isAdmin={true} />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/users"
        element={
          <AuthCheck allowed={['admin']}>
            <AdminUsersPage />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/datasets/review/:datasetID/dicomViewer/:studyID/:seriesID?/:sopID?"
        element={
          <AuthCheck redirectPath="/" allowed={['admin']}>
            <DicomViewerPage
              isAdmin={true}
              getImageUrl={({datasetID, studyID, seriesID, sopID}) => {
                return `/admin/datasets/review/${datasetID}/dicomViewer/${studyID}${
                  seriesID ? `/${seriesID}` : ''
                }${seriesID && sopID ? `/${sopID}` : ''}`;
              }}
            />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/datasets/review"
        element={
          <AuthCheck allowed={['admin']}>
            <AdminDatasetsReviewPage />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/datasets/review/:datasetID"
        element={
          <AuthCheck allowed={['admin']}>
            <DatasetReviewProvider>
              <AdminDatasetsResultsReviewPage />
            </DatasetReviewProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/admin/datasets/review/:datasetId/report/:studyId/:displayData?"
        element={
          <AuthCheck allowed={['admin']}>
            <ReportSelectionProvider>
              <AdminReviewReportDetails />
            </ReportSelectionProvider>
          </AuthCheck>
        }
      />
      <Route
        path="/synthetic"
        element={
          <AuthCheck allowed={['internal']}>
            <SyntheticGrid />
          </AuthCheck>
        }
      />
      <Route
        path="/synthetic/:syntheticId"
        element={
          <AuthCheck allowed={['internal']}>
            <SyntheticDisplay />
          </AuthCheck>
        }
      />
      <Route
        path="/admin/synthetic/credits"
        element={
          <AuthCheck allowed={['admin']}>
            <AdminSyntheticUsersPage />
          </AuthCheck>
        }
      />
      <Route
        path="/tools"
        element={
          <AuthCheck allowed={['internal']}>
            <ToolsPage />
          </AuthCheck>
        }
      />
      <Route
        path="/tools/dicom-header-selector"
        element={
          <AuthCheck allowed={['internal']}>
            <DicomHeaderSelectorPage />
          </AuthCheck>
        }
      />
      <Route
        path="/:datset_id/distributions"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <DistributionsPage />
          </AuthCheck>
        }
      />
      <Route element={<ErrorPage statusCode={404} />} />
      <Route
        path="/internal/dora"
        element={
          <AuthCheck redirectPath="/" allowed={['internal']}>
            <DoraPage />
          </AuthCheck>
        }
      />
    </>
  )
);

// @todo: Better organization for routes
export const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    trackEvent('LOAD_SITE', {
      path: location.pathname,
      query: location.search,
    });

    trackEvent('NAVIGATE', {
      path: location.pathname,
      query: location.search,
    });
  }, [navigate, location.pathname, location.search]);
  return <> </>;
};
