import {DatasetStatusEnum} from './datasetstatusenum';

export const GroupedStatuses = {
  All: Object.values(DatasetStatusEnum),
  Created: [DatasetStatusEnum.DatasetStatusDatasetCreated],
  MetadataRequested: [
    DatasetStatusEnum.DatasetStatusMetadataCustRequested,
    DatasetStatusEnum.DatasetStatusMetadataCustRequestedBlocked,
    DatasetStatusEnum.DatasetStatusMetadataDPRequested,
    DatasetStatusEnum.DatasetStatusMetadataReady,
    DatasetStatusEnum.DatasetStatusMetadataEmailSent,
    DatasetStatusEnum.DatasetStatusMetadataStalled,
  ],
  SamplesRequested: [
    DatasetStatusEnum.DatasetStatusSamplesPacking,
    DatasetStatusEnum.DatasetStatusSamplesPacked,
    DatasetStatusEnum.DatasetStatusSampleEvaluating,
    DatasetStatusEnum.DatasetStatusSampleEvaluated,
    DatasetStatusEnum.DatasetStatusSamplesScanning,
    DatasetStatusEnum.DatasetStatusSamplesScanned,
    DatasetStatusEnum.DatasetStatusSamplesClean,
    DatasetStatusEnum.DatasetStatusSamplesPHIDetected,
    DatasetStatusEnum.DatasetStatusSamplesDelivered,
    DatasetStatusEnum.DatasetStatusSamplesRecalled,
  ],
  QuotesSigned: [
    DatasetStatusEnum.DatasetStatusDatasetOrdered,
    DatasetStatusEnum.DatasetStatusOrderDisapproved,
    DatasetStatusEnum.DatasetStatusOrderCanceled,
    DatasetStatusEnum.DatasetStatusQuotesSent,
    DatasetStatusEnum.DatasetStatusQuotesSigned,
  ],
  DatasetScanning: [
    DatasetStatusEnum.DatasetStatusDatasetPacking,
    DatasetStatusEnum.DatasetStatusDatasetPacked,
    DatasetStatusEnum.DatasetStatusDatasetEvaluating,
    DatasetStatusEnum.DatasetStatusDatasetEvaluated,
    DatasetStatusEnum.DatasetStatusDatasetPHIDetected,
    DatasetStatusEnum.DatasetStatusDatasetScanning,
    DatasetStatusEnum.DatasetStatusDatasetScanned,
    DatasetStatusEnum.DatasetStatusDatasetClean,
  ],
  DatasetDelivered: [
    DatasetStatusEnum.DatasetStatusDatasetDelivered,
    DatasetStatusEnum.DatasetStatusDeliveryEmailSent,
    DatasetStatusEnum.DatasetStatusDatasetRecalled,
    DatasetStatusEnum.DatasetStatusOrderFullfilled,
  ],
};
