import React, {useEffect, useRef, FC} from 'react';
import {Select, SelectOption} from '../../core/components/select'; // Update the import path accordingly
import {PatientSortOption} from '../../models/minerva';
import {ExtendedSearchDriverOptions} from '../../models/minerva';

interface SortDropdownProps {
  config: ExtendedSearchDriverOptions;
  onSortChange: (option: PatientSortOption) => void;
}

export const PatientGroupsSortDropdown: FC<SortDropdownProps> = ({
  config,
  onSortChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Adapt options for the Select component
  const options: SelectOption<PatientSortOption>[] = [
    {label: 'Exam date ASC', value: 'Exam date ASC'},
    {label: 'Exam date DESC', value: 'Exam date DESC'},
    {label: 'Body Part', value: 'Body Part'},
    {label: 'Modality', value: 'Modality'},
  ];

  const handleSelectChange = (
    selectedOption: SelectOption<PatientSortOption>
  ) => {
    onSortChange(selectedOption.value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        // Handle click outside logic if needed, Select component manages its own open state
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col bg-gray-50 relative" ref={wrapperRef}>
      <p className="text-sm text-gray-500 pb-1">Sort by</p>
      <Select
        options={options}
        value={config.searchQuery.sortPatientIDsBy}
        onSelect={handleSelectChange}
        text={config.searchQuery.sortPatientIDsBy} // Assuming this is the current selected value's label
      />
    </div>
  );
};
