import {useState, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useNavigate} from 'react-router-dom';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {MinervaSearch} from './minerva-search';
import ElasticsearchAPIConnector, {
  ElasticsearchTransporter,
} from '@segmed/search-ui-elasticsearch-connector';
import {Filter} from '@elastic/search-ui';
import {HiOutlineArrowRight} from 'react-icons/hi';
import {
  buildDisjunctiveFacetsConfigFromConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from '../../../config/config-helper';
import {
  ExtendedSearchDriverOptions,
  parseElasticSearchBody,
} from '../../../models/minerva';
import _ from 'lodash';
import {
  PatientSortOption,
  SortOption,
  trackSearchEvent,
  extractSearchTerms,
  extractUniquePatientCount,
  deserializeSearchBody,
  serializeSearchBody,
} from '../../../models/minerva';
import {useAxios} from 'src/utils/http';
import {useAuth0} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

const {endpointBase, engineName, endpointBaseAppSearch} = getConfig();

export const MinervaSearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let elasticSearchBody = location.state?.elasticSearchBody;
  let encodedSearchBody = false;

  // Check if there are search parameters in the URL
  if (location.search && location.search.length > 0) {
    elasticSearchBody = deserializeSearchBody(location.search);
    encodedSearchBody = true;
  }
  const [lastRequestBody, lastRequestBodyChange] = useState(elasticSearchBody);
  const [patientCount, patientCountChange] = useState<number | null>(null);
  const http = useAxios();
  const {user} = useAuth0();
  let initialSearchQuery = {
    facets: buildFacetConfigFromConfig(),
    disjunctiveFacets: buildDisjunctiveFacetsConfigFromConfig(),
    ...buildSearchOptionsFromConfig(),
    groupPatientIDs: false,
    sortPatientIDsBy: 'Exam date DESC',
    useVectorSearch: false,
    queryText: '',
  };
  let initialState = {
    resultsPerPage: 50,
    sortList: [{field: 'study_id.keyword', direction: 'desc'}] as SortOption[],
    filters: [] as Filter[],
  };

  if (elasticSearchBody && !_.isEmpty(elasticSearchBody)) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      groupPatientsByID,
      sortPatientIDsBy,
      useVectorSearch,
    } = parseElasticSearchBody(elasticSearchBody);
    const formattedFilters = elasticSearchBody.formattedFilters;
    initialSearchQuery = {
      ...initialSearchQuery,
      query: elasticSearchBody.query,
      groupPatientIDs: groupPatientsByID,
      sortPatientIDsBy: sortPatientIDsBy || 'Exam date DESC',
      useVectorSearch: useVectorSearch,
    };
    initialState = {
      ...initialState,
      filters: formattedFilters,
    };
  }

  const connector = new ElasticsearchAPIConnector(
    new ElasticsearchTransporter(
      endpointBase,
      engineName,
      async (host, _engineName, requestBody) => {
        const startTime = performance.now(); // Start time
        try {
          const response = await http.post(`${host}`, requestBody);
          const duration = performance.now() - startTime; // Calculate duration
          if ((requestBody as any)['groupPatientIDs']) {
            patientCountChange(extractUniquePatientCount(response.data));
          }
          trackSearchEvent(
            requestBody,
            response.data?.aggregations?.facet_bucket_all?.doc_count,
            duration, // Pass duration
            user?.email, // Pass user email for Sentry context
            'external_search' // Pass search type 'external' for sentry
          );
          lastRequestBodyChange(requestBody);
          const serializedSearchBody = serializeSearchBody(requestBody);
          const newUrl = `${window.location.pathname}?${serializedSearchBody}`;
          navigate(newUrl, {
            state: {elasticSearchBody: requestBody},
            replace: true,
          });

          return response.data;
        } catch (error) {
          Sentry.captureException(error);
          throw error;
        }
      }
    ),
    (requestBody, _requestState, queryConfig) => {
      const queryBuilderQuery = (queryConfig as {queryBuilderQuery?: Object})
        .queryBuilderQuery;
      if (!_.isEmpty(queryBuilderQuery)) {
        if (!_.isEmpty(requestBody.query)) {
          requestBody.query = {
            bool: {
              must: [requestBody.query, queryBuilderQuery],
            },
          };
        } else {
          requestBody.query = queryBuilderQuery;
        }
      }

      // HACK: the queryBuilderQuery isn't present on first page load, so we're using that to check if we should use the URL
      if (
        encodedSearchBody &&
        !(queryConfig as {queryBuilderQuery?: Object}).queryBuilderQuery
      ) {
        requestBody.query = elasticSearchBody.query;
        (requestBody as any)['groupPatientIDs'] =
          elasticSearchBody.groupPatientIDs;
        (requestBody as any)['sortPatientIDsBy'] =
          elasticSearchBody.sortPatientIDsBy;
        (requestBody as any)['useVectorSearch'] =
          elasticSearchBody.useVectorSearch;
      }

      const groupPatientIDs = (queryConfig as {groupPatientIDs?: boolean})
        .groupPatientIDs;
      const sortPatientIDsBy =
        (queryConfig as {sortPatientIDsBy?: PatientSortOption})
          .sortPatientIDsBy || 'Exam date DESC';
      if (groupPatientIDs) {
        (requestBody as any)['groupPatientIDs'] = true;
        (requestBody as any)['sortPatientIDsBy'] = sortPatientIDsBy;
      }

      const useVectorSearch = (queryConfig as {useVectorSearch?: boolean})
        .useVectorSearch;
      if (useVectorSearch) {
        (requestBody as any)['useVectorSearch'] = true;
        const searchTerms = extractSearchTerms(requestBody);
        if (searchTerms && searchTerms.size > 0) {
          (requestBody as any)['queryText'] = Array.from(searchTerms).join(' ');
        }
      }

      // Set highlight terms
      const searchTerms = extractSearchTerms(requestBody);
      if (searchTerms && searchTerms.size > 0) {
        requestBody.highlight!.highlight_query = {
          bool: {
            must: {
              bool: {
                should: Array.from(searchTerms).map(term => ({
                  multi_match: {
                    query: term,
                  },
                })),
              },
            },
          },
        };
      }

      return requestBody;
    }
  );

  const defaultConfig: ExtendedSearchDriverOptions = {
    searchQuery: initialSearchQuery,
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true,
    initialState: initialState,
    appSearchEndpoint: endpointBaseAppSearch,
    trackUrlState: false,
  };

  const [config, setConfig] =
    useState<ExtendedSearchDriverOptions>(defaultConfig);

  const toggleGroupPatientIDs = () => {
    setConfig(prevConfig => ({
      ...prevConfig,
      searchQuery: {
        ...prevConfig.searchQuery,
        groupPatientIDs: !prevConfig.searchQuery.groupPatientIDs,
      },
    }));
  };

  const setSortPatientIDsBy = (PatientSortOption: PatientSortOption) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      searchQuery: {
        ...prevConfig.searchQuery,
        sortPatientIDsBy: PatientSortOption,
      },
    }));
  };

  const toggleVectorSearch = () => {
    setConfig(prevConfig => ({
      ...prevConfig,
      searchQuery: {
        ...prevConfig.searchQuery,
        useVectorSearch: !prevConfig.searchQuery.useVectorSearch,
      },
    }));
  };

  const onSubmit = useCallback(
    (query?: Object) => {
      if (!_.isEqual(query, config.searchQuery.queryBuilderQuery)) {
        setConfig(prevConfig => ({
          ...prevConfig,
          searchQuery: {
            ...prevConfig.searchQuery,
            queryBuilderQuery: query,
          },
        }));
        const fullQuery = {
          ...config.searchQuery,
          query: query,
          aggs: lastRequestBody?.aggs || {},
          post_filter: lastRequestBody?.post_filter || {},
        };
        const serializedSearchBody = serializeSearchBody(fullQuery);
        const newUrl = `${window.location.pathname}?${serializedSearchBody}`;
        navigate(newUrl, {
          state: {elasticSearchBody: fullQuery},
          replace: true,
        });
      }
    },
    [config.searchQuery, navigate]
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Search</title>
      </Helmet>

      <div className="w-full w-max-full">
        <ApplicationShell
          bgcolor="bgcolor"
          contained={false}
          navbar={
            <div className="navbar">
              <div className="ml-auto text-sm text-gray-700">
                Having trouble finding data?
                <a
                  className="link ml-1"
                  href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a help session
                  <HiOutlineArrowRight className="inline-block ml-1" />
                </a>
              </div>
            </div>
          }
          noPadding
        >
          <MinervaSearch
            config={config}
            lastRequestBody={lastRequestBody}
            toggleGroupPatientIDs={toggleGroupPatientIDs}
            patientCount={patientCount}
            splitView
            onSubmit={onSubmit}
            setSortPatientIDsBy={setSortPatientIDsBy}
            isInternalPage={false}
            toggleVectorSearch={toggleVectorSearch}
          />
        </ApplicationShell>
      </div>
    </>
  );
};
