import cx from 'classnames';

export type MenuButtonProps = {
  roundLeft?: boolean;
  roundRight?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const MenuButton = ({
  children,
  roundLeft,
  roundRight,
  className,
  disabled,
  ...props
}: MenuButtonProps) => {
  return (
    <button
      type="button"
      className={cx(
        'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-30 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary',
        {
          'rounded-l-md': roundLeft,
          'rounded-r-md': roundRight,
          'opacity-50 cursor-not-allowed': disabled,
        },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
