import amplitude from 'amplitude-js';

const AMPLITUDE_API_KEY = 'd33ee3bc0b8d1cb3fbcec85e68f6fa4e';

export const initAmplitude = ({userId}: {userId?: string}) => {
  const amplitudeConfig = {
    includeReferrer: true,
    includeUtm: true,
  };

  amplitude.getInstance().init(AMPLITUDE_API_KEY, userId, amplitudeConfig);
};

export const amp = () => amplitude.getInstance();

export const ampLog = (eventType: string, eventProperties?: object) => {
  const commonProperies = {
    url: window.location.href,
    app_origin: window.location.origin,
  };
  amp().logEvent(eventType, {...eventProperties, ...commonProperies});
};
