import {useMemo, useState} from 'react';
import {filter} from 'lodash';
import {HiOutlineExclamation} from 'react-icons/hi';
import {Column} from 'react-table';
import {DateTime} from 'luxon';
import {useMutation, useQueryClient} from 'react-query';
import {Modal} from '../../../core/layout/modal';

import {
  DatasetLocation,
  fetchPresignedDatasetLocations,
} from '../../../models/dataset';
import {formatBytes} from '../../../utils/strings';
import {ReactTable} from '../../../core/components/react-table';
import {DatasetLocStatusEnum} from 'src/enums/datasetlocstatusenum';
import {useAxios} from 'src/utils/http';

export const DatasetDownloadTable = ({
  datasetLocations,
  datasetId,
}: {
  datasetLocations: DatasetLocation[];
  datasetId: number;
}) => {
  const queryClient = useQueryClient();
  const api = useAxios();

  const downloadDatasetFilesMut = useMutation(
    (locationIds: number[]) => fetchPresignedDatasetLocations(api, locationIds),
    {
      onSuccess: presignedLocations => {
        presignedLocations.forEach((loc, i) =>
          // eslint-disable-next-line security/detect-non-literal-fs-filename
          setTimeout(() => window.open(loc.presignedURL, '_blank'), 500 * i)
        );
        queryClient.refetchQueries(['datasetLocations', datasetId], {
          exact: true,
        });
      },
    }
  );

  const [enablePopupsModalOpen, enablePopupsModalOpenChange] = useState(false);
  const [modalLocationIds, modalLocationIdsChange] = useState<number[]>();

  const openEnablePopupsModal = (locationIds: number[]) => {
    modalLocationIdsChange(locationIds);
    enablePopupsModalOpenChange(true);
  };

  const closeEnablePopupsModal = () => {
    enablePopupsModalOpenChange(false);
    modalLocationIdsChange(undefined);
  };

  const renderEnablePopupsModal = (modalLocationIds: number[]) => {
    return (
      <div className="flex w-full">
        <HiOutlineExclamation className="text-yellow-600 bg-yellow-100 w-10 h-10 p-2 rounded-full flex-none my-2" />
        <div className="ml-4">
          <div className="text-lg leading-6 font-medium text-gray-900 flex flex-row my-2">
            Enable pop-ups to download all
          </div>
          <div className="text-sm leading-5 font-normal text-gray-500 mb-3">
            Make sure the pop-ups on your browser are enabled. Otherwise, the
            browser may prevent some files from downloading.
          </div>
          <div className="flex flex-row-reverse">
            <button
              className="btn btn-primary"
              onClick={() => {
                downloadDatasetFilesMut.mutate(modalLocationIds);
                closeEnablePopupsModal();
              }}
            >
              I understand; continue downloading
            </button>
          </div>
        </div>
      </div>
    );
  };

  const columns = useMemo<Array<Column<DatasetLocation>>>(
    () => [
      {
        Header: 'Name',
        accessor: 'filename',
      },
      {
        Header: 'Study Count',
        accessor: 'studyCount',
      },
      {
        Header: 'fileCount',
        accessor: 'fileCount',
      },
      {
        Header: 'Size',
        accessor: 'size',
        Cell: ({value}) => {
          return formatBytes(value);
        },
      },
      {
        Header: 'Last Downloaded',
        accessor: 'downloadedAt',
        Cell: ({value}) => {
          return value?.toLocaleString(DateTime.DATE_MED) ?? null;
        },
        sortType: (rowA, rowB) => {
          const a =
            rowA.original.downloadedAt?.toMillis() ?? Number.MAX_SAFE_INTEGER;
          const b =
            rowB.original.downloadedAt?.toMillis() ?? Number.MAX_SAFE_INTEGER;

          if (a > b) {
            return 1;
          }
          if (b > a) {
            return -1;
          }

          return 0;
        },
      },
      {
        id: 'download',
        Header: () => {
          return (
            <>
              <button
                className="btn btn-link nopadding"
                title="Download"
                onClick={() => {
                  openEnablePopupsModal(
                    filter(datasetLocations, {
                      status: DatasetLocStatusEnum.DatasetLocStatusDelivered,
                    }).map(loc => loc.locationID)
                  );
                }}
              >
                Download All
              </button>
            </>
          );
        },
        accessor: 'locationID',
        Cell: cell => {
          return cell.row.original.status ===
            DatasetLocStatusEnum.DatasetLocStatusDelivered ? (
            <button
              className="btn btn-link nopadding"
              title="Download"
              onClick={() => downloadDatasetFilesMut.mutate([cell.value])}
            >
              Download
            </button>
          ) : (
            <span className="text-red-600">Recalled</span>
          );
        },
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datasetLocations]
  );

  const data = useMemo(() => datasetLocations, [datasetLocations]);

  return (
    <>
      <ReactTable
        tableColumns={columns}
        tableData={data}
        defaultSortBy={[{id: 'filename', desc: false}]}
        paginationDisabled={true}
        sortType={'toggle'}
      />
      <Modal
        isOpen={enablePopupsModalOpen}
        onRequestClose={() => closeEnablePopupsModal()}
        className="w-auto max-w-lg"
      >
        {modalLocationIds && renderEnablePopupsModal(modalLocationIds)}
      </Modal>
    </>
  );
};
