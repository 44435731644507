/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-unsafe-regex */
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import _, {
  cloneDeep,
  concat,
  filter,
  flatMap,
  includes,
  map,
  sortBy,
} from 'lodash';
import cx from 'classnames';
import {useQuery, useQueryClient} from 'react-query';
import {
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
  HiOutlineShieldExclamation,
} from 'react-icons/hi';
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {
  DATASET_STAGE_TYPE,
  datasetStatusToStage,
  fetchAllDatasets,
  updateDatasetReports,
} from '../../models/dataset';
import {fetchSnomedFindings} from '../../models/snomed';
import {isInternal} from '../../models/auth';
import {
  DicomSeries,
  fetchDicomStudyInfo,
  getDicomSeries,
} from '../../models/dicom-viewer';

import {Badge} from '../../core/components/badge';
import {Report, reportPHI, truncateStudyID} from '../../models/report';
import {ToastMessage} from '../../core/components/toast';
import {FullReport} from './full-report';
import {validateParentheses} from '../../utils/strings';
import {
  datasetToDatasetTag,
  getAvailableTags,
  updateCachedTags,
  updateStudyTags,
} from '../../models/tags';
import {Tag, TagDropdown} from '../tags';
import {ClassificationDropdown} from './classification-dropdown';
import {Modal} from '../../core/layout/modal';
import {useAuth} from '../../hooks/auth';
import {useAxios} from 'src/utils/http';

export interface ReportSectionBounds {
  [key: string]: ReportSectionBound;
}

export interface ReportSectionBound {
  start: number;
  end: number;
}

export interface ReportSection {
  header: string;
  content: string;
}

export const exRegex = new RegExp('(X){2,}', 'g');

// grabs every instance of text inside parentheses, braces,
// and brackets.

export const parenthesesRegex = new RegExp(
  '\\[([^\\]]+)]|\\(([^\\)]+)\\)|\\{([^\\}]+)\\}',
  'g'
);

let commonHeaders = [
  'history\\s?:',
  'technique\\s?:',
  'comparison\\s?:',
  'findings\\s?:',
  'indication\\s?:',
  'impression\\s?:',
  'signed by\\s?:',
  'signed date\\s?:',
];

const commonPrefixesList = ['other', 'more', 'additional'];

const prefixedHeaders = flatMap(commonHeaders, commonHeader =>
  commonPrefixesList.map(prefix => prefix + ' ' + commonHeader)
);

commonHeaders = concat(commonHeaders, prefixedHeaders);

export const prettyWords: {[key: string]: string} = {
  MONTH: 'Month',
  MMDDYYYY: 'MM/DD/YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  MM: 'MM',
  DD: 'DD',
  YYYY: 'YYYY',
  TIMESTAMP: 'Timestamp',
};

export const specialCases: {[key: string]: JSX.Element} = {
  '\n': (
    <>
      <br />
      <br />
    </>
  ),
};

const renderReportContent = (content: string) => {
  const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  const emTagRegex = /<em>(.*?)<\/em>/g; // Regex to match <em> tags
  const spanAttr =
    'px-2 py-0.5 rounded bg-gray-200 font-normal text-sm mr-1 whitespace-nowrap';

  // Split content into parts with and without <em> tags
  const parts = content.split(emTagRegex);

  return (
    <p className="font-light text-gray-600">
      {parts.map((part, partIndex) => {
        // Render each part, processing <em> tags separately
        if (partIndex % 2 === 1) {
          // Parts with <em> tags
          return (
            <em
              style={{backgroundColor: '#DBEAFE', color: '#2563EB'}}
              key={`em-${partIndex}`}
            >
              {part}
            </em>
          );
        } else {
          // Regular parts
          return (
            <Fragment key={`part-${partIndex}`}>
              {part.split(' ').map((word, wordIndex) => {
                const checkWord = word.replace(punctuationRegex, '');
                if (checkWord.substring(0, 6) === 'segmed') {
                  return (
                    <Fragment key={`segmed-${wordIndex}`}>
                      <span className={spanAttr}>{checkWord.substring(6)}</span>{' '}
                    </Fragment>
                  );
                } else if (prettyWords[checkWord]) {
                  return (
                    <Fragment key={`pretty-${wordIndex}`}>
                      <span className={spanAttr}>{prettyWords[checkWord]}</span>{' '}
                    </Fragment>
                  );
                } else if (specialCases[checkWord]) {
                  return (
                    <Fragment key={`special-${wordIndex}`}>
                      <span>{specialCases[checkWord]}</span>{' '}
                    </Fragment>
                  );
                } else if (checkWord.match(exRegex)) {
                  return (
                    <Fragment key={`ex-${wordIndex}`}>
                      <span className={spanAttr}>{checkWord}</span>{' '}
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={`word-${wordIndex}`}>{word + ' '}</Fragment>
                  );
                }
              })}
            </Fragment>
          );
        }
      })}
    </p>
  );
};

export const ReportSectionComponent = ({section}: {section: ReportSection}) => {
  return (
    <div className="grid grid-cols-1 gap-10">
      <div>
        <h1 className="text-lg capitalize text-gray-900 font-normal mb-3">
          {section.header}
        </h1>
        <div>{renderReportContent(section.content)}</div>
      </div>
    </div>
  );
};

export const ReportComponent = ({
  report,
  reportChanged,
  PHIReported,
  navMessage,
  showPrevButton = false,
  showNextButton = false,
  onPrevButtonClick,
  onNextButtonClick,
  dicomSeries,
  splitViewDataset = false,
  splitViewSearch = false,
  viewDicomUrl,
}: {
  report: Report;
  reportChanged?: (report: Report) => void;
  PHIReported: () => void;
  navMessage?: string;
  showPrevButton?: boolean;
  showNextButton?: boolean;
  onPrevButtonClick?: () => void;
  onNextButtonClick?: () => void;
  dicomSeries?: DicomSeries[];
  splitViewDataset?: boolean;
  splitViewSearch?: boolean;
  viewDicomUrl?: string;
}) => {
  const queryClient = useQueryClient();
  const {authState} = useAuth();
  const http = useAxios();

  type modalType = 'reportPHI';

  const {data: reportDicomsSeries} = useQuery(
    ['reportDicomsSeries', report.studyId],
    async () => {
      const dicomStudyInfo = await fetchDicomStudyInfo(http, report.studyId);
      return getDicomSeries(report.studyId, dicomStudyInfo);
    },
    {
      enabled: !splitViewDataset && !splitViewSearch,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: datasets} = useQuery(
    ['dataset'],
    () =>
      fetchAllDatasets(http).then(datasets => {
        return sortBy(datasets, dataset => {
          const included = includes(report.assignedDatasets, dataset.id)
            ? '0'
            : '1';
          return included + dataset.name;
        });
      }),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: snomed_findings} = useQuery(
    ['snomed_findings', report.studyId],
    () =>
      fetchSnomedFindings(http, report.studyId).then(snomed_findings => {
        return sortBy(snomed_findings, finding => {
          return finding.candidateScore;
        });
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const reportPHIRequest = (studyId: string) => {
    return reportPHI(http, studyId)
      .then(() => {
        toast(
          <ToastMessage title={'PHI Reported Successfully'} icon="success" />
        );
        PHIReported && PHIReported();
      })
      .catch(err => {
        const message = err?.response?.data?.message ?? 'Error reporting PHI';
        toast(<ToastMessage title={message} icon="error" />);
      });
  };

  const [activeModalType, activeModalTypeChange] = useState<modalType>();
  const closeModal = () => activeModalTypeChange(undefined);
  const openModal = (modalType: modalType) => activeModalTypeChange(modalType);

  const reportPHIModal = () => {
    return (
      <Modal
        isOpen={activeModalType === 'reportPHI'}
        onRequestClose={closeModal}
        className="max-w-lg"
      >
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <HiOutlineShieldExclamation className="mr-1 w-5 h-auto text-red-600" />
            <div className="text-lg">Reporting PHI</div>
          </div>
          <div className="text-sm text-gray-500">
            Are you sure you want to report PHI for this study? This study will
            be removed immediately from the platform. Segmed will investigate
            the breach and notify any relevant parties. This action cannot be
            undone.
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <button className="btn btn-white" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              // Submit report as PHI
              report && reportPHIRequest(report.studyId);
              closeModal();
            }}
          >
            Report PHI
          </button>
        </div>
      </Modal>
    );
  };

  const getSections = (): ReportSection[] => {
    const excessDashRegex = new RegExp('-(\\s*-)+', 'g');
    const newLineRegex = '----- Page Break -----';
    const text = report
      .text!.replace(newLineRegex, '\n[Page Break]')
      .replaceAll(excessDashRegex, '');
    let boundaryParseText = text;
    // TODO similar logic as below but for segmed_ PHI words
    Object.keys(prettyWords).forEach(
      prettyWord =>
        (boundaryParseText = boundaryParseText
          .replaceAll(exRegex, match => '*'.repeat(match.length))
          .replaceAll(prettyWord, prettyWord.toLowerCase()))
    );
    commonHeaders.forEach(header => {
      boundaryParseText = boundaryParseText.replace(
        new RegExp(header, 'gi'),
        header.substring(0, header.length - 4).toUpperCase() + ':'
      );
    });

    // unmark potential header content if found within parentheses
    if (validateParentheses(boundaryParseText)) {
      boundaryParseText = boundaryParseText.replaceAll(
        parenthesesRegex,
        match => match.toLowerCase()
      );
    }

    // regex will grab every uppercase sequence of spaces or chars
    // that ends with a colon and can be separated by lowercase
    // conjunctions (and/or)
    const divideRegex = new RegExp(
      '\\b[A-Z](\\bor\\b|\\band\\b|[\\bA-Z /-])*[A-Z]:',
      'g'
    );
    // Array of length-1 arrays
    const sections = Array.from(boundaryParseText.matchAll(divideRegex));
    const boundaries: ReportSectionBounds = {};
    // resolve history conflict by finding the history header which,
    // if the history header includes spilled over uppercase keywords
    // from the report title, will remove them and readjust boundaries
    // accordingly.
    sections.forEach(section => {
      if (section[0].includes('HISTORY:')) {
        section.index! += section[0].length - 'HISTORY:'.length;
        section[0] = 'HISTORY:';
      }
    });

    // fill in section boundaries object
    // iterates through sections, and fills in the ReportSectionBounds object
    // with ReportSection objects: {header, content}.
    // remnantText is the text that did not get assigned to a section, and will
    // be under the catch-all "Info"
    let remnantText = text;
    const duplicateCount: {[key: string]: number} = {};
    for (let i = 0; i < sections.length; i++) {
      const sectionName = sections[i][0];
      let currSectionName = sectionName;
      const sectionNameLength = sectionName.length;
      if (currSectionName in boundaries) {
        if (currSectionName in duplicateCount) {
          duplicateCount[currSectionName] += 1;
        } else {
          duplicateCount[currSectionName] = 2;
        }
        currSectionName =
          currSectionName.substring(0, sectionNameLength - 1) +
          ' (' +
          duplicateCount[currSectionName] +
          '):';
      }
      if (i === sections.length - 1) {
        boundaries[currSectionName] = {
          start: sections[i].index! + sectionNameLength,
          end: text.length,
        };
      } else {
        boundaries[currSectionName] = {
          start: sections[i].index! + sectionNameLength,
          end: sections[i + 1].index!,
        };
      }
      remnantText = remnantText
        .replace(
          text.substring(
            boundaries[currSectionName].start,
            boundaries[currSectionName].end
          ),
          ''
        )
        .replace(new RegExp(sectionName, 'i'), '');
    }

    // returns all data in array of ReportSection objects
    const reportSections: ReportSection[] = Object.entries(boundaries).map(
      ([header, boundary]) => ({
        header: header.toLowerCase().replace(':', ''),
        content: text.slice(boundary.start, boundary.end),
      })
    );
    if (remnantText) {
      reportSections.unshift({header: 'Info', content: remnantText});
    }
    return reportSections;
  };

  const sections: ReportSection[] = [
    ...getSections(),
    {header: 'Raw Report', content: report.text ?? ''},
  ];

  const impressionSection = sections.find(
    section => section.header.replaceAll(' ', '').toLowerCase() === 'impression'
  );

  const {data: tags} = useQuery(['tags'], () => getAvailableTags(http), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const userTags = useMemo(() => {
    return tags?.filter(tag => tag.scope === 'user') ?? [];
  }, [tags]);

  const userStudyTags = useMemo(() => {
    return report.tags?.filter(tag => tag.scope === 'user') ?? [];
  }, [report]);

  const datasetTags = useMemo(() => {
    if (datasets) {
      return datasets
        .filter(dataset => includes(report.assignedDatasets, dataset.id))
        .map(dataset => datasetToDatasetTag(dataset))
        .sort((firstDS, secondDS) => (firstDS.name > secondDS.name ? 1 : -1));
    }
    return [];
  }, [datasets, report.assignedDatasets]);

  const globalTags = useMemo(() => {
    return report.tags?.filter(tag => tag.scope === 'global') ?? [];
  }, [report]);

  return (
    <>
      <div className="grid grid-cols-4 gap-8">
        <div className="col-span-3">
          <div className="text-xs font-normal text-gray-400">
            Study ID: {report.studyId}
          </div>
          {!_.isEmpty(report.patientId) && (
            <div className="text-xs font-normal text-gray-400">
              Patient ID: {report.patientId}
            </div>
          )}
          <div className="text-2xl font-medium font-gray-900 mb-9">
            {report.reportTitle ?? truncateStudyID(report.studyId)}
          </div>
          <div className="grid grid-cols-1 gap-10 mb-9">
            {impressionSection && (
              <div className="bg-gray-50 p-7 shadow rounded-lg">
                <ReportSectionComponent section={impressionSection} />
              </div>
            )}
            <FullReport
              sections={sections.filter(
                section =>
                  section.header.replaceAll(' ', '').toLowerCase() !==
                  'impression'
              )}
            />
          </div>
        </div>

        <div>
          <div>
            {navMessage ? (
              <div className="text-gray-400 text-sm mb-4 italic text-center">
                {navMessage}
              </div>
            ) : (
              (showPrevButton || showNextButton) && (
                <div className="text-gray-400 font-medium text-sm mb-4 flex justify-between">
                  {showPrevButton ? (
                    <div
                      className="flex items-center space-x-2 text-primary cursor-pointer hover:text-primary-active"
                      onClick={() => onPrevButtonClick && onPrevButtonClick()}
                    >
                      <HiOutlineArrowLeft className="w-4 h-4" />
                      <div>Previous</div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {showNextButton ? (
                    <div
                      className="flex items-center space-x-2 text-primary cursor-pointer hover:text-primary-active"
                      onClick={() => onNextButtonClick && onNextButtonClick()}
                    >
                      <div>Next</div>
                      <HiOutlineArrowRight className="w-4 h-4" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )
            )}
            <hr className="border border-gray-200 my-5" />
            <div className="text-gray-400 uppercase font-medium text-sm tracking-wider mb-4 flex items-center justify-between">
              <div>User Tags</div>
              <TagDropdown
                tags={userTags}
                studyIDs={[report.studyId]}
                align="right"
              />
            </div>
            {userStudyTags.length ? (
              <div className="flex flex-row flex-wrap gap-1 text-xs font-medium">
                {userStudyTags.map(tag => (
                  <Tag
                    key={tag.tid}
                    tag={tag}
                    action={() =>
                      updateStudyTags(http, report.studyId, {
                        remove_tags: [tag.tid],
                      })
                        .then(() => {
                          toast(
                            <ToastMessage title="Tag removed" icon="success" />
                          );
                          queryClient.invalidateQueries([
                            'report',
                            report.studyId,
                          ]);

                          updateCachedTags(http, queryClient, [report.studyId]);
                        })
                        .catch(err => {
                          const message =
                            err?.response?.data?.message ??
                            'Error removing tag';
                          toast(<ToastMessage title={message} icon="error" />);
                        })
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="text-gray-400 text-xs font-medium">
                No User Tags
              </div>
            )}

            <hr className="border border-gray-200 my-5" />
            <div className="text-gray-400 uppercase font-medium text-sm tracking-wider mb-4">
              Datasets
            </div>
            <div>
              {datasets &&
                map(datasets, (dataset, i) => {
                  const included = includes(
                    report.assignedDatasets,
                    dataset.id
                  );
                  const mutable =
                    datasetStatusToStage(dataset.status) ===
                    DATASET_STAGE_TYPE.DatasetCreated;
                  let toolTipMessage: string;
                  if (!mutable) {
                    toolTipMessage = 'Dataset already requested and locked';
                  } else if (included) {
                    toolTipMessage = 'Remove from dataset';
                  } else {
                    toolTipMessage = 'Add to dataset';
                  }
                  return (
                    <Badge
                      key={i}
                      type="primary"
                      empty={!included}
                      className={cx('mr-1', {
                        'cursor-pointer': mutable,
                        'cursor-not-allowed': !mutable,
                      })}
                      data-tip={toolTipMessage}
                      onClick={() => {
                        if (mutable) {
                          ReactTooltip.hide();
                          const request = included
                            ? {add: {ids: []}, remove: {ids: [report.studyId]}}
                            : {add: {ids: [report.studyId]}, remove: {ids: []}};
                          updateDatasetReports(
                            http,
                            dataset.id,
                            request,
                            included ? 'remove' : 'add'
                          ).then(() => {
                            const updatedReport = cloneDeep(report);
                            if (included) {
                              updatedReport.assignedDatasets = filter(
                                updatedReport.assignedDatasets,
                                datasetId => datasetId !== dataset.id
                              );
                            } else {
                              updatedReport.assignedDatasets = [
                                ...updatedReport.assignedDatasets!,
                                dataset.id,
                              ];
                            }

                            updateCachedTags(http, queryClient, [
                              report.studyId,
                            ]);

                            queryClient.invalidateQueries([
                              'report',
                              report.studyId,
                            ]);

                            reportChanged && reportChanged(updatedReport);
                          });
                        }
                      }}
                    >
                      {dataset.name}
                    </Badge>
                  );
                })}
            </div>
          </div>
          {false && (
            // @todo Dataset tags hidden on report page until add remove dataset badges are removed
            <>
              <hr className="border border-gray-200 my-5" />
              <div className="text-gray-400 uppercase font-medium text-sm tracking-wider mb-4">
                Dataset Tags
              </div>
              {datasetTags.length ? (
                <div className="flex flex-row flex-wrap gap-1 text-xs font-medium">
                  {datasetTags.map(tag => (
                    <Tag key={tag.tid} tag={tag} />
                  ))}
                </div>
              ) : (
                <div className="text-gray-400 text-xs font-medium">
                  No Dataset Tags
                </div>
              )}
            </>
          )}
          {false && (
            // @todo Global tags for when global tags are added in
            <>
              <hr className="border border-gray-200 my-5" />
              <div className="text-gray-400 uppercase font-medium text-sm tracking-wider mb-4">
                Global Tags
              </div>
              {globalTags.length ? (
                <div className="flex flex-row flex-wrap gap-1 text-xs font-medium">
                  {globalTags
                    .filter(tag => tag.scope === 'global')
                    .map(tag => (
                      <Tag key={tag.tid} tag={tag} />
                    ))}
                </div>
              ) : (
                <div className="text-gray-400 text-xs font-medium">
                  No Global Tags
                </div>
              )}
            </>
          )}
          <hr className="border border-gray-200 my-5" />
          <div className="text-gray-400 uppercase font-medium text-sm tracking-wider mb-4">
            Snomed CT
          </div>
          <div>
            {snomed_findings && snomed_findings.length ? (
              map(snomed_findings, (finding, i) => {
                let score = finding.candidateScore * -1;
                score = score / 10;

                const toolTipMessage = finding.candidateCui;
                return (
                  <Badge
                    key={i}
                    type="info"
                    empty={false}
                    className="mr-1"
                    data-tip={toolTipMessage}
                    onClick={() => {
                      ReactTooltip.hide();
                    }}
                  >
                    {`${score}% ${finding.candidateMatched}`}
                    {finding.negated === '1' && ' [N]'}
                  </Badge>
                );
              })
            ) : (
              <div className="text-gray-400 text-xs font-medium">
                No SNOMED CT Yet
              </div>
            )}
          </div>

          <hr className="border border-gray-200 my-5" />

          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => openModal('reportPHI')}
          >
            <HiOutlineShieldExclamation className="h-5 w-5 inline mr-1 align-middle" />
            Report PHI
          </button>
          {isInternal(authState.profile!.email) && (
            <ClassificationDropdown
              modalityLLM={report.modality_llm}
              studyId={report.studyId}
              bodyPartLLM={report.body_part_llm}
            />
          )}
          {(dicomSeries || reportDicomsSeries) && (
            <>
              <hr className="border border-gray-200 my-5" />
              <Link
                className="btn btn-primary w-auto inline-block"
                to={
                  viewDicomUrl ??
                  (splitViewSearch || splitViewDataset
                    ? `/study/${report.studyId}/dicomViewer`
                    : `${location.pathname}/dicomViewer`)
                }
                target="_blank"
              >
                View DICOMs
              </Link>
            </>
          )}
        </div>
      </div>
      {reportPHIModal()}
    </>
  );
};
