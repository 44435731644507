import React from 'react';
import {Tour} from '../base/tour';

export const SearchTour = ({
  tourActive,
  tourActiveChange,
}: {
  tourActive: boolean;
  tourActiveChange: (tourActive: boolean) => void;
}) => {
  const tourSteps = [
    {
      target: '[data-tour=search-form-step-start-search]',
      content: (
        <div className="m-auto inline-block text-left">
          {
            "Let's start with a quick tour of how to use Segmed Openda's search functionality!"
          }
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '[data-tour=search-form-step-terms]',
      title: 'Enter search term(s)',
      content: (
        <div className="m-auto inline-block text-left">
          <ul className="list-disc">
            <li>
              Note that search also supports Regex for more advanced queries.
            </li>
            <li>
              {
                'For example mass|nodule will search for matches with "mass" or "nodule".'
              }
            </li>
          </ul>
        </div>
      ),
    },
    {
      target: '[data-tour=search-form-step-modality]',
      content: (
        <div className="m-auto inline-block text-left">
          Select a single modality.
        </div>
      ),
    },
    {
      target: '[data-tour=search-form-step-bodyArea]',
      title: 'Select body area(s)',
      content: (
        <div className="m-auto inline-block text-left">
          {"If you don't see the body area you're looking for leave it blank."}
        </div>
      ),
    },
    {
      target: '[data-tour=search-form-step-location]',
      title: 'Select source location(s)',
      content: (
        <div className="m-auto inline-block text-left">
          {"We have more sites that aren't on Openda yet -- email us at "}
          <a href="mailto:support@segmed.ai" className="link">
            support@segmed.ai
          </a>{' '}
          for more information.
        </div>
      ),
    },
    {
      target: '[data-tour=search-form-step-report-impression]',
      title: 'Which part of the report do you want to search through?',
      content: (
        <div className="m-auto inline-block text-left">
          <ul className="list-disc">
            <li>{'Select "Report" to search through the whole report.'}</li>
            <li>
              {
                'Select "Impression" to only search through the summary of the report.'
              }
            </li>
          </ul>
        </div>
      ),
      styles: {
        tooltipTitle: {
          padding: '15px 0 0 0',
        },
      },
    },
    {
      target: '[data-tour=search-form-step-contains]',
      title: 'Refine your search parameters:',
      content: (
        <div className="m-auto inline-block text-left">
          <ul className="list-disc">
            <li>
              Contains - returns all studies containing the specific keyword.
            </li>
            <li>
              Contains (No Negations) - returns all studies containing the
              specified keyword but excludes negations.
            </li>
            <li>
              Excludes - returns studies that do not contain the specified
              keyword.
            </li>
          </ul>
        </div>
      ),
    },
    {
      target: '[data-tour=search-form-step-add]',
      content: (
        <div className="m-auto inline-block text-left">
          Add additional terms to the search if needed.
        </div>
      ),
    },
  ];

  return (
    <Tour
      tourActive={tourActive}
      tourActiveChange={tourActiveChange}
      steps={tourSteps}
    />
  );
};
