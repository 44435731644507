import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import cx from 'classnames';
import {toast} from 'react-toastify';
import _ from 'lodash';

import {trackEvent} from '../../utils/tracking';
import {ToastMessage} from '../../core/components/toast';
import {createDataset} from '../../models/dataset';
import {CSVUpload} from '../file-upload/csv-upload';
import {
  DatasetUpdateRequest,
  updateDatasetReports,
  Dataset,
} from '../../models/dataset';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

interface CreateDatasetFields {
  name: string;
}

export const CreateDatasetForm = ({
  closeModal = () => {},
  selectedStudyIDs = [],
  allowCSVUpload = false,
  created,
}: {
  closeModal?: () => void;
  allowCSVUpload?: boolean;
  selectedStudyIDs?: string[];
  created?: (dataset: Dataset) => void;
}) => {
  const [uploadedCSV, uploadedCSVChange] = useState(false);
  const [csvStudyIDs, csvStudyIDsChange] = useState<string[]>([]);
  const {register, handleSubmit, errors} = useForm<CreateDatasetFields>();

  const queryClient = useQueryClient();
  const http = useAxios();

  const createDatasetMut = useMutation(
    (form: {name: string}) => createDataset(http, form),
    {
      onSuccess: dataset => {
        toast(<ToastMessage title="Dataset created" icon="success" />);
        const updateRequest: DatasetUpdateRequest = {
          add: {
            query: undefined,
            ids: [...csvStudyIDs, ...selectedStudyIDs],
          },
          remove: {
            query: undefined,
            ids: [],
          },
        };

        _.defer(() => {
          created && created(dataset);
          updateDatasetReports(http, dataset.id, updateRequest, 'add');
          if (uploadedCSV) {
            trackEvent('CREATE_DATASET_FROM_CSV', {datasetID: dataset.id});
          }
          queryClient.invalidateQueries(['dataset'], {exact: true});
          queryClient.invalidateQueries(['dataset', dataset.id]);
          queryClient.removeQueries(['dataset']);
          closeModal();
        });
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error creating dataset';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const onSubmit = handleSubmit((form: CreateDatasetFields) => {
    createDatasetMut.mutate(form);
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="text-lg mb-3">New Dataset</div>
      <div className="mb-3">
        <label htmlFor="name" className="input-label">
          Name
        </label>
        <input
          name="name"
          type="text"
          data-cy="CreateDatasetForm_nameInput"
          className={cx('text-input w-full mt-2', {
            'text-input-error': !!errors.name,
          })}
          autoComplete="off"
          ref={register({required: true})}
        />
      </div>
      <div className="flex flex-row-reverse justify-between">
        <button
          className="btn btn-primary max-h-8 ml-2 min-w-max"
          type="submit"
          disabled={createDatasetMut.isLoading}
        >
          Create Dataset
        </button>

        {allowCSVUpload &&
          (uploadedCSV ? (
            <CSVUpload
              onCSVUpload={studyIDs => {
                csvStudyIDsChange(studyIDs);
              }}
            />
          ) : (
            <div
              className="text-blue-700 text-xs underline hover:cursor-pointer"
              onClick={() => {
                uploadedCSVChange(true);
              }}
            >
              Or upload a .csv file
            </div>
          ))}
      </div>
    </form>
  );
};
