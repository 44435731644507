// Code generated by "typescripter -type=DatasetStatus -linecomment -output=../../portal/src/enums/datasetstatusenum.ts"; DO NOT EDIT.

export enum DatasetStatusEnum {
  DatasetStatusDatasetCreated = 'dataset_created',
  DatasetStatusOrderCanceled = 'order_canceled',
  DatasetStatusMetadataCustRequested = 'metadata_cust_requested',
  DatasetStatusMetadataCustRequestedBlocked = 'metadata_cust_requested_blocked',
  DatasetStatusMetadataDPRequested = 'metadata_dp_requested',
  DatasetStatusMetadataReady = 'metadata_ready',
  DatasetStatusSamplesPacking = 'samples_packing',
  DatasetStatusSamplesPacked = 'samples_packed',
  DatasetStatusSampleEvaluating = 'samples_evaluating',
  DatasetStatusSampleEvaluated = 'samples_evaluated',
  DatasetStatusSamplesScanning = 'samples_scanning',
  DatasetStatusSamplesScanned = 'samples_scanned',
  DatasetStatusSamplesClean = 'samples_clean',
  DatasetStatusSamplesPHIDetected = 'samples_phi_detected',
  DatasetStatusSamplesDelivered = 'samples_delivered',
  DatasetStatusMetadataEmailSent = 'metadata_email_sent',
  DatasetStatusDatasetOrdered = 'dataset_ordered',
  DatasetStatusOrderDisapproved = 'order_dispproved',
  DatasetStatusSamplesRecalled = 'samples_recalled',
  DatasetStatusQuotesSent = 'quotes_sent',
  DatasetStatusQuotesSigned = 'quotes_signed',
  DatasetStatusDatasetPacking = 'dataset_packing',
  DatasetStatusDatasetPacked = 'dataset_packed',
  DatasetStatusDatasetEvaluating = 'dataset_evaluating',
  DatasetStatusDatasetEvaluated = 'dataset_evaluated',
  DatasetStatusDatasetScanning = 'dataset_scanning',
  DatasetStatusDatasetScanned = 'dataset_scanned',
  DatasetStatusDatasetClean = 'dataset_clean',
  DatasetStatusDatasetPHIDetected = 'dataset_phi_detected',
  DatasetStatusDatasetDelivered = 'dataset_delivered',
  DatasetStatusDeliveryEmailSent = 'delivery_email_sent',
  DatasetStatusDatasetRecalled = 'dataset_recalled',
  DatasetStatusOrderFullfilled = 'order_fullfilled',
  DatasetStatusMetadataStalled = 'metadata_stalled',
}
