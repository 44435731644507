import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {HiFilter, HiRefresh, HiX} from 'react-icons/hi';
import _ from 'lodash';
import {SearchIcon} from '@heroicons/react/solid';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {MdDragIndicator} from 'react-icons/md';

import {DicomHeader, fetchDICOMHeaders} from '../../models/dicom-header';
import {Modal} from '../../core/layout/modal';
import {ModalityModal} from '../modality-modal';
import {useAxios} from 'src/utils/http';

export const DicomHeadersModal = ({
  isOpen,
  visibleDicomHeaders,
  onVisibleDicomHeadersChange,
  onRequestClose,
}: {
  isOpen: boolean;
  visibleDicomHeaders: string[];
  onVisibleDicomHeadersChange: (visibleDicomHeaders: string[]) => void;
  onRequestClose: () => void;
}) => {
  const [unsavedDicomHeaders, unsavedDicomHeadersChange] =
    useState<string[]>(visibleDicomHeaders);
  const http = useAxios();

  const [modalityDicomHeaderModalOpen, modalityDicomHeaderModalOpenChange] =
    useState<boolean>(false);
  const [modalityDicomHeaders, modalityDicomHeadersChange] = useState<string[]>(
    ['CT', 'MR', 'Xray', 'Other']
  );

  const {data: dicomHeaders} = useQuery(
    ['dicomHeaders'],
    () => fetchDICOMHeaders(http),
    {
      staleTime: Infinity,
    }
  );

  const onApply = () => {
    onVisibleDicomHeadersChange(unsavedDicomHeaders);
    onRequestClose();
  };

  const onSelectHeader = (header: string, checked: boolean) => {
    if (checked) {
      unsavedDicomHeadersChange([...unsavedDicomHeaders, header]);
    } else {
      unsavedDicomHeadersChange(
        _.filter(unsavedDicomHeaders, h => h !== header)
      );
    }
  };

  // const dicomHeadersArray: DicomHeader[] = dicomHeaders as DicomHeader[];

  const [headerFilter, headerFilterChange] = useState('');
  const headerAppliesToModalities = (
    header: DicomHeader,
    modalities: string[]
  ) => {
    return modalities.some((modality: string) => {
      if (modality === 'Other') {
        return (
          header.modality.indexOf('CT') === -1 &&
          header.modality.indexOf('MR') === -1 &&
          header.modality.indexOf('Xray') === -1
        );
      }
      return header.modality.indexOf(modality) !== -1;
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newHeaders = [...unsavedDicomHeaders];
    const [removed] = newHeaders.splice(result.source.index, 1);
    newHeaders.splice(result.destination.index, 0, removed);
    unsavedDicomHeadersChange(newHeaders);
  };

  const dicomHeadersFiltered = (dicomHeaders as DicomHeader[]).filter(
    header =>
      headerAppliesToModalities(header, modalityDicomHeaders) &&
      (header.header.toLowerCase().includes(headerFilter.toLowerCase()) ||
        header.tag.toLowerCase().includes(headerFilter.toLowerCase()) ||
        header.description.toLowerCase().includes(headerFilter.toLowerCase()) ||
        headerFilter === '')
  );

  const groupedDicomHeadersFiltered = _.groupBy(dicomHeadersFiltered, 'group');
  return (
    <>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        hideExitButton
        padding={false}
        onRequestClose={onRequestClose}
        className="w-lg"
      >
        <div className="flex flex-col">
          <div className="flex-grow flex">
            <div className="flex-grow flex flex-col max-h-md pt-4 border-r border-gray-200">
              <div className="border-b border-gray-200 px-4 pb-4 space-y-4 shadow-sm">
                <div className="text-xl">Manage Columns</div>
                <div className="text-l flex space-x-4">
                  <label
                    htmlFor="headerFilter"
                    className="flex-grow block relative"
                  >
                    <div className="absolute pl-3 inset-y-0 flex items-center">
                      <SearchIcon className="h-6 w-6 text-gray-300 inline-block" />
                    </div>
                    <input
                      id="headerFilter"
                      name="headerFilter"
                      type="text"
                      className="text-input w-full text-gray-700 inline pl-10 mr-4"
                      value={headerFilter}
                      onChange={event => headerFilterChange(event.target.value)}
                    />
                  </label>
                  <button
                    className="btn btn-white inline"
                    onClick={() => modalityDicomHeaderModalOpenChange(true)}
                  >
                    <HiFilter
                      className="h-4 w-4 mr-2 inline"
                      aria-hidden="true"
                    />
                    Filter by modality
                  </button>
                </div>
              </div>
              <div className="flex-grow overflow-y-auto p-4">
                {Object.entries(groupedDicomHeadersFiltered).map(
                  ([group, headers]) => (
                    <div key={group}>
                      <div className="py-2">{group}</div>
                      <div className="grid grid-cols-3 gap-4">
                        {headers.map(header => (
                          <div key={header.header} className="flex space-x-2">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              id={'checkbox_dicom_header_' + header.header}
                              checked={_.includes(
                                unsavedDicomHeaders,
                                header.header
                              )}
                              onChange={e =>
                                onSelectHeader(header.header, e.target.checked)
                              }
                            />
                            <label
                              htmlFor={'checkbox_dicom_header_' + header.header}
                              className="flex flex-col flex-grow space-y-1"
                            >
                              <div className="text-xs font-medium">
                                {header.description}
                              </div>
                              <div className="text-xs text-gray-500">
                                {header.tag}
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="w-1/4 flex flex-col max-h-md">
              <div className="bg-gray-50 p-4">
                {unsavedDicomHeaders.length} of{' '}
                {(dicomHeaders as DicomHeader[]).length} Selected
                <p className="text-sm text-gray-500">
                  These will appear as columns in the data table. Drag to
                  reorder.
                </p>
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div
                      className="p-4 flex flex-col space-y-2 overflow-y-auto"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {_.chain(unsavedDicomHeaders)
                        .map(header => _.find(dicomHeaders, {header}))
                        .compact()
                        .map((dicomHeader, index) => (
                          <Draggable
                            draggableId={dicomHeader.header}
                            key={dicomHeader.header}
                            index={index}
                          >
                            {provided => (
                              <div
                                key={dicomHeader.header}
                                className="rounded bg-blue-50 text-blue-700 flex p-2 text-xs"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="my-auto mr-2">
                                  <MdDragIndicator className="h-5 w-5 font-normal text-blue-200" />
                                </div>
                                <div className="flex flex-col flex-grow space-y-1">
                                  <div className="font-medium">
                                    {dicomHeader.description}
                                  </div>
                                  <div>{dicomHeader.tag}</div>
                                </div>
                                <div className="flex flex-col justify-center">
                                  <button
                                    onClick={() =>
                                      onSelectHeader(dicomHeader.header, false)
                                    }
                                  >
                                    <HiX
                                      className="h-4 w-4 mr-2 text-blue-500"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))
                        .value()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <div className="flex justify-between flex-shrink-0 bg-gray-50 py-2 px-4">
            <div>
              <button
                className="btn btn-white"
                onClick={() => unsavedDicomHeadersChange([])}
              >
                <HiRefresh className="h-4 w-4 mr-2" aria-hidden="true" />
                Restore to default
              </button>
            </div>
            <div className="space-x-2">
              <button
                className="btn btn-white inline-block"
                onClick={onRequestClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary inline-block"
                onClick={onApply}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {modalityDicomHeaderModalOpen && (
        <ModalityModal
          isOpen
          onRequestClose={() => modalityDicomHeaderModalOpenChange(false)}
          modalityDicomHeaders={modalityDicomHeaders}
          onModalityDicomHeadersChange={newModalityDicomHeaders => {
            modalityDicomHeadersChange(newModalityDicomHeaders);
            localStorage.setItem(
              'modality_dicom_header_columns',
              JSON.stringify(newModalityDicomHeaders)
            );
          }}
        />
      )}
    </>
  );
};
