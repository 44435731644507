import {useMemo} from 'react';
import {every, includes, some, map} from 'lodash';
import {Column, Row} from 'react-table';
import {Link} from 'react-router-dom';

import {TextFilter, ReactTable} from '../../../core/components/react-table';
import {EvalonErrorResult} from '../../../models/evalon';

export const EvalonReportErrorResultsTable = ({
  evalonErrorResults,
  selected = [],
  onSelect,
  datasetID,
}: {
  evalonErrorResults: EvalonErrorResult[];
  selected: string[];
  onSelect: (studyIds: string[], checked: boolean) => void;
  datasetID: number;
}) => {
  const columns = useMemo<Array<Column<EvalonErrorResult>>>(
    () => [
      {
        id: 'selected',
        width: 65,
        Header: props => {
          const {filteredRows} = props;
          const selectedState = every(filteredRows, row =>
            includes(selected, row.original.studyID)
          )
            ? 'all'
            : some(filteredRows, row =>
                includes(selected, row.original.studyID)
              )
            ? 'indeterminate'
            : 'none';
          return (
            <div className="inline">
              <input
                type="checkbox"
                className="checkbox-input"
                checked={includes(['all', 'indeterminate'], selectedState)}
                ref={el =>
                  el && (el.indeterminate = selectedState === 'indeterminate')
                }
                onChange={e =>
                  onSelect(
                    map(filteredRows, row => row.original.studyID),
                    e.target.checked
                  )
                }
              />
            </div>
          );
        },
        accessor: row => {
          return includes(selected, row.studyID);
        },
        Cell: ({row}: {value: boolean; row: Row<EvalonErrorResult>}) => {
          const studyID = row.original.studyID;
          const included = includes(selected, studyID);
          return (
            <>
              <input
                type="checkbox"
                className="checkbox-input"
                checked={included}
                onChange={e => onSelect([studyID], e.target.checked)}
              />
            </>
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Study Info',
        columns: [
          {
            Header: 'Study ID',
            accessor: 'studyID',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({row}: {row: Row<EvalonErrorResult>}) => (
              <Link
                to={`/admin/datasets/review/${datasetID}/report/${row.original.studyID}/nerphiError`}
                target="_blank"
                rel="noreferrer"
                className="text-primary hover:text-primary-active"
              >
                {row.original.studyID}
              </Link>
            ),
          },
        ],
      },
      {
        Header: 'Evalon Result',
        columns: [
          {
            Header: 'Evaluator Name',
            accessor: 'evalName',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Error Message',
            accessor: 'message',
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
    ],
    [onSelect, selected, datasetID]
  );

  const data = useMemo(() => evalonErrorResults, [evalonErrorResults]);

  const renderShowCount = (
    pageSize: number,
    pageIndex: number,
    rowsLength: number
  ) => {
    return (
      <div className="mt-4 mb-2 text-xl flex items-center">
        Non Evaluated Reports and Report Error Results{' '}
        <div className="ml-2 text-gray-500 text-base">
          (Showing {(pageSize * pageIndex + 1).toLocaleString('en-US')}-
          {Math.min(pageSize * (pageIndex + 1), rowsLength).toLocaleString(
            'en-US'
          )}{' '}
          of {rowsLength.toLocaleString('en-US')})
        </div>
      </div>
    );
  };

  return evalonErrorResults.length > 0 ? (
    <ReactTable
      tableColumns={columns}
      tableData={data}
      renderCustomShownCount={renderShowCount}
      defaultSortBy={[{id: 'studyID', desc: true}]}
    />
  ) : (
    <div className="text-xl mt-4 mb-2">
      No Report Non Evaluated or Error Results
    </div>
  );
};
