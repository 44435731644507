// File copied from generated output
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../config/engine.json';
import _ from 'lodash';

/**
 * This file abstracts most logic around the configuration of the Reference UI.
 *
 * Configuration is an important part of the "reusability" and "generic-ness" of
 * the Reference UI, but if you are using this app as a starting point for own
 * project, everything related to configuration can largely be thrown away. To
 * that end, this file attempts to contain most of that logic to one place.
 */

export function getConfig(): any {
  const res = _.cloneDeep(config);
  if (process.env.NODE_ENV === 'test') {
    return {};
  }

  if (config.engineName) {
    if (!_.isEmpty(process.env.REACT_APP_API_URL)) {
      res.endpointBase = process.env.REACT_APP_API_URL + res.endpointBase;
      res.endpointBaseAppSearch =
        process.env.REACT_APP_API_URL + res.endpointBaseAppSearch;
      res.endpointBaseInternal =
        process.env.REACT_APP_API_URL + res.endpointBaseInternal;
      res.endpointBaseAppSearchInternal =
        process.env.REACT_APP_API_URL + res.endpointBaseAppSearchInternal;
    }

    return res;
  }

  return {};
}

function toLowerCase(string: string) {
  if (string) return string.toLowerCase();
  return '';
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTitleField() {
  // If no title field configuration has been provided, we attempt
  // to use a "title" field, if one exists
  return getConfig().titleField || 'title';
}

export function getUrlField() {
  return getConfig().urlField;
}

export function getThumbnailField() {
  return getConfig().thumbnailField;
}

export function getFacetFields() {
  return getConfig().facets || [];
}

export function getSortFields() {
  return getConfig().sortFields || [];
}

export function getResultTitle(result: {getSnippet: (arg0: any) => any}) {
  const titleField = getTitleField();

  return result.getSnippet(titleField);
}

// Because if a field is configured to display as a "title", we don't want
// to display it again in the fields list
export function stripUnnecessaryResultFields(resultFields: {[x: string]: any}) {
  return Object.keys(resultFields).reduce((acc: any, n) => {
    if (
      [
        '_meta',
        'id',
        toLowerCase(getTitleField()),
        toLowerCase(getUrlField()),
        toLowerCase(getThumbnailField()),
      ].includes(toLowerCase(n))
    ) {
      return acc;
    }

    acc[n] = resultFields[n];
    return acc;
  }, {});
}

export function buildSearchOptionsFromConfig() {
  const config = getConfig();
  const searchFields = (config.searchFields || config.fields || []).reduce(
    (acc: {[x: string]: {}}, n: string | number) => {
      acc = acc || {};
      acc[n] = {};
      return acc;
    },
    undefined
  );

  const resultFields = (config.resultFields || config.fields || []).reduce(
    (
      acc: {
        [x: string]: {raw: {}; snippet?: {size: number; fallback: boolean}};
      },
      n: string | number
    ) => {
      acc = acc || {};

      // Account for nested fields
      if (`${n}`.indexOf('.') !== -1) {
        acc[n] = {
          raw: {},
        };
      } else {
        acc[n] = {
          raw: {},
          snippet: {
            size: 200,
            fallback: true,
          },
        };
      }
      return acc;
    },
    undefined
  );

  // We can't use url, thumbnail, or title fields unless they're actually
  // in the reuslts.
  if (config.urlField) {
    resultFields[config.urlField] = {
      raw: {},
      snippet: {
        size: 100,
        fallback: true,
      },
    };
  }

  if (config.thumbnailField) {
    resultFields[config.thumbnailField] = {
      raw: {},
      snippet: {
        size: 100,
        fallback: true,
      },
    };
  }

  if (config.titleField) {
    resultFields[config.titleField] = {
      raw: {},
      snippet: {
        size: 1000,
        fallback: true,
      },
    };
  }

  const searchOptions: any = {};
  searchOptions.result_fields = resultFields;
  searchOptions.search_fields = searchFields;
  return searchOptions;
}

export function buildFacetConfigFromConfig() {
  const config = getConfig();

  const facets = (config.facets || []).reduce(
    (
      acc: {[x: string]: {type: string; size?: number; ranges?: any}},
      n: string | number
    ) => {
      acc = acc || {};
      if ((config.rangeFacets || []).indexOf() >= 0) {
        // range facet
        acc[n] = {
          type: 'range',
          ranges: [{from: 0, to: 100, name: '0-100'}],
        };
      } else {
        // value facet
        acc[n] = {
          type: 'value',
          size: 200,
        };
      }

      return acc;
    },
    undefined
  );

  return facets;
}

export function buildDisjunctiveFacetsConfigFromConfig() {
  const config = getConfig();
  return config.disjunctiveFacets;
}

export function buildSortOptionsFromConfig() {
  const config = getConfig();
  return [
    {
      // Default sort option with relevance based on score and consistent ordering by `study_id.keyword`
      name: 'Relevance',
      value: [
        {field: '_score', direction: 'desc'}, // Primary sort by score
        {field: 'study_id.keyword', direction: 'asc'}, // Secondary sort by study ID
      ],
    },
    // Generate dynamic sort options based on configuration
    ...(config.sortFields || []).reduce(
      (
        acc: {
          name: string;
          value: {field: string; direction: 'asc' | 'desc'}[];
        }[],
        sortField: string
      ) => {
        // For each field specified in config, create ascending and descending sort options
        acc.push({
          name: `${capitalizeFirstLetter(
            _.replace(sortField.split('.')[0], '_', ' ')
          )} ASC`,
          value: [
            {
              field: sortField,
              direction: 'asc',
            },
            {
              field: 'study_id.keyword', // Secondary sort by `study_id.keyword` for consistency
              direction: 'asc',
            },
          ],
        });
        acc.push({
          name: `${capitalizeFirstLetter(
            _.replace(sortField.split('.')[0], '_', ' ')
          )} DESC`,
          value: [
            {
              field: sortField,
              direction: 'desc',
            },
            {
              field: 'study_id.keyword', // Secondary sort by `study_id.keyword` for consistency
              direction: 'asc',
            },
          ],
        });
        return acc;
      },
      []
    ),
  ];
}

export function buildAutocompleteQueryConfig() {
  const querySuggestFields = getConfig().querySuggestFields;
  if (
    !querySuggestFields ||
    !Array.isArray(querySuggestFields) ||
    querySuggestFields.length === 0
  ) {
    return {};
  }

  return {
    suggestions: {
      types: {
        documents: {
          fields: getConfig().querySuggestFields,
        },
      },
    },
  };
}
