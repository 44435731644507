import {DateTime} from 'luxon';
import {AxiosInstance, AxiosResponse} from 'axios';

export interface FileUploadLocation {
  uflId: number;
  userId: number;
  location: string;
  filename: string;
  uploadId?: string;
  size: number;
  status: string;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface FileUploadLocationResp {
  ufl_id: number;
  user_id: number;
  location: string;
  filename: string;
  upload_id?: string;
  size: number;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface FileUploadPresignedReq {
  filename: string;
  size: number;
}

interface PresignedURLPartResp {
  part_number: number;
  part_size: number;
  presigned_url: string;
  upload_id: string;
}

export interface PresignedURLPart {
  partNumber: number;
  partSize: number;
  presignedURL: string;
  uploadID: string;
}

export interface FileUploadPresignedUrl {
  fileUploadLocation: FileUploadLocation;
  presignedURLParts?: PresignedURLPart[];
  presignedURL?: string;
}

interface FileUploadPresignedUrlResp {
  file_upload_location: FileUploadLocationResp;
  presigned_url_parts?: PresignedURLPartResp[];
  presigned_url?: string;
}

export interface FileUploadCompletedPart {
  etag: string;
  partNumber: number;
}

export const responseToPresignedURLPart = (
  presignedURLPartResp: PresignedURLPartResp
): PresignedURLPart => {
  return {
    partNumber: presignedURLPartResp.part_number,
    partSize: presignedURLPartResp.part_size,
    presignedURL: presignedURLPartResp.presigned_url,
    uploadID: presignedURLPartResp.upload_id,
  };
};

export const responseToFileUploadPresignedUrl = (
  fileUploadPresignedUrlResp: FileUploadPresignedUrlResp
): FileUploadPresignedUrl => {
  return {
    fileUploadLocation: responseToFileUploadLocation(
      fileUploadPresignedUrlResp.file_upload_location
    ),
    presignedURLParts: fileUploadPresignedUrlResp.presigned_url_parts?.map(
      partResp => responseToPresignedURLPart(partResp)
    ),
    presignedURL: fileUploadPresignedUrlResp.presigned_url,
  };
};

export const responseToFileUploadLocation = (
  fileUploadLocationResp: FileUploadLocationResp
): FileUploadLocation => {
  return {
    uflId: fileUploadLocationResp.ufl_id,
    userId: fileUploadLocationResp.user_id,
    location: fileUploadLocationResp.location,
    filename: fileUploadLocationResp.filename,
    uploadId: fileUploadLocationResp.upload_id,
    size: fileUploadLocationResp.size,
    status: fileUploadLocationResp.status,
    createdAt: DateTime.fromISO(fileUploadLocationResp.created_at),
    updatedAt: DateTime.fromISO(fileUploadLocationResp.updated_at),
  };
};

//update file statuses in backend table
export const updateUploadedFileStatus = (http: AxiosInstance) => {
  return http
    .put('v1/data_partner/files')
    .then((response: AxiosResponse<FileUploadLocationResp[]>) => {
      const {data} = response;
      return data.map((fileUploadLocation: FileUploadLocationResp) => {
        return responseToFileUploadLocation(fileUploadLocation);
      });
    });
};

export const deleteUploadedFile = (
  http: AxiosInstance,
  uflIds: number[]
): Promise<AxiosResponse<void>> => {
  return http.delete('v1/data_partner/files', {params: {fileIds: uflIds}});
};

export const getPresignedUrls = (
  http: AxiosInstance,
  files: FileUploadPresignedReq[]
) => {
  if (files.length === 0) {
    return Promise.reject();
  }

  return http
    .post('v1/data_partner/upload', files)
    .then((response: AxiosResponse<FileUploadPresignedUrlResp[]>) => {
      const {data} = response;
      return data.map((presignedUrl: FileUploadPresignedUrlResp) => {
        return responseToFileUploadPresignedUrl(presignedUrl);
      });
    });
};

export const completeMultipartUpload = (
  http: AxiosInstance,
  uploadID: string,
  completedParts: FileUploadCompletedPart[]
) => {
  return http.put(
    `v1/data_partner/upload/${uploadID}/complete`,
    completedParts
  );
};

export const abortMultipartUpload = (http: AxiosInstance, uploadID: string) => {
  return http.put(`v1/data_partner/upload/${uploadID}/abort`);
};
