import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from 'react';

import {cloneDeep, union, filter, includes} from 'lodash';

// Interfaces
export interface DatasetReviewState {
  id?: string;
  reports: {ids: string[]};
  dicoms: {ids: string[]};
}

export enum DatasetReviewEventType {
  RESET = 'RESET',
  SELECT = 'SELECT',
  UNSELECT = 'UNSELECT',
}

export type DatasetReviewEvent =
  | {
      type: DatasetReviewEventType.SELECT | DatasetReviewEventType.UNSELECT;
      payload: {sopIDs?: string[]; studyIDs?: string[]};
    }
  | {
      type: DatasetReviewEventType.RESET;
      payload?: {id: string};
    };

// Context

const datasetReviewStateInitial: DatasetReviewState = {
  reports: {ids: []},
  dicoms: {ids: []},
};

const DatasetReviewContext = createContext({
  datasetReviewState: cloneDeep(datasetReviewStateInitial),
  datasetReviewDispatch: null as unknown as React.Dispatch<DatasetReviewEvent>,
});

const useDatasetReview = () => {
  return useContext(DatasetReviewContext);
};

const datasetReviewReducer: Reducer<DatasetReviewState, DatasetReviewEvent> = (
  datasetReviewState: DatasetReviewState,
  action: DatasetReviewEvent
) => {
  switch (action.type) {
    case DatasetReviewEventType.RESET: {
      return {
        ...cloneDeep(datasetReviewStateInitial),
        id: action.payload?.id,
      };
    }
    case DatasetReviewEventType.SELECT: {
      return {
        ...datasetReviewState,
        reports: {
          ids: union(datasetReviewState.reports.ids, action.payload.studyIDs),
        },
        dicoms: {
          ids: union(datasetReviewState.dicoms.ids, action.payload.sopIDs),
        },
      };
    }
    case DatasetReviewEventType.UNSELECT: {
      return {
        ...datasetReviewState,
        reports: {
          ids: filter(
            datasetReviewState.reports.ids,
            id => !includes(action.payload.studyIDs, id)
          ),
        },
        dicoms: {
          ids: filter(
            datasetReviewState.dicoms.ids,
            id => !includes(action.payload.sopIDs, id)
          ),
        },
      };
    }
    default:
      // Shouldn't reach here
      // @todo: Log error
      return datasetReviewState;
  }
};

// Provider / Consumer

const DatasetReviewProvider = ({children}: {children: ReactNode}) => {
  const [datasetReviewState, datasetReviewDispatch] = useReducer(
    datasetReviewReducer,
    datasetReviewStateInitial
  );

  const value = {datasetReviewState, datasetReviewDispatch};

  return (
    <DatasetReviewContext.Provider value={value}>
      {children}
    </DatasetReviewContext.Provider>
  );
};

const DatasetReviewConsumer = DatasetReviewContext.Consumer;

export {DatasetReviewProvider, DatasetReviewConsumer, useDatasetReview};
