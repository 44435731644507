import _ from 'lodash';
import {Badge} from '../../core/components/badge';
import {Keyword} from '../../models/search';

export const renderCompactKeywords = (keywords: Keyword[]) => {
  keywords = _.chain(keywords)
    .filter(keyword => !_.isEmpty(_.trim(keyword.text)))
    .value();

  const getKeywordLabel = (keyword: Keyword) => {
    if (keyword.operator === 'excludes substring') {
      return `excludes "${_.trim(keyword.text)}"`;
    } else if (keyword.operator === 'contains substring') {
      return `contains "${_.trim(keyword.text)}"`;
    }
    return `contains "${_.trim(keyword.text)}" (w/o negations)`;
  };

  const keywordOperatorIsIncluded = (keyword: Keyword) => {
    return keyword.operator !== 'excludes substring';
  };

  return (
    <div className="inline-block">
      {keywords.length === 0 && <span className="italic">none</span>}
      {_.times(keywords.length - 2, i => (
        <span key={i} className="mx-1">
          (
        </span>
      ))}
      {keywords.map((keyword, i) => (
        <span key={i}>
          <Badge
            type={keywordOperatorIsIncluded(keyword) ? 'success' : 'danger'}
            rounded="rounded"
            className="mx-1"
          >
            report {getKeywordLabel(keyword)}
          </Badge>
          {i < keywords.length - 1 && <span>{i > 0 && ')'} and</span>}
        </span>
      ))}
    </div>
  );
};
