import React, {ReactNode, useEffect} from 'react';
import {includes} from 'lodash';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';

import {useAuth} from '../../hooks/auth';
import {isInternal, isTerminalCustomer} from '../../models/auth';
import {useAxios} from 'src/utils/http';
import {checkExternalUserLoginStatus} from 'src/models/kill-switch';
import {useQuery} from 'react-query';
import {KillSwitchPage} from './kill-switch-page';

export const AuthCheck = ({
  requireLogin = true,
  redirectPath = '/login',
  allowed,
  children,
}: {
  requireLogin?: boolean;
  redirectPath?: string;
  allowed?: (
    | 'customer'
    | 'data_partner'
    | 'internal'
    | 'admin'
    | 'terminalCustomer'
  )[];
  children: ReactNode;
}) => {
  const http = useAxios();
  const navigate = useNavigate();
  const {user, isLoading, isAuthenticated} = useAuth0();
  const {authState} = useAuth();
  const isLiveTest = process.env.REACT_APP_LIVE_TEST === 'true';

  const {data: killswitchStatus, isLoading: killswitchStatusIsLoading} =
    useQuery(['killswitch'], () => checkExternalUserLoginStatus(http), {
      keepPreviousData: true,
      staleTime: Infinity,
    });

  useEffect(() => {
    if (requireLogin && !isLoading && !isAuthenticated && !isLiveTest) {
      // possible race condition bw isLoding / isAuthenticated
      setTimeout(() => {
        if (!isAuthenticated) {
          navigate('/login');
        }
      }, 1000);
    }
  }, [isLoading, isAuthenticated]);
  /*if (requireLogin && !isLoading && !isAuthenticated && !isLiveTest) {
    return <Navigate to="/login" />;
  }*/

  if (!isInternal(user?.email ?? '')) {
    if (killswitchStatusIsLoading) {
      return <></>;
    } else if ((killswitchStatus as any)?.data.logins_disabled) {
      return <KillSwitchPage />;
    }
  }

  if ((!authState.loaded || isLoading) && !isLiveTest) {
    // Auth is loading
    return <></>;
  }

  if (
    isLiveTest ||
    (allowed &&
      isAuthenticated &&
      ((includes(allowed, 'internal') && isInternal(user?.email ?? '')) ||
        (includes(allowed, 'terminalCustomer') &&
          isTerminalCustomer(user?.email ?? ''))))
  ) {
    return <>{children}</>;
  }

  if (
    !isLiveTest &&
    !isLoading &&
    (requireLogin !== authState.loggedIn ||
      (allowed &&
        !(
          includes(allowed, authState.profile?.userType) ||
          (includes(allowed, 'internal') && isInternal(user?.email ?? '')) ||
          (includes(allowed, 'admin') && authState.profile?.admin)
        )))
  ) {
    return <Navigate to={redirectPath} />;
  }

  return <>{children}</>;
};
