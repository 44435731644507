import {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import cx from 'classnames';
import {map} from 'lodash';

import {MenuButton, MenuButtonProps} from './menu-button';
import {MenuItem, MenuItemProps} from './menu-item';

export const MenuDropdown = ({
  buttons,
  menuButtonDisabled,
  menuItems,
  align = 'right',
}: {
  buttons: MenuButtonProps[];
  menuButtonDisabled?: boolean;
  menuItems: MenuItemProps[];
  align?: 'left' | 'right';
}) => {
  return (
    <span className="relative inline-flex shadow-sm rounded-md">
      {map(buttons, (menuButton, buttonIndex) => (
        <Fragment key={buttonIndex}>
          <MenuButton roundLeft={buttonIndex === 0} {...menuButton} />
        </Fragment>
      ))}
      <Menu as="span" className="block -ml-px">
        <Menu.Button
          disabled={menuButtonDisabled}
          className="relative inline-flex items-center btn btn-white rounded-l-none px-2"
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon
            className="h-5 w-5"
            aria-hidden="true"
            strokeWidth="4"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={cx(
              'absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30',
              {
                'origin-top-right left-0 -ml-1': align === 'left',
                'origin-top-left right-0 -mr-1': align === 'right',
              }
            )}
          >
            <div className="py-1">
              {map(menuItems, (menuItem, buttonIndex) => (
                <MenuItem key={buttonIndex} {...menuItem} />
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  );
};
