/* eslint-disable security/detect-object-injection */
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {fetchStats, StatData} from '../../models/stats';
import {Pie} from 'react-chartjs-2';
import {formatNumber, formatNumberWithSuffix} from '../../utils/strings';
import {ApplicationShell} from '../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const StatsPage = () => {
  const api = useAxios();

  const randomRGB = (total: number, start: number, l: number) => {
    const colors = new Array<string>(total);
    for (let i = 0; i < total; i++) {
      colors[i] =
        'hsl(' +
        (((((i + start) * 2) % 30) * (360 / 30)) % 360) +
        ',95%,' +
        l +
        '%)';
    }
    return colors;
  };

  const cleanPieData = (data: StatData[]) => {
    data.sort((a, b) => (a.stat > b.stat ? -1 : 1));
    if (data.length < 14) {
      return data;
    }

    const slice = data.slice(14);
    const start = data.slice(0, 14);
    const remainingNumber = slice.reduce(
      (sum, current) => sum + current.stat,
      0
    );
    start.push({
      label: 'Remaining',
      stat: remainingNumber,
    });
    return start;
  };

  const {data: stats} = useQuery(['stats'], () => fetchStats(api), {
    staleTime: Infinity,
  });

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    plugins: {
      legend: {
        position: 'left' as const,
        labels: {
          fontsize: 6,
          padding: 4,
          boxWidth: 12,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const {label = ''} = context;
            return `${label}`;
          },
        },
      },
    },
  };

  function getModalityData() {
    return {
      labels: cleanPieData(stats!.modalities).map(value => {
        return `${value.label} ${formatNumberWithSuffix(value.stat)}`;
      }),
      datasets: [
        {
          label: 'Modalities',
          data: cleanPieData(stats!.modalities).map(value => {
            return value.stat;
          }),
          backgroundColor: randomRGB(stats!.modalities.length, 10, 85),
          borderColor: randomRGB(stats!.modalities.length, 10, 70),
          borderWidth: 1,
        },
      ],
    };
  }

  function getBodyPartData() {
    return {
      labels: cleanPieData(stats!.body_parts).map(value => {
        return `${value.label} ${formatNumberWithSuffix(value.stat)}`;
      }),
      datasets: [
        {
          label: 'Body Parts',
          data: cleanPieData(stats!.body_parts).map(value => {
            return value.stat;
          }),
          backgroundColor: randomRGB(stats!.body_parts.length, 15, 90),
          borderColor: randomRGB(stats!.body_parts.length, 15, 70),
          borderWidth: 1,
        },
      ],
    };
  }

  function getNumReports() {
    return stats!.total_reports.reduce((sum, current) => sum + current.stat, 0);
  }

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Stats</title>
      </Helmet>

      <ApplicationShell>
        <div className="grid grid-cols-1 w-full gap-7 auto-rows-min">
          <h1 className="text-3xl">Statistics</h1>
          <div className="flex flex-row gap-4 justify-around">
            <div className="flex flex-col justify-start">
              <div className="flex flex-col pb-3">
                <h1 className="text-2xl">Modalities</h1>
                <h3 className="text-sm text-gray-500">
                  Segmed offers reports of many modalities
                </h3>
              </div>
              {stats && (
                <Pie
                  height={250}
                  width={400}
                  data={getModalityData()}
                  options={options}
                />
              )}
            </div>
            <div className="flex flex-col justify-start">
              <div className="flex flex-col pb-3">
                <h1 className="text-2xl">Body Parts</h1>
                <h3 className="text-sm text-gray-500">
                  Reports are on many body parts
                </h3>
              </div>
              {stats && (
                <Pie
                  height={250}
                  width={400}
                  data={getBodyPartData()}
                  options={options}
                />
              )}
            </div>
          </div>
          <div className="grid justify-self-center pt-3">
            <div className="text-blue-800 text-7xl">
              {stats && formatNumber(getNumReports())}{' '}
            </div>
            <div className="italic justify-self-end"> total reports</div>
          </div>
        </div>
      </ApplicationShell>
    </>
  );
};
