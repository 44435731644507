import {ReactNode} from 'react';
import {HiOutlineX} from 'react-icons/hi';
import cx from 'classnames';

export const Banner = ({
  children,
  className,
  onClick,
}: {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={cx('relative bg-ebony/90', className)}>
      <div className="max-w-7xl mx-auto py-3 px-8">
        <div className="text-base leading-6 font-medium text-white flex flex-wrap gap-x-1 justify-center items-center mr-4">
          {children}
        </div>
        <div className="absolute inset-y-0 right-0 pt-1 px-1 flex items-center">
          <button
            type="button"
            onClick={onClick}
            className="flex p-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-white mr-1"
          >
            <span className="sr-only">Dismiss</span>
            <HiOutlineX className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
