import {ReactNode} from 'react';

import cx from 'classnames';

export const Wrapper = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div
      className={cx('min-h-screen w-full text-gray-900', className)}
      {...props}
    >
      {children}
    </div>
  );
};
