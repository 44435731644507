/* eslint-disable security/detect-object-injection */
import React, {useState} from 'react';
import {FilterProps, Renderer} from 'react-table';
import _ from 'lodash';
import {DatasetStudy} from '../../../models/dataset';
import {HiOutlineSearch} from 'react-icons/hi';

type SelectedOptionsType = {[key: string]: boolean};

interface CheckboxFilterProps extends FilterProps<DatasetStudy> {
  onCancel: () => void;
  onApply: () => void;
}

export const CheckboxFilter: Renderer<CheckboxFilterProps> = ({
  column: {filterValue, setFilter, preFilteredRows, id},
}) => {
  const closeFilterModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    let currentElement = e.currentTarget; // Assumes `event` is available

    // Navigate up the DOM tree 7 levels
    // XXXHACK: This is a hack to get the button element
    // since the react-table compoenent doesn't provide a way to close it
    for (let i = 0; i < 7; i++) {
      if (currentElement.parentNode) {
        currentElement = currentElement.parentNode as HTMLButtonElement;
      } else {
        console.error('Could not find button header element');
        return;
      }
    }

    // Look for a button element among the children of the current element
    const buttonElement = Array.from(currentElement.childNodes).find(
      node => node.nodeName === 'BUTTON'
    );

    if (buttonElement) {
      (buttonElement as HTMLButtonElement).click();
    }
  };
  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    closeFilterModal(e);
  };

  const handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilter(
      Object.entries(tempSelectedOptions)
        .filter(([, value]: [string, boolean]) => value)
        .map(([key]: [string, boolean]) => key)
    );
    closeFilterModal(e);
  };

  // Calculate the unique values for this column
  const options = React.useMemo(() => {
    const optionsMap = new Map<string, number>();
    preFilteredRows.forEach(row => {
      const value = row.values[id] as string;
      optionsMap.set(value, (optionsMap.get(value) || 0) + 1);
    });
    return optionsMap;
  }, [id, preFilteredRows]);

  const [tempSelectedOptions, setTempSelectedOptions] =
    useState<SelectedOptionsType>(() => {
      const initialValue: SelectedOptionsType = {};
      options.forEach(option => {
        initialValue[option] = (filterValue || []).includes(option);
      });
      return initialValue;
    });

  const toggleOption = (option: string) => {
    setTempSelectedOptions(prev => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  return (
    <div>
      <div className="relative text-gray-600">
        <input
          type="text"
          className="text-input w-full text-gray-700 pl-10 pr-4 rounded border border-gray-300"
          value={filterValue || ''}
          placeholder="Search"
          onChange={e => setFilter(e.target.value ? [e.target.value] : [])}
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <HiOutlineSearch
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
      <br />
      {Array.from(options.entries()).map(([option, count]) => (
        <div key={option} onClick={() => toggleOption(option)}>
          <input
            type="checkbox"
            className="checkbox-input"
            checked={tempSelectedOptions[option] || false}
          />
          <label className="text-gray-700 text-sm">{` ${_.capitalize(
            option
          )} (${count})`}</label>
        </div>
      ))}
      <div className="flex justify-between">
        <button onClick={handleCloseModal} className="btn btn-white ml-auto">
          Cancel
        </button>
        <button onClick={handleApply} className="btn btn-primary ml-3">
          Apply
        </button>
      </div>
    </div>
  );
};
