import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {Column} from 'react-table';

import {Badge} from '../../core/components/badge';
import {ReactTable, TextFilter} from '../../core/components/react-table';
import {ApplicationShell} from '../../core/layout/application-shell';
import {
  DatasetOrder,
  fetchUserAllDatasetOrders,
} from '../../models/dataset-order';
import {useAxios} from 'src/utils/http';

export const OrdersPage = () => {
  const api = useAxios();
  const {data: datasetOrders, isLoading: ordersIsLoading} = useQuery(
    ['order'],
    () => {
      return fetchUserAllDatasetOrders(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const columns = useMemo<Array<Column<DatasetOrder>>>(
    () => [
      {
        Header: 'Order Id',
        accessor: 'orderId',
        Filter: TextFilter,
        filter: 'inclues',
        Cell: ({value}: {value: string}) => (
          <span className="text-gray-500  text-sm leading-5 font-normal">
            {value}
          </span>
        ),
      },
      {
        Header: 'Dataset',
        accessor: 'datasetName',
        Filter: TextFilter,
        filter: 'inclues',
        Cell: ({value}: {value: string}) => (
          <Badge type="info">
            <span className="truncate max-w-xs">DS: {value}</span>
          </Badge>
        ),
      },
      {
        Header: 'Ordered',
        accessor: 'orderedAt',
        Filter: TextFilter,
        filter: 'inclues',
        Cell: ({value}) => (
          <span className="text-sm leading-5 font-normal text-gray-500">
            {value?.toFormat('LLL dd, yyyy') || ''}
          </span>
        ),
      },
      {
        id: 'View',
        Header: ' ',
        accessor: 'orderId',
        Cell: ({value}: {value: string}) => (
          <Link className="link" to={`/orders/${value}`}>
            View order details
          </Link>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => datasetOrders ?? [], [datasetOrders]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dataset Order</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor" contained={false}>
        <h1 className="text-2xl mb-5 font-bold text-gray-900">Orders</h1>
        {ordersIsLoading ? (
          'Loading dataset orders...'
        ) : datasetOrders?.length ? (
          <ReactTable
            tableColumns={columns}
            tableData={data}
            defaultSortBy={[{id: 'datasetId', desc: true}]}
          />
        ) : (
          <div className="justify-self-center text-base leading-7 font-normal text-gray-500">
            You don&apos;t have any orders yet. Place an order from one of your{' '}
            <Link className="link" to="/datasets">
              datasets.
            </Link>
          </div>
        )}
      </ApplicationShell>
    </>
  );
};
