/* eslint-disable security/detect-object-injection */
import PropTypes from 'prop-types';
import HTTPStatus from 'http-status';
import {Helmet} from 'react-helmet';

import {ApplicationShell} from '../application-shell';

export const ErrorPage = ({statusCode}: {statusCode: number}) => {
  const title =
    statusCode === 404
      ? 'This page could not be found'
      : HTTPStatus[statusCode] || 'An unexpected error has occurred';

  return (
    <>
      <Helmet>
        <title>Segmed Openda - {title}</title>
      </Helmet>

      <ApplicationShell>
        <div className="flex items-stretch min-h-screen">
          <div className="w-full flex justify-center items-center">
            <p className="text-4xl font-medium">
              {statusCode ?? 500} - {title}
            </p>
          </div>
        </div>
      </ApplicationShell>
    </>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
};
