import {ReactNode} from 'react';
import cx from 'classnames';

export const InfoCard = ({
  children,
  title,
  icon,
  titleClassName = '',
  containerClassName = '',
}: {
  children?: ReactNode;
  title?: string;
  icon?: ReactNode;
  titleClassName?: string;
  containerClassName?: string;
}) => {
  return (
    <div
      className={cx(
        'flex flex-col font-normal bg-gray-50 gap-y-4 w-2/3 p-8 rounded-lg',
        containerClassName
      )}
    >
      {icon}
      {title && (
        <div
          className={cx('text-lg text-gray-900 font-medium', titleClassName)}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};
