import {SyntheticEvent} from 'react';
import {AxiosInstance} from 'axios';

import {RectangleROIAnnotation} from '@segmed/cornerstonejs-tools/dist/esm/types/ToolSpecificAnnotationTypes';
import {BlackoutRectangle} from './evalon';
import {colorForBlackoutRectangleScope} from 'src/enums/BlackoutRectangleScopeEnum';

export type prebuiltBlackouts = 'Dicomocr' | 'PHI' | 'Saved' | 'None';

export interface prebuiltBlackout {
  name: prebuiltBlackouts;
  displayName?: string;
  toolType?: 'viewport' | 'measurement';
  mode: 'active' | 'disabled' | 'enabled' | 'passive';
  modeOptions?: {mouseButtonMask: number};
  disabled?: boolean;
}

export interface prebuiltTool {
  name: string;
  displayName?: string;
  toolType?: 'viewport' | 'measurement';
  mode: 'active' | 'disabled' | 'enabled' | 'passive';
  modeOptions?: {mouseButtonMask: number; blackoutRect?: boolean};
  disabled?: boolean;
  addAnnotationHook?: (annotation: any) => any;
}

export interface customActionTool {
  name: string;
  value?: string;
  disabled?: boolean;
  action: () => void;
}

// TODO properly define type
export interface cornerstoneEnabledEvent extends SyntheticEvent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail: any;
}

interface DicomStudyResp {
  study_id: string;
  image_count: number;
  series: DicomSeriesResp[];
}

interface DicomSeriesResp {
  series_id: string;
  series_num?: string;
  series_desc: string;
  image_count: number;
}

export interface DicomInfo {
  datasetID?: number | undefined;
  status: string;
  studyCount: number;
  offset: number;
  limit: number;
  studies: DicomStudy[];
}

export interface DicomStudy {
  studyID: string;
  imageCount: number;
  series: DicomSeries[];
}

export interface DicomSeries {
  seriesID: string;
  seriesNum?: string;
  seriesDesc: string;
  imageCount: number;
}

interface DicomImagePresignedResp {
  sop_id: string;
  instance_num?: string;
  size: number;
  presigned_url: string;
  num_frames: number;
}

export interface DicomSeriesPresigned {
  datasetID: number;
  status: string;
  studyID: string;
  seriesNum?: string;
  seriesID: string;
  images: DicomImagePresigned[];
}

export interface DicomImagePresigned {
  sopID: string;
  instanceNum?: string;
  size: number;
  presignedUrl: string;
  numFrames: number;
}

const responseToDatasetDicomStudy = (datasetDicomSeriesResp: {
  study_id: string;
  image_count: number;
  series: DicomSeriesResp[];
}): DicomStudy => {
  return {
    studyID: datasetDicomSeriesResp.study_id,
    imageCount: datasetDicomSeriesResp.image_count,
    series: datasetDicomSeriesResp.series.map(series =>
      responseToDatasetDicomSeries(series)
    ),
  };
};

const responseToDatasetDicomSeries = (datasetDicomStudyResp: {
  series_id: string;
  series_num?: string;
  series_desc: string;
  image_count: number;
}): DicomSeries => {
  return {
    seriesID: datasetDicomStudyResp.series_id,
    seriesNum: datasetDicomStudyResp.series_num,
    seriesDesc: datasetDicomStudyResp.series_desc,
    imageCount: datasetDicomStudyResp.image_count,
  };
};

export const responseToDatasetDicomInfo = (datasetDicomInfoResp: {
  dataset_id: number;
  status: string;
  study_count: number;
  offset: number;
  limit: number;
  studies: DicomStudyResp[];
}): DicomInfo => {
  return {
    datasetID: datasetDicomInfoResp.dataset_id
      ? datasetDicomInfoResp.dataset_id
      : undefined,
    status: datasetDicomInfoResp.status,
    studyCount: datasetDicomInfoResp.study_count,
    offset: datasetDicomInfoResp.offset,
    limit: datasetDicomInfoResp.limit,
    studies: datasetDicomInfoResp.studies.map(study =>
      responseToDatasetDicomStudy(study)
    ),
  };
};

export const responseToDicomImagePresigned = (dicomImagePresignedResp: {
  sop_id: string;
  instance_num?: string;
  size: number;
  presigned_url: string;
  num_frames: number;
}): DicomImagePresigned => {
  return {
    sopID: dicomImagePresignedResp.sop_id,
    instanceNum: dicomImagePresignedResp.instance_num,
    size: dicomImagePresignedResp.size,
    presignedUrl: dicomImagePresignedResp.presigned_url,
    numFrames: dicomImagePresignedResp.num_frames,
  };
};

export const responseToDicomSeriesPresigned = (dicomStudyPresignedResp: {
  dataset_id: number;
  status: string;
  study_id: string;
  series_id: string;
  series_num?: string;
  images: DicomImagePresignedResp[];
}): DicomSeriesPresigned => {
  return {
    datasetID: dicomStudyPresignedResp.dataset_id,
    status: dicomStudyPresignedResp.status,
    studyID: dicomStudyPresignedResp.study_id,
    seriesID: dicomStudyPresignedResp.series_id,
    seriesNum: dicomStudyPresignedResp.series_num,
    images: dicomStudyPresignedResp.images.map(image =>
      responseToDicomImagePresigned(image)
    ),
  };
};

export const fetchDatasetDicomInfo = (
  http: AxiosInstance,
  datasetID: number,
  isAdmin?: boolean
) => {
  let url = `/v1/datasets/${datasetID}/dicom_viewing`;
  if (isAdmin) {
    url = `/v1/admin/datasets/${datasetID}/dicom_viewing`;
  }
  return http.get(url).then(response => {
    const {data} = response;

    const datasetDicomInfo = responseToDatasetDicomInfo(data);

    return datasetDicomInfo;
  });
};

export const fetchSeriesDicomPresignedUrls = (
  http: AxiosInstance,
  studyID: string,
  seriesID: string
) => {
  const url = `/v1/dicom_viewing/${studyID}/${seriesID}`;
  return http.get(url).then(response => {
    const {data} = response;

    const dicomStudyPresigned = responseToDicomSeriesPresigned(data);
    return dicomStudyPresigned;
  });
};

export const fetchDicomStudyInfo = (http: AxiosInstance, studyID: string) => {
  const url = `/v1/dicom_viewing/${studyID}`;
  return http
    .get(url)
    .then(response => {
      const {data} = response;

      const datasetDicomInfo = responseToDatasetDicomInfo(data);
      return datasetDicomInfo;
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        console.error(`Study with ID ${studyID} not found.`);
        // TODO: Handle the error as appropriate, here we just return empty
        const emptyDicomInfo: DicomInfo = {
          status: '', // replace with default value
          studyCount: 0, // replace with default value
          offset: 0, // replace with default value
          limit: 0, // replace with default value
          studies: [], // replace with default value
        };
        return emptyDicomInfo;
      } else {
        // If it's not a 404 error, re-throw the error so it can be handled elsewhere
        throw error;
      }
    });
};

export const getDicomSeries = (
  studyID: string,
  dicomInfo: DicomInfo | undefined
) => {
  if (dicomInfo && dicomInfo.studyCount > 0) {
    const studyIndex = dicomInfo?.studies.findIndex(
      study => study.studyID === studyID
    );
    if (studyIndex !== -1) {
      // eslint-disable-next-line security/detect-object-injection
      const dicomSeries = dicomInfo.studies[studyIndex].series;
      return dicomSeries;
    }
  }
  return undefined;
};

export const getAllDicomSeries = (dicomInfo: DicomInfo | undefined) => {
  if (dicomInfo === undefined) {
    return undefined;
  }

  const allDicomSeries: Record<string, DicomSeries[]> = {};
  if (dicomInfo && dicomInfo.studyCount > 0) {
    dicomInfo.studies.forEach(study => {
      allDicomSeries[study.studyID] = study.series;
    });
  }
  return allDicomSeries;
};

export const getAllFramesImageIds = (
  presignedUrl: string,
  numFrames: number,
  study_id: string,
  series_id: string,
  sop_id: string,
  series_num?: string,
  instance_num?: string
) => {
  const frameImageIds: ImageDetail[] = [];
  for (let i = 0; i < numFrames; i++) {
    const presignedUrlWithFrame = new URL(presignedUrl);
    presignedUrlWithFrame.searchParams.append('frame', `${i}`);
    const isDicom = presignedUrl.split('?')[0].endsWith('.dcm');
    const frameImageId = isDicom
      ? `wadouri:${presignedUrlWithFrame.toString()}`
      : `web:${presignedUrlWithFrame.toString()}`;
    frameImageIds.push({
      presignedUrl: frameImageId,
      seriesID: series_id,
      sopID: sop_id,
      seriesNum: series_num,
      instanceNum: instance_num,
      frame: i,
    });
  }
  return frameImageIds;
};

export interface ImageDetail {
  presignedUrl: string;
  seriesID: string;
  sopID: string;
  seriesNum?: string;
  instanceNum?: string;
  frame: number;
  width?: number;
  height?: number;
}

export const getDicomAvailability = (
  http: AxiosInstance,
  studyIDs: string[]
) => {
  const url = '/v1/dicom_viewing/availability';
  return http
    .post(url, studyIDs)
    .then(response => {
      const {data} = response;
      const res = [];
      for (const [studyId, availability] of Object.entries(data.studies)) {
        if (availability) {
          res.push(studyId);
        }
      }
      return res;
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // We throw a 400 for empty study_ids
        return [];
      } else {
        // If it's not a 400 error, re-throw the error so it can be handled elsewhere
        throw error;
      }
    });
};

export const reportCorruptedDicom = (
  http: AxiosInstance,
  feedback: string,
  URL: string
) => {
  http.post('/v1/report_corrupted_dicom', {feedback: feedback, url: URL});
};

export const blackoutsToRectangleROIAnnotation = (
  blackouts: BlackoutRectangle[]
): RectangleROIAnnotation[] => {
  const blackoutAnnotations: RectangleROIAnnotation[] = blackouts.map(
    blackout => {
      const color = colorForBlackoutRectangleScope[blackout.scope] ?? 'white';
      return {
        invalidated: true,
        highlighted: true,
        metadata: {
          toolName: 'RectangleROI',
          viewPlaneNormal: [0, 0, -1],
          viewUp: [0, -1, 0],
          FrameOfReferenceUID: 'FORUID',
        },
        data: {
          label: '',
          handles: {
            points: [
              [blackout.x1, blackout.y1, 0],
              [blackout.x2, blackout.y1, 0],
              [blackout.x1, blackout.y2, 0],
              [blackout.x2, blackout.y2, 0],
            ],
            textBox: {
              hasMoved: false,
              worldPosition: [blackout.x1, blackout.y1, 0],
              worldBoundingBox: {
                topLeft: [blackout.x1, blackout.y1, 0],
                topRight: [blackout.x2, blackout.y1, 0],
                bottomLeft: [blackout.x1, blackout.y2, 0],
                bottomRight: [blackout.x2, blackout.y2, 0],
              },
            },
            activeHandleIndex: null,
          },
          scope: blackout.scope,
          color: color,
          text: blackout.text,
          isPHI: !!blackout.is_phi,
        },
        isVisible: true,
        isLocked: false,
        color: color,
      };
    }
  );

  return blackoutAnnotations;
};
