import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {useQuery, useQueryClient} from 'react-query';

import {Loading} from '../../core/components/loading';
import {ReportComponent} from '../../components/report';
import {ErrorPage} from '../../core/layout/error-page';
import {fetchReport} from '../../models/report';
import {ApplicationShell} from '../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const StudyDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const api = useAxios();

  const q = useParams<{
    studyId?: string;
  }>();
  const studyId = _.isString(q.studyId) ? q.studyId : undefined;

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', studyId],
    () => {
      return fetchReport(api, studyId as string);
    },
    {
      enabled: _.isString(studyId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  if (reportError || _.isNil(studyId)) {
    // Error getting report
    return <ErrorPage statusCode={404} />;
  }

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Report Details</title>
      </Helmet>

      <ApplicationShell>
        {reportLoading && <Loading />}

        {report && (
          <ReportComponent
            report={report}
            PHIReported={() => {
              queryClient.removeQueries(['report', report.studyId]);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['search']);
              navigate({
                pathname: '/',
              });
            }}
          />
        )}
      </ApplicationShell>
    </>
  );
};
