import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {trackEvent} from '../../utils/tracking';
import {SavedSearches} from '../../models/saved-search';
import {Card} from '../../core/layout/card';
import ReactTooltip from 'react-tooltip';
import {HiHeart} from 'react-icons/hi';
import {parseElasticSearchBody} from '../../models/minerva';

export const SaveSearchCard = ({
  savedSearch,
  action,
}: {
  savedSearch: SavedSearches;
  action?: (actionType: 'edit' | 'delete') => void;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [savedSearch]);

  const {
    searchBoxes,
    filters,
    groupPatientsByID,
    sortPatientIDsBy,
    useVectorSearch,
  } = parseElasticSearchBody(savedSearch.elasticSearchBody);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US').replace(',', '');
  };

  const urlObject = new URL(savedSearch.url);
  const relativeUrl = urlObject.pathname + urlObject.search;

  const handleOpenSearch = () => {
    trackEvent('CLICK_SAVED_SEARCH_CARD');
    navigate(relativeUrl, {
      state: {elasticSearchBody: savedSearch.elasticSearchBody},
    });
  };

  return (
    <>
      <Card data-cy="datasetCard">
        <div className="text-gray-400 flex justify-between items-center mb-6 pb-6 border-b">
          <div>
            <div className="flex flex-col text-gray-900">
              {searchBoxes.map((searchBox, index) => (
                <div key={index} className="flex items-center mb-2">
                  <div>
                    <span className="font-bold text-lg">
                      {searchBox.reportType} {searchBox.operation}:{' '}
                    </span>
                    {searchBox.value}
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <div>
                <strong>Filters:</strong>{' '}
                {filters ? filters : 'No filters selected'}
              </div>
              <div>
                <strong>Group Patients:</strong>{' '}
                {groupPatientsByID
                  ? `On (Sort by: ${sortPatientIDsBy})`
                  : 'Off'}
              </div>
              <div>
                <strong>AI Sorting:</strong> {useVectorSearch ? 'On' : 'Off'}
              </div>
            </div>
          </div>
          <div>
            {formatDate(savedSearch.createdAt)}
            <div className="inline ml-4">
              <button
                className="btn btn-link inline-block px-0 py-0"
                data-tip="Delete saved searches"
                data-event="mouseenter"
                data-event-off="mouseleave click"
                onClick={() => {
                  trackEvent('CLICK_DELETE_SAVED_SEARCHES_BTN', {
                    ssId: savedSearch.ssId,
                  });
                  action && action('delete');
                }}
              >
                <HiHeart className="w-5 h-5 inline" />
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <div>
              <div
                className="text-2xl font-extrabold"
                data-cy="datasetStudyCount"
              >
                {savedSearch.numberOfResultsSaved.toLocaleString('en-US')}
              </div>
              <div className="text-gray-500">Total studies results</div>
            </div>
          </div>
          <div>
            <button
              onClick={handleOpenSearch}
              className="btn btn-primary max-w-[40%] float-right"
            >
              View Search results
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};
