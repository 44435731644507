import {Badge} from '../../core/components/badge';
import {Cart} from '../../models/cart';
import {formatCurrency} from '../../utils/strings';

export const DatasetSubtotal = ({cart}: {cart: Cart}) => {
  return (
    <table className="table-fixed w-full text-gray-500">
      <thead>
        <tr className="uppercase">
          <th className="w-1/4 py-2 font-semibold text-left">QTY</th>
          <th className="w-1/4 py-2 font-semibold text-left">DESCRIPTION</th>
          {/*<th className="w-1/4 py-2 font-semibold text-right">UNIT PRICE</th>*/}
          {/*<th className="w-1/4 py-2 font-semibold text-right">AMOUNT</th>*/}
        </tr>
      </thead>
      <tbody>
        {cart.items.map((item, itemIndex) => {
          const [description, status] = item.description.split(' - ');
          return (
            <tr key={itemIndex}>
              <td className="py-2">{item.quantity}</td>
              <td className="py-2">
                {description + ' - '}
                <Badge type={status === 'added' ? 'success' : 'info'}>
                  {status === 'added' ? 'Added' : 'Undetermined'}
                </Badge>
              </td>
              {/*<td className="py-2 text-right">*/}
              {/*  {formatCurrency(item.unitPrice)}*/}
              {/*</td>*/}
              {/*<td className="py-2 text-right">{formatCurrency(item.total)}</td>*/}
            </tr>
          );
        })}
        {cart.discounts.map((discount, discountIndex) => (
          <tr key={discountIndex}>
            <td colSpan={2} />
            <td className="py-2 text-right">{discount.description}</td>
            <td className="py-2 text-right">
              {formatCurrency(discount.total)}
            </td>
          </tr>
        ))}
        {/*<tr className="border-t">*/}
        {/*  <td colSpan={2} />*/}
        {/*  <td className="py-2 text-right font-semibold">TOTAL</td>*/}
        {/*  <td className="py-2 text-right">{formatCurrency(cart.total)}</td>*/}
        {/*</tr>*/}
      </tbody>
    </table>
  );
};
