import {HiCheckCircle} from 'react-icons/hi';

export const Step = ({
  status,
  children,
  ...props
}: {
  status: 'complete' | 'current' | 'upcoming';
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const renderIcon = (status: 'complete' | 'current' | 'upcoming') => {
    switch (status) {
      case 'complete':
        return <HiCheckCircle className="text-primary" />;
      case 'current':
        return (
          <>
            <span className="absolute h-4 w-4 rounded-full bg-blue-200"></span>
            <span className="relative block w-2 h-2 bg-blue-600 rounded-full"></span>
          </>
        );
      case 'upcoming':
      default:
        return <div className="h-2 w-2 bg-gray-400 rounded-full"></div>;
    }
  };

  return (
    <div>
      <span className="flex items-center">
        <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
          {renderIcon(status)}
        </span>
        <span {...props} className="ml-3 text-sm text-gray-500">
          {children}
        </span>
      </span>
    </div>
  );
};
