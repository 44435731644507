import _ from 'lodash';
import {toast} from 'react-toastify';
import {DateTime, Duration} from 'luxon';
import {AxiosInstance} from 'axios';

import {ToastMessage} from '../core/components/toast';
import {trackEvent} from '../utils/tracking';
import {SearchRequestQuery} from './search';
import {SecondsToDuration} from '../utils/duration';
import {responseToCart} from './cart';
import {FavoriteTagRequest, Tag} from './tags';
import {User} from './user';
import {downloadFileFromURLSameTab} from './fileDownload';
import {DatasetStatusEnum} from 'src/enums/datasetstatusenum';
import {DatasetLocStatusEnum} from 'src/enums/datasetlocstatusenum';

export interface Dataset {
  id: number;
  userId: number;
  name: string;
  status: DatasetStatusEnum;
  studyCount?: number;
  includeUndetermined: boolean;
  favorite: boolean;
  dicomViewingProcessing: boolean;
  // @todo: dicom_viewing_processing_ready_at
}

export type DatasetWithUser = Dataset & {
  user: User;
};

interface DatasetResponse {
  dataset_id: number;
  user_id: number;
  name: string;
  status: DatasetStatusEnum;
  study_count?: number;
  include_undetermined: boolean;
  favorite: boolean;
  dicom_viewing_processing: boolean;
  // @todo: dicom_viewing_processing_ready_at
}

export interface DatasetStudy {
  delemId?: string;
  datasetId: number;
  studyId: string;
  patientId: string;
  modality: string;
  bodyPart: string;
  examDate?: DateTime;
  manufacturer: string;
  sourceLocation: string;
  previouslyOrdered?: boolean;
  patientAge: string;
  patientSex: string;
  model: string;
  site: string;
  metadata?: {
    patientAge: string;
    patientAgeSortable: Duration;
    patientSex: string;
    model: string;
    site: string;
    series: Series[];
    dicom_headers_study: {[key: string]: string};
    dicom_headers_series: {[key: string]: {[key: string]: string}};
    dicom_headers_images: {
      [key: string]: {[key: string]: string[] | {min: number; max: number}};
    };
  };
  approved: -1 | 0 | 1;
  tags?: Tag[];
}

export interface Series {
  seriesID: string;
  sliceThickness: number;
  convolutionKernel: string;
  seriesDescription: string;
}

interface SeriesResponse {
  series_id: string;
  slice_thickness: string;
  convolution_kernel: string;
  series_description: string;
}

export interface DatasetCreateRequest {
  name: string;
}

// @todo: Make add/remove/ids all optional
export interface DatasetUpdateRequest {
  add: {query?: SearchRequestQuery; ids: string[]};
  remove: {query?: SearchRequestQuery; ids: string[]};
}

export enum DATASET_STAGE_TYPE {
  DatasetCreated = 'dataset_created',
  MetadataRequested = 'metadata_requested',
  MetadataReady = 'metadata_ready',
  MetadataEmailSent = 'metadata_email_sent',
  DatasetOrdered = 'dataset_ordered',
  DatasetDelivered = 'dataset_delivered',
}

export const datasetStatusToStage = (
  status: DatasetStatusEnum
): DATASET_STAGE_TYPE => {
  switch (status) {
    case DatasetStatusEnum.DatasetStatusDatasetCreated:
      return DATASET_STAGE_TYPE.DatasetCreated;
    case DatasetStatusEnum.DatasetStatusMetadataCustRequested:
    case DatasetStatusEnum.DatasetStatusMetadataCustRequestedBlocked:
    case DatasetStatusEnum.DatasetStatusMetadataDPRequested:
    case DatasetStatusEnum.DatasetStatusSampleEvaluating:
    case DatasetStatusEnum.DatasetStatusSampleEvaluated:
    case DatasetStatusEnum.DatasetStatusSamplesPacking:
    case DatasetStatusEnum.DatasetStatusSamplesScanning:
    case DatasetStatusEnum.DatasetStatusMetadataStalled:
      return DATASET_STAGE_TYPE.MetadataRequested;
    case DatasetStatusEnum.DatasetStatusMetadataReady:
    case DatasetStatusEnum.DatasetStatusSamplesDelivered:
    case DatasetStatusEnum.DatasetStatusSamplesPacked:
    case DatasetStatusEnum.DatasetStatusSamplesScanned:
    case DatasetStatusEnum.DatasetStatusSamplesClean:
    case DatasetStatusEnum.DatasetStatusSamplesPHIDetected:
    case DatasetStatusEnum.DatasetStatusSamplesRecalled:
      return DATASET_STAGE_TYPE.MetadataReady;
    case DatasetStatusEnum.DatasetStatusMetadataEmailSent:
      return DATASET_STAGE_TYPE.MetadataEmailSent;
    case DatasetStatusEnum.DatasetStatusOrderCanceled:
    case DatasetStatusEnum.DatasetStatusOrderDisapproved:
    case DatasetStatusEnum.DatasetStatusDatasetOrdered:
    case DatasetStatusEnum.DatasetStatusDatasetEvaluated:
    case DatasetStatusEnum.DatasetStatusDatasetEvaluating:
    case DatasetStatusEnum.DatasetStatusDatasetPacking:
    case DatasetStatusEnum.DatasetStatusDatasetScanning:
      return DATASET_STAGE_TYPE.DatasetOrdered;
    case DatasetStatusEnum.DatasetStatusQuotesSent:
    case DatasetStatusEnum.DatasetStatusQuotesSigned:
    case DatasetStatusEnum.DatasetStatusDatasetPacked:
    case DatasetStatusEnum.DatasetStatusDatasetScanned:
    case DatasetStatusEnum.DatasetStatusDatasetClean:
    case DatasetStatusEnum.DatasetStatusDatasetDelivered:
    case DatasetStatusEnum.DatasetStatusDeliveryEmailSent:
    case DatasetStatusEnum.DatasetStatusDatasetPHIDetected:
    case DatasetStatusEnum.DatasetStatusDatasetRecalled:
    case DatasetStatusEnum.DatasetStatusOrderFullfilled:
      return DATASET_STAGE_TYPE.DatasetDelivered;
  }
};

export interface DatasetLocation {
  locationID: number;
  datasetID: number;
  location: string;
  filename: string;
  size: number;
  status: DatasetLocStatusEnum;
  isSamples: boolean;
  studyCount: number;
  fileCount: number;
  downloadedAt?: DateTime;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface PresignedDatasetLocation {
  id: number;
  presignedURL: string;
  accessDuration: Duration;
}

export const responseToDataset = (datasetResp: DatasetResponse) => {
  const dataset: Dataset = {
    id: datasetResp.dataset_id,
    userId: datasetResp.user_id,
    name: datasetResp.name,
    status: datasetResp.status,
    studyCount: datasetResp.study_count,
    includeUndetermined: datasetResp.include_undetermined,
    favorite: datasetResp.favorite,
    dicomViewingProcessing: datasetResp.dicom_viewing_processing,
  };

  return dataset;
};

export const responseToDatasetLocation = (datasetLocationResp: {
  dl_id: number;
  dataset_id: number;
  location: string;
  filename: string;
  size: number;
  status: DatasetLocStatusEnum;
  is_samples: boolean;
  study_count: number;
  file_count: number;
  downloaded_at: {Time: string; Valid: boolean};
  created_at: string;
  updated_at: string;
}): DatasetLocation => {
  return {
    locationID: datasetLocationResp.dl_id,
    datasetID: datasetLocationResp.dataset_id,
    location: datasetLocationResp.location,
    filename: datasetLocationResp.filename,
    size: datasetLocationResp.size,
    status: datasetLocationResp.status,
    isSamples: datasetLocationResp.is_samples,
    studyCount: datasetLocationResp.study_count,
    fileCount: datasetLocationResp.file_count,
    downloadedAt: datasetLocationResp.downloaded_at.Valid
      ? DateTime.fromISO(datasetLocationResp.downloaded_at.Time)
      : undefined,
    createdAt: DateTime.fromISO(datasetLocationResp.created_at),
    updatedAt: DateTime.fromISO(datasetLocationResp.updated_at),
  };
};

export const responseToPresignedDatasetLocation =
  (presignedDatasetLocationResp: {
    id: number;
    presigned_url: string;
    access_duration: number;
  }): PresignedDatasetLocation => {
    return {
      id: presignedDatasetLocationResp.id,
      presignedURL: presignedDatasetLocationResp.presigned_url,
      accessDuration: Duration.fromMillis(
        presignedDatasetLocationResp.access_duration
      ),
    };
  };

export const responseToDatasetStudy = (studyResp: {
  delem_id: string;
  dataset_id: number;
  study_id: string;
  patient_id: string;
  modality: string;
  body_part: string;
  exam_date: string;
  manufacturer: string;
  source_location: string;
  site: string;
  patient_age?: string;
  patient_sex?: string;
  model?: string;
  metadata?: {
    patient_age: string;
    patient_age_sortable: number;
    patient_sex: string;
    model: string;
    site: string;
    series?: SeriesResponse | SeriesResponse[];
    dicom_headers_study: {[key: string]: string};
    dicom_headers_series: {[key: string]: {[key: string]: string}};
    dicom_headers_images_string: {[key: string]: {[key: string]: string[]}};
    dicom_headers_images_int: {
      [key: string]: {[key: string]: {min: number; max: number}};
    };
    dicom_headers_images_decimal: {
      [key: string]: {[key: string]: {min: number; max: number}};
    };
  };
  previously_ordered: boolean;
  approved: -1 | 0 | 1;
}) => {
  let seriesResp = studyResp.metadata?.series;
  let series: Series[] = [];
  if (!_.isEmpty(studyResp.metadata?.series)) {
    if (!_.isArray(seriesResp)) {
      seriesResp = [seriesResp!];
    }
    series = _.map(_.uniqWith(seriesResp, _.isEqual), s => responseToSeries(s));
  }

  const study: DatasetStudy = {
    delemId: studyResp.delem_id,
    datasetId: studyResp.dataset_id,
    studyId: studyResp.study_id,
    patientId: studyResp.patient_id,
    modality: studyResp.modality,
    bodyPart: studyResp.body_part,
    examDate:
      !_.isEmpty(studyResp.exam_date) &&
      studyResp.exam_date !== '0001-01-01T00:00:00Z'
        ? DateTime.fromISO(studyResp.exam_date)
        : undefined,
    manufacturer: studyResp.manufacturer,
    sourceLocation: studyResp.source_location,
    patientAge: studyResp.patient_age ?? '',
    patientSex: studyResp.patient_sex ?? '',
    model: studyResp.model ?? '',
    site: studyResp.site,
    metadata: _.isNil(studyResp.metadata)
      ? undefined
      : {
          patientAge: studyResp.metadata.patient_age,
          patientAgeSortable: SecondsToDuration(
            studyResp.metadata.patient_age_sortable
          ),
          patientSex: studyResp.metadata.patient_sex,
          model: studyResp.metadata.model,
          site: studyResp.metadata.site,
          dicom_headers_study: studyResp.metadata.dicom_headers_study,
          dicom_headers_series: studyResp.metadata.dicom_headers_series,
          dicom_headers_images: _.merge(
            studyResp.metadata.dicom_headers_images_string,
            studyResp.metadata.dicom_headers_images_int,
            studyResp.metadata.dicom_headers_images_decimal
          ),
          series: series,
        },
    previouslyOrdered: studyResp.previously_ordered,
    approved: studyResp.approved,
  };

  return study;
};

export const responseToSeries = (seriesResp: SeriesResponse): Series => {
  return {
    seriesID: seriesResp.series_id,
    sliceThickness: _.toNumber(seriesResp.slice_thickness),
    convolutionKernel: seriesResp.convolution_kernel,
    seriesDescription: seriesResp.series_description,
  };
};

export const fetchAllDatasets = (http: AxiosInstance) => {
  return http.get('/v1/datasets').then(response => {
    const {data} = response;

    const datasets = _.map(data, datasetResp =>
      responseToDataset(datasetResp)
    ).reverse();

    return datasets;
  });
};

export interface DicomRequestStatus {
  studyId: string;
  dicomRequestStatus: string;
  errorMsg: string;
  logTime: string;
  createdAt: string;
  updatedAt: string;
}

interface DicomRequestStatusResponse {
  study_id: string;
  status: string;
  error_msg: string;
  log_time: string;
  created_at: string;
  updated_at: string;
}

export const responseToDicomRequestStatus = (
  dicomRequestStatusResp: DicomRequestStatusResponse
) => {
  const dicomRequestStatus: DicomRequestStatus = {
    studyId: dicomRequestStatusResp.study_id,
    dicomRequestStatus: dicomRequestStatusResp.status,
    errorMsg: dicomRequestStatusResp.error_msg,
    logTime: dicomRequestStatusResp.log_time,
    createdAt: dicomRequestStatusResp.created_at,
    updatedAt: dicomRequestStatusResp.updated_at,
  };

  return dicomRequestStatus;
};

export interface DicomRequestSummaryData {
  total: number;
  handled: number;
  statusCount: {
    NOT_HANDLE: number;
    OK: number;
    TIMEOUT: number;
  };
  metadataCount: Array<{
    source_location: string;
    available: number;
    total: number;
  }>;
}

export const fetchDatasetDicomRequestStatusAdmin = (
  http: AxiosInstance,
  datasetId: number
) => {
  return http
    .get(`/v1/admin/dataset/dicom-request-status/${datasetId}`)
    .then(response => {
      const {data} = response;

      const datasets = _.map(data.details, datasetResp =>
        responseToDicomRequestStatus(datasetResp)
      );

      const summary: DicomRequestSummaryData = {
        total: data.total,
        handled: data.handled,
        statusCount: data.status_count,
        metadataCount: data.metadata_count,
      };

      return {datasets, summary};
    });
};

export const fetchAllDatasetsAdmin = (http: AxiosInstance) => {
  return http.get('/v1/admin/datasets').then(response => {
    const {data} = response;

    const datasets = _.map(data, datasetResp => responseToDataset(datasetResp));

    return datasets;
  });
};

export const fetchDataset = (http: AxiosInstance, datasetId: number) => {
  return http.get(`/v1/datasets/${datasetId}`).then(response => {
    const {data} = response;

    const {dataset: datasetResp, studies: studiesResp} = data;

    const dataset = responseToDataset(datasetResp);
    const studies = _.map(studiesResp, study => responseToDatasetStudy(study));

    return {dataset, studies};
  });
};

export const fetchDatasetLocations = (
  http: AxiosInstance,
  datasetId: number
) => {
  return http.get(`/v1/datasets/${datasetId}/locations`).then(response => {
    const {data} = response;

    const datasetLocations = _.map(data, datasetLocationResponse =>
      responseToDatasetLocation(datasetLocationResponse)
    );

    return datasetLocations;
  });
};

export const fetchPresignedDatasetLocations = (
  http: AxiosInstance,
  locationIds: number[]
) => {
  return http
    .get('v1/dataset_locations', {params: {id: locationIds}})
    .then(response => {
      const {data} = response;

      const presignedDatasetLocations = _.map(
        data,
        presignedDatasetLocationResponse =>
          responseToPresignedDatasetLocation(presignedDatasetLocationResponse)
      );

      return presignedDatasetLocations;
    });
};

export const fetchDatasetPrice = (http: AxiosInstance, datasetId: number) => {
  return http.get(`/v1/datasets/${datasetId}/price`).then(response => {
    const {data} = response;

    const cart = responseToCart(data);

    return cart;
  });
};

export const createDataset = (
  http: AxiosInstance,
  request: DatasetCreateRequest
) => {
  trackEvent('DATASET_CREATE', request);
  return http.post('/v1/datasets', request).then(response => {
    const {data} = response;

    const dataset = responseToDataset(data);

    return dataset;
  });
};

export const updateDatasetReports = (
  http: AxiosInstance,
  datasetId: number,
  request: DatasetUpdateRequest,
  updateType: 'update' | 'add' | 'remove',
  hideToast?: boolean
) => {
  trackEvent('ADD_TO_DATASET', {datasetId, request});

  return http
    .put(`/v1/datasets/${datasetId}/reports`, request)
    .then(response => {
      const {data} = response;
      const {dataset: datasetResponse} = data;
      const dataset = responseToDataset(datasetResponse);

      if (!hideToast) {
        let toastMessage: {title: string; message: string};
        switch (updateType) {
          case 'update':
            toastMessage = {
              title: 'Reports updated!',
              message: 'Reports were updated for ' + dataset.name,
            };
            break;
          case 'add':
            toastMessage = {
              title: 'Reports added!',
              message: 'Reports were added to ' + dataset.name,
            };
            break;
          case 'remove':
            toastMessage = {
              title: 'Reports removed!',
              message: 'Reports were removed from ' + dataset.name,
            };
            break;
        }

        toast(
          <ToastMessage title={toastMessage.title} icon="success">
            {toastMessage.message}
          </ToastMessage>
        );
      }

      return dataset;
    });
};

export const approveDatasetStudies = (
  http: AxiosInstance,
  datasetId: number,
  studyIDs: string[],
  approved: -1 | 0 | 1,
  hideToast?: boolean
) => {
  const request = {
    study_ids: studyIDs,
    approved: approved,
  };

  trackEvent('APPROVE_STUDIES', {datasetId, request});

  return http.put(`/v1/datasets/${datasetId}/approve`, request).then(() => {
    if (!hideToast) {
      toast(<ToastMessage title="Studies updated" icon="success" />);
    }

    return true;
  });
};

export const updateDatasetIncludeUndetermined = (
  http: AxiosInstance,
  datasetId: number,
  includeUndetermined: boolean
) => {
  const request: {include_undetermined: boolean} = {
    include_undetermined: includeUndetermined,
  };

  trackEvent('DATASET_UPDATE_INCLUDE_UNDETERMINED', {datasetId, request});

  return http
    .put(`/v1/datasets/${datasetId}/include_undetermined`, request)
    .then(() => {
      return true;
    });
};

export const orderDataset = (
  http: AxiosInstance,
  datasetId: number,
  hideToast?: boolean
) => {
  trackEvent('ORDER_DATASET', {datasetId});

  return http.post(`/v1/datasets/${datasetId}/order`).then(response => {
    const {data} = response;

    const dataset = responseToDataset(data);

    if (!hideToast) {
      toast(<ToastMessage title="Dataset ordered" icon="success" />);
    }

    return dataset;
  });
};

export const requestDatasetMetadata = (
  http: AxiosInstance,
  datasetId: number
) => {
  return http
    .post(`/v1/datasets/${datasetId}/request`)
    .then(response => {
      // Success
      const {data: datasetResponse} = response;
      return responseToDataset(datasetResponse);
    })
    .catch(err => {
      const message =
        err?.response?.data?.message ?? 'Error requesting dataset';
      toast(<ToastMessage title={message} icon="error" />);
      throw err;
    });
};

export const favoriteDataset = (
  http: AxiosInstance,
  datasetID: number,
  request: FavoriteTagRequest
) => {
  return http.put(`v1/datasets/${datasetID}/favorite`, request);
};

export const archiveDataset = (http: AxiosInstance, datasetID: number) => {
  trackEvent('ARCHIVE_DATASET', {datasetID});
  return http
    .put(`v1/datasets/${datasetID}/archive`)
    .then(() => {
      // Success
      toast(<ToastMessage title="Dataset archived" icon="success" />);
      return datasetID;
    })
    .catch(err => {
      const message = err?.response?.data?.message ?? 'Error archiving dataset';
      toast(<ToastMessage title={message} icon="error" />);
      throw err;
    });
};

// downloadDatasetAsCSV will download the dataset as a CSV file
export const downloadDatasetAsCSV = (
  http: AxiosInstance,
  datasetId: number
) => {
  trackEvent('DOWNLOAD_DATASET_AS_CSV', {datasetId});
  const baseURL: string =
    http.defaults.baseURL === undefined
      ? 'https://openda.segmed.ai'
      : http.defaults.baseURL;
  downloadFileFromURLSameTab(
    http,
    `${baseURL}/v1/datasets/${datasetId}/export/csv`
  );
};

export const editDataset = (
  http: AxiosInstance,
  datasetId: number,
  name: string
) => {
  trackEvent('DATASET_EDIT', {datasetId, name});
  return http.put(`/v1/datasets/${datasetId}`, {name: name});
};

export const deleteDataset = (http: AxiosInstance, datasetId: number) => {
  trackEvent('DATASET_DELETE', {datasetId});
  return http.delete(`/v1/datasets/${datasetId}`);
};
