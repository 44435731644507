import _ from 'lodash';
import cx from 'classnames';
import {HiOutlineArrowLeft, HiOutlineArrowRight} from 'react-icons/hi';

// getPaginationNums gets the page numbers to display in the pagination component with the current page as the middle number
const getPaginationNums = (
  current: number,
  max: number,
  maxPaginationLength: number
) => {
  // Get first number
  const min = Math.max(
    Math.min(
      current - Math.floor(maxPaginationLength / 2),
      max - maxPaginationLength + 1
    ),
    1
  );

  // Generate array
  const nums = _.map(
    new Array(_.min([max, maxPaginationLength])),
    (_, i) => min + i
  );

  return nums;
};

export const Pagination = ({
  max,
  current,
  linkFunc,
  maxPaginationLength = 10,
}: {
  max: number;
  current: number;
  linkFunc: (pageNum: number) => void;
  maxPaginationLength?: number;
}) => {
  const renderNum = (num: number, isActive?: boolean) => {
    return (
      <a
        key={num}
        onClick={() => {
          // Do not navigate to page if already on page
          if (!isActive) {
            linkFunc(num);
          }
        }}
        className={cx(
          'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
          {
            'border-blue-500 text-blue-600 cursor-default': isActive,
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer':
              !isActive,
          }
        )}
      >
        {num}
      </a>
    );
  };

  const renderNums = () => {
    const nums = getPaginationNums(current, max, maxPaginationLength);

    return (
      <>
        {nums.map(num => {
          const isActive = current === num;
          return renderNum(num, isActive);
        })}
      </>
    );
  };

  return (
    <nav className="border-t border-gray-200 px-4 pb-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex px-4">
        {current > 1 && (
          <button
            onClick={() => linkFunc(current - 1)}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
          >
            <HiOutlineArrowLeft className="mr-3 h-5 w-5 text-gray-400" />
            Previous
          </button>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">{renderNums()}</div>
      <div className="-mt-px w-0 flex-1 flex px-4 justify-end">
        {current < max && (
          <button
            onClick={() => linkFunc(current + 1)}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
          >
            Next
            <HiOutlineArrowRight className="ml-3 h-5 w-5 text-gray-400" />
          </button>
        )}
      </div>
    </nav>
  );
};
