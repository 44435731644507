import {useMemo} from 'react';
import {Column} from 'react-table';
import {DateTime} from 'luxon';
import {toast} from 'react-toastify';

import {ToastMessage} from '../../../core/components/toast';
import {formatBytes} from '../../../utils/strings';
import {
  getPresignedDownloadUrls,
  UserFileInfo,
  downloadFilesWithPresignedLocations,
} from '../../../models/fileDownload';
import {ReactTable, TextFilter} from '../../../core/components/react-table';
import {useAxios} from 'src/utils/http';

export const FileDownloadTable = ({
  uploadedFiles,
}: {
  uploadedFiles: UserFileInfo[];
}) => {
  const api = useAxios();

  const downloadFile = (locationIDs: number[]) => {
    getPresignedDownloadUrls(api, locationIDs)
      .then(presignedLocations => {
        downloadFilesWithPresignedLocations(presignedLocations);
      })
      .catch(err => {
        const message =
          err?.response?.data?.message ?? 'Error downloading file';
        toast(<ToastMessage title={message} icon="error" />);
      });
  };

  const columns = useMemo<Array<Column<UserFileInfo>>>(
    () => [
      {
        Header: 'Name',
        accessor: 'filename',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Uploaded By',
        accessor: row => `${row.lastName}, ${row.firstName}`,
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Organization',
        accessor: 'orgName',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Size',
        accessor: 'size',
        Cell: ({value}) => {
          return formatBytes(value);
        },
      },
      {
        Header: 'Uploaded At',
        accessor: 'updatedAt',
        Cell: ({value}) => {
          return value?.toLocaleString(DateTime.DATE_MED) ?? null;
        },
        sortType: (rowA, rowB) => {
          const a =
            rowA.original.updatedAt?.toMillis() ?? Number.MAX_SAFE_INTEGER;
          const b =
            rowB.original.updatedAt?.toMillis() ?? Number.MAX_SAFE_INTEGER;

          if (a > b) {
            return 1;
          }
          if (b > a) {
            return -1;
          }
          return 0;
        },
      },
      {
        id: 'download',
        Header: () => null,
        accessor: 'locationID',
        Cell: cell => {
          return (
            <button
              className="btn btn-link nopadding"
              title="Download"
              onClick={() => {
                downloadFile([cell.value]);
              }}
            >
              Download
            </button>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const data = useMemo(() => uploadedFiles, [uploadedFiles]);

  return (
    <ReactTable
      tableColumns={columns}
      tableData={data}
      defaultSortBy={[{id: 'updatedAt', desc: true}]}
      paginationDisabled={true}
    />
  );
};
