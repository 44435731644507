import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {Column} from 'react-table';
import {Helmet} from 'react-helmet';

import {TextFilter, ReactTable} from '../../../core/components/react-table';
import {fetchAllUsersAdmin, User} from '../../../models/user';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const AdminUsersPage = () => {
  const api = useAxios();

  const {data: users, isLoading: usersIsLoading} = useQuery(
    ['adminUsers'],
    () => {
      return fetchAllUsersAdmin(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const columns = useMemo<Array<Column<User>>>(
    () => [
      {
        Header: 'User Info',
        columns: [
          {
            Header: 'User ID',
            accessor: 'userId',
            Filter: TextFilter,
            filter: 'exactText',
          },
          {
            Header: 'Email',
            accessor: 'email',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}: {value: string}) => (
              <span title={value}>{value}</span>
            ),
          },
          {
            Header: 'Type',
            accessor: 'userType',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'First Name',
            accessor: 'firstname',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Last Name',
            accessor: 'lastname',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'AWS Identifier',
            accessor: 'awsIdentifier',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Email Confirmed',
            accessor: user => (user.confirmed ? 'True' : 'False'),
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Admin',
            accessor: user => (user.admin ? 'True' : 'False'),
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
    ],
    []
  );

  const data = useMemo(() => users ?? [], [users]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Manage Users</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <h1 className="text-2xl mb-5">All Users</h1>

        {usersIsLoading ? (
          'Loading users...'
        ) : (
          <ReactTable
            tableColumns={columns}
            tableData={data}
            showCountDesc={'Users'}
            defaultSortBy={[{id: 'userId', desc: true}]}
          />
        )}
      </ApplicationShell>
    </>
  );
};
