import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import queryString from 'query-string';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {useAuth0} from '@auth0/auth0-react';
import {useMutation} from 'react-query';

import {useURLQuery} from '../../../utils/router-helper';
import {Card} from 'src/core/layout/card';
import {currentAuth0Constants} from 'src';
import {resendVerificationEmail} from 'src/models/auth';
import {useAxios} from 'src/utils/http';
import {quickToast} from 'src/core/components/toast';

export const VerifyEmail = () => {
  const query = useURLQuery();
  const navigate = useNavigate();
  const http = useAxios({useAuth: false});
  const {logout} = useAuth0();
  useEffect(() => {
    if (_.isEmpty(query.state) || _.isEmpty(query.user_id)) {
      navigate('/');
    }
  }, [query]);

  const resendVerificationEmailMut = useMutation(
    ({userID}: {userID: string}) => resendVerificationEmail(http, userID),
    {
      onSuccess: () => {
        quickToast({title: 'Resent verification email', icon: 'success'});
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Verify Email</title>
      </Helmet>

      <div className="flex w-screen h-screen justify-center items-center flex-col space-y-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <img
            className="mx-auto h-24 w-auto"
            src="assets/logo/segmed_logo_elements.png"
            alt="Segmed"
          />
        </div>

        <Card className="max-w-screen-lg p-6">
          <div className="flex flex-col justify-center items-center space-y-4">
            <div className="text-3xl font-medium">Verification email sent</div>
            <div>Please click the link in your email to continue</div>
            <div className="flex space-x-2">
              <button
                onClick={() =>
                  (window.location.href = `https://${
                    currentAuth0Constants.domain
                  }/continue?${queryString.stringify({state: query.state})}`)
                }
                className="btn btn-primary btn-sm"
              >
                I have verified my email
              </button>
              <button
                onClick={() =>
                  resendVerificationEmailMut.mutate({
                    userID: query.user_id as string,
                  })
                }
                className="btn btn-secondary btn-sm"
              >
                Resend verification email
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
                className="btn btn-xs btn-white"
              >
                Log out
              </button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
