import {AxiosInstance} from 'axios';

export interface PriceIndices {
  modalities: PriceIndex;
  discounts?: PriceIndex;
}

export interface PriceIndex {
  label: string;
  pricePoints: PricePoint[];
}

export interface PricePoint {
  label: string;
  price: string;
}

export interface PriceIndicesResponse {
  modalities: PriceIndexResponse;
  discounts?: PriceIndexResponse;
}

export interface PriceIndexResponse {
  label: string;
  price_points: PricePointResponse[];
}

export interface PricePointResponse {
  label: string;
  price: string;
}

export const fetchPrices = (http: AxiosInstance) => {
  return http.get('/v1/pricing').then(response => {
    const {data} = response;
    return responseToPriceIndices(data);
  });
};

export const responseToPriceIndices = (
  priceIndicesResp: PriceIndicesResponse
) => {
  const priceIndices: PriceIndices = {
    modalities: responseToPriceIndex(priceIndicesResp.modalities),
    discounts:
      priceIndicesResp.discounts &&
      responseToPriceIndex(priceIndicesResp.discounts),
  };
  return priceIndices;
};

export const responseToPriceIndex = (priceIndexResp: PriceIndexResponse) => {
  const priceIndex: PriceIndex = {
    label: priceIndexResp.label,
    pricePoints: priceIndexResp.price_points.map(pricePointResp =>
      responseToPricePoint(pricePointResp)
    ),
  };
  return priceIndex;
};

export const responseToPricePoint = (pricePointResp: PricePointResponse) => {
  const pricePoint: PricePoint = {
    label: pricePointResp.label,
    price: pricePointResp.price,
  };
  return pricePoint;
};
