import React from 'react';
import {HiOutlineChevronRight} from 'react-icons/hi';
import cx from 'classnames';
import {isNil} from 'lodash';
import {Link} from 'react-router-dom';

export interface BreadcrumbLink {
  name: string;
  href?: string;
  current?: boolean;
}

export const Breadcrumbs = ({
  links,
  className,
  ...props
}: {links: BreadcrumbLink[]} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>) => {
  return (
    <nav className={cx('flex', className)} aria-label="Breadcrumb" {...props}>
      <ol className="flex items-center space-x-4">
        {links.map((link, linkIndex) => (
          <li key={linkIndex}>
            <div className="flex items-center">
              {linkIndex > 0 && (
                <HiOutlineChevronRight
                  className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4"
                  aria-hidden="true"
                />
              )}
              <span
                className={cx('text-sm font-medium text-gray-500', {
                  'hover:text-gray-700': !isNil(link.href),
                  'cursor-default': isNil(link.href),
                })}
              >
                {isNil(link.href) ? (
                  <>{link.name}</>
                ) : (
                  <Link
                    to={link.href}
                    aria-current={link.current ? 'page' : undefined}
                  >
                    {link.name}
                  </Link>
                )}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
