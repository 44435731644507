/* eslint-disable security/detect-object-injection */
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {useQuery} from 'react-query';

import {ApplicationShell} from '../../../core/layout/application-shell';
import {fetchDICOMHeadersCSV} from '../../../models/dicom-header';
import {useEffect, useMemo, useState} from 'react';

export const DicomHeaderSelectorPage = () => {
  const {data: dicomHeadersCSV} = useQuery(
    ['dicomHeaders'],
    () => fetchDICOMHeadersCSV(),
    {
      staleTime: Infinity,
    }
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - DICOM Header Selector</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="text-xl">DICOM Header Selector</div>

        <div className="text-md">
          Select the DICOM headers to be include in the de-id result. Unchecked
          dicom headers will be stripped during the de-id process
        </div>

        {dicomHeadersCSV && (
          <DicomHeaderSelector dicomHeadersCSV={dicomHeadersCSV} />
        )}
      </ApplicationShell>
    </>
  );
};

const dicomHeaderSelectorLocalStorageKey = 'dicom_header_selector_selected';

const DicomHeaderSelector = ({
  dicomHeadersCSV,
}: {
  dicomHeadersCSV: string[][];
}) => {
  const [selectedDicomHeaders, selectedDicomHeadersChanged] = useState<
    string[]
  >([]);

  const csvColumnHeaders = dicomHeadersCSV[0];
  const csvRows = dicomHeadersCSV.slice(1);
  const rowIndexes: {[tagname: string]: number} = {};
  const rows = _.map(csvRows, (csvRow, rowIndex) => {
    const row: {[header: string]: string} = {};
    _.forEach(csvColumnHeaders, (header, index) => {
      row[header] = csvRow[index];
    });
    rowIndexes[row.tagname] = rowIndex;
    return row;
  });
  const columnsToShow = [
    'hex1',
    'hex2',
    'vr',
    'vm',
    'description',
    'retired',
    'tagname',
  ];

  // Initialize selected headers with value from localStorage
  useEffect(() => {
    const savedDicomHeaderColumns = localStorage.getItem(
      dicomHeaderSelectorLocalStorageKey
    );
    if (savedDicomHeaderColumns) {
      onSelectedDicomHeadersChanged(JSON.parse(savedDicomHeaderColumns));
    } else {
      onSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectAll = (selected: boolean) => {
    let selectedHeaders: string[] = [];
    if (selected) {
      selectedHeaders = rows.map(row => row.tagname);
    }
    onSelectedDicomHeadersChanged(selectedHeaders);
  };

  const onSelectedDicomHeadersChanged = (dicomHeaders: string[]) => {
    const headersToSave = rows
      .map(row => row.tagname)
      .filter(dicomHeader => _.includes(dicomHeaders, dicomHeader));
    selectedDicomHeadersChanged(headersToSave);
    localStorage.setItem(
      dicomHeaderSelectorLocalStorageKey,
      JSON.stringify(headersToSave)
    );
  };

  const selectedIndeterminate =
    selectedDicomHeaders.length > 0 &&
    selectedDicomHeaders.length < csvRows.length;

  const exportToCsv = (tagNames: string[]) => {
    let csvContents = `${csvColumnHeaders.join(',')}\n`;
    const csvBody = _.chain(rowIndexes)
      .toPairsIn() // [tagname, rowIndex][]
      .filter(row => _.includes(tagNames, row[0])) // filter for rows included in csv
      .sort(rowIndex => rowIndex[1]) // sort by original row index
      .map(rowIndex => csvRows[rowIndex[1]]) // get final csv contents for row
      .value();
    for (const csvRow of csvBody) {
      csvContents += `${csvRow.join(',')}\n`;
    }

    const fileName = 'selected_segmed_dicom_headers.csv';

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContents)
    );
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const data = useMemo(() => rows, [rows]);

  return (
    <>
      <div className="flex justify-between items-end">
        <div className="my-4">
          {`${selectedDicomHeaders.length}/${csvRows.length}`} DICOM headers
          selected for de-id
        </div>
        <button
          className="btn btn-primary my-4"
          onClick={() => exportToCsv(selectedDicomHeaders)}
        >
          Export as CSV
        </button>
      </div>

      <table className="text-left min-w-full divide-y divide-gray-200 my-4">
        <thead className="bg-gray-50 divide-y divide-gray-200">
          <tr className="">
            <th className="px-2">
              <input
                id="toggleAllPageRowsSelected"
                type="checkbox"
                className="checkbox-input"
                ref={el => el && (el.indeterminate = selectedIndeterminate)}
                checked={selectedDicomHeaders.length === csvRows.length}
                onChange={() => onSelectAll(selectedDicomHeaders.length === 0)}
              />
            </th>
            {columnsToShow.map((header, i) => (
              <th
                key={i}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="px-2">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id={'checkbox_dicom_header_' + rowIndex}
                  checked={_.includes(selectedDicomHeaders, row.tagname)}
                  onChange={e => {
                    e.target;
                    let dicomHeaders = selectedDicomHeaders;
                    if (e.target.checked) {
                      dicomHeaders = [...dicomHeaders, row.tagname];
                    } else {
                      dicomHeaders = dicomHeaders.filter(
                        tagName => tagName !== row.tagname
                      );
                    }
                    onSelectedDicomHeadersChanged(dicomHeaders);
                  }}
                />
              </td>
              {columnsToShow.map((header, i) => (
                <td key={i} className="px-6 py-4 whitespace-nowrap text-sm">
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
