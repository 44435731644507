import {useState} from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {
  HiOutlineExternalLink,
  HiOutlineInformationCircle,
  HiOutlineArrowRight,
} from 'react-icons/hi';

import {ApplicationShell} from '../../../core/layout/application-shell';
import {SearchForm, emptySearchQuery} from '../../../components/search-form';
import {Card} from '../../../core/layout/card';
import {SearchQuery} from '../../../models/search';
import {Banner} from '../../../core/components/banner';
import {trackEvent} from '../../../utils/tracking';
import {EmbedVideo} from '../../../core/components/embed-video';
import {premadeDatasets, demoVideos} from '../minerva-search/minerva-search';

export const SearchOldPage = () => {
  const [searchQuery, searchQueryChange] = useState<SearchQuery>(
    _.cloneDeep(emptySearchQuery)
  );

  const [showBanner, setShowBanner] = useState(
    !localStorage.getItem('searchTour')
  );
  const [tourActive, tourActiveChange] = useState(false);

  const sampleSearches: {
    label: string;
    bodyText: string;
    query: SearchQuery;
    hide?: boolean;
  }[] = [
    {
      label: 'Example search for mammo screening with nodule or mass',
      bodyText:
        'CT; Breast; US East; report contains “screening”, impression contains (no negations) “nodule|mass”',
      query: {
        ...emptySearchQuery,
        modality: ['MAMMO'],
        bodyArea: ['Breast'],
        sourceLocation: ['us_east'],
        keywords: [
          {
            area: 'all',
            operator: 'contains substring',
            text: 'screening',
            booloperator: 'AND',
          },
          {
            area: 'impression',
            operator: 'contains substring (no negations)',
            text: 'nodule|mass',
            booloperator: 'AND',
          },
        ],
      },
    },
    {
      label: 'Example search for CT Angiogram (CTA) with aortic aneurysm',
      bodyText:
        'CT; US East; report contains “CTA-ABD|CTA ABD|CTA-CHEST|CTA CHEST”, impression contains (no negations) “aortic aneurysm|AAA”, impression excludes “status post|repair|graft”',
      query: {
        ...emptySearchQuery,
        modality: ['CT'],
        sourceLocation: ['us_east'],
        keywords: [
          {
            area: 'all',
            operator: 'contains substring',
            text: 'CTA-ABD|CTA ABD|CTA-CHEST|CTA CHEST',
            booloperator: 'AND',
          },
          {
            area: 'impression',
            operator: 'contains substring (no negations)',
            text: 'aortic aneurysm|AAA',
            booloperator: 'AND',
          },
          {
            area: 'impression',
            operator: 'excludes substring',
            text: 'status post|repair|graft',
            booloperator: 'AND',
          },
        ],
      },
    },
    {
      label: 'Example search for "normal" chest CTs without contrast',
      bodyText:
        'CT; Chest; US East; impression contains “unremarkable|negative”, report contains “non contrast|w/o con|without contrast”, impression excludes “otherwise”',
      query: {
        ...emptySearchQuery,
        modality: ['CT'],
        sourceLocation: ['us_east'],
        bodyArea: ['Chest'],
        keywords: [
          {
            area: 'impression',
            operator: 'contains substring',
            text: 'unremarkable|negative',
            booloperator: 'AND',
          },
          {
            area: 'all',
            operator: 'contains substring',
            text: 'non contrast|w/o con|without contrast',
            booloperator: 'AND',
          },
          {
            area: 'impression',
            operator: 'excludes substring',
            text: 'otherwise',
            booloperator: 'AND',
          },
        ],
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Segmed Openda</title>
      </Helmet>

      <ApplicationShell
        bgcolor="bgcolor"
        contained={false}
        navbar={
          <div className="navbar">
            <div className="ml-auto text-sm text-gray-700">
              Having trouble finding data?
              <a
                className="link ml-1"
                href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                target="_blank"
                rel="noreferrer"
              >
                Schedule a help session
                <HiOutlineArrowRight className="inline-block ml-1" />
              </a>
            </div>
          </div>
        }
      >
        {showBanner && (
          <Banner
            className="-mt-6 -mx-6 mb-6"
            onClick={() => {
              localStorage.setItem('searchTour', 'true');
              setShowBanner(false);
            }}
          >
            <>
              Tap on the information icons
              <HiOutlineInformationCircle className="h-5 w-5 text-white" />
              to tour the features in that area.
              <span
                className="text-white font-bold underline cursor-pointer"
                onClick={() => {
                  localStorage.setItem('searchTour', 'true');
                  setShowBanner(false);
                  tourActiveChange(true);
                }}
              >
                Start the first tour &rarr;
              </span>
            </>
          </Banner>
        )}

        <Card>
          <SearchForm
            query={searchQuery}
            queryChange={searchQueryChange}
            tourActive={tourActive}
          />
        </Card>

        <div className="grid grid-cols-1 w-full gap-8 auto-rows-min my-6">
          <div className="mx-auto space-y-2 text-gray-900">
            <div>
              <div className="text-xl mb-2 font-medium">Sample Searches</div>
              <div className="text-md text-gray-900">
                Try out these search ideas to experiment with results.
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
              {sampleSearches.map((sampleSearch, i) => {
                return sampleSearch.hide ? null : (
                  <div
                    key={i}
                    className="flex flex-col gap-y-1 text-sm bg-white shadow rounded-lg pt-5"
                  >
                    <div className="px-4 pb-4">
                      <div className="mb-1">{sampleSearch.label}</div>
                      <div className="text-gray-500 text-xs">
                        {sampleSearch.bodyText}
                      </div>
                    </div>

                    <div
                      className="mt-auto link bg-gray-50 py-2 px-4 rounded-lg hover:cursor-pointer"
                      onClick={() => {
                        trackEvent('CLICK_SAMPLE_SEARCH', {
                          label: sampleSearch.label,
                        });
                        searchQueryChange(sampleSearch.query);
                      }}
                    >
                      Try this search &rarr;
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="space-y-2 text-gray-900">
            <div>
              <div className="text-xl mb-2 font-medium">
                Datasets ready to purchase today
              </div>
              <div className="text-md text-gray-900">
                These pre-made datasets can be purchased as is. Click on an
                option for details.
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
              {premadeDatasets.map((dataset, i) => {
                return (
                  <Card key={i}>
                    <div key={i} className="flex flex-col gap-y-1 text-sm">
                      <a
                        className="link font-sm"
                        href={dataset.url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          trackEvent('CLICK_OPEN_PREMADE_DATASET', {
                            label: dataset.label,
                            url: dataset.url,
                          })
                        }
                      >
                        <div className="flex flex-row justify-between items-center">
                          {dataset.label}
                          <div className="mb-auto">
                            <HiOutlineExternalLink className="w-5 h-5 inline-block ml-1" />
                          </div>
                        </div>
                      </a>
                      <div className="text-gray-500 text-xs">
                        {dataset.bodyText}
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
          {/* TODO consider a modal for the videos */}
          <div className="space-y-2 text-gray-900">
            <div className="text-xl mb-2 font-medium">Demo videos</div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
              {demoVideos.map((demo, i) => {
                return (
                  <div key={i} className="flex flex-col">
                    <div className="text-md text-gray-900 pb-2">
                      {demo.label}
                    </div>
                    <div className="flex flex-col gap-y-1 text-smborder border-transparent rounded-md shadow-sm border-gray-300 z-10 overflow-hidden">
                      <EmbedVideo src={demo.embed} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ApplicationShell>
    </>
  );
};
