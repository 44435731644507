import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import queryString from 'query-string';
import {useHotkeys} from 'react-hotkeys-hook';
import {useQuery, useQueryClient} from 'react-query';
import {HiOutlineChevronLeft} from 'react-icons/hi';

import {
  decodeURLObject,
  encodeURLObject,
  useURLQuery,
} from '../../../utils/router-helper';

import {Loading} from '../../../core/components/loading';
import {ReportComponent} from '../../../components/report';
import {ErrorPage} from '../../../core/layout/error-page';
import {fetchReport} from '../../../models/report';
import {fetchSearchDemogResults, SearchQuery} from '../../../models/search';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export interface SearchReportNavigation {
  searchQuery: string;
  currentStudyId: string;
  error?: boolean;
  prevLoading: boolean;
  prevStudy?: {
    page: number;
    studyId: string;
  };
  nextLoading: boolean;
  nextStudy?: {
    page: number;
    studyId: string;
  };
}

export const AdvancedSearchReportDetails = () => {
  const urlQuery = useURLQuery();
  const api = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const studyId = _.isString(urlQuery?.id) ? urlQuery!.id : undefined;
  const searchQuery: SearchQuery = decodeURLObject(urlQuery.q ?? undefined);
  const searchPage =
    _.toInteger(urlQuery.page) > 0 ? _.toInteger(urlQuery.page) : 1;
  const [reportNavigation, reportNavigationChange] =
    useState<SearchReportNavigation>();

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', studyId],
    () => {
      return fetchReport(api, studyId as string);
    },
    {
      enabled: _.isString(studyId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: currentPageResults, isLoading: currentPageLoading} = useQuery(
    ['search_demog', JSON.stringify(searchQuery), searchPage],
    () => fetchSearchDemogResults(api, searchQuery, searchPage),
    {
      staleTime: Infinity,
      enabled: !_.isNil(searchQuery), // @todo: Validation for searchquery
    }
  );
  const {data: prevPageResults} = useQuery(
    ['search_demog', JSON.stringify(searchQuery), searchPage - 1],
    () => fetchSearchDemogResults(api, searchQuery, searchPage - 1),
    {
      staleTime: Infinity,
      enabled: !_.isNil(currentPageResults) && searchPage > 1, // @todo: Validation for searchquery
    }
  );
  const {data: nextPageResults} = useQuery(
    ['search_demog', JSON.stringify(searchQuery), searchPage + 1],
    () => fetchSearchDemogResults(api, searchQuery, searchPage + 1),
    {
      staleTime: Infinity,
      enabled:
        !_.isNil(currentPageResults) &&
        searchPage < currentPageResults.totalPages, // @todo: Validation for searchquery
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setupNavigation = (studyId: string) => {
    const errorReportNavigation: SearchReportNavigation = {
      searchQuery: JSON.stringify(searchQuery),
      currentStudyId: studyId,
      error: true,
      prevLoading: false,
      nextLoading: false,
    };

    if (_.isNil(currentPageResults)) {
      if (!currentPageLoading) {
        reportNavigationChange(errorReportNavigation);
      }
      return;
    }

    const studyIndex = _.findIndex(currentPageResults.reports, {
      studyId,
    });

    if (studyIndex === -1) {
      if (!_.isEqual(reportNavigation, errorReportNavigation)) {
        reportNavigationChange(errorReportNavigation);
      }
      return;
    }

    let prevStudy: {page: number; studyId: string} | undefined;
    let nextStudy: {page: number; studyId: string} | undefined;
    let prevLoading = true;
    let nextLoading = true;
    if (studyIndex !== -1) {
      if (studyIndex > 0) {
        prevStudy = {
          page: currentPageResults.page,
          studyId: currentPageResults.reports[studyIndex - 1].studyId,
        };
        prevLoading = false;
      } else if (studyIndex === 0) {
        if (currentPageResults.page === 1) {
          // No previous study
          prevLoading = false;
        } else if (!_.isNil(prevPageResults)) {
          prevStudy = {
            page: prevPageResults.page,
            studyId: _.last(prevPageResults.reports)!.studyId,
          };
          prevLoading = false;
        }
      }

      if (studyIndex < currentPageResults.reports.length - 1) {
        nextStudy = {
          page: currentPageResults.page,
          studyId: currentPageResults.reports[studyIndex + 1].studyId,
        };
        nextLoading = false;
      } else if (studyIndex === currentPageResults.reports.length - 1) {
        if (currentPageResults.page === currentPageResults.totalPages) {
          // Last page
          nextLoading = false;
        } else if (!_.isNil(nextPageResults)) {
          nextStudy = {
            page: nextPageResults.page,
            studyId: nextPageResults.reports[0].studyId,
          };
          nextLoading = false;
        }
      }
    }

    const newReportNavigation: SearchReportNavigation = {
      searchQuery: JSON.stringify(searchQuery),
      currentStudyId: studyId,
      prevLoading,
      prevStudy,
      nextLoading,
      nextStudy,
    };

    if (!_.isEqual(reportNavigation, newReportNavigation)) {
      reportNavigationChange(newReportNavigation);
    }
  };

  useEffect(() => {
    if (!_.isNil(report)) {
      setupNavigation(report.studyId);
    }
  }, [
    currentPageResults,
    prevPageResults,
    report,
    reportNavigation,
    searchQuery,
    setupNavigation,
  ]);

  const prevButtonPressed = () => {
    if (!_.isNil(reportNavigation?.prevStudy)) {
      navigate({
        pathname: '/advanced_search/report',
        search: queryString.stringify({
          q: urlQuery?.q,
          page: reportNavigation!.prevStudy.page,
          id: reportNavigation!.prevStudy.studyId,
        }),
      });
    }
  };
  const nextButtonPressed = () => {
    if (!_.isNil(reportNavigation?.nextStudy)) {
      navigate({
        pathname: '/advanced_search/report',
        search: queryString.stringify({
          q: urlQuery?.q,
          page: reportNavigation!.nextStudy.page,
          id: reportNavigation!.nextStudy.studyId,
        }),
      });
    }
  };
  useHotkeys('left', () => prevButtonPressed(), [reportNavigation]);
  useHotkeys('right', () => nextButtonPressed(), [reportNavigation]);

  if (reportError || _.isNil(studyId)) {
    // Error getting report
    return <ErrorPage statusCode={404} />;
  }

  const navLoading =
    (_.isNil(currentPageResults) && currentPageLoading) ||
    reportNavigation?.prevLoading ||
    reportNavigation?.nextLoading;

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Report Details</title>
      </Helmet>

      <ApplicationShell>
        <div className="mb-5">
          <Link
            to={{
              pathname: '/advanced_search/results',
              search: queryString.stringify({
                q: encodeURLObject(searchQuery),
                page: searchPage,
              }),
            }}
            className="inline-flex items-center text-primary hover:text-primary-active"
          >
            <HiOutlineChevronLeft className="h-4 w-4 inline-block mr-1" />
            <div>Back to results</div>
          </Link>
        </div>

        {reportLoading && <Loading />}

        {report && (
          <ReportComponent
            report={report}
            PHIReported={() => {
              queryClient.removeQueries(['report', report.studyId]);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['dataset']);
              queryClient.removeQueries(['search']);
              navigate({
                pathname: '/advanced_search/results',
                search: queryString.stringify({
                  q: encodeURLObject(searchQuery),
                  page: searchPage,
                }),
              });
            }}
            navMessage={
              (navLoading && 'Loading navigation...') ||
              (reportNavigation?.error && 'Error loading navigation') ||
              undefined
            }
            showPrevButton={!_.isNil(reportNavigation?.prevStudy?.studyId)}
            showNextButton={!_.isNil(reportNavigation?.nextStudy?.studyId)}
            onPrevButtonClick={() => prevButtonPressed()}
            onNextButtonClick={() => nextButtonPressed()}
          />
        )}
      </ApplicationShell>
    </>
  );
};
