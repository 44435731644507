import {useMemo} from 'react';
import {Column} from 'react-table';
import {DateTime} from 'luxon';

import {TextFilter, ReactTable} from '../../core/components/react-table';
import {Report} from '../../models/report';

export const ReportsTable = ({reports}: {reports: Report[]}) => {
  const columns = useMemo<Array<Column<Report>>>(
    () => [
      {
        Header: 'Study ID',
        accessor: 'studyId',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Patient ID',
        accessor: 'patientId',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Title',
        accessor: 'reportTitle',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Exam date',
        accessor: 'examDate',
        Cell: ({value}) => {
          return <>{value?.toLocaleString(DateTime.DATE_MED) ?? null}</>;
        },
        sortType: (rowA, rowB) => {
          const a =
            rowA.original.examDate?.toMillis() ?? Number.MAX_SAFE_INTEGER;
          const b =
            rowB.original.examDate?.toMillis() ?? Number.MAX_SAFE_INTEGER;

          if (a > b) {
            return 1;
          }
          if (b > a) {
            return -1;
          }
          return 0;
        },
      },
      {
        Header: 'Modality',
        accessor: 'modality',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer',
        Filter: TextFilter,
        filter: 'includes',
      },
      {
        Header: 'Report Text',
        accessor: 'text',
        Filter: TextFilter,
        filter: 'includes',
        Cell: ({value}) => (
          <div className="overflow-hidden text-ellipsis max-w-xs" title={value}>
            {value}
          </div>
        ),
      },
      // @todo: Include assigned datasets/tags on table
    ],
    []
  );

  const data = useMemo(() => reports, [reports]);

  return (
    <ReactTable
      tableColumns={columns}
      tableData={data}
      defaultSortBy={[{id: 'studyId', desc: true}]}
    />
  );
};
