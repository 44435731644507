/* eslint-disable security/detect-object-injection */
export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes: {[key: number]: string} = {
    0: 'B',
    1: 'KB',
    2: 'MB',
    3: 'GB',
    4: 'TB',
    5: 'PB',
    6: 'EB',
    7: 'ZB',
    8: 'YB',
  };

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatNumber = (num?: number) => {
  if (num === undefined) {
    return '';
  }
  // eslint-disable-next-line security/detect-unsafe-regex
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function formatNumberWithSuffix(num: number): string {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'm';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + 'k';
  } else {
    return num.toString();
  }
}

export const validateParentheses = (text: string) => {
  const stack: string[] = [];
  for (let i = 0; i < text.length; i++) {
    if (text[i] === '(') {
      stack.push('(');
    } else if (text[i] === ')') {
      if (stack.pop() !== '(') {
        return false;
      }
    } else if (text[i] === '{') {
      stack.push('{');
    } else if (text[i] === '}') {
      if (stack.pop() !== '{') {
        return false;
      }
    } else if (text[i] === '[') {
      stack.push('[');
    } else if (text[i] === ']') {
      if (stack.pop() !== '[') {
        return false;
      }
    }
  }
  return stack.length === 0;
};

export const formatCurrency = (amount: number, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(amount);
};

export const formatOrdinalNumber = (num?: number) => {
  if (num === undefined) {
    return '';
  }
  const suffixes = ['st', 'nd', 'rd'];
  const index = ((((Math.abs(num) + 90) % 100) - 10) % 10) - 1;
  const suffix = suffixes[index] || 'th';
  return formatNumber(num) + suffix;
};
