import {useEffect, useMemo, useState} from 'react';
import _, {cloneDeep, filter, forEach, includes} from 'lodash';
import {Helmet} from 'react-helmet';
import {
  HiCheck,
  HiDownload,
  HiInformationCircle,
  HiViewBoards,
} from 'react-icons/hi';
import {useMutation, useQueries, useQuery, useQueryClient} from 'react-query';
import {trackEvent} from '../../../utils/tracking';
import ReactTooltip from 'react-tooltip';

import {
  isAnyReportSelected,
  ReportSelectionEventType,
  useReportSelection,
} from '../../../hooks/report-selection-provider';
import {MetadataTable} from './metadata-table';
import {
  approveDatasetStudies,
  Dataset,
  DATASET_STAGE_TYPE,
  datasetStatusToStage,
  DatasetStudy,
  downloadDatasetAsCSV,
  fetchAllDatasets,
  fetchDatasetPrice,
  orderDataset,
} from '../../../models/dataset';
import {Breadcrumbs} from '../../../core/components/breadcrumbs';
import {Modal} from '../../../core/layout/modal';
import {Badge} from '../../../core/components/badge';
import {MetadataTour} from '../../../core/components/tour/metadata-tour';
import {
  AddToDatasetDropdown,
  DatasetPricingQuoteCard,
  DatasetRequestMetadataButton,
  DatasetRequestMetadataConfirmedModal,
  DatasetSubtotal,
  IncludeUndeterminedStudiesToggle,
  RemoveFromDatasetButton,
} from '../../../components/dataset';
import {DicomHeadersModal} from '../../../components/dicom-header-modal';
import {MenuDropdown} from '../../../core/components/menu';
import {formatNumber} from '../../../utils/strings';
import {
  getAvailableTags,
  getStudiesTags,
  StudyTag,
  studyTagsBatchSize,
} from '../../../models/tags';
import {TagDropdown} from '../../../components/tags';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {Link} from 'react-router-dom';
import {isInternal} from '../../../models/auth';
import {useAuth} from '../../../hooks/auth';
import {HiDocumentArrowDown} from 'react-icons/hi2';
import {Card} from '../../../core/layout/card';
import {useAxios} from 'src/utils/http';

export const MetadataPage = ({
  dataset,
  studies,
}: {
  dataset: Dataset;
  studies: DatasetStudy[];
}) => {
  const queryClient = useQueryClient();
  const api = useAxios();

  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  type modalTypes =
    | 'orderDataset'
    | 'orderDatasetConfirmation'
    | 'requestMetadataConfirmation'
    | undefined;
  const [modalType, modalTypeChange] = useState<modalTypes>();
  const [dicomHeaderModalOpen, dicomHeaderModalOpenChange] =
    useState<boolean>(false);
  const [visibleDicomHeaders, visibleDicomHeadersChange] = useState<string[]>(
    []
  );

  const openModal = (type: modalTypes) => {
    modalTypeChange(type);
  };

  const closeModal = () => {
    modalTypeChange(undefined);
  };
  const {authState} = useAuth();

  const datasetOrdered = [
    DATASET_STAGE_TYPE.DatasetOrdered,
    DATASET_STAGE_TYPE.DatasetDelivered,
  ].includes(datasetStatusToStage(dataset.status));
  const metadataRequested = ![DATASET_STAGE_TYPE.DatasetCreated].includes(
    datasetStatusToStage(dataset.status)
  );
  const datasetDelivered = [
    DATASET_STAGE_TYPE.MetadataReady,
    DATASET_STAGE_TYPE.MetadataEmailSent,
    DATASET_STAGE_TYPE.DatasetOrdered,
    DATASET_STAGE_TYPE.DatasetDelivered,
  ].includes(datasetStatusToStage(dataset.status));
  // @todo: Put these checks into model
  const datasetCanApproveStudies = ![
    DATASET_STAGE_TYPE.DatasetCreated,
    DATASET_STAGE_TYPE.DatasetOrdered,
    DATASET_STAGE_TYPE.DatasetDelivered,
  ].includes(datasetStatusToStage(dataset.status));
  const datasetCanPlaceOrder = [DATASET_STAGE_TYPE.MetadataEmailSent].includes(
    datasetStatusToStage(dataset.status)
  );
  const datasetCanRequestMetadata = !metadataRequested;
  const datasetCanRemoveStudies = [DATASET_STAGE_TYPE.DatasetCreated].includes(
    datasetStatusToStage(dataset.status)
  );

  const [metadataTourActive, metadataTourActiveChange] = useState(false);

  // Initialize visible columns with value from localStorage
  useEffect(() => {
    const savedDicomHeaderColumns = localStorage.getItem(
      'visible_dicom_header_columns'
    );
    if (savedDicomHeaderColumns) {
      visibleDicomHeadersChange(JSON.parse(savedDicomHeaderColumns));
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataset.status]);

  const {data: datasetPrice} = useQuery(
    ['dataset', dataset.id, 'price'],
    () => fetchDatasetPrice(api, dataset.id),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: tags} = useQuery(['tags'], () => getAvailableTags(api), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const batchStudyTags = useQueries(
    _.chunk(studies, studyTagsBatchSize).map(chunkedStudies => {
      return {
        queryKey: [
          'batchStudyTags',
          chunkedStudies.map(study => study.studyId),
          'tags',
        ],
        queryFn: () => {
          return getStudiesTags(
            api,
            chunkedStudies.map(study => study.studyId)
          );
        },
        staleTime: 5 * 60 * 1000, // 5 minutes,
        enabled: !!chunkedStudies,
        onSuccess: (batchTags: StudyTag[]) =>
          batchTags.forEach(tag =>
            queryClient.setQueryData(['report', tag.studyID, 'tag'], tag)
          ),
      };
    })
  ).flatMap(tag => tag.data ?? []);

  const studyTags = useQueries(
    (batchStudyTags ?? []).map(studyTag => {
      return {
        queryKey: ['report', studyTag.studyID, 'tag'],
        queryFn: () => {
          return getStudiesTags(api, [studyTag.studyID]);
        },
        enabled: false,
        staleTime: Infinity,
        initialData: studyTag,
      };
    })
  ).flatMap(tag => tag.data ?? []);

  const {data: datasets} = useQuery(['dataset'], () => fetchAllDatasets(api), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const approveStudiesMut = useMutation(
    ({studyIds, approved}: {studyIds: string[]; approved: -1 | 0 | 1}) =>
      approveDatasetStudies(api, dataset.id, studyIds, approved),
    {
      onSuccess: (_result, {studyIds, approved}) => {
        forEach(
          filter(studies, study => includes(studyIds, study.studyId)),
          study => {
            study.approved = approved;
          }
        );

        queryClient.invalidateQueries(['dataset', dataset.id, 'price']);

        queryClient.setQueryData(['dataset', dataset.id], {dataset, studies});
        reportSelectionDispatch({
          type: ReportSelectionEventType.RESET,
        });
      },
    }
  );

  const studiesSelected = isAnyReportSelected(reportSelectionState);
  const approveStudiesDisabled =
    !datasetCanApproveStudies ||
    !studiesSelected ||
    approveStudiesMut.isLoading;

  const orderDatasetMut = useMutation(
    ({datasetId}: {datasetId: number}) => orderDataset(api, datasetId),
    {
      onSuccess: dataset => {
        closeModal();
        openModal('orderDatasetConfirmation');

        queryClient.setQueryData(['dataset', dataset.id], {
          dataset: cloneDeep(dataset),
          studies,
        });
      },
    }
  );

  const placeOrderModal = () => {
    return (
      <>
        <Modal
          isOpen={modalType === 'orderDataset'}
          onRequestClose={() => closeModal()}
          className="w-full md:w-md"
          side="right"
          padding={false}
        >
          <div className="p-6 text-sm space-y-4">
            <div className="text-lg">Your DICOM Order</div>

            <IncludeUndeterminedStudiesToggle
              dataset={dataset}
              description={
                <div>
                  <span className="text-gray-900">
                    Include studies with <Badge type="info">Undetermined</Badge>{' '}
                    order status
                  </span>
                  <br />
                  <span className="text-gray-500">
                    (Studies that have neither been added to nor excluded from
                    the order)
                  </span>
                </div>
              }
              onIncludeUndeterminedChange={includeUndetermined => {
                // update dataset data if exists
                if (queryClient.getQueryData(['dataset', dataset.id])) {
                  queryClient.setQueryData(
                    ['dataset', dataset.id],
                    (data?: {dataset: Dataset; studies: DatasetStudy[]}) => {
                      data = data!;
                      data.dataset.includeUndetermined = includeUndetermined;
                      return data;
                    }
                  );
                }
              }}
            />

            {datasetPrice && (
              <>
                <div className="text-gray-500">
                  You have {formatNumber(datasetPrice.numStudies)} studies in
                  your order
                </div>

                <DatasetSubtotal cart={datasetPrice} />
              </>
            )}

            <div className="text-red-600">
              Please note that the dataset cannot be altered once the order is
              submitted.
            </div>

            <div className="text-gray-500">
              Payment instructions and the final contract will be handled
              through email. You will receive an email when the DICOMs are ready
              to download. The DICOMs are usually available 14 business days
              after the contract is signed.
            </div>
          </div>

          <div className="absolute bottom-0 w-full p-4 border-t">
            <div className="flex justify-end space-x-2 w-full">
              <button
                className="btn btn-white inline-block"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary inline-block"
                onClick={() => orderDatasetMut.mutate({datasetId: dataset.id})}
              >
                Submit DICOM Order
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalType === 'orderDatasetConfirmation'}
          onRequestClose={() => closeModal()}
          className="w-96"
        >
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100 mb-5">
            <HiCheck className="h-6 w-6 text-emerald-600" aria-hidden="true" />
          </div>
          <div className="text-lg mb-3 text-center">DICOM order received</div>
          <div className="text-sm text-gray-500 mb-5 text-center">
            Thank you for your order. Please watch your email for payment
            instructions and the final contract.
          </div>

          <button
            className="btn btn-primary w-full"
            onClick={() => closeModal()}
          >
            Return to dataset
          </button>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dataset Details</title>
      </Helmet>

      <ApplicationShell
        bodyClassName="h-screen"
        noPadding
        navbar={
          <div className="flex justify-end w-full h-full items-center">
            {datasetCanRequestMetadata && (
              <DatasetRequestMetadataButton
                datasetId={dataset.id}
                disabled={studies.length === 0}
                numStudies={studies.length}
                onSuccess={() => modalTypeChange('requestMetadataConfirmation')}
              />
            )}
            {metadataRequested && (
              <div>
                {!datasetOrdered && (
                  <div
                    className="justify-self-end"
                    data-tip={
                      !datasetCanPlaceOrder
                        ? 'Button will be enabled once DICOM metadata has been retrieved and samples are ready. A notification will be sent via email. Contact support@segmed.ai for additional info'
                        : undefined
                    }
                    data-event="mouseenter"
                    data-event-off="mouseleave click"
                  >
                    <button
                      className="btn btn-primary"
                      data-tour="metadata-page-dicom-order"
                      onClick={() => {
                        openModal('orderDataset');
                      }}
                      disabled={!datasetCanPlaceOrder}
                    >
                      Place DICOM Order
                    </button>
                  </div>
                )}
                {datasetOrdered && (
                  <div>
                    <Badge type="success">Order submitted</Badge>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        contained={false}
        bgcolor="bgcolor"
      >
        <div className="md:px-6 px-4 pt-6 space-y-2 mb-10">
          <Breadcrumbs
            links={[
              {name: 'Your Datasets', href: '/datasets'},
              {
                name: dataset.name,
                current: true,
              },
            ]}
            className="mb-4"
          />

          <div className="flex justify-between">
            <div className="text-3xl font-medium mb-6 flex items-center">
              {dataset.name}{' '}
              <HiInformationCircle
                className="mx-1 text-xl text-primary hover:text-primary-active hover:cursor-pointer"
                onClick={() => {
                  trackEvent('CLICK_METADATA_TOUR_BTN');
                  metadataTourActiveChange(true);
                }}
              />
            </div>
          </div>

          <div className="text-gray-500 w-1/2">
            If a desired parameter is missing from the metadata table, please
            contact us at{' '}
            <a className="link" href="mailto:support@segmed.ai">
              support@segmed.ai
            </a>
            . Additionally here are some things we do to ensure HIPAA
            compliance:
            <ul className="list-disc list-inside">
              <li>Age - Patients older than 89 are listed simply as 89+</li>{' '}
              <li>
                Exam Date - Exam dates are timeshifted. Time between a patient’s
                exams is preserved.
              </li>
            </ul>
          </div>

          <div>
            {isInternal(authState.profile!.email) && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 my-10 w-full">
                {/* Pricing Card */}
                <DatasetPricingQuoteCard
                  dataset={dataset}
                  showIncludeUndeterminedToggle={datasetCanApproveStudies}
                  onIncludeUndeterminedChange={includeUndetermined => {
                    // update dataset data if exists
                    if (queryClient.getQueryData(['dataset', dataset.id])) {
                      queryClient.setQueryData(
                        ['dataset', dataset.id],
                        (data?: {
                          dataset: Dataset;
                          studies: DatasetStudy[];
                        }) => {
                          data = data!;
                          data.dataset.includeUndetermined =
                            includeUndetermined;
                          return data;
                        }
                      );
                    }
                  }}
                />
                {/* Distribution Card */}
                <div className="ml-8">
                  <Card>
                    <div className="text-gray-500 text-sm font-medium">
                      Internal distribution prototype
                    </div>
                    <div className="text-gray-700 text-sm">
                      <Link
                        className="text-primary hover:text-primary-active"
                        to={'/' + dataset.id + '/distributions'}
                      >
                        Create a distribution
                      </Link>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-4" data-tour="metadata-page-all-metadata">
          <div className="flex justify-between px-4">
            <div className="flex space-x-4 items-center">
              <div className="text-xs">
                {reportSelectionState.add.ids.size} selected
              </div>
              {datasetCanApproveStudies && (
                <div
                  data-tour="metadata-page-approve-exclude"
                  className="space-x-4"
                >
                  <MenuDropdown
                    align="left"
                    buttons={[
                      {
                        children: 'Add to Order',
                        disabled: approveStudiesDisabled,
                        onClick: () =>
                          approveStudiesMut.mutate({
                            studyIds: Array.from(reportSelectionState.add.ids),
                            approved: 1,
                          }),
                      },
                    ]}
                    menuButtonDisabled={approveStudiesDisabled}
                    menuItems={[
                      {
                        itemText: () => 'Exclude from Order',
                        disabled: approveStudiesDisabled,
                        onClick: () =>
                          approveStudiesMut.mutate({
                            studyIds: Array.from(reportSelectionState.add.ids),
                            approved: -1,
                          }),
                      },
                      {
                        itemText: () => 'Reset to Undetermined',
                        disabled: approveStudiesDisabled,
                        onClick: () =>
                          approveStudiesMut.mutate({
                            studyIds: Array.from(reportSelectionState.add.ids),
                            approved: 0,
                          }),
                      },
                    ]}
                  />
                </div>
              )}
              <AddToDatasetDropdown
                disabled={!studiesSelected}
                reportSelectionState={reportSelectionState}
                datasetFilter={datasets =>
                  datasets.filter(d => d.id !== dataset.id)
                }
              />

              {datasetCanRemoveStudies && (
                <RemoveFromDatasetButton
                  disabled={!studiesSelected}
                  reportSelectionState={reportSelectionState}
                  datasetId={dataset.id}
                  removed={() =>
                    reportSelectionDispatch({
                      type: ReportSelectionEventType.RESET,
                    })
                  }
                />
              )}
              <TagDropdown
                tags={userTags}
                studyIDs={Array.from(reportSelectionState.add.ids)}
                align="left"
                disabled={!studiesSelected}
                created={() =>
                  reportSelectionDispatch({
                    type: ReportSelectionEventType.RESET,
                  })
                }
                added={() =>
                  reportSelectionDispatch({
                    type: ReportSelectionEventType.RESET,
                  })
                }
              />
            </div>
            <div className="flex space-x-4">
              {datasetDelivered && (
                <button className="btn btn-white">
                  <Link
                    className="flex"
                    to={`/datasets/${dataset.id}/download`}
                  >
                    <HiDownload className="h-4 w-4 mr-2" aria-hidden="true" />{' '}
                    Download Dataset
                  </Link>
                </button>
              )}
              {(isInternal(authState.profile!.email) || datasetOrdered) && (
                <>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                      trackEvent('CLICK_DOWNLOAD_DATASET_AS_CSV_BUTTON', {
                        datasetId: dataset.id,
                      });
                      downloadDatasetAsCSV(api, dataset.id);
                    }}
                  >
                    <HiDocumentArrowDown
                      className="h-4 w-4 mr-2"
                      aria-hidden="true"
                    />
                    Download as CSV
                  </button>
                </>
              )}
              <button
                className="btn btn-white"
                onClick={() => {
                  dicomHeaderModalOpenChange(true);
                  trackEvent('CLICK_METADATA_MANAGE_COLUMNS_BUTTON');
                }}
              >
                <HiViewBoards className="h-4 w-4 mr-2" aria-hidden="true" />
                Manage Columns
              </button>
            </div>
          </div>

          <div className="">
            <MetadataTable
              studies={studies}
              datasets={datasets}
              datasetId={dataset.id}
              studyTags={studyTags}
              userTags={userTags}
              hideOrderApproval={!datasetCanApproveStudies}
              visibleDicomHeaders={visibleDicomHeaders}
              splitView
            />
          </div>
        </div>

        {placeOrderModal()}
        {/* @todo: Use a global component for confirm windows */}
        <DatasetRequestMetadataConfirmedModal
          isOpen={modalType === 'requestMetadataConfirmation'}
          onRequestClose={() => modalTypeChange(undefined)}
        />
        <MetadataTour
          tourActive={metadataTourActive}
          tourActiveChange={metadataTourActiveChange}
          datasetStudyApprovalMutable={datasetCanApproveStudies}
          datasetCanPlaceOrder={datasetCanPlaceOrder}
        />
      </ApplicationShell>

      {dicomHeaderModalOpen && (
        <DicomHeadersModal
          isOpen
          onRequestClose={() => dicomHeaderModalOpenChange(false)}
          visibleDicomHeaders={visibleDicomHeaders}
          onVisibleDicomHeadersChange={newVisibleDicomHeaders => {
            visibleDicomHeadersChange(newVisibleDicomHeaders);
            localStorage.setItem(
              'visible_dicom_header_columns',
              JSON.stringify(newVisibleDicomHeaders)
            );
          }}
        />
      )}
    </>
  );
};
