import {ReactNode} from 'react';
import cx from 'classnames';

export const Badge = ({
  type,
  rounded = 'rounded-full',
  empty,
  size = 'sm',
  className,
  children,
  customColor,
  ...props
}: {
  type:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'danger'
    | 'warning'
    | 'custom';
  rounded?:
    | 'rounded-none'
    | 'rounded-sm'
    | 'rounded'
    | 'rounded-md'
    | 'rounded-lg'
    | 'rounded-full';
  empty?: boolean;
  size?: 'sm' | 'lg';
  customColor?: {
    bgColor: string;
    fontColor: string;
  };
  children: ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  let colorClass = '';

  switch (type) {
    case 'primary':
      colorClass = cx('text-blue-800 border-blue-100', {
        'bg-blue-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'secondary':
      colorClass = cx('text-violet-800 border-violet-100', {
        'bg-violet-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'info':
      colorClass = cx('text-gray-800 border-gray-100', {
        'bg-gray-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'success':
      colorClass = cx('text-emerald-800 border-emerald-100', {
        'bg-emerald-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'danger':
      colorClass = cx('text-red-800 border-red-100', {
        'bg-red-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'warning':
      colorClass = cx('text-yellow-800 border-yellow-100', {
        'bg-yellow-100': !empty,
        'bg-white': empty,
      });
      break;
    case 'custom':
      colorClass = customColor
        ? cx(
            `text-${customColor.fontColor} border-${customColor.bgColor} bg-${customColor.bgColor}`,
            {
              [`bg-${customColor.bgColor}`]: !empty,
              'bg-white': empty,
            }
          )
        : cx('text-gray-800 border-gray-100', {
            'bg-gray-100': !empty,
            'bg-white': empty,
          });
      break;
  }

  switch (size) {
    case 'sm':
      colorClass = cx(colorClass, 'px-2.5 py-0.5  text-xs');
      break;
    case 'lg':
      colorClass = cx(colorClass, 'px-3 py-0.5  text-sm');
      break;
  }

  return (
    <div
      className={cx(
        'inline-flex items-center font-medium border',
        rounded,
        colorClass,
        className || ''
      )}
      {...props}
    >
      {children}
    </div>
  );
};
