import {useQuery} from 'react-query';

import {fetchPatientReports} from '../../models/report';
import {Modal} from '../../core/layout/modal';
import {ReportsTable} from './reports-table';
import _ from 'lodash';
import {Loading} from '../../core/components/loading';
import {useAxios} from 'src/utils/http';

export const PatientReportsModal = ({
  patientId,
  onRequestClose,
}: {
  patientId: string;
  onRequestClose: () => void;
}) => {
  const http = useAxios();
  const {data: patientReports} = useQuery(
    ['patientReports', patientId],
    () => fetchPatientReports(http, patientId),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  return (
    <Modal isOpen onRequestClose={onRequestClose} className="w-screen">
      <div className="flex flex-col gap-y-4">
        <div className="text-xl">Patient {patientId}</div>

        {_.isNil(patientReports) ? (
          <Loading />
        ) : (
          <ReportsTable reports={patientReports} />
        )}
      </div>
    </Modal>
  );
};
