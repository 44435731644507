import {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {DateTime} from 'luxon';

import {ApplicationShell} from '../../core/layout/application-shell';
import {formatCurrency, formatNumber} from '../../utils/strings';
import {DatasetSubtotal} from '../../components/dataset';
import {
  fetchDatasetOrder,
  datasetOrderToCart,
} from '../../models/dataset-order';
import {Loading} from '../../core/components/loading';
import {Breadcrumbs} from '../../core/components/breadcrumbs';
import {DatasetOrderStatusEnum} from 'src/enums/datasetorderstatusenum';
import {useAxios} from 'src/utils/http';

export const OrderDetailsPage = ({isAdmin}: {isAdmin?: boolean}) => {
  const api = useAxios();
  const q = useParams<{
    orderId?: string;
  }>();
  const orderId = _.isString(q.orderId) ? q.orderId : undefined;

  // Fetch dataset order
  const {data: datasetOrder, isLoading: datasetOrderLoading} = useQuery(
    ['order', orderId],
    () => fetchDatasetOrder(api, orderId!, isAdmin),
    {
      keepPreviousData: true,
      enabled: !_.isNil(orderId),
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const rowsToDisplay: {
    label: string;
    value?: string | number;
    adminOnly?: Boolean;
  }[] = [
    {
      label: 'Dataset ID',
      value: datasetOrder?.datasetId,
      adminOnly: true,
    },
    {
      label: 'Dataset',
      value: datasetOrder?.datasetName,
    },
    {
      label: 'Date Ordered',
      value: datasetOrder?.orderedAt?.toLocaleString(DateTime.DATE_MED),
    },
    {
      label: 'Amount',
      value: datasetOrder?.amount
        ? formatCurrency(datasetOrder?.amount)
        : undefined,
    },
    {
      label: 'Number of Studies',
      value: formatNumber(datasetOrder?.numStudies),
    },
    {
      label: 'Order Status',
      value: `${_.capitalize(datasetOrder?.orderStatus)} ${
        (datasetOrder?.orderStatus ===
          DatasetOrderStatusEnum.DatasetOrderStatusPaid &&
          datasetOrder?.paidAt?.toLocaleString(DateTime.DATE_MED)) ||
        ''
      }`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Order Details</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="grid grid-cols-1 max-w-3xl gap-y-5 mb-1 auto-rows-min mx-auto text-gray-900">
          <div>
            <Breadcrumbs
              links={[
                isAdmin
                  ? {name: 'Orders', href: '/admin/orders'}
                  : {name: 'Orders', href: '/orders'},
                {
                  name: `Order ${datasetOrder?.orderId ?? 'Details'}`,
                  current: true,
                },
              ]}
            />
          </div>
          {datasetOrderLoading ? (
            <Loading text="Loading Dataset Order..." />
          ) : (
            <>
              <h1 className="text-2xl leading-7 font-bold">
                Order {datasetOrder?.orderId}
              </h1>
              <h2 className="text-lg leading-6 font-medium">Details</h2>
              <div className="grid grid-cols-1 grid-flow-row gap-5 text-sm leading-5 justify-center items-center mb-7">
                {rowsToDisplay.map((row, index) => {
                  if (row.adminOnly && !isAdmin) {
                    return <Fragment key={index}></Fragment>;
                  }
                  return (
                    <Fragment key={index}>
                      <hr className="border-t border-gray-200" />
                      <div className="grid grid-cols-3">
                        <h3 className="text-gray-500">{row.label}</h3>
                        <h3 className="text-gray-900 col-span-2">
                          {row.value}
                        </h3>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
              {datasetOrder?.subtotalItems && (
                <>
                  <div>Price Breakdown</div>
                  <hr className="border-t border-gray-200" />
                  <div className="text-sm">
                    <DatasetSubtotal cart={datasetOrderToCart(datasetOrder)} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </ApplicationShell>
    </>
  );
};
