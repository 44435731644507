export const SearchResultsNotFoundTips = () => {
  return (
    <>
      <ul className="text-gray-400 list-disc list-inside">
        <li>Try expanding your search with synonyms and alternate spellings</li>
        <li>
          Have questions or want tips?{' '}
          <a
            href="https://calendly.com/alinelutz/30min"
            target="_blank"
            rel="noreferrer"
            className="text-primary hover:text-primary-active"
          >
            Schedule a demo
          </a>
        </li>
        <li>
          {"Still can't find what you're looking for? Make a request at "}
          <a
            href="mailto:support@segmed.ai"
            className="text-primary hover:text-primary-active"
          >
            support@segmed.ai
          </a>
        </li>
      </ul>
    </>
  );
};
