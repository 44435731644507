import React, {useState} from 'react';
import JoyRide, {ACTIONS, EVENTS, STATUS, CallBackProps} from 'react-joyride';
import {TourProps} from '../../../../models/tour';
import {includes} from 'lodash';

// Tour component
export const Tour = ({tourActive, tourActiveChange, steps}: TourProps) => {
  const [stepIndex, setStepIndex] = useState(0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {action, index, status, type} = data;

    if (
      includes([STATUS.FINISHED, STATUS.SKIPPED], status) ||
      action === 'close'
    ) {
      // reset to start and toggle off
      tourActiveChange(false);
      setStepIndex(0);
    } else if (includes([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND], type)) {
      // move between steps
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  return (
    <>
      <JoyRide
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={false}
        disableScrolling={true}
        run={tourActive}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#2464eb',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            textColor: '#333',
            width: undefined,
            zIndex: 100,
          },
          beacon: {
            display: 'hidden',
          },
        }}
      />
    </>
  );
};
