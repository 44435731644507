import {AxiosInstance} from 'axios';
import {map} from 'lodash';

export interface User {
  userId: number;
  orgId: number;
  firstname: string;
  lastname: string;
  email: string;
  confirmed: boolean;
  userType: 'customer' | 'data_partner';
  awsIdentifier: string;
  admin: boolean;
}

export interface UserResponse {
  user_id: number;
  org_id: number;
  firstname: string;
  lastname: string;
  email: string;
  confirmed: boolean;
  user_type: 'customer' | 'data_partner';
  aws_identifier: string;
  admin: boolean;
}

export const responseToUser = (userResp: UserResponse): User => {
  return {
    userId: userResp.user_id,
    orgId: userResp.org_id,
    firstname: userResp.firstname,
    lastname: userResp.lastname,
    email: userResp.email,
    confirmed: userResp.confirmed,
    userType: userResp.user_type,
    awsIdentifier: userResp.aws_identifier,
    admin: userResp.admin,
  };
};

export const fetchAllUsersAdmin = (http: AxiosInstance) =>
  http.get('/v1/admin/all_users').then(response => {
    // Log in successful
    const {data} = response;
    const users = map(data, userResp => responseToUser(userResp));
    return users;
  });
