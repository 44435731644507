import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {Column, Row} from 'react-table';
import {Helmet} from 'react-helmet';
import {constant} from 'lodash';

import {TextFilter, ReactTable} from '../../../core/components/react-table';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {ResetSyntheticDataCredits} from '../../../components/synthetic-data/credit';
import {
  UserSyntheticDataCredit,
  fetchAllSyntheticCreditUsersAdmin,
} from '../../../models/synthetic-data';
import {useAxios} from 'src/utils/http';

export const AdminSyntheticUsersPage = () => {
  const api = useAxios();

  const {data: users, isLoading: usersIsLoading} = useQuery(
    ['adminSyntheticDataCreditUsers'],
    () => {
      return fetchAllSyntheticCreditUsersAdmin(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 1000, // 5 seconds
    }
  );

  const columns = useMemo<Array<Column<UserSyntheticDataCredit>>>(
    () => [
      {
        Header: 'User Info',
        columns: [
          {
            Header: 'User ID',
            accessor: 'userId',
            Filter: TextFilter,
            filter: 'exactText',
          },
          {
            Header: 'Email',
            accessor: 'email',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}: {value: string}) => (
              <span title={value}>{value}</span>
            ),
          },
          {
            Header: 'Credit',
            accessor: 'creditCounter',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            id: 'actions',
            Header: 'Actions',
            width: 85,
            accessor: constant(null),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({row}: {row: Row<UserSyntheticDataCredit>}) => {
              return (
                <ResetSyntheticDataCredits
                  userSyntheticDataCredit={row.original}
                />
              );
            },
          },
        ],
      },
    ],
    []
  );

  const data = useMemo(() => users ?? [], [users]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Manage Synthetic Data User Credit</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <h1 className="text-2xl mb-5">All users using synthetic data</h1>

        {usersIsLoading ? (
          'Loading users...'
        ) : (
          <ReactTable
            tableColumns={columns}
            tableData={data}
            showCountDesc={'Credit'}
            defaultSortBy={[{id: 'credit_counter', desc: false}]}
          />
        )}
      </ApplicationShell>
    </>
  );
};
