import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Helmet} from 'react-helmet';

export const Logout = () => {
  const {logout} = useAuth0();

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Segmed Openda - Logging out...</title>
        <div>Logging out...</div>;
      </Helmet>
    </div>
  );
};
