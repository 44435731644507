/* eslint-disable */
import * as cornerstone from '@cornerstonejs/core';

// Add hardcoded meta data provider for color images
// https://github.com/cornerstonejs/cornerstone3D/blob/main/packages/core/examples/webLoader/hardcodedMetaDataProvider.ts
export function hardcodedMetaDataProvider(
  type: string,
  imageId: string,
  imageIds: string[]
) {
  if (!imageId.startsWith('web:')) {
    return undefined;
  }

  if (type === 'imagePixelModule') {
    const imagePixelModule = {
      pixelRepresentation: 0,
      bitsAllocated: 24,
      bitsStored: 24,
      highBit: 24,
      photometricInterpretation: 'RGB',
      samplesPerPixel: 3,
    };

    return imagePixelModule;
  } else if (type === 'generalSeriesModule') {
    const generalSeriesModule = {
      modality: 'SC',
      seriesNumber: 1,
      seriesDescription: 'Color',
      seriesDate: '20190201',
      seriesTime: '120000',
      seriesInstanceUID: '1.2.276.0.7230010.3.1.4.83233.20190201120000.1',
    };

    return generalSeriesModule;
  } else if (type === 'imagePlaneModule') {
    const index = imageIds.indexOf(imageId);
    // console.warn(index);
    const imagePlaneModule = {
      imageOrientationPatient: [1, 0, 0, 0, 1, 0],
      imagePositionPatient: [0, 0, index * 5],
      pixelSpacing: [1, 1],
      columnPixelSpacing: 1,
      rowPixelSpacing: 1,
      frameOfReferenceUID: 'FORUID',
      columns: 2048,
      rows: 1216,
      rowCosines: [1, 0, 0],
      columnCosines: [0, 1, 0],
    };

    return imagePlaneModule;
  } else if (type === 'voiLutModule') {
    return {
      // According to the DICOM standard, the width is the number of samples
      // in the input, so 256 samples.
      windowWidth: [256],
      // The center is offset by 0.5 to allow for an integer value for even
      // sample counts
      windowCenter: [128],
    };
  } else if (type === 'modalityLutModule') {
    return {
      rescaleSlope: 1,
      rescaleIntercept: 0,
    };
  } else {
    return undefined;
  }
}

// Creates a cornerstone Image object for the specified Image and imageId
// https://github.com/cornerstonejs/cornerstone3D/blob/main/packages/core/examples/webLoader/registerWebImageLoader.ts
const canvas = document.createElement('canvas');
let lastImageIdDrawn: any;

/**
 * creates a cornerstone Image object for the specified Image and imageId
 *
 * @param image - An Image
 * @param imageId - the imageId for this image
 * @returns Cornerstone Image Object
 */
export function createImage(image: any, imageId: any) {
  // extract the attributes we need
  const rows = image.naturalHeight;
  const columns = image.naturalWidth;

  function getPixelData(targetBuffer: any) {
    const imageData = getImageData();

    let targetArray;

    // Check if targetBuffer is provided for volume viewports
    if (targetBuffer) {
      targetArray = new Uint8Array(
        targetBuffer.arrayBuffer,
        targetBuffer.offset,
        targetBuffer.length
      );
    } else {
      targetArray = new Uint8Array(imageData.width * imageData.height * 3);
    }

    // modify original image data and remove alpha channel (RGBA to RGB)
    convertImageDataToRGB(imageData, targetArray);

    return targetArray;
  }

  function convertImageDataToRGB(imageData: any, targetArray: any) {
    for (let i = 0, j = 0; i < imageData.data.length; i += 4, j += 3) {
      targetArray[j] = imageData.data[i];
      targetArray[j + 1] = imageData.data[i + 1];
      targetArray[j + 2] = imageData.data[i + 2];
    }
  }

  function getImageData() {
    let context;

    if (lastImageIdDrawn === imageId) {
      context = canvas.getContext('2d', {willReadFrequently: true})!;
    } else {
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context = canvas.getContext('2d', {willReadFrequently: true})!;
      context.drawImage(image, 0, 0);
      lastImageIdDrawn = imageId;
    }

    return context.getImageData(0, 0, image.naturalWidth, image.naturalHeight);
  }

  function getCanvas() {
    if (lastImageIdDrawn === imageId) {
      return canvas;
    }

    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    const context = canvas.getContext('2d', {willReadFrequently: true})!;

    context.drawImage(image, 0, 0);
    lastImageIdDrawn = imageId;

    return canvas;
  }

  // Extract the various attributes we need
  return {
    imageId,
    minPixelValue: 0,
    maxPixelValue: 255,
    slope: 1,
    intercept: 0,
    windowCenter: 128,
    windowWidth: 255,
    getPixelData,
    getCanvas,
    getImage: () => image,
    rows,
    columns,
    height: rows,
    width: columns,
    color: true,
    // we converted the canvas rgba already to rgb above
    rgba: false,
    columnPixelSpacing: 1, // for web it's always 1
    rowPixelSpacing: 1, // for web it's always 1
    invert: false,
    sizeInBytes: rows * columns * 3,
  };
}

export function arrayBufferToImage(arrayBuffer: any) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const arrayBufferView = new Uint8Array(arrayBuffer);
    const blob = new Blob([arrayBufferView]);
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(blob);

    image.src = imageUrl;
    image.onload = () => {
      resolve(image);
      urlCreator.revokeObjectURL(imageUrl);
    };

    image.onerror = error => {
      urlCreator.revokeObjectURL(imageUrl);
      reject(error);
    };
  });
}

//
// This is a cornerstone image loader for web images such as PNG and JPEG
//
const options = {
  // callback allowing customization of the xhr (e.g. adding custom auth headers, cors, etc)
  beforeSend: (xhr: any) => {
    // xhr
  },
};

// Loads an image given a url to an image
function loadImage(uri: any, imageId: any) {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.open('GET', uri, true);
  xhr.responseType = 'arraybuffer';
  options.beforeSend(xhr);

  xhr.onprogress = function (oProgress) {
    if (oProgress.lengthComputable) {
      // evt.loaded the bytes browser receive
      // evt.total the total bytes set by the header
      const loaded = oProgress.loaded;
      const total = oProgress.total;
      const percentComplete = Math.round((loaded / total) * 100);

      const eventDetail = {
        imageId,
        loaded,
        total,
        percentComplete,
      };

      cornerstone.triggerEvent(
        cornerstone.eventTarget,
        'cornerstoneimageloadprogress',
        eventDetail
      );
    }
  };

  const promise = new Promise((resolve, reject) => {
    xhr.onload = function () {
      const imagePromise = arrayBufferToImage(this.response);

      imagePromise
        .then(image => {
          const imageObject = createImage(image, imageId);

          resolve(imageObject);
        }, reject)
        .catch(error => {
          console.error(error);
        });
    };
    xhr.onerror = function (error) {
      reject(error);
    };

    xhr.send(null);
  });

  const cancelFn = () => {
    xhr.abort();
  };

  return {
    promise,
    cancelFn,
  };
}

export function registerWebImageLoader(imageLoader: any): void {
  imageLoader.registerImageLoader('web', _loadImageIntoBuffer);
}

/**
 * Small stripped down loader from cornerstoneDICOMImageLoader
 * Which doesn't create cornerstone images that we don't need
 */
function _loadImageIntoBuffer(
  imageId: string,
  options?: Record<string, any>
): {promise: Promise<Record<string, any>>; cancelFn?: () => void} {
  const uri = imageId.replace('web:', '');

  const promise = new Promise((resolve, reject) => {
    // get the pixel data from the server
    loadImage(uri, imageId)
      .promise.then(
        image => {
          if (
            !options ||
            !options.targetBuffer ||
            !options.targetBuffer.length ||
            !options.targetBuffer.offset
          ) {
            resolve(image);
            return;
          }

          (image as any).getPixelData(options.targetBuffer);

          resolve(true);
        },
        error => {
          reject(error);
        }
      )
      .catch(error => {
        reject(error);
      });
  });

  return {
    promise: promise as any,
    cancelFn: undefined,
  };
}
