import {Fragment, ReactNode} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import cx from 'classnames';
import {map} from 'lodash';

import {MenuItem, MenuItemProps} from './menu-item';

export const MenuDropdownSingle = ({
  buttonText,
  useDefaultButtonClass = true,
  buttonClassName,
  menuButtonDisabled,
  menuItems,
  align = 'right',
  alwaysShowDivider = false,
}: {
  buttonText: ReactNode;
  useDefaultButtonClass?: boolean;
  buttonClassName?: string;
  menuButtonDisabled?: boolean;
  menuItems: MenuItemProps[];
  align?: 'left' | 'right';
  alwaysShowDivider?: boolean;
}) => {
  return (
    <Menu as="span" className="block -ml-px relative">
      <Menu.Button
        disabled={menuButtonDisabled}
        className={cx(
          {
            'relative inline-flex items-center shadow-sm rounded-md btn btn-white':
              useDefaultButtonClass,
          },
          buttonClassName
        )}
      >
        <span className="sr-only">Open options</span>
        {buttonText}
        <ChevronDownIcon
          className="h-5 w-5"
          aria-hidden="true"
          strokeWidth="4"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 divide-y divide-gray-100 max-h-72 overflow-y-auto',
            {
              'origin-top-right left-0 -ml-1': align === 'left',
              'origin-top-left right-0 -mr-1': align === 'right',
            }
          )}
        >
          <div className="py-0.5">
            {map(menuItems, (menuItem, menuItemIndex) => (
              <Fragment key={menuItemIndex}>
                <MenuItem {...menuItem} />
                {alwaysShowDivider &&
                  menuItemIndex < menuItems.length - 1 && ( // Ensure no divider after last item
                    <hr className="border-gray-300 my-0.5 h-px" />
                  )}
              </Fragment>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
