import {useState} from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {useQuery} from 'react-query';
import {HiOutlineUserCircle, HiOutlineDocumentText} from 'react-icons/hi';

import {useAuth} from '../../../hooks/auth';
import {fetchCredentials} from '../../../models/auth';
import {Loading} from '../../../core/components/loading';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const AccountSettings = () => {
  const {authState} = useAuth();
  const api = useAxios();

  const [credentialsHidden, credentialsHiddenChange] = useState<boolean>(true);

  const {data: credentials, isLoading: credentialsLoading} = useQuery(
    ['credentials', authState.profile?.email],
    () => fetchCredentials(api),
    {
      enabled: authState.loaded,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const renderCredentials = () => {
    return (
      <div>
        <div className="text-xs text-gray-700 mb-4">
          Encrypted AWS S3 buckets can be used to deliver DICOM files. Please
          find instructions and your credentials here.
        </div>

        <a
          href={
            authState.profile?.userType === 'data_partner'
              ? 'https://segmed.notion.site/Segmed-Data-Transfer-Process-Data-Partners-15204e44377f490c938fa9dc7bb2767c'
              : 'https://segmed.notion.site/Segmed-Data-Transfer-Process-c62c77a17fde4ff0b65d624bdc8f81d3'
          }
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:text-blue-400 mt-2 text-sm block mb-4"
        >
          <HiOutlineDocumentText className="w-5 h-5 inline-block mr-1" />
          Data Transfer Instructions
        </a>

        <div className="text-lg">
          Credentials
          <div className="inline-block">
            <button
              onClick={() => credentialsHiddenChange(!credentialsHidden)}
              className="btn btn-link inline-block text-xs"
            >
              {credentialsHidden ? 'Show' : 'Hide'}
            </button>
          </div>
        </div>

        {!_.isNil(credentials) ? (
          <div className="text-xs text-gray-700">
            <div className="mb-1">
              <span className="font-semibold">Access Key ID</span>
              <div>
                {credentialsHidden ? '∗∗∗∗∗∗∗∗∗∗' : credentials?.S3Login}
              </div>
            </div>
            <div className="mb-1">
              <span className="font-semibold">Secret Access Key</span>
              <div>
                {credentialsHidden ? '∗∗∗∗∗∗∗∗∗∗' : credentials?.S3Pass}
              </div>
            </div>
            <div className="mb-1">
              <span className="font-semibold">Directory</span>
              <div>{credentials?.S3Directory}</div>
            </div>
          </div>
        ) : credentialsLoading ? (
          <Loading />
        ) : (
          <div className="text-gray-400 italic text-center">
            Error loading credentials
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Account Settings</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        {!_.isNil(credentials) && (
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav>
                  <a
                    className="bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    aria-current="page"
                  >
                    <HiOutlineUserCircle className="text-blue-500 group-hover:text-blue-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" />
                    <span className="truncate">Profile</span>
                  </a>
                </nav>
              </aside>

              <div className="divide-y divide-gray-200 lg:col-span-9">
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h2 className="text-lg leading-6 font-medium">Profile</h2>
                  </div>
                </div>

                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Email
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span className="flex-grow">
                          {authState.profile?.email}
                        </span>
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        S3 Credentials
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span className="flex-grow">{renderCredentials()}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        )}
      </ApplicationShell>
    </>
  );
};
