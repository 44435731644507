import {ReactNode, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import cx from 'classnames';
import {HiOutlineChevronDown, HiOutlineChevronUp} from 'react-icons/hi';

export const Dropdown = ({
  children,
  text = '',
  onToggle,
  contained,
  open,
  disabled,
  buttonClassName,
  customButton,
  hideButton = false,
  buttonStyle = 'btn-white',
}: {
  children: ReactNode;
  text?: string;
  onToggle?: (opened: boolean) => void;
  contained?: boolean;
  open?: boolean;
  disabled?: boolean;
  buttonClassName?: string;
  customButton?: ReactNode;
  hideButton?: boolean;
  buttonStyle?:
    | 'btn-primary'
    | 'btn-white'
    | 'btn-secondary'
    | 'btn-danger'
    | 'btn-link';
}) => {
  const [dropdownOpened, dropdownOpenedChange] = useState(false);

  const dropdownToggle = (opened: boolean) => {
    dropdownOpenedChange(opened);

    onToggle && onToggle(opened);
  };

  if (open !== undefined && open !== dropdownOpened) {
    dropdownOpenedChange(open);
  }

  const renderButton = () => {
    if (customButton) {
      return (
        <button
          type="button"
          className={buttonClassName}
          disabled={disabled}
          onClick={() => dropdownToggle(!dropdownOpened)}
        >
          {customButton}
        </button>
      );
    }

    return (
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        className={cx(
          buttonStyle,
          'btn relative pr-8 w-full justify-start',
          buttonClassName
        )}
        disabled={disabled}
        onClick={() => dropdownToggle(!dropdownOpened)}
      >
        <span className="block truncate">{text ? text : '\u00A0'}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <HiOutlineChevronDown
            className={cx('h-3 w-3 text-gray-500', {hidden: dropdownOpened})}
          />
          <HiOutlineChevronUp
            className={cx('h-3 w-3 text-gray-500', {hidden: !dropdownOpened})}
          />
        </span>
      </button>
    );
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        dropdownToggle(false);
      }}
    >
      {!hideButton ? renderButton() : ''}

      <div className={cx({relative: contained})}>
        <div
          className={
            'absolute min-w-min mt-2 rounded-md shadow-lg bg-white ring-1 ring-gray-900 ring-opacity-5 z-30 m-b-5 break-words overflow-auto' +
            (dropdownOpened ? '' : ' hidden') +
            (contained ? ' w-full' : '')
          }
        >
          {children}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
