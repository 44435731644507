import React from 'react';
import {Tour} from '../base/tour';

export const DatasetTour = ({
  tourActive,
  tourActiveChange,
  datasetId,
}: {
  tourActive: boolean;
  tourActiveChange: (tourActive: boolean) => void;
  datasetId: number;
}) => {
  const tourSteps = [
    {
      target: `[data-tour=dataset-card-step-name-${datasetId}]`,
      content: (
        <div className="m-auto inline-block text-left" data-cy="tourText">
          View Studies in dataset
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour=dataset-card-step-metadata-sample-${datasetId}]`,
      content: (
        <div className="m-auto inline-block text-left" data-cy="tourText">
          Request metadata and samples for the studies in the dataset. Once you
          request, it will take approximately 14 business days for us to gather
          the DICOMs.
        </div>
      ),
    },
    {
      target: `[data-tour=dataset-card-step-sample-retrieval-${datasetId}]`,
      content: (
        <div className="m-auto inline-block text-left" data-cy="tourText">
          After the metadata and samples are received you will be able to
          download them here
        </div>
      ),
    },
    {
      target: `[data-tour=dataset-card-step-dicom-order-${datasetId}]`,
      content: (
        <div className="m-auto inline-block text-left" data-cy="tourText">
          Finalize and submit the final DICOM order here
        </div>
      ),
    },
    {
      target: `[data-tour=dataset-card-step-dicom-retrieval-${datasetId}]`,
      content: (
        <div className="m-auto inline-block text-left" data-cy="tourText">
          Once the order is processed you will be able to access the DICOMs
        </div>
      ),
    },
  ];

  return (
    <Tour
      tourActive={tourActive}
      tourActiveChange={tourActiveChange}
      steps={tourSteps}
    />
  );
};
