import cx from 'classnames';

export const Card = ({
  children,
  className,
  noBgColor,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  noBgColor?: boolean;
}) => {
  return (
    <div
      className={cx({'bg-white': !noBgColor}, 'shadow rounded-lg', className)}
      {...props}
    >
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
};
