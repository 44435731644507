import React from 'react';
import {FilterProps, Renderer} from 'react-table';
import {HiOutlineSearch} from 'react-icons/hi';
import {Report} from '../../../models/report';

export const TextFilter: Renderer<FilterProps<Report>> = ({
  column: {filterValue, setFilter},
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <HiOutlineSearch className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        className="text-input w-full text-gray-700 pl-10"
        value={filterValue || ''}
        placeholder="Search"
        onChange={e => setFilter(e.target.value || undefined)}
      />
    </div>
  );
};
