import React, {useState} from 'react';

import {Loading} from '../../../core/components/loading';

export const EmbedVideo = ({
  src,
  ...props
}: {
  src: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLIFrameElement
>) => {
  const [isLoading, IsLoadingChange] = useState(true);

  return (
    <div className="relative aspect-w-16 aspect-h-9">
      {isLoading && <Loading />}
      <iframe
        src={src}
        frameBorder="0"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full"
        onLoad={() => IsLoadingChange(false)}
        {...props}
      ></iframe>
    </div>
  );
};
