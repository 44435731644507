/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {RouterProvider} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';

import {initAmplitude} from './utils/amplitude';
import {CustomToastContainer} from './core/components/toast';
import {AuthProvider} from './hooks/auth';
import {router} from './pages/routes/routes';
import ReactTooltip from 'react-tooltip';

const App = () => {
  initAmplitude({});

  return (
    <>
      <Helmet>
        <title>Segmed Openda</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>

      <CustomToastContainer />

      <ReactTooltip
        effect="solid"
        arrowColor="transparent"
        className="xs .75/medium bg-gray-900/75 text-white py-1 px-2 mr-2 my-2.5"
        place="bottom"
        overridePosition={({left, top}, _currentEvent, currentTarget, node) => {
          const targetWidth = (currentTarget as HTMLElement).offsetWidth;
          const tipWidth = node?.clientWidth ?? 0;
          left += tipWidth / 2 - targetWidth / 2;
          return {left, top};
        }}
      />
    </>
  );
};

export default App;
