import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from 'react';

import _ from 'lodash';

import {DatasetUpdateRequest} from '../models/dataset';
import {queryToRequest, SearchQuery} from '../models/search';

// Interfaces
export interface ReportSelectionState {
  id?: string;
  add: {query?: SearchQuery; ids: Set<string>};
  remove: {query?: SearchQuery; ids: Set<string>};
}

export enum ReportSelectionEventType {
  RESET = 'RESET',
  SELECT_ALL = 'SELECT_ALL',
  UNSELECT_ALL = 'UNSELECT_ALL',
  SELECT = 'SELECT',
  UNSELECT = 'UNSELECT',
  SET_CACHE = 'SET_CACHE',
}

export type ReportSelectionEvent =
  | {
      type: ReportSelectionEventType.SELECT | ReportSelectionEventType.UNSELECT;
      payload: string[];
    }
  | {
      type: ReportSelectionEventType.SELECT_ALL;
      payload: SearchQuery;
    }
  | {
      type: ReportSelectionEventType.UNSELECT_ALL;
    }
  | {
      type: ReportSelectionEventType.RESET;
      payload?: {
        id: string;
        add?: {query?: SearchQuery; ids: Set<string>};
        remove?: {query?: SearchQuery; ids: Set<string>};
      };
    };

// Context

const newReportSelectionState = (): ReportSelectionState => {
  return {
    add: {ids: new Set<string>()},
    remove: {ids: new Set<string>()},
  };
};

const ReportSelectionContext = createContext({
  reportSelectionState: newReportSelectionState(),
  reportSelectionDispatch:
    null as unknown as React.Dispatch<ReportSelectionEvent>,
});

export const isAnyReportSelected = (
  reportSelectionState: ReportSelectionState
) => {
  return (
    !_.isEmpty(reportSelectionState.add.ids) ||
    !_.isNil(reportSelectionState.add.query)
  );
};

export const isReportSelected = (
  reportSelectionState: ReportSelectionState,
  studyId: string
) => {
  return _.isNil(reportSelectionState.add.query)
    ? reportSelectionState.add.ids.has(studyId)
    : !reportSelectionState.remove.ids.has(studyId);
};

const useReportSelection = () => {
  return useContext(ReportSelectionContext);
};

export const reportSelectionToDatasetUpdateRequest = (
  reportSelectionState: ReportSelectionState
) => {
  const request: DatasetUpdateRequest = {
    add: {
      query: reportSelectionState.add.query
        ? queryToRequest(reportSelectionState.add.query)
        : undefined,
      ids: Array.from(reportSelectionState.add.ids),
    },
    remove: {
      query: reportSelectionState.remove.query
        ? queryToRequest(reportSelectionState.remove.query)
        : undefined,
      ids: Array.from(reportSelectionState.remove.ids),
    },
  };

  return request;
};

const reportSelectionReducer: Reducer<
  ReportSelectionState,
  ReportSelectionEvent
> = (
  reportSelectionState: ReportSelectionState,
  action: ReportSelectionEvent
) => {
  const allSelected = !!reportSelectionState.add.query;

  switch (action.type) {
    case ReportSelectionEventType.RESET: {
      const initialState = newReportSelectionState();
      return {
        ...initialState,
        add: action.payload?.add ?? initialState.add,
        remove: action.payload?.remove ?? initialState.remove,
        id: action.payload?.id,
      };
    }
    case ReportSelectionEventType.UNSELECT_ALL: {
      const initialState = newReportSelectionState();
      return {
        ...reportSelectionState,
        add: initialState.add,
        remove: initialState.remove,
      };
    }
    case ReportSelectionEventType.SELECT_ALL: {
      // Select all
      return {
        ...reportSelectionState,
        add: {query: action.payload, ids: new Set<string>()},
        remove: {ids: new Set<string>()},
      };
    }
    case ReportSelectionEventType.SELECT: {
      if (allSelected) {
        for (const id of action.payload) {
          if (reportSelectionState.remove.ids.has(id)) {
            reportSelectionState.remove.ids.delete(id);
          }
        }

        return {
          ...reportSelectionState,
          add: {...reportSelectionState.add},
          remove: {
            ids: reportSelectionState.remove.ids,
          },
        };
      } else {
        for (const id of action.payload) {
          reportSelectionState.add.ids.add(id);
        }

        return {
          ...reportSelectionState,
          add: {
            ids: reportSelectionState.add.ids,
          },
          remove: {ids: new Set<string>()},
        };
      }
    }
    case ReportSelectionEventType.UNSELECT: {
      if (allSelected) {
        for (const id of action.payload) {
          reportSelectionState.remove.ids.add(id);
        }

        return {
          ...reportSelectionState,
          add: {...reportSelectionState.add},
          remove: {
            ids: reportSelectionState.remove.ids,
          },
        };
      } else {
        for (const id of action.payload) {
          if (reportSelectionState.add.ids.has(id)) {
            reportSelectionState.add.ids.delete(id);
          }
        }

        return {
          ...reportSelectionState,
          add: {
            ids: reportSelectionState.add.ids,
          },
          remove: {
            ...reportSelectionState.remove,
          },
        };
      }
    }
    default:
      // Shouldn't reach here
      // @todo: Log error
      return reportSelectionState;
  }
};

// Provider / Consumer

const ReportSelectionProvider = ({children}: {children: ReactNode}) => {
  const [reportSelectionState, reportSelectionDispatch] = useReducer(
    reportSelectionReducer,
    newReportSelectionState()
  );

  const value = {reportSelectionState, reportSelectionDispatch};

  return (
    <ReportSelectionContext.Provider value={value}>
      {children}
    </ReportSelectionContext.Provider>
  );
};

const ReportSelectionConsumer = ReportSelectionContext.Consumer;

export {ReportSelectionProvider, ReportSelectionConsumer, useReportSelection};
