import React from 'react';
import {Tour} from '../base/tour';

export const MetadataTour = ({
  tourActive,
  tourActiveChange,
  datasetCanPlaceOrder,
  datasetStudyApprovalMutable,
}: {
  tourActive: boolean;
  tourActiveChange: (tourActive: boolean) => void;
  datasetCanPlaceOrder: boolean;
  datasetStudyApprovalMutable: boolean;
}) => {
  const tourSteps = [
    {
      target: '[data-tour=metadata-page-all-metadata]',
      content: (
        <div className="m-auto inline-block text-left">
          View available metadata for the studies in your dataset.
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '[data-tour=metadata-table-header-1]',
      title: 'Filter and sort the columns',
      content: (
        <div className="m-auto inline-block text-left">
          You can filter or sort your data by clicking any of the column
          headers.
        </div>
      ),
    },
    ...(datasetStudyApprovalMutable
      ? [
          {
            target: '[data-tour=metadata-page-approve-exclude]',
            title: 'Add or exclude studies',
            content: (
              <div className="m-auto inline-block text-left">
                Select studies and add them to your final DICOM order.
                Optionally exclude specific studies you do not want in your
                final order.
              </div>
            ),
          },
        ]
      : []),
    ...(datasetCanPlaceOrder
      ? [
          {
            target: '[data-tour=metadata-page-dicom-order]',
            title: 'Place final DICOM order',
            content: (
              <div className="m-auto inline-block text-left">
                {
                  "After placing the final order we'll email you the final contract and let you know when the DICOMs are ready to download."
                }
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Tour
      tourActive={tourActive}
      tourActiveChange={tourActiveChange}
      steps={tourSteps}
    />
  );
};
