import {Helmet} from 'react-helmet';
import {useState, useEffect} from 'react';
import {ApplicationShell} from '../../core/layout/application-shell';

import {AddToDatasetDropdown} from '../../components/dataset';
import {
  useReportSelection,
  isAnyReportSelected,
  ReportSelectionEventType,
} from '../../hooks/report-selection-provider';
import {
  fetchSQLResults,
  ESColumn,
  shapeColumns,
  shapeRows,
  fetchCompletion,
} from '../../models/terminal';
import {TerminalTable} from './terminal-table';

import {HiInformationCircle} from 'react-icons/hi';

// libraries for AceEditor
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-solarized_light';

// React Table Imports
import {Column} from 'react-table';

// Auth imports
import {isInternal, isTerminalCustomer} from '../../models/auth';
import {useAuth} from '../../hooks/auth';
import {useAxios} from 'src/utils/http';

export const TerminalPage = ({internalPage}: {internalPage: boolean}) => {
  const {authState} = useAuth();
  const api = useAxios();

  // useEffect with no dependency array fires on first page load
  useEffect(() => {
    // if user is not internal, redirect them to the marketing page for Terminal
    if (
      !isInternal(authState.profile!.email) &&
      !isTerminalCustomer(authState.profile!.email)
    ) {
      window.location.replace('https://segmed.ai/terminal');
    }
  });

  // primary state

  const [columns, columnsChange] = useState<Array<Column<ESColumn>>>([]);
  const [rows, rowsChange] = useState<Array<{}>>([]);
  const [errMessage, errorMessageChange] = useState<string>('');

  // state for AddToDatasetDropdown
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const studiesSelected = isAnyReportSelected(reportSelectionState);

  const [query, queryChange] = useState('');
  const [naturalLanguageQuery, naturalLanguageQueryChange] = useState('');
  const [iswritingNL, iswritingNLChange] = useState(false);

  function onQueryChange(newValue: string) {
    queryChange(newValue);
  }
  return (
    <>
      <Helmet>
        <title>Segmed Openda - SQL Search</title>
      </Helmet>

      <ApplicationShell>
        <div>
          <h1 className="text-3xl">Segmed Openda - SQL Search</h1>
          <br />
          <div className="flex justify-between">
            {!iswritingNL && (
              <button
                className="btn btn-primary mb-3"
                onClick={() => {
                  fetchSQLResults(api, query, internalPage)
                    .then(sqlResults => {
                      reportSelectionDispatch({
                        type: ReportSelectionEventType.UNSELECT_ALL,
                      });
                      columnsChange(shapeColumns(sqlResults));
                      rowsChange(shapeRows(sqlResults));
                    })
                    .catch(err => {
                      errorMessageChange(
                        err.response?.data?.error?.reason
                          ? err.response.data.error.reason
                          : err.toString()
                      );
                      columnsChange([]);
                    });
                }}
                disabled={!query.length}
              >
                Execute
              </button>
            )}
            {iswritingNL && (
              <button
                className="btn btn-primary mb-3"
                onClick={() => {
                  fetchCompletion(api, naturalLanguageQuery, internalPage).then(
                    completion => {
                      queryChange(completion);
                      iswritingNLChange(false);
                    }
                  );
                }}
                disabled={!naturalLanguageQuery.length || !iswritingNL}
              >
                Translate to SQL
              </button>
            )}
            {internalPage && (
              <button
                className="btn btn-primary mb-3"
                onClick={() => {
                  iswritingNLChange(!iswritingNL);
                  if (iswritingNL) {
                    naturalLanguageQueryChange('');
                  }
                }}
              >
                {iswritingNL ? 'Write in SQL' : 'Write in English'}
              </button>
            )}

            <div className="flex items-center">
              <a
                className="flex items-center hover:underline"
                href={
                  'https://segmed.notion.site/Terminal-Quick-Start-00aeb8756f6d423ebc841b24b590b9b5'
                }
                target="_blank"
                title="Openda Terminal Schema"
                rel="noreferrer"
              >
                <p>Quickstart</p>
                <HiInformationCircle className="mx-2 text-primary hover:text-primary-active hover:cursor-pointer" />
              </a>
            </div>
          </div>
          {!iswritingNL && (
            <AceEditor
              className="w-full h-64 border-2 border-gray-300"
              showPrintMargin={false}
              fontSize={17}
              mode="mysql"
              theme="textmate"
              onChange={onQueryChange}
              placeholder={
                '-- Welcome to Terminal. We use Elastic Search SQL syntax here \n-- Remember not to add any ; at the end of your queries, please.'
              }
              value={query}
            />
          )}
          {iswritingNL && (
            <AceEditor
              className="w-full h-64 border-2 border-gray-300"
              showPrintMargin={false}
              fontSize={17}
              mode="markdown"
              theme="solarized_light"
              onChange={naturalLanguageQueryChange}
              placeholder={
                'Welcome to Natural Language mode. Click translate to return to SQL mode with your request translated to SQL.'
              }
              value={naturalLanguageQuery}
            />
          )}
          <br />
          <div className="flex-col justify-between">
            <div className="flex justify-between mb-3">
              <h2 className="text-2xl">Results</h2>
              <AddToDatasetDropdown
                disabled={!studiesSelected}
                reportSelectionState={reportSelectionState}
              />
            </div>
            {columns.length > 0 && (
              <>
                <span>{`${reportSelectionState.add.ids.size} Selected`}</span>
                <TerminalTable
                  columns={columns}
                  data={rows}
                  selectEnabled={columns[0].Header === 'study_id'}
                />
              </>
            )}
            {errMessage && columns.length === 0 && (
              <p className="text-red-700">{errMessage}</p>
            )}
          </div>
        </div>
      </ApplicationShell>
    </>
  );
};
