import React, {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

export const Register = () => {
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const isLiveTest = process.env.REACT_APP_LIVE_TEST !== 'true';
    if (!isLoading && isLiveTest) {
      if (!isAuthenticated) {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
          },
          appState: {
            returnTo: '/',
          },
        });
      } else {
        navigate('/');
      }
    }
  }, [isAuthenticated, isLoading]);

  return (
    <div>
      <button
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
            },
            appState: {
              returnTo: '/',
            },
          })
        }
      >
        Register
      </button>
    </div>
  );
};
