import React from 'react';
import {Route, useParams, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {isNil, clone, isFinite, toNumber} from 'lodash';
import {useQuery} from 'react-query';

import {fetchDataset} from '../../../models/dataset';

import {ErrorPage} from '../../../core/layout/error-page';
import {MetadataPage} from '../metadata';
import {DatasetDownloadPage} from '../download';
import {Loading} from '../../../core/components/loading';
import {useAxios} from 'src/utils/http';

export const DatasetDetails = () => {
  const api = useAxios();
  const q = useParams<{datasetId?: string}>();
  const datasetId = isFinite(toNumber(q.datasetId))
    ? toNumber(q.datasetId)
    : undefined;

  const {
    data: datasetWithStudies,
    error: datasetError,
    isLoading,
  } = useQuery(
    ['dataset', datasetId],
    () => {
      return fetchDataset(api, datasetId!);
    },
    {
      enabled: !isNil(datasetId),
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  if (datasetError || isNil(datasetId)) {
    // Error getting results
    return <ErrorPage statusCode={404} />;
  }

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dataset Details</title>
      </Helmet>

      {isLoading && <Loading />}
      {datasetWithStudies && (
        <Routes>
          <Route
            path="download"
            element={
              <DatasetDownloadPage dataset={datasetWithStudies.dataset} />
            }
          />

          <Route
            path=""
            element={
              <MetadataPage
                dataset={datasetWithStudies.dataset}
                studies={clone(datasetWithStudies.studies)} // @todo: clone so that the memo can update. Could be more elegant
              />
            }
          />
        </Routes>
      )}
    </>
  );
};
