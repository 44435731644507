import {ReportSection, ReportSectionComponent} from './report';

export const FullReport = ({sections}: {sections: ReportSection[]}) => {
  return (
    <div className="grid grid-cols-1 gap-10">
      {sections.map((section, i) => (
        <ReportSectionComponent section={section} key={i} />
      ))}
    </div>
  );
};
