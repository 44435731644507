import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {Buffer} from 'buffer';
import _ from 'lodash';

// @todo: Remove this function and use useLocation directly where needed
export const useURLQuery = () => {
  const {search} = useLocation();

  const values = queryString.parse(search);

  return values;
};

// Encodes the object in base64 and returns a URL-friendly string. Returns undefined if encode failed
export const encodeURLObject = (obj?: Object | Object[]) => {
  if (obj === undefined) return undefined;

  let encodedObj;
  try {
    encodedObj = encodeURIComponent(
      Buffer.from(JSON.stringify(obj), 'ascii').toString('base64')
    );
  } catch (e) {
    return undefined;
  }

  return encodedObj;
};

// Takes the URL-friendly, base64 encoded object string and returns an object. Returns undefined if encode failed
export const decodeURLObject = (encodedObj?: string | string[]) => {
  if (encodedObj === undefined) return undefined;

  const decode = (encoded: string) => {
    let obj;
    try {
      obj = JSON.parse(
        Buffer.from(decodeURIComponent(encoded), 'base64').toString('ascii')
      );
    } catch {
      return undefined;
    }
    return obj;
  };

  if (_.isArray(encodedObj)) {
    return encodedObj.map(obj => decode(obj));
  }

  return decode(encodedObj);
};
