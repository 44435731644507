import {useState} from 'react';
import {toast} from 'react-toastify';

import {ToastMessage} from '../../core/components/toast';
import {
  Tag as TagInterface,
  convertTagToEditTagRequest,
  editTag,
} from '../../models/tags';
import {ColorPicker, Tag} from '.';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

export const EditTagColorForm = ({
  edited,
  cancelled,
  tag,
}: {
  edited?: (tag: TagInterface) => void;
  cancelled?: () => void;
  tag: TagInterface;
}) => {
  const api = useAxios();
  const [color, colorChange] = useState<string[]>(['', '']);

  const updatedTag: TagInterface = {
    ...tag,
    backgroundColor: color[1],
    fontColor: color[0],
  };

  const editTagMut = useMutation(
    ({tagId, updatedTag}: {tagId: number; updatedTag: TagInterface}) =>
      editTag(api, tagId, convertTagToEditTagRequest(updatedTag)),
    {
      onSuccess: () => {
        toast(<ToastMessage title="Tag color changed" icon="success" />);
        if (edited) {
          edited(updatedTag);
        }
      },
      onError: (err: AxiosError) => {
        const message = err?.response?.data?.message ?? 'Error editing tag';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const handleSubmit = () => {
    editTagMut.mutate({
      tagId: tag.tid,
      updatedTag: updatedTag,
    });
  };

  return (
    <div>
      <div className="text-lg mb-2 text-gray-900">Change color</div>
      <div className="text-sm leading-5 font-normal text-gray-500 mb-5">
        The tag color will update throughout your account.
      </div>
      <div className="mb-6 flex items-center justify-evenly">
        <ColorPicker
          onChange={colorChange}
          currentColor={color[0] ? color : [tag.fontColor, tag.backgroundColor]}
        />
      </div>
      <div className="flex flex-row mt-4 items-center input-label">
        Tag Preview:{' '}
        <div className="ml-2">
          <Tag tag={color[0] ? updatedTag : tag} />
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <button
          className="btn btn-primary ml-3"
          onClick={handleSubmit}
          disabled={!color[0] || editTagMut.isLoading}
        >
          Save
        </button>
        <button className="btn btn-white" onClick={cancelled}>
          Cancel
        </button>
      </div>
    </div>
  );
};
