import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {Column} from 'react-table';
import {Helmet} from 'react-helmet';

import {
  MinMaxFilter,
  TextFilter,
  ReactTable,
} from '../../../core/components/react-table';
import {
  DicomRequestSummaryData,
  DicomRequestStatus,
  fetchDatasetDicomRequestStatusAdmin,
} from '../../../models/dataset';
import _, {map} from 'lodash';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useParams} from 'react-router-dom';
import {useAxios} from 'src/utils/http';

export const AdminDicomRequestStatusPage = () => {
  const api = useAxios();

  const q = useParams<{
    datasetId?: string;
  }>();
  let datasetIdNum = 0; // Default value set to 0

  // Check if datasetId is a string and convert to number
  if (_.isString(q.datasetId)) {
    const convertedNumber = Number(q.datasetId);

    datasetIdNum = isNaN(convertedNumber) ? 0 : convertedNumber;
  }
  const {data: detailsAndSummary, isLoading: datasetsIsLoading} = useQuery(
    ['adminDicomRequestStatus'],
    () => {
      return fetchDatasetDicomRequestStatusAdmin(api, datasetIdNum);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const datasetDicomRequestStatus = useMemo(() => {
    return detailsAndSummary?.datasets || [];
  }, [detailsAndSummary?.datasets]);

  const summaryData =
    (detailsAndSummary?.summary as DicomRequestSummaryData) ||
    ({} as DicomRequestSummaryData);

  const columns = useMemo<Array<Column<DicomRequestStatus>>>(
    () => [
      {
        Header: 'Study Info',
        columns: [
          {
            Header: 'Study ID',
            accessor: 'studyId',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Status',
            accessor: 'dicomRequestStatus',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}) => <span title={value}>{value}</span>,
          },
          {
            Header: 'ErrorMsg',
            accessor: 'errorMsg',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}) => <span title={value}>{value}</span>,
          },
          {
            Header: 'Log Time',
            accessor: 'logTime',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Created At',
            accessor: 'createdAt',
            Filter: MinMaxFilter,
            filter: 'between',
          },
          {
            Header: 'Updated At',
            accessor: 'updatedAt',
            Filter: MinMaxFilter,
            filter: 'between',
          },
        ],
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      datasetDicomRequestStatus
        ? map(datasetDicomRequestStatus, datasetDicomStatus => {
            return datasetDicomStatus;
          })
        : [],
    [datasetDicomRequestStatus]
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Dataset Dicom Request Status</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <h1 className="text-2xl mb-5">Dataset Dicom Request Status</h1>

        {datasetsIsLoading ? (
          'Loading datasets dicom request status...'
        ) : (
          <>
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
              <h2 className="text-xl font-semibold mb-4">Summary</h2>
              <div className="grid grid-cols-2 gap-4">
                <p>
                  Total Requests:{' '}
                  <span className="font-medium">{summaryData.total}</span>
                </p>
                <p>
                  Handled Requests:{' '}
                  <span className="font-medium">{summaryData.handled}</span>
                </p>
                <p>
                  Not Handled:{' '}
                  <span className="font-medium">
                    {summaryData.statusCount.NOT_HANDLE}
                  </span>
                </p>
                <p>
                  OK:{' '}
                  <span className="font-medium">
                    {summaryData.statusCount.OK}
                  </span>
                </p>
                <p>
                  Timeout:{' '}
                  <span className="font-medium">
                    {summaryData.statusCount.TIMEOUT}
                  </span>
                </p>
                {summaryData.metadataCount.map((item, index) => (
                  <div key={index} className="col-span-2">
                    <p className="font-semibold">
                      Source Location: {item.source_location}
                    </p>
                    <div className="flex justify-between">
                      <span>
                        Available: {item.available} Total: {item.total}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ReactTable
              tableColumns={columns}
              tableData={data}
              showCountDesc={'Status'}
              defaultSortBy={[{id: 'study_id', desc: true}]}
            />
          </>
        )}
      </ApplicationShell>
    </>
  );
};
