import React, {useState, useEffect} from 'react';
import {useQueryClient, useMutation} from 'react-query';
import ReactTooltip from 'react-tooltip';

import {quickToast} from '../../core/components/toast';
import {
  DatasetOrder,
  updateDatasetOrdersStatus,
} from '../../models/dataset-order';
import {Modal} from '../../core/layout/modal';
import {DatasetOrderStatusEnum} from 'src/enums/datasetorderstatusenum';
import {useAxios} from 'src/utils/http';

export const IncludeDatasetOrderStatus = ({
  datasetOrder,
}: {
  datasetOrder: DatasetOrder;
}) => {
  const queryClient = useQueryClient();
  const http = useAxios();

  const [modalType, modalTypeChange] = useState<'quotes' | 'paid'>();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [datasetOrder]);

  const closeModal = () => {
    modalTypeChange(undefined);
  };

  const openModal = (type: 'quotes' | 'paid') => {
    modalTypeChange(type);
  };

  const orderCanBeSetPaid =
    DatasetOrderStatusEnum.DatasetOrderStatusPaid !== datasetOrder.orderStatus;

  const orderCanBeSetQuotesSigned = [
    DatasetOrderStatusEnum.DatasetOrderStatusOrdered,
    DatasetOrderStatusEnum.DatasetOrderStatusQuotesSigned,
  ].includes(datasetOrder.orderStatus);

  const renderQuotesModal = () => {
    return (
      <Modal
        isOpen={modalType === 'quotes'}
        onRequestClose={closeModal}
        className="w-96 max-w-lg"
      >
        <div>
          <div className="text-lg mb-3">Set Quotes Signed</div>
          <div className="text-sm text-gray-500 mb-3">
            Set this dataset and dataset order to quote signed status. This will
            set the full dataset to be packed and scanned.
          </div>
          <div className="flex flex-row-reverse items-center justify-between">
            <button
              className="btn btn-primary"
              onClick={() => {
                updateDatasetOrderStatusMut.mutate({
                  orderId: datasetOrder.orderId,
                  status: DatasetOrderStatusEnum.DatasetOrderStatusQuotesSigned,
                });
              }}
              disabled={updateDatasetOrderStatusMut.isLoading}
            >
              Submit
            </button>
            <button className="btn btn-white" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderPaidModal = () => {
    return (
      <Modal
        isOpen={modalType === 'paid'}
        onRequestClose={closeModal}
        className="w-96 max-w-lg"
      >
        <div>
          <div className="text-lg mb-3">Set Order Paid</div>
          <div className="text-sm text-gray-500 mb-3">
            Set this order to paid status. The dataset will not be affected,
            this just records when the order was paid for.
          </div>
          <div className="flex flex-row-reverse items-center justify-between">
            <button
              className="btn btn-primary"
              onClick={() => {
                updateDatasetOrderStatusMut.mutate({
                  orderId: datasetOrder.orderId,
                  status: DatasetOrderStatusEnum.DatasetOrderStatusPaid,
                });
              }}
              disabled={updateDatasetOrderStatusMut.isLoading}
            >
              Submit
            </button>
            <button className="btn btn-white" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const updateDatasetOrderStatusMut = useMutation(
    ({orderId, status}: {orderId: string; status: DatasetOrderStatusEnum}) =>
      updateDatasetOrdersStatus(http, orderId, status),
    {
      onSuccess: (_data, datasetOrder) => {
        const message = `Dataset order status updated with ${datasetOrder.status} status`;
        quickToast({title: message, icon: 'success'});

        queryClient.invalidateQueries(['adminDatasetOrders'], {exact: true});
        queryClient.invalidateQueries(['order', datasetOrder.orderId]);

        closeModal();
      },
    }
  );

  return (
    <>
      <div className={'space-x-2 text-center'}>
        <span
          data-tip={
            orderCanBeSetQuotesSigned
              ? ''
              : `Order Status can not be set to quotes signed. Current status is:  ${datasetOrder.orderStatus}`
          }
        >
          <button
            className="btn btn-secondary inline-block"
            onClick={() => openModal('quotes')}
            disabled={!orderCanBeSetQuotesSigned}
          >
            Quotes signed
          </button>
        </span>

        <span
          data-tip={
            orderCanBeSetPaid
              ? ''
              : `Order Status can not be set to paid. Current status is:  ${datasetOrder.orderStatus}`
          }
        >
          <button
            className="btn btn-primary inline-block"
            onClick={() => openModal('paid')}
            disabled={!orderCanBeSetPaid}
          >
            Order paid
          </button>
        </span>
      </div>
      {renderQuotesModal()}
      {renderPaidModal()}
    </>
  );
};
