import _ from 'lodash';

import {SearchQuery} from '../models/search';

export const handleLegacySearchQuery = (
  searchQuery: SearchQuery | undefined
) => {
  if (_.isNil(searchQuery)) {
    return undefined;
  }
  if (!_.isNil(searchQuery.modality) && !_.isArray(searchQuery.modality)) {
    searchQuery.modality = [searchQuery.modality];
  }
  return searchQuery;
};
