import React from 'react';
import {Helmet} from 'react-helmet';

import {FileUpload} from '../../components/file-upload';
import {ApplicationShell} from '../../core/layout/application-shell';

export const FileUploadPage = () => {
  return (
    <>
      <Helmet>
        <title>Segmed Openda - Upload</title>
      </Helmet>

      <ApplicationShell>
        <div className="grid grid-cols-4 gap-x-8 gap-y-2">
          <div className="col-span-4 text-2xl leading-7 font-bold text-gray-900 mb-5 flex-none grow-0">
            Data Partner File Uploading
          </div>
          <div className="col-start-1 col-end-3 text-base leading-7 font-normal text-gray-500">
            Upload data samples by dragging and dropping files. ZIP files are
            recommended, but most common file types such as DICOM, PDF, and .txt
            files are also supported. Maximum file size is 25 GB
          </div>
          <div className="col-start-1 col-end-3 text-base leading-7 font-normal text-gray-700">
            After uploading it takes 5 days to process the samples.
          </div>
        </div>
        <FileUpload />
      </ApplicationShell>
    </>
  );
};
