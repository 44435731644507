import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {Column, Row} from 'react-table';
import {Link} from 'react-router-dom';
import {constant} from 'lodash';

import {formatCurrency} from '../../../utils/strings';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {
  MinMaxFilter,
  ReactTable,
  TextFilter,
} from '../../../core/components/react-table';
import {
  fetchAllDatasetOrders,
  DatasetOrder,
} from '../../../models/dataset-order';
import {IncludeDatasetOrderStatus} from '../../../components/dataset-order/order-status';
import {useAxios} from 'src/utils/http';

export const AdminOrdersPage = () => {
  const api = useAxios();

  const {data: datasetOrders, isLoading: datasetsIsLoading} = useQuery(
    ['adminDatasetOrders'],
    () => {
      return fetchAllDatasetOrders(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const columns = useMemo<Array<Column<DatasetOrder>>>(
    () => [
      {
        Header: 'Orders',
        columns: [
          {
            Header: 'ID',
            accessor: 'orderId',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}: {value: string}) => (
              <Link className="link" to={`/admin/orders/${value}`}>
                {value}
              </Link>
            ),
          },
          {
            Header: 'Dataset',
            accessor: 'datasetId',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Ordered',
            accessor: 'orderedAt',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}) => value?.toFormat('LLL dd, yyyy') ?? '',
          },
          {
            Header: 'Amount',
            accessor: 'amount',
            Filter: MinMaxFilter,
            filter: 'between',
            Cell: ({value}: {value: number}) => (
              <span>{formatCurrency(value)}</span>
            ),
          },
          {
            Header: 'Num Studies',
            accessor: 'numStudies',
            Filter: MinMaxFilter,
            filter: 'between',
          },
          {
            Header: 'Order Status',
            accessor: 'orderStatus',
            Filter: TextFilter,
          },
          {
            id: 'actions',
            Header: 'Actions',
            width: 85,
            accessor: constant(null),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({row}: {row: Row<DatasetOrder>}) => {
              return <IncludeDatasetOrderStatus datasetOrder={row.original} />;
            },
          },
        ],
      },
    ],
    []
  );

  const data = useMemo(() => datasetOrders ?? [], [datasetOrders]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Manage Dataset Order</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <h1 className="text-2xl mb-5">All Dataset Orders</h1>

        {datasetsIsLoading ? (
          'Loading dataset orders...'
        ) : (
          <ReactTable
            tableColumns={columns}
            tableData={data}
            showCountDesc={'Dataset Orders'}
            defaultSortBy={[{id: 'datasetId', desc: true}]}
          />
        )}
      </ApplicationShell>
    </>
  );
};
