import React, {useEffect} from 'react';
import {useQueryClient, useMutation} from 'react-query';
import ReactTooltip from 'react-tooltip';
import {quickToast} from '../../core/components/toast';
import {
  UserSyntheticDataCredit,
  updateSyntheticDataCredit,
} from '../../models/synthetic-data';
import {useAxios} from 'src/utils/http';
export const ResetSyntheticDataCredits = ({
  userSyntheticDataCredit,
}: {
  userSyntheticDataCredit: UserSyntheticDataCredit;
}) => {
  const queryClient = useQueryClient();
  const api = useAxios();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [userSyntheticDataCredit]);

  const updateCreditStatusMut = useMutation(
    ({userId, credit}: {userId: number; credit: number}) =>
      updateSyntheticDataCredit(api, userId, credit),
    {
      onSuccess: () => {
        const message = 'User credit has been increased';
        quickToast({title: message, icon: 'success'});

        queryClient.invalidateQueries(['adminSyntheticDataCreditUsers'], {
          exact: true,
        });
      },
    }
  );

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => {
          updateCreditStatusMut.mutate({
            userId: userSyntheticDataCredit.userId,
            credit: 30,
          });
        }}
      >
        Give 30 credits
      </button>
    </>
  );
};
