import {useState} from 'react';
import {toast} from 'react-toastify';
import cx from 'classnames';

import {ToastMessage} from '../../core/components/toast';
import {
  Tag,
  convertTagToEditTagRequest,
  editTag,
  validateTagName,
} from '../../models/tags';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

export const RenameTagForm = ({
  edited,
  cancelled,
  tag,
}: {
  edited?: (tag: Tag) => void;
  cancelled?: () => void;
  tag: Tag;
}) => {
  const api = useAxios();
  const [name, nameChange] = useState<string>(tag.name);

  const editTagMut = useMutation(
    ({tagId, updatedTag}: {tagId: number; updatedTag: Tag}) =>
      editTag(api, tagId, convertTagToEditTagRequest(updatedTag)),
    {
      onSuccess: (_response, {updatedTag}) => {
        toast(<ToastMessage title="Tag renamed" icon="success" />);
        if (edited) {
          edited(updatedTag);
        }
      },
      onError: (err: AxiosError) => {
        const message = err?.response?.data?.message ?? 'Error renaming tag';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const handleSubmit = () => {
    const updatedTag = {...tag, name};
    editTagMut.mutate({
      tagId: tag.tid,
      updatedTag: updatedTag,
    });
  };

  const tagNameError = validateTagName(name);

  return (
    <div className="w-96">
      <div className="text-lg mb-3 text-gray-900">Rename Tag</div>
      <div className="text-sm leading-5 font-normal text-gray-500 mb-4">
        The tag name will update throughout your account.
      </div>
      <div className="mb-5">
        <label htmlFor="name" className="input-label">
          New name
        </label>
        <input
          id="name"
          type="text"
          className={cx('text-input w-full mt-2', {
            'text-input-error': tagNameError,
          })}
          value={name}
          onChange={e => nameChange(e.target.value)}
          autoComplete="off"
        />
      </div>
      {tagNameError && (
        <div className="text-sm text-red-900 -mt-2 mb-2">{tagNameError}</div>
      )}
      <div className="flex flex-row-reverse">
        <button
          className="btn btn-primary ml-3"
          onClick={handleSubmit}
          disabled={
            !name || editTagMut.isLoading || !!tagNameError || name === tag.name
          }
        >
          Save
        </button>
        <button className="btn btn-white" onClick={cancelled}>
          Cancel
        </button>
      </div>
    </div>
  );
};
