import React, {useState, useEffect, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {ApplicationShell} from '../../core/layout/application-shell';
import {DateTime} from 'luxon';
import {Modal} from 'src/core/layout/modal/modal';
import {toast} from 'react-toastify';
import {ToastMessage} from '../../core/components/toast';
import {AxiosInstance} from 'axios';
import {useAxios} from 'src/utils/http';

interface ReportedFailure {
  id: number;
  title: string;
  reporter: string;
  created_at: string;
  updated_at: string;
  status: 'open' | 'closed';
  team: 'piper' | 'insight' | 'other' | 'all';
}

interface TeamMapping {
  piper: string[];
  insight: string[];
  other: string[];
}

export const DoraPage = () => {
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({weeks: 2}).toISO()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toISO());
  const [selectedTeam, setSelectedTeam] = useState('all');
  const [deployments, setDeployments] = useState([]);
  const [averageLeadTime, setAverageLeadTime] = useState(0);
  const [showReportFailureModal, setShowReportFailureModal] = useState(false);
  const [failureTitle, setFailureTitle] = useState('');
  const [team, setTeam] = useState('all');
  const [reportedFailures, setReportedFailures] = useState<ReportedFailure[]>(
    []
  );
  const [activeFailures, setActiveFailures] = useState<ReportedFailure[]>([]);
  const [resolvedFailures, setResolvedFailures] = useState<ReportedFailure[]>(
    []
  );
  const [failureRate, setFailureRate] = useState<number | null>(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentFailureId, setCurrentFailureId] = useState<number | null>(null);
  const [currentAction, setCurrentAction] = useState<
    'delete' | 'resolve' | null
  >(null);
  const [averageTimeToRestore, setAverageTimeToRestore] =
    useState<string>('Calculating...');
  const [currentTeamDeployments, setCurrentTeamDeployments] = useState([]);
  const [teamMapping, setTeamMapping] = useState<TeamMapping>({
    piper: [],
    insight: [],
    other: [],
  });
  const http = useAxios();

  const fetchDoraData = useCallback(
    async (http: AxiosInstance) => {
      try {
        const response = await http.get(
          `/v1/dora_data?start_date=${startDate}&end_date=${endDate}`
        );

        const {merges, reported_failures, team_mapping} = response.data;
        setDeployments(merges);
        setCurrentTeamDeployments(merges);
        setReportedFailures(
          reported_failures.filter((f: ReportedFailure) => f.status.length > 0)
        );
        setTeamMapping(team_mapping);

        const totalLeadTime = merges.reduce(
          (total: number, deployment: any) => {
            const created = DateTime.fromISO(deployment.created_at);
            const merged = DateTime.fromISO(deployment.merged_at);
            const leadTime = merged.diff(created, 'hours').hours;
            return total + leadTime;
          },
          0
        );

        const avgLeadTime =
          merges.length > 0 ? totalLeadTime / merges.length : 0;
        setAverageLeadTime(parseFloat(avgLeadTime.toFixed(2)));
      } catch (error) {
        console.error('Failed to fetch deployment data:', error);
      }
    },
    [startDate, endDate]
  );

  useEffect(() => {
    if (startDate && endDate) {
      fetchDoraData(http);
    }
  }, [startDate, endDate, fetchDoraData]); // Only re-run the effect if startDate or endDate changes

  useEffect(() => {
    // Filter reportedFailures by the selected team
    const filteredFailures = reportedFailures.filter(failure => {
      // If 'all' is selected, return all failures
      // Otherwise, filter based on the selected team
      return (
        selectedTeam === 'all' ||
        failure.team.toLowerCase() === selectedTeam.toLowerCase()
      );
    });

    // When reportedFailures changes, filter into active and resolved lists based on the selected team
    setActiveFailures(filteredFailures.filter(f => f.status === 'open'));
    setResolvedFailures(filteredFailures.filter(f => f.status === 'closed'));
  }, [reportedFailures, selectedTeam]); // Add selectedTeam to the dependency array

  useEffect(() => {
    // Calculate failure rate for the selected team
    if (currentTeamDeployments.length > 0) {
      // Use filtered failures based on the selected team
      const teamFilteredFailures =
        selectedTeam === 'all'
          ? reportedFailures
          : reportedFailures.filter(f => f.team === selectedTeam);

      const totalFailures = teamFilteredFailures.length;
      const rate = (totalFailures / currentTeamDeployments.length) * 100; // convert to percentage
      setFailureRate(parseFloat(rate.toFixed(2))); // keep two decimals
    } else {
      setFailureRate(null); // handle the case where there are no currentTeamDeployments
    }
  }, [reportedFailures, currentTeamDeployments, selectedTeam]);

  useEffect(() => {
    // Calculate the average time to restore service for the selected team's resolved failures
    const teamFilteredResolved =
      selectedTeam === 'all'
        ? resolvedFailures
        : resolvedFailures.filter(f => f.team === selectedTeam);

    if (teamFilteredResolved.length > 0) {
      const totalTimeToRestore = teamFilteredResolved.reduce((acc, failure) => {
        const created = DateTime.fromISO(failure.created_at);
        const updated = DateTime.fromISO(failure.updated_at);
        return acc + updated.diff(created, 'hours').hours;
      }, 0);

      const avgTimeToRestore = totalTimeToRestore / teamFilteredResolved.length;
      setAverageTimeToRestore(`${avgTimeToRestore.toFixed(2)} hours`);
    } else {
      setAverageTimeToRestore('N/A');
    }
  }, [resolvedFailures, selectedTeam]);

  // When selectedTeam changes, filter the deployments accordingly
  useEffect(() => {
    // Filter the current team deployments
    const filteredDeployments = deployments.filter((deployment: any) => {
      const username = deployment.author.username;
      return (
        selectedTeam === 'all' ||
        teamMapping[selectedTeam as keyof typeof teamMapping]?.includes(
          username
        )
      );
    });

    setCurrentTeamDeployments(filteredDeployments);

    // Calculate the average lead time for the filtered deployments
    const totalLeadTime = filteredDeployments.reduce(
      (total: number, deployment: any) => {
        const created = DateTime.fromISO(deployment.created_at);
        const merged = DateTime.fromISO(deployment.merged_at);
        const leadTime = merged.diff(created, 'hours').hours;
        return total + leadTime;
      },
      0
    );

    const avgLeadTime =
      filteredDeployments.length > 0
        ? totalLeadTime / filteredDeployments.length
        : 0;
    setAverageLeadTime(parseFloat(avgLeadTime.toFixed(2)));
  }, [selectedTeam, deployments, teamMapping]);

  const handleStartDateChange = (e: any) => {
    const newStartDate = DateTime.fromISO(e.target.value)
      .startOf('day')
      .toISO();
    if (DateTime.fromISO(newStartDate) > DateTime.fromISO(endDate)) {
      toast(
        <ToastMessage
          title={'Start date must be before end date'}
          icon="error"
        />
      );
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (e: any) => {
    const newEndDate = DateTime.fromISO(e.target.value).endOf('day').toISO();
    if (DateTime.fromISO(startDate) > DateTime.fromISO(newEndDate)) {
      toast(
        <ToastMessage
          title={'End date must be after start date'}
          icon="error"
        />
      );
    } else {
      setEndDate(newEndDate);
    }
  };

  // Function to open the modal
  const openReportFailureModal = () => {
    setShowReportFailureModal(true);
  };

  // Function to close the modal
  const closeReportFailureModal = () => {
    setShowReportFailureModal(false);
  };

  const handleDeleteFailure = (id: number) => {
    setCurrentAction('delete');
    setCurrentFailureId(id);
    setShowConfirmModal(true);
  };

  const handleResolveFailure = (id: number) => {
    setCurrentAction('resolve');
    setCurrentFailureId(id);
    setShowConfirmModal(true);
  };

  const submitReportFailure = async () => {
    try {
      const payload = {title: failureTitle, team: team};
      // Include start and end dates in the request URL as query parameters
      const startDateParam = encodeURIComponent(startDate);
      const endDateParam = encodeURIComponent(endDate);
      const url = `/v1/report_failure?start_date=${startDateParam}&end_date=${endDateParam}`;
      const response = await http.post(url, payload);
      closeReportFailureModal();
      if (response && response.data) {
        setReportedFailures(response.data);
      }
      setFailureTitle('');
    } catch (error) {
      console.error('Failed to report failure:', error);
    }
  };

  const confirmDelete = async () => {
    if (currentFailureId) {
      try {
        await http.delete(`/v1/report_failure?id=${currentFailureId}`);
        await fetchDoraData(http); // Refetch data after delete
      } catch (error) {
        console.error('Failed to delete failure:', error);
      }
    }
    setShowConfirmModal(false);
  };

  const confirmResolve = async () => {
    if (currentFailureId) {
      try {
        await http.put(`/v1/report_failure?id=${currentFailureId}`);
        await fetchDoraData(http); // Refetch data after resolve
      } catch (error) {
        console.error('Failed to resolve failure:', error);
      }
    }
    setShowConfirmModal(false);
  };

  const numDays =
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
    (1000 * 3600 * 24);
  const deploymentFrequency = currentTeamDeployments.length / numDays;

  return (
    <>
      <Helmet>
        <title>Segmed Openda - DevOps Metrics</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <Modal
          isOpen={showConfirmModal}
          onRequestClose={() => setShowConfirmModal(false)}
          shouldCloseOnOverlayClick={true}
          className="max-w-lg mx-auto" // Adjust the width as needed
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">
              Confirm {currentAction === 'delete' ? 'Deletion' : 'Resolution'}
            </h2>
            <p>
              Are you sure you want to{' '}
              {currentAction === 'delete' ? 'delete' : 'resolve'} this failure?
            </p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition duration-300"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </button>
              <button
                className={`bg-${
                  currentAction === 'delete' ? 'red' : 'blue'
                }-500 text-white px-4 py-2 rounded hover:bg-${
                  currentAction === 'delete' ? 'red' : 'blue'
                }-700 transition duration-300`}
                onClick={
                  currentAction === 'delete' ? confirmDelete : confirmResolve
                }
              >
                {currentAction === 'delete' ? 'Delete' : 'Resolve'}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showReportFailureModal}
          onRequestClose={closeReportFailureModal}
          shouldCloseOnOverlayClick={true}
          className="w-lg mx-auto"
        >
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Report a Failure</h2>
            <div className="space-y-4">
              {/* Failure Title Input */}
              <div>
                <label
                  htmlFor="failure-title"
                  className="text-sm font-medium text-gray-700"
                >
                  Failure Title
                </label>
                <input
                  id="failure-title"
                  type="text"
                  placeholder="What broke and what caused it"
                  value={failureTitle}
                  onChange={e => setFailureTitle(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              </div>

              {/* Team Selection Dropdown */}
              <div>
                <label
                  htmlFor="team-selection"
                  className="text-sm font-medium text-gray-700"
                >
                  Team
                </label>
                <select
                  id="team-selection"
                  value={team}
                  onChange={e => setTeam(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="piper">Piper</option>
                  <option value="insight">Openda</option>
                  <option value="other">Other</option>
                  <option value="all">All</option>
                </select>
              </div>

              <button
                onClick={submitReportFailure}
                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>

        <h1 className="text-3xl font-semibold mb-8">DevOps Metrics</h1>
        <div className="mb-4">
          <div className="flex flex-wrap items-end gap-4">
            <div className="flex-1 min-w-0">
              <label
                htmlFor="start-date"
                className="block text-sm font-medium text-gray-700"
              >
                Start Date
              </label>
              <input
                type="date"
                id="start-date"
                name="start-date"
                value={DateTime.fromISO(startDate).toISODate()}
                onChange={handleStartDateChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
            <div className="flex-1 min-w-0">
              <label
                htmlFor="end-date"
                className="block text-sm font-medium text-gray-700"
              >
                End Date
              </label>
              <input
                type="date"
                id="end-date"
                name="end-date"
                value={DateTime.fromISO(endDate).toISODate()}
                onChange={handleEndDateChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
            {/* Team Toggle */}
            <div className="flex flex-col items-start mb-4">
              <label
                htmlFor="teamToggle"
                className="text-sm font-medium text-gray-700 mb-2"
              >
                Select Team
              </label>
              <div className="flex items-center">
                {['piper', 'insight', 'other', 'all'].map(team => (
                  <label key={team} className="inline-flex items-center mr-6">
                    <input
                      type="radio"
                      id="teamToggle"
                      name="teamToggle"
                      value={team}
                      checked={selectedTeam === team}
                      onChange={e => setSelectedTeam(e.target.value)}
                      className="form-radio h-5 w-5 text-purple-600"
                    />
                    <span className="ml-2 text-gray-700 capitalize">
                      {team}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <button
                onClick={openReportFailureModal}
                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
              >
                Report a Failure
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-1/2 px-3 mb-4">
            <div className="flex justify-between items-center p-8 bg-blue-500 text-white rounded-lg shadow-md">
              <div>
                <h2 className="text-xl font-semibold">Deployment Frequency</h2>
                <p className="mt-4">
                  Between{' '}
                  {DateTime.fromISO(startDate).toLocaleString(
                    DateTime.DATE_MED
                  )}{' '}
                  and{' '}
                  {DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}{' '}
                  Segmed deployed {currentTeamDeployments.length} times
                </p>
              </div>
              <div className="text-4xl">
                {isNaN(deploymentFrequency)
                  ? 'N/A'
                  : deploymentFrequency.toFixed(2) + ' merges/day'}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-3 mb-4">
            <div className="p-8 bg-green-500 text-white rounded-lg shadow-md flex justify-between">
              <div>
                <h2 className="text-xl font-semibold">Lead Time for Changes</h2>
                <p className="mt-4">
                  Between{' '}
                  {DateTime.fromISO(startDate).toLocaleString(
                    DateTime.DATE_MED
                  )}{' '}
                  and{' '}
                  {DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}{' '}
                  it took an average of {averageLeadTime} hours for code to be
                  reviewed and merged.
                </p>
              </div>
              <div>
                <span className="text-5xl font-bold">
                  {averageLeadTime} hours
                </span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-3 mb-4">
            <div className="p-8 bg-red-500 text-white rounded-lg shadow-md flex justify-between">
              <div>
                <h2 className="text-xl font-semibold">Change Failure Rate</h2>
                <p className="mt-4">
                  Between{' '}
                  {DateTime.fromISO(startDate).toLocaleString(
                    DateTime.DATE_MED
                  )}{' '}
                  and{' '}
                  {DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}{' '}
                  we caused a bug with {failureRate}% of deployments.
                </p>
              </div>
              <div>
                <span className="text-5xl font-bold">
                  {failureRate !== null ? `${failureRate}%` : 'N/A'}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 px-3 mb-4">
            <div className="p-8 bg-yellow-500 text-white rounded-lg shadow-md flex justify-between">
              <div>
                <h2 className="text-xl font-semibold">
                  Time to Restore Service
                </h2>
                <p className="mt-4">
                  Between{' '}
                  {DateTime.fromISO(startDate).toLocaleString(
                    DateTime.DATE_MED
                  )}{' '}
                  and{' '}
                  {DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_MED)}{' '}
                  we fixed errors in production in an average of{' '}
                  {averageTimeToRestore}
                </p>
              </div>
              <div>
                <span className="text-5xl font-bold">
                  {averageTimeToRestore}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3">
          {/* Active Failures column */}
          <div className="w-full lg:w-1/3 px-3 mb-4">
            <div className="bg-red-500 p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-white">
                Active Failures
              </h3>
              <ul>
                {activeFailures.map((failure, index) => {
                  // Calculate time since creation for each failure
                  const created = DateTime.fromISO(failure.created_at);
                  const now = DateTime.now();
                  const timeSinceCreation = now.diff(created, 'hours').hours;
                  // Format total hours since creation with two decimal places
                  const formattedHoursSinceCreation =
                    timeSinceCreation.toFixed(2);

                  return (
                    <li key={index} className="mb-2">
                      <div className="bg-red-100 p-3 rounded flex justify-between">
                        <div>
                          <p className="text-red-800">{failure.title}</p>
                          <p className="text-sm text-red-600">
                            Reported by: {failure.reporter}
                          </p>
                          {/* Display time since creation in hours with two decimal places */}
                          <p className="text-sm text-red-600">
                            Reported: {formattedHoursSinceCreation} hours ago
                          </p>
                        </div>
                        <div className="mt-2">
                          <button
                            onClick={() => handleResolveFailure(failure.id)}
                            className="text-xs font-bold uppercase px-3 py-1 mb-1 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring transition duration-300 ease-in-out w-full"
                          >
                            Resolve
                          </button>
                          <button
                            onClick={() => handleDeleteFailure(failure.id)}
                            className="text-xs font-bold uppercase px-3 py-1 rounded-lg bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring transition duration-300 ease-in-out w-full"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Resolved Failures column */}
          <div className="w-full lg:w-1/3 px-3 mb-4">
            <div className="bg-green-500 p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-white">
                Resolved Failures
              </h3>
              <ul>
                {resolvedFailures.map((failure, index) => {
                  // Calculate time to resolve for each failure
                  const created = DateTime.fromISO(failure.created_at);
                  const updated = DateTime.fromISO(failure.updated_at);
                  const timeToResolve = updated
                    .diff(created, 'hours')
                    .toObject()
                    .hours?.toFixed(2);

                  return (
                    <li key={index} className="mb-2">
                      <div className="bg-green-100 p-2 rounded">
                        <p className="text-green-800">{failure.title}</p>
                        <p className="text-sm text-green-600">
                          Reported by: {failure.reporter}
                        </p>
                        {/* Display time to resolve */}
                        <p className="text-sm text-green-600">
                          Time to Resolve: {timeToResolve} hours
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Merge Requests column */}
          <div className="w-full lg:w-1/3 px-3 mb-4">
            <div className="bg-blue-500 p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-white">
                Merge Requests
              </h3>
              <ul>
                {currentTeamDeployments.map(
                  (deployment: any, index: number) => {
                    // Calculate the duration the MR was open
                    const createdDate = DateTime.fromISO(deployment.created_at);
                    const mergedDate = DateTime.fromISO(deployment.merged_at);
                    const durationOpen = mergedDate
                      .diff(createdDate, ['days', 'hours', 'minutes'])
                      .toObject();

                    return (
                      <li key={index} className="mb-2">
                        <div className="bg-blue-100 p-3 rounded flex justify-between">
                          <div>
                            <p className="text-blue-800">{deployment.title}</p>
                            <p className="text-sm text-blue-600">
                              Merged by: {deployment.author.username}
                            </p>
                            <p className="text-sm text-blue-600">
                              Open for: {durationOpen.days} days,{' '}
                              {durationOpen.hours} hours, and{' '}
                              {durationOpen.minutes} minutes
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </ApplicationShell>
    </>
  );
};
