import _ from 'lodash';

import {responseToDataset} from './dataset';
import {AxiosInstance} from 'axios';

export const fetchAllDatasetsNeedReviewAdmin = (http: AxiosInstance) => {
  return http.get('/v1/admin/datasets/review').then(response => {
    const {data} = response;

    const datasets = _.map(data, datasetResp => responseToDataset(datasetResp));

    return datasets;
  });
};

export enum DatasetReviewState {
  CLEAN = 'clean',
  DIRTY = 'dirty',
  REPROCESS = 'reprocess',
}

export const updateDatasetReviewStatusAdmin = (
  http: AxiosInstance,
  datasetID: number,
  stateToSet: DatasetReviewState
) => {
  const request = {
    dataset_state: stateToSet,
  };
  return http
    .put(`/v1/admin/datasets/${datasetID}/review/status`, request)
    .then(response => {
      const {data} = response;
      const dataset = responseToDataset(data);
      return dataset;
    });
};

export interface DicomHeaderScan {
  Age?: string;
  BirthDate?: string;
  Gender?: string;
  InstitutionAddress?: string;
  InstitutionName?: string;
  Manufacturer?: string;
  ManufacturerModelName?: string;
  Modality?: string;
  NameOfPhysiciansReadingStudy?: string;
  OperatorName?: string;
  PatientName?: string;
  ReferringPhysicianName?: string;
  SeriesDescription?: string;
  StudyDescription?: string;
  StudyID?: string;
  TransferSyntaxUID?: string;
}

const responseToDicomHeaderScan = (
  headerScanResp: Record<string, string>
): DicomHeaderScan => {
  const dicomHeaderScanResults: DicomHeaderScan = {
    Age: headerScanResp?.Age,
    BirthDate: headerScanResp?.BirthDate,
    Gender: headerScanResp?.Gender,
    InstitutionAddress: headerScanResp?.InstitutionAddress,
    InstitutionName: headerScanResp?.InstitutionName,
    Manufacturer: headerScanResp?.Manufacturer,
    ManufacturerModelName: headerScanResp?.ManufacturerModelName,
    Modality: headerScanResp?.Modality,
    NameOfPhysiciansReadingStudy: headerScanResp?.NameOfPhysiciansReadingStudy,
    OperatorName: headerScanResp?.OperatorName,
    PatientName: headerScanResp?.PatientName,
    ReferringPhysicianName: headerScanResp?.ReferringPhysicianName,
    SeriesDescription: headerScanResp?.SeriesDescription,
    StudyDescription: headerScanResp?.StudyDescription,
    StudyID: headerScanResp?.StudyID,
    TransferSyntaxUID: headerScanResp?.TransferSyntaxUID,
  };

  return dicomHeaderScanResults;
};

export const fetchDatasetDICOMHeaderScanAdmin = (
  http: AxiosInstance,
  datasetID: number
) => {
  return http
    .get(`v1/admin/datasets/${datasetID}/review/header_scan`)
    .then(response => {
      const {data} = response;
      const headerScanResults = responseToDicomHeaderScan(data);
      return headerScanResults;
    });
};
