import React from 'react';
import {isNil, filter, isEmpty, maxBy} from 'lodash';
import {useQuery} from 'react-query';
import {Helmet} from 'react-helmet';
import {HiOutlineLightningBolt} from 'react-icons/hi';

import {Dataset, fetchDatasetLocations} from '../../../models/dataset';
import {DatasetDownloadTable} from './dataset-download-table';
import {Badge} from '../../../core/components/badge';
import {Breadcrumbs} from '../../../core/components/breadcrumbs';
import {InfoCard} from '../../../core/components/info-card';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';
import {useAuth} from 'src/hooks/auth';

export const DatasetDownloadPage = ({dataset}: {dataset: Dataset}) => {
  const api = useAxios();

  const {authState} = useAuth();
  const tenant = authState.profile?.tenant ?? 'segmed';

  const {data: datasetLocations} = useQuery(
    ['datasetLocations', dataset.id],
    () => {
      return fetchDatasetLocations(api, dataset.id);
    },
    {
      enabled: !isNil(dataset.id),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const fullDatasetLocations = filter(datasetLocations, {isSamples: false});
  const sampleDatasetLocations = filter(datasetLocations, {isSamples: true});

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dataset Download</title>
      </Helmet>

      <ApplicationShell>
        <div className="mb-4">
          <Breadcrumbs
            links={[
              {name: 'Your Datasets', href: '/datasets'},
              {
                name: dataset.name,
                href: `/datasets/${dataset.id}`,
              },
              {
                name: 'Downloads',
                current: true,
              },
            ]}
          />
        </div>
        <div className="text-3xl font-medium mb-6">{dataset.name}</div>

        {!isEmpty(datasetLocations) && (
          <div className="text-base leading-7 font-normal text-gray-500 w-1/2">
            Datasets over 20 GB are broken up into smaller ZIP files to optimize
            downloading speed.
          </div>
        )}

        {!isEmpty(sampleDatasetLocations) && (
          <>
            <div className="my-4">
              <span className="text-xl font-semibold mr-2">Samples</span>
              <Badge type="success">
                Delivered{' '}
                {maxBy(sampleDatasetLocations, loc =>
                  loc.createdAt.toMillis()
                )!.createdAt.toFormat('LLL dd, yyyy')}
              </Badge>
            </div>

            <div className="space-y-4 mb-6">
              <DatasetDownloadTable
                datasetLocations={sampleDatasetLocations}
                datasetId={dataset.id}
              />
            </div>
          </>
        )}

        {tenant === 'segmed' && (
          <>
            {!isEmpty(fullDatasetLocations) && (
              <>
                <div className="my-4">
                  <span className="text-xl font-semibold mr-2">
                    Full Dataset
                  </span>
                  <Badge type="success">
                    Delivered{' '}
                    {maxBy(fullDatasetLocations, loc =>
                      loc.createdAt.toMillis()
                    )!.createdAt.toFormat('LLL dd, yyyy')}
                  </Badge>
                </div>

                <div className="space-y-4 mb-6">
                  <DatasetDownloadTable
                    datasetLocations={fullDatasetLocations}
                    datasetId={dataset.id}
                  />
                </div>
              </>
            )}

            {!isEmpty(datasetLocations) && (
              <InfoCard
                title="Alternate Transfer Method"
                icon={
                  <HiOutlineLightningBolt
                    className="text-white text-2xl bg-blue-500 p-3 mb-2 h-12 w-12 rounded-lg leading-6"
                    stroke="white"
                    strokeWidth="2px"
                  />
                }
              >
                <div className="text-base text-gray-500">
                  If downloading from this page does not work, contact the
                  Segmed team at{' '}
                  <a href="mailto:support@segmed.ai" className="link">
                    support@segmed.ai
                  </a>{' '}
                  to arrange a transfer through command line.
                </div>
              </InfoCard>
            )}
          </>
        )}

        {isEmpty(sampleDatasetLocations) && isEmpty(fullDatasetLocations) && (
          <div className="italic text-gray-400">No downloads available</div>
        )}

        {tenant === 'bayer' && !isEmpty(fullDatasetLocations) && (
          <InfoCard title="Where can I download my dataset?">
            <div className="text-base text-gray-500">
              {"Dataset will be available through Bayer's platform"}
            </div>
          </InfoCard>
        )}
      </ApplicationShell>
    </>
  );
};
