import {BlackoutScopeEnum} from './blackoutscopeenum';

export enum BlackoutRectangleScopeEnum {
  BlackoutScopeUnspecified = 'unspecified',
}

export type BlackoutRectangleScopeType =
  | BlackoutScopeEnum
  | BlackoutRectangleScopeEnum;

export const colorForBlackoutRectangleScope: {
  [key: string]: string;
} = {
  [BlackoutScopeEnum.BlackoutScopeSeries]: 'lime',
  [BlackoutScopeEnum.BlackoutScopeImage]: 'magenta',
  [BlackoutScopeEnum.BlackoutScopeMatchingDimension]: 'aqua',
  [BlackoutRectangleScopeEnum.BlackoutScopeUnspecified]: 'yellow',
};

export const readableBlackoutRectangleScope: {
  [key: string]: string;
} = {
  [BlackoutScopeEnum.BlackoutScopeSeries]: 'To All Images In Series',
  [BlackoutScopeEnum.BlackoutScopeImage]: 'To Current Image Only',
  [BlackoutScopeEnum.BlackoutScopeMatchingDimension]:
    'To Images In Series With Same Dimensions',
  [BlackoutRectangleScopeEnum.BlackoutScopeUnspecified]: 'Unspecified',
};
