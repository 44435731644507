import React, {useState, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {HiOutlineTrash, HiOutlineStar} from 'react-icons/hi';
import cx from 'classnames';
import {toast} from 'react-toastify';

import {ToastMessage} from '../../core/components/toast';
import {Modal} from '../../core/layout/modal';
import {
  CreateTagForm,
  EditTagColorForm,
  RenameTagForm,
  Tag,
} from '../../components/tags';
import {
  Tag as TagInterface,
  deleteTag,
  favoriteTag,
  getAvailableTags,
  emptyTag,
  defaultTagColors,
  datasetToDatasetTag,
} from '../../models/tags';
import {Loading} from '../../core/components/loading';
import {fetchAllDatasets, favoriteDataset} from '../../models/dataset';
import {ApplicationShell} from '../../core/layout/application-shell';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

export const TagsPage = () => {
  const api = useAxios();

  type modalTypes = 'create' | 'editColors' | 'rename' | 'delete';
  const [modalType, modalTypeChange] = useState<modalTypes>();
  const [modalTag, modalTagChange] = useState<TagInterface>();
  const [modalOpen, modalOpenChange] = useState(false);

  const openModal = (type: modalTypes, tag?: TagInterface) => {
    modalTypeChange(type);
    modalTagChange(tag ?? {...emptyTag});
    modalOpenChange(true);
  };

  const closeModal = () => {
    modalOpenChange(false);
    modalTypeChange(undefined);
    modalTagChange(undefined);
  };

  const {data: tags} = useQuery(['tags'], () => getAvailableTags(api), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const {data: datasets} = useQuery(['dataset'], () => fetchAllDatasets(api), {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const queryClient = useQueryClient();

  const refetchTags = () => {
    queryClient.invalidateQueries(['tags']);
  };

  const deleteTagMut = useMutation(
    ({tagId}: {tagId: number}) => deleteTag(api, tagId),
    {
      onSuccess: () => {
        toast(<ToastMessage title="Tag deleted" icon="success" />);
        closeModal();
        refetchTags();
        queryClient.invalidateQueries(['report']);
      },
      onError: (err: AxiosError) => {
        const message = err?.response?.data?.message ?? 'Error deleting tag';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const renderDeleteModal = (tag: TagInterface) => {
    return (
      <div className="w-96">
        <div className="text-lg mb-3">Delete Tag</div>
        <div className="text-sm text-gray-500 mb-3">
          This tag will be permanently removed.
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteTagMut.mutate({tagId: tag.tid});
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const toggleFavoriteMut = useMutation(
    (tag: TagInterface) => favoriteTag(api, tag.tid, {favorite: !tag.favorite}),
    {
      onSuccess: (_response, tag) => {
        const {title, body} = tag.favorite
          ? {
              title: 'Tag deprioritized',
              body: 'This tag will no longer appear in the data table.',
            }
          : {
              title: 'Tag prioritized',
              body: 'This tag will appear in the data table.',
            };
        toast(
          <ToastMessage
            title={title}
            icon="success"
            children={<div>{body}</div>}
          />
        );
        closeModal();
        refetchTags();
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error toggling prioritization';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const toggleDatasetFavoriteMut = useMutation(
    (tag: TagInterface) =>
      favoriteDataset(api, tag.tid, {favorite: !tag.favorite}),
    {
      onSuccess: (_response, tag) => {
        const {title, body} = tag.favorite
          ? {
              title: 'Tag deprioritized',
              body: 'This tag will no longer appear in the data table.',
            }
          : {
              title: 'Tag prioritized',
              body: 'This tag will appear in the data table.',
            };
        toast(
          <ToastMessage
            title={title}
            icon="success"
            children={<div>{body}</div>}
          />
        );
        closeModal();
        queryClient.invalidateQueries(['dataset'], {exact: true});
      },
      onError: (err: AxiosError) => {
        const message =
          err?.response?.data?.message ?? 'Error toggling prioritization';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const renderColorSample = (tag: TagInterface) => {
    return (
      <div
        className={`hover:ring-2 ring-gray-300 ring-offset-2 rounded-full w-5 h-5 leading-5 text-center cursor-pointer ${
          tag.backgroundColor || defaultTagColors[1]
        } ${tag.fontColor || defaultTagColors[0]}`}
        onClick={() => openModal('editColors', tag)}
      >
        a
      </div>
    );
  };

  const renderCustomTagRows = (tags: TagInterface[]) => {
    return tags.map((tag, index) => (
      <div
        className={cx(
          'grid grid-cols-7 items-center justify-items-center p-2 text-xs leading-4 font-normal',
          {
            'border-b': index !== tags.length - 1,
          }
        )}
        key={index}
      >
        <div className="font-medium col-span-3 justify-self-start">
          <Tag tag={tag} />
        </div>
        <HiOutlineStar
          fill={tag.favorite ? '#FCD34D' : 'white'}
          className={cx('w-6 h-6 cursor-pointer', {
            'text-yellow-300 hover:text-yellow-500 fill-current': tag.favorite,
            'text-gray-400 hover:text-gray-500 stroke-current': !tag.favorite,
          })}
          onClick={() => toggleFavoriteMut.mutate(tag)}
        />
        {renderColorSample(tag)}
        <div
          className="link cursor-pointer"
          onClick={() => openModal('rename', tag)}
        >
          Rename
        </div>
        <HiOutlineTrash
          className="text-gray-400 w-6 h-6 cursor-pointer hover:text-gray-500"
          onClick={() => openModal('delete', tag)}
        />
      </div>
    ));
  };

  const renderDatasetTagRows = (tags: TagInterface[]) => {
    return tags.map((tag, index) => (
      <div
        className={cx(
          'grid grid-cols-7 items-center justify-items-center p-2 text-xs leading-4 font-normal',
          {
            'border-b': index !== tags.length - 1,
          }
        )}
        key={index}
      >
        <div className="font-medium col-span-3 justify-self-start">
          <Tag tag={tag} />
        </div>
        <HiOutlineStar
          fill={tag.favorite ? '#FCD34D' : 'white'}
          className={cx('w-6 h-6 cursor-pointer col-start-7 col-end-8', {
            'text-yellow-300 hover:text-yellow-500 fill-current': tag.favorite,
            'text-gray-400 hover:text-gray-500 stroke-current': !tag.favorite,
          })}
          onClick={() => toggleDatasetFavoriteMut.mutate(tag)}
        />
      </div>
    ));
  };

  const renderFavoriteOnlyTagRows = (tags: TagInterface[]) => {
    return tags.map((tag, index) => (
      <div
        className={cx(
          'grid grid-cols-7 items-center justify-items-center p-2 text-xs leading-4 font-normal',
          {
            'border-b': index !== tags.length - 1,
          }
        )}
        key={index}
      >
        <div className="font-medium col-span-3 justify-self-start">
          <Tag tag={tag} />
        </div>
        <HiOutlineStar
          fill={tag.favorite ? '#FCD34D' : 'white'}
          className={cx('w-6 h-6 cursor-pointer', {
            'text-yellow-300 hover:text-yellow-500 fill-current': tag.favorite,
            'text-gray-400 hover:text-gray-500 stroke-current': !tag.favorite,
          })}
          onClick={() => toggleFavoriteMut.mutate(tag)}
        />
      </div>
    ));
  };

  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const datasetTags = useMemo(() => {
    if (datasets) {
      return datasets
        .map(dataset => datasetToDatasetTag(dataset))
        .sort((firstDS, secondDS) => (firstDS.tid > secondDS.tid ? -1 : 1));
    }
    return [];
  }, [datasets]);

  const globalTags = useMemo(() => {
    if (tags) {
      // Filter out phi tag id 13
      return tags.filter(tag => tag.scope === 'global' && tag.tid !== 13);
    }
    return [];
  }, [tags]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Tags</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        {!tags ? (
          <Loading />
        ) : (
          <div className="grid grid-cols-1 w-full gap-3 auto-rows-min">
            <h1 className="text-2xl leading-7 font-bold text-gray-900">Tags</h1>
            <h3 className="text-base leading-7 font-normal text-gray-500 mb-2">
              Tags are applied to studies to help with organization. Star a tag
              to prioritize it. Tags not prioritized will not be shown in the
              data table. Any change made to a tag will apply wherever that tag
              is used throughout your account.
            </h3>
            <div className="flex flex-row justify-between items-center">
              <h2 className="text-xl leading-7 font-semibold text-gray-900">
                User Tags
              </h2>
              {/* @todo: use tag component */}
              <button
                className="btn btn-primary py-1 px-2"
                onClick={() => openModal('create')}
              >
                Create new tag
              </button>
            </div>
            <h2 className="text-base leading-7 font-normal text-gray-500">
              Custom tags can be created, edited, and deleted by you. User tags
              are prioritized by default
            </h2>
            <div className="grid grid-cols-1 grid-flow-row mb-2 border-1 border-gray-200 shadow rounded bg-white">
              {userTags.length ? (
                renderCustomTagRows(userTags)
              ) : (
                <div className="justify-self-center text-base leading-7 font-normal text-gray-500">
                  No available user tags
                </div>
              )}
            </div>
            <h2 className="text-lg leading-7 font-semibold text-gray-900">
              Dataset Tags
            </h2>
            <h2 className="text-base leading-7 font-normal text-gray-500">
              A dataset tag is applied when a study is added to a dataset. It
              will automatically update if the study is moved to another dataset
              or removed from the dataset. Dataset tags are prioritized by
              default.
            </h2>
            <div className="grid grid-cols-1 grid-flow-row mb-2 border-1 border-gray-200 shadow rounded bg-white">
              {datasetTags.length ? (
                renderDatasetTagRows(datasetTags)
              ) : (
                <div className="justify-self-center text-base leading-7 font-normal text-gray-500">
                  No available dataset tags
                </div>
              )}
            </div>
            <h2 className="text-lg leading-7 font-semibold text-gray-900">
              Coming Soon: Global Tags
            </h2>
            <h2 className="text-base leading-7 font-normal text-gray-500">
              Segmed is currently working on ML-based tags that will
              automatically label studies.
            </h2>
            {false && (
              // Global tags section for when global tags are added in
              <>
                <h2 className="text-lg leading-7 font-semibold text-gray-900">
                  Global Tags
                </h2>
                <h2 className="text-base leading-7 font-normal text-gray-500">
                  Global tags are assigned to studies by Segmed and cannot be
                  removed. They are indicated by a dot preceeding the text. If
                  you believe a global tag has been applied in error, please
                  email{' '}
                  <a href="mailto:support@segmed.ai" className="link">
                    support@segmed.ai
                  </a>
                  . They are not prioritized by default.
                </h2>
                <div className="grid grid-cols-1 grid-flow-row mb-2 border-1 border-gray-200 shadow rounded">
                  {globalTags.length ? (
                    renderFavoriteOnlyTagRows(globalTags)
                  ) : (
                    <div className="justify-self-center text-base leading-7 font-normal text-gray-500">
                      No available global tags
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </ApplicationShell>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        className="max-w-lg"
      >
        {modalType === 'delete' && renderDeleteModal(modalTag!)}
        {modalType === 'rename' && (
          <RenameTagForm
            edited={() => {
              closeModal();
              refetchTags();
              queryClient.invalidateQueries(['report']);
            }}
            cancelled={closeModal}
            tag={modalTag!}
          />
        )}
        {modalType === 'editColors' && (
          <EditTagColorForm
            edited={() => {
              closeModal();
              refetchTags();
              queryClient.invalidateQueries(['report']);
            }}
            cancelled={closeModal}
            tag={modalTag!}
          />
        )}
        {modalType === 'create' && (
          <CreateTagForm
            created={() => {
              closeModal();
              refetchTags();
            }}
            cancelled={closeModal}
          />
        )}
      </Modal>
    </>
  );
};
