import _ from 'lodash';
import {UseFiltersColumnProps} from 'react-table';

export const MinMaxFilter = ({
  column: {filterValue = [], setFilter},
}: {
  column: UseFiltersColumnProps<object>;
}) => {
  if (!filterValue) {
    filterValue = {};
  }

  const getValue = (value: string | number) => {
    return !_.isNil(value) && value !== '' && _.isNumber(_.toNumber(value))
      ? _.toNumber(value)
      : undefined;
  };

  const returnValue = ({min, max}: {min?: number; max?: number}) => {
    const res = [min, max];
    if (!_.some(res, v => v !== undefined)) {
      return undefined;
    }
    return res;
  };

  return (
    <div className="grid grid-cols-2">
      <div className="relative pr-1">
        <input
          type="number"
          className="text-input w-full text-gray-700"
          value={_.isNil(filterValue[0]) ? '' : filterValue[0]}
          placeholder="Min"
          onChange={e =>
            setFilter((old = []) => {
              const value = getValue(e.target.value);
              return returnValue({min: value, max: old[1]});
            })
          }
        />
      </div>
      <div className="relative pl-1">
        <input
          type="number"
          className="text-input w-full text-gray-700"
          value={_.isNil(filterValue[1]) ? '' : filterValue[1]}
          placeholder="Max"
          onChange={e =>
            setFilter((old = []) => {
              const value = getValue(e.target.value);
              return returnValue({min: old[0], max: value});
            })
          }
        />
      </div>
    </div>
  );
};
