import {HiOutlineGlobeAlt} from 'react-icons/hi';
import {Wrapper} from '../../layout/wrapper';

export const Loading = ({
  fullscreen,
  text,
  className,
}: {
  fullscreen?: boolean;
  text?: string;
  className?: string;
}) => {
  {
    /* @todo: Style fullscreen wrapper */
  }

  const loader = () => {
    return (
      <div className="flex items-center justify-center text-gray-500 italic cursor-default">
        <span className={className}>
          <HiOutlineGlobeAlt className="animate-spin mr-1 h-4 w-4 inline-block" />
          {text ?? 'Loading...'}
        </span>
      </div>
    );
  };

  if (fullscreen) {
    return (
      <Wrapper>
        <div className="bg-white flex justify-center items-center">
          {loader()}
        </div>
      </Wrapper>
    );
  } else {
    return loader();
  }
};
