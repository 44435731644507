import {Helmet} from 'react-helmet';
import {ApplicationShell} from '../../core/layout/application-shell';

export const DistributionsPage = () => {
  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dicom Viewer</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div>Distributions Incoming!!!</div>
      </ApplicationShell>
    </>
  );
};
