import React from 'react';
import {Tour} from '../base/tour';

export const SearchResultsTour = ({
  tourActive,
  tourActiveChange,
}: {
  tourActive: boolean;
  tourActiveChange: (tourActive: boolean) => void;
}) => {
  const tourSteps = [
    {
      target: '[data-tour=results-study-title-0]',
      content: (
        <div className="m-auto inline-block text-left">
          View individual reports by clicking on the titles
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '[data-tour=results-select-all]',
      content: (
        <div className="m-auto inline-block text-left">
          Select reports to add to a dataset with the checkboxes
        </div>
      ),
    },
    {
      target: '[data-tour=results-view-datasets]',
      content: (
        <div className="m-auto inline-block text-left">
          Created Datasets can be viewed by clicking here or clicking the
          Datasets tab at the top of the page
        </div>
      ),
    },
  ];

  return (
    <Tour
      tourActive={tourActive}
      tourActiveChange={tourActiveChange}
      steps={tourSteps}
    />
  );
};
