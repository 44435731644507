import {useState} from 'react';
import {trackEvent} from '../../utils/tracking';

// notifications
import {toast} from 'react-toastify';
import {ToastMessage} from '../../core/components/toast';

export const CSVUpload = ({
  onCSVUpload,
}: {
  onCSVUpload: (studyIDs: string[]) => void;
}) => {
  const [numIDsExtracted, numIDsExtractedChange] = useState(0);
  const parseCsvDataIntoStudyIDs = (csvData: string) =>
    (([headers, ...data]) =>
      data.map(row =>
        /* eslint-disable security/detect-object-injection */
        headers.reduce(
          (record, field, index) => ({...record, [field]: row[index]}),
          {}
        )
      ))(
      /* eslint-disable @typescript-eslint/no-explicit-any */
      csvData
        .trim()
        .split('\n')
        .map(line => line.trim().split(','))
    )
      .map((row: any) => row['Study ID'])
      .filter((id: string) => (id ? true : false));

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent('CLICK_CREATE_DATASET_FROM_CSV');
    if (event.target.files) {
      const input = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const studyIDs = parseCsvDataIntoStudyIDs(reader.result);
          if (!studyIDs.length) {
            toast(
              <ToastMessage
                title={
                  'Unable to extract study IDs from CSV. Did you name a column "Study ID"?'
                }
                icon="error"
              />
            );
          } else {
            numIDsExtractedChange(studyIDs.length);
            onCSVUpload(parseCsvDataIntoStudyIDs(reader.result));
          }
        }
      };
      reader.readAsText(input);
    }
  };

  return (
    <div id="upload-box" className="mb-6">
      <input
        className="block w-full text-sm text-gray-500 border border-gray-300 rounded-md cursor-pointer mb-3"
        id="file_input"
        type="file"
        accept=".xlsx, .xls, .csv"
        onChange={handleUpload}
      />
      {numIDsExtracted === 0 && (
        <p className="text-xs text-gray-400">
          *Note: one column must be named &apos;Study ID&apos;
        </p>
      )}
      {numIDsExtracted > 0 && (
        <p className="text-sm text-gray-500 min-w-max">
          {numIDsExtracted} Study IDs extracted from CSV
        </p>
      )}
    </div>
  );
};
