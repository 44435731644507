import React from 'react';
import {HiX} from 'react-icons/hi';

import {Badge} from '../../core/components/badge';
import {
  defaultTagColors,
  Tag as TagInterface,
  getBaseColor,
} from '../../models/tags';

export const Tag = ({
  tag,
  action,
  className,
}: {
  tag: TagInterface;
  action?: () => void;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const backgroundColor = tag.backgroundColor || defaultTagColors[1];
  const fontColor = tag.fontColor || defaultTagColors[0];
  return (
    <Badge
      type="custom"
      className={className}
      customColor={{
        bgColor: backgroundColor.slice(3),
        fontColor: fontColor.slice(5),
      }}
      empty={false}
    >
      {tag.scope === 'global' && (
        <span className="mr-1">{String.fromCharCode(8226)}</span>
      )}
      <span className="inline-block text-ellipsis max-w-xs whitespace-nowrap overflow-hidden">
        {tag.name}
      </span>
      {action && (
        <HiX
          type="button"
          className={`ml-1 rounded-full h-4 w-4 p-0.5 -mr-2 cursor-pointer text-${getBaseColor(
            fontColor
          )}-400 hover:bg-${fontColor.slice(
            5
          )} hover:text-${backgroundColor.slice(3)} focus:outline-none`}
          onClick={action}
        ></HiX>
      )}
    </Badge>
  );
};
