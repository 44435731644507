/* eslint-disable security/detect-object-injection */
import React, {Component, ReactElement} from 'react';
import {
  FacetContainerContext,
  FacetContainerProps,
  FacetViewProps,
  MultiCheckboxFacet,
} from '@elastic/react-search-ui-views';
import {helpers} from '@elastic/search-ui';

const accentFold = (str: any): string =>
  typeof str === 'string'
    ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : '';
import {withSearch} from '@elastic/react-search-ui';

const {markSelectedFacetValuesFromFilters} = helpers;

type FacetContainerState = {
  searchTerm: string;
  more: number;
  options: any[];
};

interface CustomObject {
  value: string;
}
interface CustomFacetContainerProps extends FacetContainerProps {
  changeOptions: ((options: any[]) => any[]) | null;
}
export class FacetContainer extends Component<
  CustomFacetContainerProps,
  FacetContainerState
> {
  static defaultProps = {
    filterType: 'all',
    isFilterable: false,
    show: 5,
  };

  constructor(props: CustomFacetContainerProps) {
    super(props);
    this.state = {
      more: props.show ?? 0,
      searchTerm: '',
      options: [],
    };
  }

  handleClickMore = (totalOptions: number) => {
    this.setState(({more}) => {
      let visibleOptionsCount: number = more + 10;
      const showingAll: boolean = visibleOptionsCount >= totalOptions;
      if (showingAll) visibleOptionsCount = totalOptions;

      this.props.a11yNotify('moreFilters', {visibleOptionsCount, showingAll});

      return {more: visibleOptionsCount};
    });
  };

  handleFacetSearch = (searchTerm: string) => {
    this.setState({searchTerm});
  };

  render(): ReactElement | null {
    const {more, searchTerm} = this.state;
    const {
      addFilter,
      className,
      facets,
      field,
      filterType,
      filters,
      label,
      removeFilter,
      setFilter,
      view,
      isFilterable,
      changeOptions,
      ...rest
    } = this.props;
    const facetsForField = facets[field];

    if (!facetsForField) return null;

    const facet = facetsForField[0];

    let facetValues = markSelectedFacetValuesFromFilters(
      facet,
      filters ?? [],
      field,
      filterType
    ).data;

    const selectedValues = facetValues
      .filter((fv: any) => fv.selected)
      .map((fv: any) => fv.value);

    if (!facetValues.length && !selectedValues.length) return null;

    if (searchTerm.trim()) {
      facetValues = facetValues.filter((option: any) => {
        let valueToSearch: string;
        switch (typeof option.value) {
          case 'string':
            valueToSearch = accentFold(option.value).toLowerCase();
            break;
          case 'number':
            valueToSearch = option.value.toString();
            break;
          case 'object':
            valueToSearch =
              typeof option?.value?.name === 'string'
                ? accentFold(option.value.name).toLowerCase()
                : '';
            break;

          default:
            valueToSearch = '';
            break;
        }
        return valueToSearch.includes(accentFold(searchTerm).toLowerCase());
      });
    }

    const View: React.ComponentType<FacetViewProps> =
      view || MultiCheckboxFacet;

    const findFullOption = (list: CustomObject[], searchString: string) =>
      list
        .filter(obj => obj.value.slice(15) === searchString)
        .map(obj => obj.value)[0];

    const viewProps: FacetViewProps = {
      className,
      label: label,
      onMoreClick: this.handleClickMore.bind(this, facetValues.length),
      onRemove: value => {
        const fullValue = this.state.options.length
          ? findFullOption(this.state.options, value.toString())
          : findFullOption(facetValues, value.toString());
        removeFilter(field, fullValue, filterType);
      },
      onChange: value => {
        const fullValue = this.state.options.length
          ? findFullOption(this.state.options, value.toString())
          : findFullOption(facetValues, value.toString());
        setFilter(field, fullValue, filterType);
      },
      onSelect: value => {
        const fullValue = this.state.options.length
          ? findFullOption(this.state.options, value.toString())
          : findFullOption(facetValues, value.toString());

        if (
          facetValues.filter((fv: any) => fv.value === fullValue && fv.selected)
            .length !== 0
        ) {
          removeFilter(field, fullValue, filterType);
          this.setState({options: []});
        } else {
          addFilter(field, fullValue, filterType);
        }
      },
      options: changeOptions
        ? changeOptions(
            this.state.options.length
              ? this.state.options.slice(0, more)
              : facetValues.slice(0, more)
          )
        : this.state.options.length
        ? this.state.options.slice(0, more)
        : facetValues.slice(0, more),
      showMore: facetValues.length > more,
      values: selectedValues,
      showSearch: isFilterable ?? false,
      onSearch: async value => {
        this.handleFacetSearch(value);
      },
      searchPlaceholder: `Filter ${label}`,
      ...rest,
    };

    return <View {...viewProps} />;
  }
}

export default withSearch<CustomFacetContainerProps, FacetContainerContext>(
  ({filters, facets, addFilter, removeFilter, setFilter, a11yNotify}) => ({
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify,
  })
)(FacetContainer);
