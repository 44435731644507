// eslint-disable-next-line
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {isInternal} from '../models/auth';

const LOGROCKET_APP_NAME = '1ydmho/insight-prod';

export const initLogrocket = ({userId}: {userId?: string}) => {
  if (userId !== undefined && userId !== null && isInternal(userId) === false) {
    LogRocket.init(LOGROCKET_APP_NAME);
    setupLogRocketReact(LogRocket);
    LogRocket.identify(userId, {});
  }
};
