import {ReactNode} from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import {HiOutlineX} from 'react-icons/hi';

export const Modal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  className,
  hideExitButton,
  side,
  padding = true,
  margin = true,
  shouldCloseOnOverlayClick = true,
}: {
  children: ReactNode;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  className?: string;
  hideExitButton?: boolean;
  side?: 'left' | 'right' | 'top' | 'bottom';
  padding?: boolean;
  margin?: boolean;
  shouldCloseOnOverlayClick?: boolean;
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={() => onAfterOpen && onAfterOpen()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={() => onRequestClose && onRequestClose()}
      className={cx(
        'bg-white shadow-xl relative focus:outline-none max-h-screen overflow-auto',
        {
          'rounded-lg': !side,
          'p-5': padding,
          'm-4': margin,
        },
        className
      )}
      overlayClassName={cx('bg-gray-500/75 inset-0 fixed w-full flex z-20', {
        '': side,
        'items-center justify-center': !side,
        'items-stretch justify-start': side === 'left',
        'items-stretch justify-end': side === 'right',
        'items-start justify-stretch': side === 'top',
        'items-end justify-stretch': side === 'bottom',
      })}
      ariaHideApp={false}
    >
      {!hideExitButton && (
        <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className={cx('bg-white text-gray-400 hover:text-gray-500', {
              'rounded-md': !side,
            })}
            onClick={() => onRequestClose && onRequestClose()}
          >
            <span className="sr-only">Close</span>

            <HiOutlineX className="h-6 w-6" />
          </button>
        </div>
      )}

      {children}
    </ReactModal>
  );
};
