import {AxiosInstance} from 'axios';
import _ from 'lodash';

export interface SnomedFinding {
  findingsId: number;
  studyId: string;
  candidateScore: number;
  candidateCui: string;
  candidateMatched: string;
  matchedWord: string;
  startPos: string;
  length: string;
  reportSection: string;
  negated: string;
}

interface SnomedFindingResponse {
  findings_id: number;
  study_id: string;
  candidate_score: number;
  candidate_cui: string;
  candidate_matched: string;
  matched_word: string;
  start_pos: string;
  length: string;
  report_section: string;
  negated: string;
}

export const responseToSnomedFinding = (
  snomedFindingResp: SnomedFindingResponse
) => {
  const snomedFinding: SnomedFinding = {
    findingsId: snomedFindingResp.findings_id,
    studyId: snomedFindingResp.study_id,
    candidateScore: snomedFindingResp.candidate_score,
    candidateCui: snomedFindingResp.candidate_cui,
    candidateMatched: snomedFindingResp.candidate_matched,
    matchedWord: snomedFindingResp.matched_word,
    startPos: snomedFindingResp.start_pos,
    length: snomedFindingResp.length,
    reportSection: snomedFindingResp.report_section,
    negated: snomedFindingResp.negated,
  };

  return snomedFinding;
};

export const fetchSnomedFindings = (http: AxiosInstance, studyId: string) => {
  return http.get(`/v1/snomed/findings/${studyId}`).then(response => {
    const {data} = response;
    const findings = _.map(data, snomedFindingResp =>
      responseToSnomedFinding(snomedFindingResp)
    );
    return findings;
  });
};
