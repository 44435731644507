import React, {useState} from 'react';
import _ from 'lodash';
import cx from 'classnames';

import {Modal} from '../../core/layout/modal';

export const ModalityModal = ({
  isOpen,
  modalityDicomHeaders = ['CT', 'MR', 'Xray', 'Other'],
  onModalityDicomHeadersChange,
  onRequestClose,
}: {
  isOpen: boolean;
  modalityDicomHeaders: string[];
  onModalityDicomHeadersChange: (modalities: string[]) => void;
  onRequestClose: () => void;
}) => {
  const [unsavedModalityDicomHeaders, unsavedModalityDicomHeadersChange] =
    useState<string[]>(modalityDicomHeaders);

  const onApply = () => {
    onModalityDicomHeadersChange(unsavedModalityDicomHeaders);
    onRequestClose();
  };

  const changeModalitiesSelected = (
    modalitySelected: string,
    checked: boolean
  ) => {
    if (checked) {
      unsavedModalityDicomHeadersChange([
        ...unsavedModalityDicomHeaders,
        modalitySelected,
      ]);
    } else {
      unsavedModalityDicomHeadersChange(
        _.filter(unsavedModalityDicomHeaders, m => m !== modalitySelected)
      );
    }
  };

  const modalitiesToFilter: string[] = ['CT', 'MR', 'Xray', 'Other'];

  return (
    <Modal
      isOpen={isOpen}
      padding={false}
      hideExitButton
      onRequestClose={onRequestClose}
      className="w-96 max-w-lg"
    >
      <div className="flex flex-col">
        {modalitiesToFilter.map((modality, i) => (
          <div key={modality} className="flex-grow flex">
            <label
              className={cx(
                'relative flex flex-grow items-start py-2 px-4 hover:bg-gray-100 hover:text-gray-900 text-gray-700'
              )}
              key={'filterByModality-' + i}
            >
              <div>
                <input
                  id={'filterByModality-' + modality}
                  name={'filterByModality-' + modality}
                  type="checkbox"
                  className="checkbox-input"
                  checked={_.includes(unsavedModalityDicomHeaders, modality)}
                  onChange={e =>
                    changeModalitiesSelected(modality, e.target.checked)
                  }
                />
              </div>
              <div className="ml-3 mt-1 text-sm inline-block w-full">
                <div className="font-normal text-sm inline-block w-full">
                  {modality}
                </div>
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className="flex justify-between flex-shrink-0 bg-gray-50 py-2 px-4">
        <div></div>
        <div className="space-x-2">
          <button className="btn btn-white inline" onClick={onRequestClose}>
            Cancel
          </button>

          <button
            className="btn btn-primary inline"
            onClick={() => {
              onApply();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};
