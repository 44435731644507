import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import {HiOutlineArrowRight} from 'react-icons/hi';

import {ApplicationShell} from '../../../core/layout/application-shell';
import {
  SearchForm,
  emptyPatientSearchQuery,
} from '../../../components/search-form';
import {Card} from '../../../core/layout/card';
import {SearchQuery} from '../../../models/search';
import {encodeURLObject} from '../../../utils/router-helper';
import {useAuth} from '../../../hooks/auth';

export const PatientSearchPage = () => {
  const {authState} = useAuth();
  const navigate = useNavigate();
  const [searchQuery, searchQueryChange] = useState<SearchQuery>(
    _.cloneDeep(emptyPatientSearchQuery)
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Patient Search</title>
      </Helmet>

      <ApplicationShell
        bgcolor="bgcolor"
        contained={false}
        navbar={
          <div className="navbar">
            <div className="ml-auto text-sm text-gray-700">
              Having trouble finding data?
              <a
                className="link ml-1"
                href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                target="_blank"
                rel="noreferrer"
              >
                Schedule a help session
                <HiOutlineArrowRight className="inline-block ml-1" />
              </a>
            </div>
          </div>
        }
      >
        <Card>
          <SearchForm
            query={searchQuery}
            queryChange={searchQueryChange}
            onSearch={query => {
              navigate({
                pathname: '/patient_search/results',
                search: queryString.stringify({
                  q: encodeURLObject(query),
                }),
              });
              return false;
            }}
            patientFilter={authState.profile?.admin}
            isPatientSearch
          ></SearchForm>
        </Card>
      </ApplicationShell>
    </>
  );
};
