import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {Helmet} from 'react-helmet';
import {HiOutlineLightningBolt} from 'react-icons/hi';

import {FileDownloadTable} from './file-download-table';
import {InfoCard} from '../../../core/components/info-card';
import {
  getAllUploadedFiles,
  getCountAllUploadedFiles,
} from '../../../models/fileDownload';
import {Loading} from '../../../core/components/loading';
import {Pagination} from '../../../core/components/pagination';
import {Select, SelectOption} from '../../../core/components/select';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const SampleDownloadPage = () => {
  const api = useAxios();

  const [currentPage, currentPageChange] = useState(1);
  const [resultsPerPage, resultsPerPageChange] = useState(50);

  const {data: resultsCount} = useQuery(
    ['fileDownloadResultsCount'],
    () => {
      return getCountAllUploadedFiles(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: uploadedFiles} = useQuery(
    ['fileDownload', currentPage, resultsPerPage],
    () => {
      return getAllUploadedFiles(api, currentPage - 1, resultsPerPage);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const resultsPerPageOptions = [10, 25, 50, 100].map(num => {
    return {
      value: num.toString(),
      label: num.toString(),
    };
  });

  const renderResultsPerPageDropdown = () => {
    return (
      <Select
        text={resultsPerPage.toString()}
        value={resultsPerPage.toString()}
        options={resultsPerPageOptions}
        onSelect={(option: SelectOption<string>) => {
          resultsPerPageChange(parseInt(option.value));
          currentPageChange(1);
        }}
        contained
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Data Download</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="mb-4"></div>
        <div className="text-3xl font-medium">Data Partner Uploaded Files</div>

        <div className="text-base leading-7 font-normal text-gray-500 flex justify-between items-center">
          <div>
            All files that were uploaded by data partners are listed below.
          </div>
          <div className="flex flex-col w-auto whitespace-nowrap">
            <div>Results Per Page</div>
            {renderResultsPerPageDropdown()}
          </div>
        </div>
        {uploadedFiles ? (
          <div className="space-y-4 my-6">
            <FileDownloadTable uploadedFiles={uploadedFiles} />
            <Pagination
              max={resultsCount ? Math.ceil(resultsCount / resultsPerPage) : 1}
              current={currentPage}
              linkFunc={num => {
                currentPageChange(num);
              }}
            ></Pagination>
          </div>
        ) : (
          <div className="my-20">
            <Loading />
          </div>
        )}

        <InfoCard
          title="Alternate Transfer Method"
          icon={
            <HiOutlineLightningBolt
              className="text-white text-2xl bg-blue-500 p-3 mb-2 h-12 w-12 rounded-lg leading-6"
              stroke="white"
              strokeWidth="2px"
            />
          }
        >
          <div className="text-base text-gray-500">
            If downloading from this page does not work, contact the Segmed team
            to arrange a transfer through a different method.
          </div>
        </InfoCard>
      </ApplicationShell>
    </>
  );
};
