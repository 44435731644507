import {useState} from 'react';
import {useQuery} from 'react-query';

import {Card} from '../../core/layout/card';
import {Dataset, fetchDatasetPrice} from '../../models/dataset';
import {formatCurrency} from '../../utils/strings';
import {Modal} from '../../core/layout/modal';
import {HiOutlineCurrencyDollar} from 'react-icons/hi';
import {DatasetSubtotal} from './dataset-subtotal';
import {IncludeUndeterminedStudiesToggle} from './include-undetermined-studies-toggle';
import {Badge} from '../../core/components/badge';
import {useAxios} from 'src/utils/http';

export const DatasetPricingQuoteCard = ({
  dataset,
  showIncludeUndeterminedToggle = true,
  onIncludeUndeterminedChange,
}: {
  dataset: Dataset;
  showIncludeUndeterminedToggle?: boolean;
  onIncludeUndeterminedChange?(includeUndetermined: boolean): void;
}) => {
  const [subtotalModalOpen, subtotalModalOpenChange] = useState(false);
  const http = useAxios();

  const {data: datasetPrice} = useQuery(
    ['dataset', dataset.id, 'price'],
    () => fetchDatasetPrice(http, dataset.id),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const subtotalModal = () => {
    return (
      <Modal
        isOpen={subtotalModalOpen}
        onRequestClose={() => subtotalModalOpenChange(false)}
        hideExitButton
        className="w-xs text-xs"
      >
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100 mb-5">
          <HiOutlineCurrencyDollar
            className="h-6 w-6 text-emerald-600"
            aria-hidden="true"
          />
        </div>
        <div className="text-lg mb-3 text-center">
          {dataset.name} price breakdown
        </div>

        {datasetPrice && datasetPrice.numStudies === 0 && (
          <div className="text-gray-500 my-4">
            You have not added any studies to your order yet.
          </div>
        )}

        {datasetPrice && datasetPrice.numStudies > 0 && (
          <DatasetSubtotal cart={datasetPrice} />
        )}

        <button
          className="btn btn-primary w-full mt-4"
          onClick={() => subtotalModalOpenChange(false)}
        >
          Close
        </button>
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <div className="text-gray-500 text-sm font-medium">
          Order Pricing Quote
        </div>

        <div className="text-2xl font-semibold">
          {datasetPrice ? formatCurrency(datasetPrice.total) : '-'}
        </div>

        <div className="text-gray-700 text-sm">
          {showIncludeUndeterminedToggle && (
            <IncludeUndeterminedStudiesToggle
              dataset={dataset}
              onIncludeUndeterminedChange={onIncludeUndeterminedChange}
              description={
                <span className="text-sm text-gray-500">
                  Include studies with <Badge type="info">Undetermined</Badge>{' '}
                  order status
                </span>
              }
            />
          )}
          <button
            className="text-primary hover:text-primary-active"
            onClick={() => subtotalModalOpenChange(true)}
          >
            View Breakdown
          </button>
        </div>
      </Card>
      {subtotalModal()}
    </>
  );
};
