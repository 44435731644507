import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Column} from 'react-table';
import {Helmet} from 'react-helmet';

import {
  MinMaxFilter,
  TextFilter,
  ReactTable,
} from '../../../core/components/react-table';
import {DatasetWithUser} from '../../../models/dataset';
import {fetchAllDatasetsNeedReviewAdmin} from '../../../models/dataset-review';
import {fetchAllUsersAdmin} from '../../../models/user';
import {find, map} from 'lodash';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {useAxios} from 'src/utils/http';

export const AdminDatasetsReviewPage = () => {
  const api = useAxios();

  const {data: datasets, isLoading: datasetsIsLoading} = useQuery(
    ['adminDatasetsReview'],
    () => {
      return fetchAllDatasetsNeedReviewAdmin(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: users, isLoading: usersIsLoading} = useQuery(
    ['adminUsers'],
    () => {
      return fetchAllUsersAdmin(api);
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const columns = useMemo<Array<Column<DatasetWithUser>>>(
    () => [
      {
        Header: 'Study Info',
        columns: [
          {
            Header: 'Review',
            id: 'review',
            accessor: 'id',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}) => (
              <Link to={`/admin/datasets/review/${value}`} className="link">
                Review {value} &rarr;
              </Link>
            ),
          },
          {
            Header: 'ID',
            accessor: 'id',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Name',
            accessor: 'name',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}) => <span title={value}>{value}</span>,
          },
          {
            Header: 'Status',
            accessor: 'status',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Study Count',
            accessor: 'studyCount',
            Filter: MinMaxFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'User Info',
        columns: [
          {
            Header: 'User ID',
            accessor: 'user.userId',
            Filter: TextFilter,
            filter: 'exactText',
          },
          {
            Header: 'Email',
            accessor: 'user.email',
            Filter: TextFilter,
            filter: 'inclues',
            Cell: ({value}: {value: string}) => (
              <span title={value}>{value}</span>
            ),
          },
          {
            Header: 'First Name',
            accessor: 'user.firstname',
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'Last Name',
            accessor: 'user.lastname',
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      datasets && users
        ? map(datasets, dataset => {
            const datasetWithUser: DatasetWithUser = {
              ...dataset,
              user: find(users, {userId: dataset.userId})!,
            };

            return datasetWithUser;
          })
        : [],
    [datasets, users]
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Admin - Review Datasets</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <h1 className="text-2xl mb-5">Datasets To Review</h1>

        {datasetsIsLoading || usersIsLoading ? (
          'Loading datasets and users...'
        ) : (
          <ReactTable
            tableColumns={columns}
            tableData={data}
            showCountDesc={'Datasets'}
            defaultSortBy={[{id: 'id', desc: true}]}
          />
        )}
      </ApplicationShell>
    </>
  );
};
