import {ReactNode} from 'react';
import cx from 'classnames';

import {Sidebar} from '../../components/sidebar';

export const ApplicationShell = ({
  navbar,
  contained = true,
  noPadding = false,
  bgcolor = 'white',
  bodyClassName,
  children,
}: {
  navbar?: ReactNode;
  contained?: boolean;
  noPadding?: boolean;
  bgcolor?: 'white' | 'bgcolor';
  bodyClassName?: string;
  children: ReactNode;
}) => {
  return (
    <>
      <div>
        <Sidebar />
        <div
          className={cx(
            'md:pl-64 flex flex-col min-h-screen',
            {
              'bg-bgcolor': bgcolor === 'bgcolor',
              'bg-white': bgcolor === 'white',
            },
            bodyClassName
          )}
        >
          <main className="flex-1">
            <div className="sticky z-20 top-0 py-2 pr-2 h-16 -mt-16 md:mt-0 pl-18 md:pl-2 bg-white shadow">
              {navbar}
            </div>
            <div
              data-cy="application-body"
              className={cx({
                'container max-w-screen-lg': contained,
                'p-6': !noPadding,
              })}
            >
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
