import {isNil, find, isEmpty, trim} from 'lodash';
import {renderCompactKeywords} from './keyword';
import {
  SearchQuery,
  fetchBodyParts,
  fetchModalities,
  fetchSourceLocations,
} from '../../models/search';
import {useQuery} from 'react-query';
import {useAxios} from 'src/utils/http';

export const CompactSearchQuery = ({
  searchQuery,
  className,
}: {
  searchQuery: SearchQuery;
  className?: string;
}) => {
  const http = useAxios();

  const {data: modalities} = useQuery(
    ['modalities'],
    () => fetchModalities(http),
    {
      staleTime: Infinity,
    }
  );
  const {data: bodyParts} = useQuery(
    ['bodyParts'],
    () => fetchBodyParts(http),
    {
      staleTime: Infinity,
    }
  );
  const {data: sourceLocations} = useQuery(
    ['sourceLocations'],
    () => fetchSourceLocations(http),
    {
      staleTime: Infinity,
    }
  );

  // Don't render until all categories have loaded
  if (isNil(modalities) || isNil(bodyParts) || isNil(sourceLocations)) {
    return <></>;
  }

  let modalityString = `${searchQuery.modality
    .map(s => find(modalities, {modality: s})?.label)
    .join(', ')}`;
  if (modalityString === '') {
    modalityString = 'Any Modality';
  }

  let bodyAreaString = `${searchQuery.bodyArea
    .map(b => find(bodyParts.categories, {bodyPart: b})?.label)
    .join(', ')}`;
  if (bodyAreaString === '') {
    bodyAreaString = 'Any Body Area';
  }

  let sourceLocationString = `${searchQuery.sourceLocation
    .map(s => find(sourceLocations, {datapartner: s})?.label)
    .join(', ')}`;
  if (sourceLocationString === '') {
    sourceLocationString = 'Any Location';
  }

  const paramStrings = [
    modalityString,
    bodyAreaString,
    sourceLocationString,
  ].filter(s => !isEmpty(s));

  return (
    <span className={className}>
      {paramStrings.join('; ')}

      {!isEmpty(
        searchQuery.keywords.filter(keyword => !isEmpty(trim(keyword.text)))
      ) && renderCompactKeywords(searchQuery.keywords)}
    </span>
  );
};
