import {SearchRequest} from '@segmed/search-ui-elasticsearch-connector';
import _ from 'lodash';

import {AxiosInstance} from 'axios';
import {removeSizeAndFrom} from './minerva';

// saved searches
export const saveSearch = (
  http: AxiosInstance,
  user: number,
  currentURL: string,
  studyCount: number,
  lastRequestBody: any
) => {
  const requestBody = {
    url: currentURL,
    userid: user,
    number_of_results_saved: studyCount,
    elastic_search_body: removeSizeAndFrom(JSON.stringify(lastRequestBody)),
  };
  return http.post('v1/saved/searches', requestBody);
};

export const unsaveSearch = (http: AxiosInstance, ssId: number) => {
  const requestBody = {
    ss_id: ssId,
  };
  return http.delete('v1/saved/searches', {data: requestBody});
};

export const checkSearchSaved = (
  http: AxiosInstance,
  user: number,
  currentURL: string
) => {
  const requestBody = {
    user: user,
    url: currentURL,
  };
  return http
    .post('v1/saved/searches/check', requestBody)
    .then(response => {
      return response.data as {
        ss_id: number;
      };
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        return undefined; // Search is not saved
      } else {
        throw error;
      }
    });
};

export interface SavedSearches {
  ssId: number;
  userId: number;
  url: string;
  numberOfResultsSaved: number;
  currentNumberOfResults: number;
  elasticSearchBody: SearchRequest;
  createdAt: string;
}

interface SavedSearchesResponse {
  ss_id: number;
  userid: number;
  url: string;
  number_of_results_saved: number;
  current_number_of_results: number;
  elastic_search_body: string;
  created_at: string;
}

export const responseToSavedSearches = (
  savedSearchResp: SavedSearchesResponse
) => {
  const savedSearch: SavedSearches = {
    ssId: savedSearchResp.ss_id,
    userId: savedSearchResp.userid,
    url: savedSearchResp.url,
    numberOfResultsSaved: savedSearchResp.number_of_results_saved,
    currentNumberOfResults: savedSearchResp.current_number_of_results,
    elasticSearchBody: JSON.parse(savedSearchResp.elastic_search_body),
    createdAt: savedSearchResp.created_at,
  };

  return savedSearch;
};

export const fetchAllSavedSearches = (http: AxiosInstance) => {
  return http.get('/v1/saved/searches/getall').then(response => {
    const {data} = response;

    const savedSearches = _.map(data, savedSearchResp =>
      responseToSavedSearches(savedSearchResp)
    );

    return savedSearches;
  });
};
