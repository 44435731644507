import {useState, useEffect, useRef, ReactText, useMemo} from 'react';
import {Link, useNavigate, Navigate} from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import {useQuery, useQueryClient} from 'react-query';
import {HiInformationCircle} from 'react-icons/hi';
import {SearchIcon} from '@heroicons/react/solid';

import {Loading} from '../../../core/components/loading';
import {CompactSearchQuery, SearchForm} from '../../../components/search-form';
import {
  useReportSelection,
  ReportSelectionEventType,
  isAnyReportSelected,
} from '../../../hooks/report-selection-provider';
import {Pagination} from '../../../core/components/pagination';
import {
  useURLQuery,
  decodeURLObject,
  encodeURLObject,
} from '../../../utils/router-helper';
import {Dropdown} from '../../../core/components/dropdown';
import {trackEvent} from '../../../utils/tracking';
import {quickToast, ToastMessage} from '../../../core/components/toast';
import {SearchQuery, fetchSearchDemogResults} from '../../../models/search';
import {SearchResultsTour} from '../../../core/components/tour/search-results-tour';
import {getAvailableTags} from '../../../models/tags';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {SearchResultsTable} from '../../../components/search-results/search-results-table';
import {SearchResultsNotFoundTips} from '../../../components/search-results/search-results-not-found-tips';
import {handleLegacySearchQuery} from '../../../utils/search-helper';
import {AddToDatasetDropdown} from '../../../components/dataset';
import {TagDropdown} from '../../../components/tags';
import {Modal} from '../../../core/layout/modal';
import {useAxios} from 'src/utils/http';

export const resultsPerPage = 100;

export const AdvancedSearchResultsPage = () => {
  const navigate = useNavigate();
  const urlQuery = useURLQuery();
  const api = useAxios();
  const queryClient = useQueryClient();

  const [searchForm, searchFormChange] = useState<SearchQuery>();
  const [searchFormModalOpen, searchFormModalOpenChange] =
    useState<boolean>(false);
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const [selectDropdownOpened, selectDropdownOpenedChange] = useState(false);
  const demoToast = useRef<ReactText>();
  const [demoToastCount, demoToastCountChange] = useState(2); // # of times left until toast shown, -1 for dismissed
  const [resultsTourActive, resultsTourActiveChange] = useState(false);

  const studiesSelected = isAnyReportSelected(reportSelectionState);

  const searchQuery: SearchQuery | undefined = handleLegacySearchQuery(
    decodeURLObject(urlQuery.q ?? undefined)
  );

  const page: number =
    _.toInteger(urlQuery.page) > 0 ? _.toInteger(urlQuery.page) : 1;

  const {data: tags, isLoading: tagsLoading} = useQuery(
    ['tags'],
    () => getAvailableTags(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const {
    data: result,
    isLoading: resultIsLoading,
    isError: resultIsError,
  } = useQuery(
    ['search_demog', JSON.stringify(searchQuery), page],
    () => fetchSearchDemogResults(api, searchQuery!, page),
    {
      staleTime: Infinity,
      enabled: !_.isNil(searchQuery),
      onSuccess: data => {
        if (data.totalCount < 100 && demoToastCount >= 0) {
          let leftUntilToast = demoToastCount;
          if (leftUntilToast > 0) {
            leftUntilToast--;
            demoToastCountChange(leftUntilToast);
          }
          if (leftUntilToast === 0) {
            demoToast.current = quickToast({
              title: 'Not enough results?',
              children: (
                <>
                  <span>
                    We might be able to give you some tips to optimize your
                    search
                  </span>
                  <div className="mt-2 space-x-2">
                    <a
                      href="https://calendly.com/alinelutz/30min"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-primary inline-block"
                    >
                      Schedule a demo
                    </a>

                    <button
                      className="btn btn-sm btn-white inline-block"
                      onClick={() => {
                        demoToastCountChange(-1);
                        toast.dismiss(demoToast.current);
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </>
              ),
            });
          }
        }
      },
      retry: (failureCount, err) => {
        const {response} = err;
        const {status} = response;
        if ([400, 401, 422].includes(status)) {
          return false;
        }
        return failureCount <= 2;
      },
      onError: (err: never) => {
        const {response} = err;
        const {status, data} = response || {};

        let errMessage = 'Something went wrong, please try again later';
        let errBody = <></>;
        switch (status) {
          case 400:
          case 401:
            errMessage = (data as {message: string})?.message ?? errMessage;
            break;
          case 422:
            errMessage = 'Invalid search link';
            errBody = (
              <>
                Please conduct a{' '}
                <Link
                  to={{
                    pathname: '/',
                  }}
                  className="text-primary hover:text-primary-active"
                >
                  new search
                </Link>
              </>
            );
            break;
          case 504:
            errMessage =
              'Search timed out. Please refine your search and try again';
            break;
        }
        toast(
          <ToastMessage title={errMessage} icon="error">
            {errBody}
          </ToastMessage>
        );
      },
    }
  );

  const allSelected = !!reportSelectionState.add.query;
  const pageSelected = allSelected
    ? !_.some(result?.reports, report =>
        reportSelectionState.remove.ids.has(report.studyId)
      )
    : _.every(result?.reports, report =>
        reportSelectionState.add.ids.has(report.studyId)
      );

  const pageSomeSelected = pageSelected
    ? false
    : allSelected
    ? !_.every(result?.reports, report =>
        reportSelectionState.remove.ids.has(report.studyId)
      )
    : _.some(result?.reports, report =>
        reportSelectionState.add.ids.has(report.studyId)
      );

  useEffect(() => {
    if (_.isNil(searchQuery)) {
      // Query does not exist
      navigate('/');
    }

    if (searchForm === undefined) {
      // First load
      searchFormChange(_.cloneDeep(searchQuery));
    }

    // Prefetch previous/next pages
    if (!_.isNil(result)) {
      if (page < result.totalPages) {
        queryClient.prefetchQuery(
          ['search_demog', JSON.stringify(searchQuery), page + 1],
          () => fetchSearchDemogResults(api, searchQuery!, page + 1),
          {staleTime: Infinity}
        );
      }

      if (page > 1) {
        queryClient.prefetchQuery(
          ['search_demog', JSON.stringify(searchQuery), page - 1],
          () => fetchSearchDemogResults(api, searchQuery!, page - 1),
          {staleTime: Infinity}
        );
      }
    }

    // Reset report selection if dataset changed
    if (
      _.isNil(reportSelectionState.id) ||
      reportSelectionState.id !== JSON.stringify(searchQuery)
    ) {
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {id: JSON.stringify(searchQuery)},
      });
    }
  }, [
    navigate,
    page,
    queryClient,
    reportSelectionDispatch,
    reportSelectionState.id,
    result,
    searchForm,
    searchQuery,
  ]);

  if (searchForm === undefined) {
    return <></>;
  }

  if (searchQuery === undefined) {
    return <Navigate to="/" />;
  }

  const numSelected =
    result && allSelected
      ? result.totalCount - (reportSelectionState.remove.ids.size || 0)
      : reportSelectionState.add.ids.size || 0;

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Search Results</title>
      </Helmet>

      <ApplicationShell
        bgcolor="bgcolor"
        contained={false}
        noPadding
        navbar={
          <div className="navbar">
            {searchQuery && (
              <button
                onClick={() => searchFormModalOpenChange(true)}
                className="text-input items-center w-1/2 overflow-x-scroll text-left whitespace-nowrap"
                data-cy="navbar__CompactSearchQuery__editSearchBtn"
              >
                <SearchIcon className="text-gray-400 w-6 h-6 mr-2 inline-block" />
                <CompactSearchQuery
                  searchQuery={searchQuery}
                  className="whitespace-nowrap"
                />
              </button>
            )}
          </div>
        }
      >
        <div>
          {resultIsLoading ? (
            <Loading />
          ) : (
            <>
              <div
                className="flex justify-between items-center p-6"
                data-cy="Results_resultsDiv"
              >
                <div>
                  <h1 className="font-medium color-gray-900 text-3xl flex items-center">
                    {result?.reports.length === 0 ? '0 Results' : 'Results'}
                    {result?.reports.length !== 0 && (
                      <HiInformationCircle
                        className="mx-1 text-xl text-primary hover:text-primary-active hover:cursor-pointer"
                        onClick={() => {
                          trackEvent('CLICK_RESULTS_TOUR_BTN');
                          resultsTourActiveChange(true);
                        }}
                      />
                    )}
                  </h1>
                </div>
                <div>
                  {result && result.totalCount > 0 && (
                    <>
                      Showing{' '}
                      {(resultsPerPage * (result.page - 1) + 1).toLocaleString(
                        'en-US'
                      )}
                      -
                      {Math.min(
                        resultsPerPage * result.page,
                        result.totalCount
                      ).toLocaleString('en-US')}{' '}
                      of {result.totalCount.toLocaleString('en-US')}
                    </>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center p-6">
                <div className="space-x-3 flex items-center">
                  {result && result.reports.length > 0 && (
                    <>
                      <input
                        type="checkbox"
                        data-tour="results-select-all"
                        className="checkbox-input"
                        checked={pageSelected}
                        ref={el => el && (el.indeterminate = pageSomeSelected)}
                        data-cy="Results_selectAllInput"
                        onChange={e => {
                          const checked = e.target.checked;

                          trackEvent(
                            checked
                              ? 'REPORT_SELECT_PAGE'
                              : 'REPORT_UNSELECT_PAGE'
                          );

                          reportSelectionDispatch({
                            type: e.target.checked
                              ? ReportSelectionEventType.SELECT
                              : ReportSelectionEventType.UNSELECT,
                            payload: _.map(
                              result.reports,
                              report => report.studyId
                            ),
                          });
                        }}
                      />

                      <div className="inline-block">
                        <Dropdown
                          text={
                            numSelected > 0
                              ? `${numSelected} Selected`
                              : 'Select'
                          }
                          buttonStyle="btn-link"
                          buttonClassName="btn-xs"
                          open={selectDropdownOpened}
                          onToggle={opened => {
                            selectDropdownOpenedChange(opened);
                          }}
                        >
                          <div className="w-48 rounded-md py-1 bg-white z-30 max-h-72">
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer w-full text-left"
                              onClick={() => {
                                selectDropdownOpenedChange(false);

                                trackEvent(
                                  allSelected
                                    ? 'REPORT_UNSELECT_ALL'
                                    : 'REPORT_SELECT_ALL'
                                );

                                reportSelectionDispatch({
                                  type: allSelected
                                    ? ReportSelectionEventType.UNSELECT_ALL
                                    : ReportSelectionEventType.SELECT_ALL,
                                  payload: searchQuery,
                                });
                              }}
                            >
                              All {result && result.totalCount} results
                            </button>
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer w-full text-left"
                              onClick={() => {
                                selectDropdownOpenedChange(false);

                                trackEvent(
                                  !pageSelected
                                    ? 'REPORT_SELECT_PAGE'
                                    : 'REPORT_UNSELECT_PAGE'
                                );

                                reportSelectionDispatch({
                                  type: !pageSelected
                                    ? ReportSelectionEventType.SELECT
                                    : ReportSelectionEventType.UNSELECT,
                                  payload: _.map(
                                    result.reports,
                                    report => report.studyId
                                  ),
                                });
                              }}
                            >
                              {result.reports.length} results on page
                            </button>
                          </div>
                        </Dropdown>
                      </div>
                      <SearchResultsTour
                        tourActive={resultsTourActive}
                        tourActiveChange={resultsTourActiveChange}
                      />
                    </>
                  )}
                  <div
                    className="flex flex-row gap-x-4"
                    data-cy="Results_datasetsDropdown"
                  >
                    <AddToDatasetDropdown
                      disabled={!studiesSelected}
                      reportSelectionState={reportSelectionState}
                    />
                    <TagDropdown
                      tags={userTags}
                      studyIDs={Array.from(reportSelectionState.add.ids)}
                      query={reportSelectionState.add.query}
                      align="left"
                      disabled={!studiesSelected || tagsLoading}
                    />
                  </div>
                </div>
                <div>
                  <Link
                    to="/datasets"
                    data-tour="results-view-datasets"
                    className="btn btn-xs btn-white"
                    onClick={() => trackEvent('CLICK_VIEW_DATASETS_BTN')}
                  >
                    View Datasets
                  </Link>
                </div>
              </div>

              {result && result.reports.length > 0 ? (
                <>
                  <SearchResultsTable
                    searchResult={result}
                    searchQuery={searchQuery}
                    allSelected={allSelected}
                    reportPathName="advanced_search/report"
                    isAdvanced
                  />

                  <Pagination
                    max={result.totalPages || 1}
                    current={result.page}
                    linkFunc={num => {
                      trackEvent('SEARCH_PAGE_CHANGE', {
                        searchQuery,
                        page: num,
                      });
                      navigate({
                        pathname: '/advanced_search/results',
                        search: queryString.stringify({
                          q: encodeURLObject(searchQuery),
                          page: num,
                        }),
                      });
                    }}
                  ></Pagination>
                </>
              ) : (
                <div className="px-6">
                  <SearchResultsNotFoundTips />
                </div>
              )}
            </>
          )}
        </div>
      </ApplicationShell>

      <Modal
        isOpen={searchFormModalOpen}
        onRequestClose={() => {
          searchFormModalOpenChange(false);
          searchFormChange(searchQuery!);
        }}
        className="w-screen max-h-full overflow-y-scroll"
        shouldCloseOnOverlayClick={false}
      >
        <SearchForm
          query={searchForm}
          queryChange={q => searchFormChange(q)}
          searchDisabled={_.isEqual(searchForm, searchQuery)}
          onSearch={query => {
            searchFormModalOpenChange(false);
            navigate({
              pathname: '/advanced_search/results',
              search: queryString.stringify({
                q: encodeURLObject(query),
              }),
            });
            return false;
          }}
          compactable={!resultIsError}
          isAdvanced
        />
      </Modal>
    </>
  );
};
