import React, {useEffect} from 'react';
import {AuthorizationParams, useAuth0} from '@auth0/auth0-react';
import {useSearchParams} from 'react-router-dom';
import _ from 'lodash';

import {Auth0EnterpriseConnections} from 'src/models/auth0';

export const Login = () => {
  const {loginWithRedirect, isAuthenticated, isLoading, logout} = useAuth0();

  const [searchParams] = useSearchParams();
  const connectionParam = searchParams.get('connection') ?? '';
  const authorizationParams: AuthorizationParams | undefined = _.includes(
    Auth0EnterpriseConnections,
    connectionParam
  )
    ? {
        connection: connectionParam,
        screen_hint: 'consent',
      }
    : undefined;

  const login = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: authorizationParams,
    });
  };

  useEffect(() => {
    const isLiveTest = process.env.REACT_APP_LIVE_TEST === 'true';
    if (!isLoading && !isLiveTest) {
      if (!isAuthenticated) {
        login();
      } else {
        logout({
          logoutParams: {
            returnTo: window.location.href,
          },
        });
      }
    }
  }, [isAuthenticated, isLoading]);

  return (
    <div>
      <button onClick={login}>Login</button>
    </div>
  );
};
