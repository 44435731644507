import {useState} from 'react';
import cx from 'classnames';
import {toast} from 'react-toastify';
import {HiOutlineStar} from 'react-icons/hi';

import {ToastMessage} from '../../core/components/toast';
import {
  CreateTagRequest,
  createTag,
  defaultTagColors,
  favoriteTag,
  validateTagName,
} from '../../models/tags';
import {ColorPicker, Tag} from '.';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import {useAxios} from 'src/utils/http';

export const CreateTagForm = ({
  created,
  cancelled,
}: {
  created: (tagID: number) => void;
  cancelled: () => void;
}) => {
  const api = useAxios();
  const [name, nameChange] = useState<string>('');
  const [color, colorChange] = useState<string[]>([...defaultTagColors]);
  const [favorite, favoriteChange] = useState(true);

  const createTagMut = useMutation(
    (tagRequest: CreateTagRequest) => createTag(api, tagRequest),
    {
      onSuccess: tagID => {
        toast(<ToastMessage title="Tag created" icon="success" />);
        // Favorite newly created tags
        favoriteTag(api, tagID, {favorite}).then(() => {
          if (created) {
            created(tagID);
          }
        });
      },
      onError: (err: AxiosError) => {
        const message = err?.response?.data?.message ?? 'Error creating tag';
        toast(<ToastMessage title={message} icon="error" />);
      },
    }
  );

  const handleSubmit = () => {
    const tagRequest: CreateTagRequest = {
      name,
      description: '',
      scope: 'user',
      font_color: color[0],
      background_color: color[1],
    };
    createTagMut.mutate(tagRequest);
  };

  const renderPreview = () => {
    if (name && color) {
      return (
        <Tag
          tag={{
            tid: 0,
            name: name,
            description: '',
            scope: 'user',
            backgroundColor: color[1],
            fontColor: color[0],
            favorite: false,
          }}
          className="max-w-xxs"
        />
      );
    }
    return null;
  };

  const tagNameError = validateTagName(name);

  return (
    <div className="w-auto max-w-full">
      <div className="text-lg mb-3 text-gray-900">Create New Tag</div>
      <div className="mb-4 grid grid-rows-8">
        <label htmlFor="name" className="input-label">
          Name
        </label>
        <input
          name="name"
          type="text"
          className={cx('text-input max-w-md my-2 mb-3', {
            'text-input-error': tagNameError,
          })}
          value={name}
          onChange={e => nameChange(e.target.value)}
          autoComplete="off"
        />
        {tagNameError && (
          <div className="text-sm text-red-900 -mt-2">{tagNameError}</div>
        )}
        <label className="input-label mt-2">Color</label>
        <div className="my-2 max-w-md justify-self-center">
          <ColorPicker onChange={colorChange} currentColor={color} />
        </div>
        <div className="flex flex-row items-center justify-between mt-4 ">
          <div className="flex flex-row whitespace-nowrap max-w-md items-center input-label h-6">
            Tag Preview{' '}
            <div className="ml-2 flex items-center">{renderPreview()}</div>
          </div>
          <div className="input-label flex flex-row items-center">
            Prioritize
            <HiOutlineStar
              fill={favorite ? '#FCD34D' : 'white'}
              className={cx('w-6 h-6 ml-1 cursor-pointer', {
                'text-yellow-300 hover:text-yellow-500 fill-current': favorite,
                'text-gray-400 hover:text-gray-500 stroke-current': !favorite,
              })}
              onClick={() => favoriteChange(!favorite)}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-items-start">
        <button className="btn btn-white ml-auto" onClick={cancelled}>
          Cancel
        </button>
        <button
          className="btn btn-primary ml-3"
          onClick={handleSubmit}
          disabled={
            !name || !color[0] || createTagMut.isLoading || !!tagNameError
          }
        >
          Create
        </button>
      </div>
    </div>
  );
};
