import {useState} from 'react';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';

import {ApplicationShell} from '../../../core/layout/application-shell';
import {
  SearchForm,
  emptyPatientSearchQuery,
} from '../../../components/search-form';
import {Card} from '../../../core/layout/card';
import {SearchQuery} from '../../../models/search';
import {encodeURLObject} from '../../../utils/router-helper';
import {useAuth} from '../../../hooks/auth';

export const AdvancedSearchPage = () => {
  const {authState} = useAuth();
  const navigate = useNavigate();
  const [searchQuery, searchQueryChange] = useState<SearchQuery>(
    _.cloneDeep(emptyPatientSearchQuery)
  );

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Advanced Search</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor" contained={false}>
        <Card>
          <SearchForm
            query={searchQuery}
            queryChange={searchQueryChange}
            onSearch={query => {
              navigate({
                pathname: '/advanced_search/results',
                search: queryString.stringify({
                  q: encodeURLObject(query),
                }),
              });
              return false;
            }}
            patientFilter={authState.profile?.admin}
            isAdvanced
          ></SearchForm>
        </Card>
      </ApplicationShell>
    </>
  );
};
