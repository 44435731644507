import React from 'react';
import {Switch} from '@headlessui/react';
import cx from 'classnames';

export const Toggle = ({
  checked,
  onChange,
  disabled = false,
}: {
  checked: boolean;
  onChange(checked: boolean): void;
  disabled?: boolean;
  readonly?: boolean;
}) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={cx(
        checked ? 'bg-blue-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      disabled={disabled}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={cx(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};
