import React, {Fragment, useState} from 'react';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {MenuAlt2Icon, XIcon} from '@heroicons/react/outline';
import cx from 'classnames';
import {Link, matchPath, useLocation} from 'react-router-dom';
import {filter, some} from 'lodash';

import {
  navigationLinkGroups,
  navigationLinkGroupSecondary,
} from './sidebar-links';
import {useAuth} from '../../../hooks/auth';
import {isInternal, isTerminalCustomer} from '../../../models/auth';
import {trackEvent} from '../../../utils/tracking';

export const isLinkActive = ({
  currentPath,
  link,
}: {
  currentPath: string;
  link: {
    to: string;
    exact?: boolean;
    pathMatches?: string[];
  };
}): boolean => {
  link.exact;
  const match =
    matchPath(
      {
        path: link.to,
        caseSensitive: false,
        end: link.exact,
      },
      currentPath
    ) ||
    some(link.pathMatches || [], pathMatch =>
      matchPath(
        {
          path: pathMatch,
        },
        currentPath
      )
    );

  return !!match;
};

const userNavigation = [
  {name: 'Account Settings', href: '/settings'},
  {name: 'Sign Out', href: '/logout'},
];

export const Sidebar = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {authState} = useAuth();

  if (!authState.loaded) {
    return <></>;
  }

  const sidebarLinkGroups = navigationLinkGroups.map(navigationLinkGroup =>
    filter(navigationLinkGroup, link => {
      return (
        process.env.REACT_APP_LIVE_TEST === 'true' ||
        link.allowed === undefined ||
        (!!authState.profile &&
          ((link.allowed.includes('internal') &&
            isInternal(authState.profile!.email)) ||
            (link.allowed.includes('admin') && authState.profile!.admin) ||
            link.allowed.includes(authState.profile!.userType) ||
            (link.allowed.includes('terminalCustomer') &&
              isTerminalCustomer(authState.profile!.email))))
      );
    })
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-ebony/75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-ebony">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <Link
                  className={cx('flex-shrink-0 flex items-center px-4 h-16')}
                  to="/"
                >
                  <img
                    className="h-8 w-auto"
                    src="assets/logo/segmed_logo_full_white.png"
                    alt="Segmed"
                  />
                </Link>
                <div className="mt-5 flex-1 flex-col h-0 overflow-y-auto">
                  <nav className="flex-1 px-2 space-y-1">
                    {sidebarLinkGroups.map((sidebarLinkGroup, i) => (
                      <Fragment key={i}>
                        {i > 0 && <hr className="opacity-25" />}
                        {sidebarLinkGroup.map(item => {
                          const isActive = isLinkActive({
                            currentPath: location.pathname,
                            link: item,
                          });

                          return item.external ? (
                            <a
                              href={item.to}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={cx(
                                'sidebarlink-mobile group',
                                'sidebarlink-mobile-inactive'
                              )}
                              key={item.name}
                              onClick={() =>
                                trackEvent('SIDEBAR_LINK_CLICK', {
                                  name: item.name,
                                  path: item.to,
                                })
                              }
                            >
                              {item.name}
                            </a>
                          ) : (
                            <Link
                              to={item.to}
                              className={cx(
                                'sidebarlink-mobile group',
                                isActive
                                  ? 'sidebarlink-mobile-active'
                                  : 'sidebarlink-mobile-inactive'
                              )}
                              key={item.name}
                              onClick={() =>
                                trackEvent('SIDEBAR_LINK_CLICK', {
                                  name: item.name,
                                  path: item.to,
                                })
                              }
                            >
                              {item.icon && (
                                <item.icon
                                  className={cx(
                                    'text-vellum',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                              {item.name}
                            </Link>
                          );
                        })}
                      </Fragment>
                    ))}
                  </nav>
                </div>

                <nav className="justify-self-end px-2 space-y-1">
                  {navigationLinkGroupSecondary.map(item => {
                    const isActive = isLinkActive({
                      currentPath: location.pathname,
                      link: item,
                    });
                    return item.external ? (
                      <a
                        href={item.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cx(
                          'sidebarlink-mobile group',
                          'sidebarlink-mobile-inactive'
                        )}
                        key={item.name}
                        onClick={() =>
                          trackEvent('SIDEBAR_LINK_CLICK', {
                            name: item.name,
                            path: item.to,
                          })
                        }
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        to={item.to}
                        className={cx(
                          'sidebarlink group',
                          isActive
                            ? 'sidebarlink-active'
                            : 'sidebarlink-inactive'
                        )}
                        key={item.name}
                        onClick={() =>
                          trackEvent('SIDEBAR_LINK_CLICK', {
                            name: item.name,
                            path: item.to,
                          })
                        }
                      >
                        {item.icon && (
                          <item.icon
                            className={cx(
                              'text-vellum',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {item.name}
                      </Link>
                    );
                  })}
                </nav>

                {/* User Settings */}
                <div className="justify-self-end flex flex-col text-white">
                  <div className="py-2 px-4 gap-x-2 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 flex-none rounded-full bg-red-400 flex items-center justify-center">
                      {authState.profile?.firstname[0].toUpperCase()}
                    </div>
                    <div className="text-sm text-white px-2">
                      {authState.profile?.firstname}{' '}
                      {authState.profile?.lastname}
                    </div>
                  </div>

                  {userNavigation.map(item => (
                    <div key={item.name}>
                      <Link
                        to={item.href}
                        className={cx(
                          'block px-4 py-2 text-sm hover:bg-turquoise/50 focus:bg-turquoise/50'
                        )}
                        role="menuitem"
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 md:flex-1 md:bg-ebony z-10">
          <Link
            to="/"
            key={Math.random().toString()}
            className={cx('flex items-center h-16 flex-shrink-0 px-4', {
              isActive: isLinkActive({
                currentPath: location.pathname,
                link: {
                  to: '/',
                  exact: true,
                },
              }),
            })}
          >
            <img
              className="h-8"
              src="assets/logo/segmed_logo_full_white.png"
              alt="Segmed"
            />
          </Link>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {sidebarLinkGroups.map((sidebarLinkGroup, i) => (
                <Fragment key={i}>
                  {i > 0 && <hr className="opacity-25" />}
                  {sidebarLinkGroup.map(item => {
                    const isActive = isLinkActive({
                      currentPath: location.pathname,
                      link: item,
                    });
                    return item.external ? (
                      <a
                        href={item.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cx(
                          'sidebarlink-mobile group',
                          'sidebarlink-mobile-inactive'
                        )}
                        key={item.name}
                        onClick={() =>
                          trackEvent('SIDEBAR_LINK_CLICK', {
                            name: item.name,
                            path: item.to,
                          })
                        }
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        to={item.to}
                        className={cx(
                          'sidebarlink group',
                          isActive
                            ? 'sidebarlink-active'
                            : 'sidebarlink-inactive'
                        )}
                        key={item.name}
                        onClick={() =>
                          trackEvent('SIDEBAR_LINK_CLICK', {
                            name: item.name,
                            path: item.to,
                          })
                        }
                      >
                        {item.icon && (
                          <item.icon
                            className={cx(
                              'text-vellum',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {item.name}
                      </Link>
                    );
                  })}
                </Fragment>
              ))}
            </nav>
          </div>

          <nav className="justify-self-end px-2 py-2 space-y-1">
            {navigationLinkGroupSecondary.map(item => {
              const isActive = isLinkActive({
                currentPath: location.pathname,
                link: item,
              });
              return item.external ? (
                <a
                  href={item.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cx(
                    'sidebarlink-mobile group',
                    'sidebarlink-mobile-inactive'
                  )}
                  key={item.name}
                  onClick={() =>
                    trackEvent('SIDEBAR_LINK_CLICK', {
                      name: item.name,
                      path: item.to,
                    })
                  }
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  to={item.to}
                  className={cx(
                    'sidebarlink group',
                    isActive ? 'sidebarlink-active' : 'sidebarlink-inactive'
                  )}
                  key={item.name}
                  onClick={() =>
                    trackEvent('SIDEBAR_LINK_CLICK', {
                      name: item.name,
                      path: item.to,
                    })
                  }
                >
                  {item.icon && (
                    <item.icon
                      className={cx(
                        'text-vellum',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                  )}

                  {item.name}
                </Link>
              );
            })}
          </nav>

          {/* Profile dropdown */}
          <Menu as="div" className="relative justify-self-end">
            <Menu.Button className="w-full py-2 px-4 bg-ebony flex items-center text-sm hover:bg-turquoise/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">Open user menu</span>
              <div className="h-8 w-8 flex-none rounded-full bg-turquoise flex items-center justify-center text-white">
                {authState.profile?.firstname[0].toUpperCase()}
              </div>
              <div className="text-sm text-white px-2">
                {authState.profile?.firstname} {authState.profile?.lastname}
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-bottom absolute left-0 bottom-full rounded-sm mt-2 w-full bg-ebony border text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map(item => (
                  <Menu.Item key={item.name}>
                    {({active}) => (
                      <Link
                        to={item.href}
                        className={cx(
                          'block px-4 py-2 text-sm hover:bg-turquoise/50 focus:bg-turquoise/50',
                          {
                            'bg-turquoise': active,
                          }
                        )}
                        role="menuitem"
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="md:hidden">
          <button
            type="button"
            className="sticky top-0 z-30 h-16 w-16 border-r border-gray-200"
            onClick={() => setSidebarOpen(true)}
          >
            <div className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 md:hidden">
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
