import {AxiosInstance} from 'axios';

export type SnomedConcept = {
  conceptId: string;
  term: string;
};

export type SnomedConceptResult = SnomedConcept & {
  synonyms: SnomedConcept[];
  children: SnomedConcept[];
};

export type SnomedConceptResponse = {
  candidate_concepts: SnomedConceptResult[];
};

export const fetchSnomedConceptSuggestions = (
  http: AxiosInstance,
  term: string
) => {
  return http
    .post('/v1/search/autocomplete', {
      term: term,
    })
    .then(response => {
      const {data} = response;
      const concepts = (data as SnomedConceptResponse).candidate_concepts;
      const trimmedConcepts = removeLongSnomedConcepts(concepts);
      return trimmedConcepts;
    });
};

const removeLongSnomedConcepts = (concepts: SnomedConceptResult[]) => {
  const trimmedConcepts = concepts.filter(concept => {
    return concept.term.length < 75 || concept.term.split(' ').length < 6;
  });
  const trimmedSynonyms = trimmedConcepts.map(concept => {
    return {
      ...concept,
      synonyms: concept.synonyms.filter(synonym => {
        return synonym.term.length < 75 || synonym.term.split(' ').length < 6;
      }),
    };
  });
  const trimmedChildren = trimmedSynonyms.map(concept => {
    return {
      ...concept,
      children: concept.children.filter(child => {
        return child.term.length < 75 || child.term.split(' ').length < 6;
      }),
    };
  });
  return trimmedChildren;
};

export function removeTextInParentheses(input: string): string {
  return input.replace(/\s*\([^)]*\)/g, '').trim();
}
