import {ReactNode} from 'react';
import {useQueryClient, useMutation} from 'react-query';
import {Badge} from '../../core/components/badge';

import {quickToast} from '../../core/components/toast';
import {Toggle} from '../../core/components/toggle';
import {
  updateDatasetIncludeUndetermined,
  Dataset,
  datasetStatusToStage,
  DATASET_STAGE_TYPE,
} from '../../models/dataset';
import {useAxios} from 'src/utils/http';

export const IncludeUndeterminedStudiesToggle = ({
  dataset,
  description = `Include studies with ${(
    <Badge type="info">Undetermined</Badge>
  )} order status`,
  onIncludeUndeterminedChange,
}: {
  dataset: Dataset;
  description?: ReactNode;
  onIncludeUndeterminedChange?(includeUndetermined: boolean): void;
}) => {
  const queryClient = useQueryClient();
  const http = useAxios();

  const updateDatasetIncludeUndeterminedMut = useMutation(
    ({
      datasetId,
      includeUndetermined,
    }: {
      datasetId: number;
      includeUndetermined: boolean;
    }) =>
      updateDatasetIncludeUndetermined(http, datasetId, includeUndetermined),
    {
      onSuccess: (_data, variables) => {
        const message = `Studies with undetermined order status have been ${
          variables.includeUndetermined ? 'added to' : 'excluded from'
        } order`;
        quickToast({title: message, icon: 'success'});

        queryClient.invalidateQueries(['dataset'], {exact: true});
        queryClient.invalidateQueries(['dataset', dataset.id]);

        onIncludeUndeterminedChange &&
          onIncludeUndeterminedChange(variables.includeUndetermined);
      },
    }
  );

  const datasetCanApproveStudies = ![
    DATASET_STAGE_TYPE.DatasetCreated,
    DATASET_STAGE_TYPE.DatasetOrdered,
    DATASET_STAGE_TYPE.DatasetDelivered,
  ].includes(datasetStatusToStage(dataset.status));

  return (
    <div className="flex items-center justify-between space-x-2">
      {description && description}

      <Toggle
        checked={dataset.includeUndetermined}
        onChange={includeUndetermined =>
          updateDatasetIncludeUndeterminedMut.mutate({
            datasetId: dataset.id,
            includeUndetermined,
          })
        }
        disabled={!datasetCanApproveStudies}
      />
    </div>
  );
};
