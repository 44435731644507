import React from 'react';
import {
  InboxIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  HeartIcon,
  DatabaseIcon,
  SearchIcon,
  TagIcon,
  UploadIcon,
  UserIcon,
  ViewGridIcon,
  DownloadIcon,
  DocumentReportIcon,
  TerminalIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';
import {HiOutlineWrench} from 'react-icons/hi2';

export interface NavigationLink {
  name: string;
  to: string;
  external?: boolean;
  exact?: boolean;
  pathMatches?: string[];
  allowed?: (
    | 'customer'
    | 'data_partner'
    | 'internal'
    | 'admin'
    | 'terminalCustomer'
  )[];
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export const navigationLinkGroups: NavigationLink[][] = [
  [
    {
      name: 'Search',
      icon: SearchIcon,
      to: '/',
      exact: true,
      pathMatches: ['/study'],
    },
    {
      name: 'Search (old)',
      icon: SearchIcon,
      to: '/search',
      pathMatches: ['/search'],
      allowed: ['internal'],
    },
    {
      name: 'Search (Internal)',
      icon: SearchIcon,
      to: '/internal/search',
      pathMatches: ['/internal/search'],
      allowed: ['internal'],
    },
    {
      name: 'Patient Search',
      icon: SearchIcon,
      to: '/patient_search',
      pathMatches: ['/patient_search'],
      allowed: ['internal'],
    },
    {
      name: 'Advanced Search',
      icon: SearchIcon,
      to: '/advanced_search',
      pathMatches: ['/advanced_search'],
      allowed: ['internal'],
    },
    {
      name: 'Terminal',
      icon: TerminalIcon,
      to: '/terminal',
      pathMatches: ['/terminal'],
    },
    {
      name: 'Terminal Internal',
      icon: TerminalIcon,
      to: '/internal/terminal',
      pathMatches: ['/internal/terminal'],
      allowed: ['internal'],
    },
    {
      name: 'Datasets',
      to: '/datasets',
      icon: ViewGridIcon,
      allowed: ['customer'],
    },
    {
      name: 'Saved Searches',
      to: '/saved-searches',
      icon: HeartIcon,
    },
    {
      name: 'Pricing',
      to: '/pricing',
      icon: CurrencyDollarIcon,
      allowed: ['internal'],
    },
    {
      name: 'Tags',
      to: '/tags',
      icon: TagIcon,
      allowed: ['customer'],
    },
    {
      name: 'Orders',
      to: '/orders',
      icon: InboxIcon,
      allowed: ['internal'],
    },
    {
      name: 'Statistics',
      to: '/stats',
      icon: ChartPieIcon,
      allowed: ['customer', 'data_partner'],
    },
    {
      name: 'Data Partner Uploading',
      to: '/datapartner/upload',
      icon: UploadIcon,
      allowed: ['data_partner'],
    },
    {
      name: 'Manage Datasets',
      to: '/admin/datasets',
      exact: true,
      icon: DatabaseIcon,
      allowed: ['admin'],
    },
    {
      name: 'Manage Datasets Orders',
      to: '/admin/orders',
      exact: true,
      icon: InboxIcon,
      allowed: ['admin'],
    },
    {
      name: 'Manage Users',
      to: '/admin/users',
      icon: UserIcon,
      allowed: ['admin'],
    },
    {
      name: 'Sample Downloads',
      to: '/admin/datapartner/download',
      icon: DownloadIcon,
      allowed: ['admin'],
    },
    {
      name: 'Review Datasets',
      to: '/admin/datasets/review',
      exact: true,
      icon: DocumentReportIcon,
      allowed: ['admin'],
    },
    {
      name: 'Synthetic Data',
      to: '/synthetic',
      exact: true,
      icon: InboxIcon,
      allowed: ['internal'],
    },
    {
      name: 'Manage Synthetic Data Users Credit',
      to: '/admin/synthetic/credits',
      icon: UserIcon,
      allowed: ['admin'],
    },
    {
      name: 'Tools',
      to: '/tools',
      icon: HiOutlineWrench,
      allowed: ['internal'],
    },
    {
      name: 'DevOps Metrics',
      icon: LightningBoltIcon,
      to: '/internal/dora',
      allowed: ['internal'],
    },
  ],
];

export const navigationLinkGroupSecondary: NavigationLink[] = [
  {
    name: 'Demos and Guides',
    to: 'https://segmed.notion.site/segmed/Insight-Demos-and-Guides-510210cd13d7452db8116c128651a564',
    external: true,
  },
];
