import {AuthProfile} from '../models/auth';

const intercomAppID = 'vf1qfiov';
const intercomApiBase = 'https://api-iam.intercom.io';

export const bootIntercom = (profile?: AuthProfile, intercomHash?: string) => {
  if (profile && intercomHash) {
    // Logged in and verified users
    window.Intercom('boot', {
      api_base: intercomApiBase,
      app_id: intercomAppID,
      email: profile.email,
      user_id: profile.userId.toString(),
      user_hash: intercomHash,
      name: `${profile.firstname} ${profile.lastname}`,
    });
  } else {
    // Visitors without user info
    window.Intercom('boot', {
      api_base: intercomApiBase,
      app_id: intercomAppID,
    });
  }
};

export const shutdownIntercom = () => {
  Intercom('shutdown');
};

export const updateIntercom = (settings?: Intercom_.IntercomSettings) => {
  Intercom('update', settings);
};

export const hideIntercom = () => {
  Intercom('hide');
};

export const showIntercom = () => {
  Intercom('show');
};

export const showIntercomMessages = () => {
  Intercom('showMessages');
};

export const showIntercomNewMessage = (message?: string) => {
  Intercom('showNewMessage', message);
};

export const showIntercomArticle = (articleID: number) => {
  Intercom('showArticle', articleID);
};

export const trackEventIntercom = (
  eventType: string,
  eventProperties?: object
) => {
  const commonProperies = {
    url: window.location.href,
    app_origin: window.location.origin,
  };
  Intercom('trackEvent', eventType, {...eventProperties, ...commonProperies});
};
