import _ from 'lodash';

import {trackEvent} from '../utils/tracking';
import {Report, responseToReport, ReportResponse} from './report';
import {AxiosInstance} from 'axios';

export interface Keyword {
  area: string;
  operator: string;
  text: string;
  booloperator: string;
}

export interface SearchQuery {
  modality: string[];
  bodyArea: string[];
  sourceLocation: string[];
  keywords: Keyword[];
  patients: string;
  additionalPatientStudies: boolean;
  isPatientSearch: boolean;
  vendor: string[];
  gender: string[];
  ethnicity: string[];
  race: string[];
  sliceThickness: number[];
  age: number[];
}

export interface SearchRequestQuery {
  booloperators: string[];
  columns: string[];
  operators: string[];
  searchterms: string[];
  areas: string[];
  modalities: string[];
  bodyparts: string[];
  datapartners: string[];
  patients: string[];
  additional_patient_studies: boolean;
  is_patient_search: boolean;
  vendor: string[];
  gender: string[];
  ethnicity: string[];
  race: string[];
  slice_thickness: number[];
  age: number[];
}

export interface SearchResult {
  reports: Report[];
  totalCount: number;
  page: number;
  totalPages: number;
}

export interface PatientSearchPatient {
  patientId: string;
  studyCount: number;
  studies: Report[];
  additionalStudies: Report[];
}

export interface PatientSearchResult {
  searchResults: PatientSearchPatient[];
  totalPatients: number;
  totalStudies: number;
  totalAdditionalStudies: number;
  page: number;
  totalPages: number;
}

export interface Modality {
  modality: string;
  label: string;
}

export interface SourceLocation {
  datapartner: string;
  label: string;
  status: string;
}

export interface BodyPartCategory {
  bodyPart: string;
  section: string;
  label: string;
}

export interface BodyPartSection {
  section: string;
  label: string;
}

export enum SourceLocationStatus {
  DOWN = 'DOWN',
  UP = 'UP',
  UNKNOWN = 'UNKNOWN',
}

export const queryToRequest = (query: SearchQuery): SearchRequestQuery => {
  return {
    booloperators: query.keywords.map(k => k.booloperator),
    columns: query.keywords.map(() => 'report'),
    operators: query.keywords.map(k => k.operator),
    searchterms: query.keywords.map(k => k.text),
    areas: query.keywords.map(k => k.area),
    modalities: query.modality,
    bodyparts: query.bodyArea,
    datapartners: query.sourceLocation,
    patients: query.patients
      .split(',')
      .map(patientId => _.trim(patientId))
      .filter(patientId => !_.isEmpty(patientId)),
    additional_patient_studies: query.additionalPatientStudies,
    is_patient_search: query.isPatientSearch,
    vendor: query.vendor,
    gender: query.gender,
    ethnicity: query.ethnicity,
    race: query.race,
    slice_thickness: [
      query.sliceThickness[0] ?? 0,
      query.sliceThickness[1] ?? 0,
    ],
    age: [query.age[0] ?? 0, query.age[1] ?? 0],
  };
};

export const fetchSearchResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('SEARCH', {query, page});

  return http.post('/v1/search', request).then(response => {
    const {data} = response;
    const {page, search_results, total_count, total_pages} = data;

    const reports: Report[] = _.map(search_results, (r: ReportResponse) =>
      responseToReport(r)
    );

    const searchResult: SearchResult = {
      reports: reports,
      totalCount: total_count,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchSearchDemogResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('SEARCH_DEMOG', {query, page});

  return http.post('/v1/search_demog', request).then(response => {
    const {data} = response;
    const {page, search_results, total_count, total_pages} = data;

    const reports: Report[] = _.map(search_results, (r: ReportResponse) =>
      responseToReport(r)
    );

    const searchResult: SearchResult = {
      reports: reports,
      totalCount: total_count,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchPatientSearchResults = (
  http: AxiosInstance,
  query: SearchQuery,
  page = 1
) => {
  const request = {
    save: false,
    page: page,
    search: queryToRequest(query),
  };

  trackEvent('PATIENT_SEARCH', {query, page});

  return http.post('/v1/patient_search', request).then(response => {
    const {data} = response;
    const {
      search_results,
      total_patients,
      total_studies,
      total_additional_studies,
      total_pages,
      page,
    } = data;

    const patients: PatientSearchPatient[] = _.map(
      search_results,
      (p: {
        patient_id: string;
        study_count: number;
        studies: ReportResponse[];
        additional_studies: ReportResponse[];
      }) => {
        const reports: Report[] = _.map(p.studies, s => responseToReport(s));
        const additionalReports: Report[] = _.map(p.additional_studies, s =>
          responseToReport(s)
        );
        return {
          patientId: p.patient_id,
          studyCount: p.study_count,
          studies: reports,
          additionalStudies: additionalReports,
        };
      }
    );

    const searchResult: PatientSearchResult = {
      searchResults: patients,
      totalPatients: total_patients,
      totalStudies: total_studies,
      totalAdditionalStudies: total_additional_studies,
      page: page,
      totalPages: total_pages,
    };

    return searchResult;
  });
};

export const fetchModalities = (http: AxiosInstance) => {
  return http.get('/v1/categories/modalities').then(response => {
    const {data} = response;
    const modalityOptions: Modality[] = data;
    return modalityOptions;
  });
};

export const fetchSourceLocations = (http: AxiosInstance) => {
  return http.get('/v1/categories/datapartners').then(response => {
    const {data} = response;
    const locationOptions: SourceLocation[] = data;
    return locationOptions;
  });
};

export const fetchBodyParts = (http: AxiosInstance) => {
  return http.get('/v1/categories/bodyparts').then(response => {
    const {data} = response;
    const sections: BodyPartSection[] = data.sections;
    const categories: BodyPartCategory[] = _.map(
      data.categories,
      categoryResp => ({
        bodyPart: categoryResp.body_part,
        section: categoryResp.section,
        label: categoryResp.label,
      })
    );
    return {sections, categories};
  });
};

export const fetchAllowedModalities = (http: AxiosInstance) => {
  return http.get('/v1/categories/modalities/allowed').then(response => {
    const {data} = response;
    const allowedModalities: string[] = data;
    return allowedModalities;
  });
};

export const fetchAllowedDataPartners = (http: AxiosInstance) => {
  return http.get('/v1/categories/datapartners/allowed').then(response => {
    const {data} = response;
    const allowedDataPartners: string[] = data;
    return allowedDataPartners;
  });
};
