/* eslint-disable security/detect-object-injection */
import {AxiosInstance, AxiosResponse} from 'axios';
import {QueryClient} from 'react-query';
import _ from 'lodash';

import {Dataset} from './dataset';
import {SearchRequestQuery} from '../models/search';

export interface Tag {
  tid: number;
  name: string;
  description?: string;
  scope: 'user' | 'org' | 'global';
  userID?: string;
  orgID?: string;
  backgroundColor: string;
  fontColor: string;
  favorite: boolean;
}

const colorPickerColors = [
  'red',
  'orange',
  'yellow',
  'lime',
  'emerald',
  'sky',
  'violet',
  'gray',
];

export const defaultTagColors = ['text-gray-800', 'bg-gray-100'];

export const colorPickerColorsFormatted = [
  ...colorPickerColors.map(color => [`text-${color}-800`, `bg-${color}-100`]),
  ...colorPickerColors.map(color => [`text-${color}-100`, `bg-${color}-700`]),
];

export const getBaseColor = (formattedColor: string) => {
  return formattedColor.split('-')[1];
};

export interface TagResponse {
  id: number;
  name: string;
  description?: string;
  scope: 'user' | 'org' | 'global';
  user_id?: string;
  org_id?: string;
  background_color: string;
  font_color: string;
  favorite: boolean;
}

export const responseToTag = (tagResp: TagResponse): Tag => {
  return {
    tid: tagResp.id,
    name: tagResp.name,
    description: tagResp.description,
    scope: tagResp.scope,
    userID: tagResp.user_id,
    orgID: tagResp.org_id,
    backgroundColor: tagResp.background_color,
    fontColor: tagResp.font_color,
    favorite: tagResp.favorite,
  };
};

export const datasetToDatasetTag = (dataset: {
  id: number;
  name: string;
  favorite: boolean;
}): Tag => {
  return {
    tid: dataset.id,
    name: `DS: ${dataset.name}`,
    description: '',
    scope: 'user',
    userID: '0',
    orgID: '0',
    backgroundColor: defaultTagColors[1],
    fontColor: defaultTagColors[0],
    favorite: dataset.favorite,
  };
};

export const getAvailableTags = (http: AxiosInstance) => {
  return http
    .get('v1/tags')
    .then((response: AxiosResponse<{tags: TagResponse[]}>) => {
      const {data} = response;
      return data.tags
        .map((tag: TagResponse) => {
          return responseToTag(tag);
        })
        .sort((firstTag, secondTag) =>
          firstTag.name > secondTag.name ? 1 : -1
        );
    });
};

export interface CreateTagRequest {
  name: string;
  description: string;
  scope: 'user' | 'org' | 'global';
  background_color: string;
  font_color: string;
}

export const createTag = (http: AxiosInstance, request: CreateTagRequest) => {
  return http
    .post('v1/tags', request)
    .then((response: AxiosResponse<{id: number}>) => {
      const {data} = response;
      return data.id;
    });
};

export interface EditTagRequest {
  name: string;
  description: string;
  background_color: string;
  font_color: string;
}

export const editTag = (
  http: AxiosInstance,
  tagID: number,
  request: EditTagRequest
) => {
  return http.put(`v1/tags/${tagID}`, request);
};

export const convertTagToEditTagRequest = (tag: Tag): EditTagRequest => {
  return {
    name: tag.name,
    description: tag.description ?? '',
    background_color: tag.backgroundColor,
    font_color: tag.fontColor,
  };
};

export interface FavoriteTagRequest {
  favorite: boolean;
}

export const favoriteTag = (
  http: AxiosInstance,
  tagID: number,
  request: FavoriteTagRequest
) => {
  return http.put(`v1/tags/${tagID}/favorite`, request);
};

export const deleteTag = (http: AxiosInstance, tagID: number) => {
  return http.delete(`v1/tags/${tagID}`);
};

export interface UpdateStudyTagsRequest {
  add_tags?: number[];
  remove_tags?: number[];
  tags?: number[];
}

export const updateStudyTags = (
  http: AxiosInstance,
  studyID: string,
  request: UpdateStudyTagsRequest
) => {
  return http.put(`v1/report/${studyID}`, request);
};

export interface UpdateStudiesTagsRequest {
  study_ids: string[];
  query?: SearchRequestQuery;
  updates: UpdateStudyTagsRequest;
}

export const updateStudiesTags = (
  http: AxiosInstance,
  request: UpdateStudiesTagsRequest
) => {
  return http.put('v1/reports/tags', request);
};

export const emptyTag: Tag = {
  tid: 0,
  name: '',
  scope: 'user',
  description: '',
  backgroundColor: '',
  fontColor: '',
  favorite: false,
};

export interface StudyTag {
  studyID: string;
  tags?: number[];
  assignedDatasets?: number[];
}

export interface StudiesTagsResp {
  StudyID: string;
  Tags?: number[];
  AssignedDatasets?: number[];
}

export const responseToStudiesTags = (
  studiesTagsResp: StudiesTagsResp
): StudyTag => {
  return {
    studyID: studiesTagsResp.StudyID,
    tags: studiesTagsResp.Tags,
    assignedDatasets: studiesTagsResp.AssignedDatasets,
  };
};

export const getStudiesTags = (
  http: AxiosInstance,
  studyIDs: string[]
): Promise<StudyTag[]> => {
  return http
    .post('v1/reports/tags', {
      study_ids: studyIDs,
    })
    .then((response: AxiosResponse<StudiesTagsResp[]>) => {
      const {data} = response;
      return (data ?? []).map(studyResp => responseToStudiesTags(studyResp));
    });
};

export const getMatchedTags = (
  studyID: string,
  studyTags?: StudyTag[],
  datasets?: Dataset[],
  userTags?: Tag[]
) => {
  if (!studyTags) {
    return [];
  }
  const studyTag = studyTags.find(studyTag => studyTag.studyID === studyID);
  let tags: Tag[] = [];

  if (studyTag) {
    tags =
      userTags
        ?.filter(userTag => studyTag.tags?.includes(userTag.tid))
        .sort((a, b) => (a.name < b.name ? -1 : 1)) ?? [];

    const datasetTags =
      datasets
        ?.filter(dataset => studyTag.assignedDatasets?.includes(dataset.id))
        .map(dataset => datasetToDatasetTag(dataset)) ?? [];

    tags = [...tags, ...datasetTags];
  }
  return tags;
};

export const validateTagName = (name: string) => {
  const tagLengthLimit = 75;
  const tagNameRegex = new RegExp('^[ a-zA-Z0-9._-]*$');
  if (!tagNameRegex.test(name)) {
    return 'Invalid characters in tag name';
  } else if (name.length > tagLengthLimit) {
    return `Maximum tag name length of ${tagLengthLimit} characters`;
  }
  return '';
};

export const updateCachedTags = (
  http: AxiosInstance,
  queryClient: QueryClient,
  studyIDs: string[]
) => {
  // Refetch study tags for updated studies
  _.chunk(studyIDs, studyTagsBatchSize).forEach(chunkedStudyIDs => {
    getStudiesTags(http, chunkedStudyIDs).then((studyTags: StudyTag[]) => {
      studyTags.forEach(studyTag =>
        queryClient.setQueryData(['report', studyTag.studyID, 'tag'], studyTag)
      );
    });
  });
};

// Number of studies to fetch tags for in single query
export const studyTagsBatchSize = 1000;
