import {useEffect, useState, useCallback, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';

import {ToastMessage} from '../../../core/components/toast';
import {Loading} from '../../../core/components/loading';
import {ApplicationShell} from '../../../core/layout/application-shell';
let currentImageIndex = 0;

export const SyntheticDisplay = () => {
  const [image, setCurrentImage] = useState('');
  const [image2, setImage2] = useState('');
  const [creditCounter, setCreditCounter] = useState(null);

  const isLoading = useRef(false);

  const handleNextImage = useCallback(async () => {
    isLoading.current = true;
    const response = await fetch('/v1/synthetic/dirs/01_chest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({image_index: currentImageIndex}),
    });
    isLoading.current = false;
    const data = await response.json();
    if (data.credit_counter < 0) {
      toast(
        <ToastMessage
          title="Credit limit reached. Please ask for more credits"
          icon="error"
        />
      );
    } else if (data.base64 && data.image2_base64) {
      setCurrentImage(`${data.base64}`);
      setImage2(`${data.image2_base64}`);

      setCreditCounter(data.credit_counter);
      currentImageIndex = currentImageIndex + 1;
    }
  }, [setCurrentImage, setImage2]);

  useEffect(() => {
    handleNextImage();
  }, [handleNextImage]);

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Synthetic Display</title>
      </Helmet>

      <ApplicationShell bgcolor="bgcolor">
        <h1 className="text-2xl leading-7 font-bold text-gray-900">
          {' '}
          Synthetic Data
        </h1>
        <br />
        <label>Credits Counter: {creditCounter}</label>
        <div style={{position: 'relative', width: '800px', height: '340px'}}>
          {isLoading.current ? (
            <Loading text="Loading Synthetic Image..." />
          ) : (
            <>
              <img className="mx-auto h-auto w-80 float-left" src={image} />
              <img
                className="image2 mx-auto h-auto w-80 float-right"
                src={image2}
              />

              <br />
            </>
          )}
          <br />
          <br />
          {creditCounter === 0 && (
            <h2>
              Email us at{' '}
              <a href="mailto:support@segmed.ai" className="link">
                support@segmed.ai
              </a>{' '}
              for more credits
            </h2>
          )}
        </div>
        <div style={{position: 'relative', width: '800px', height: '140px'}}>
          <label className="float-left">Segmed Chest XRay</label>
          <label className="float-right">Nvidia Ultrasound</label>
          <br />
          <button
            disabled={creditCounter === 0}
            className="btn btn-primary float-right mt-3 mb-3"
            onClick={handleNextImage}
          >
            Next (1 credit)
          </button>
        </div>
      </ApplicationShell>
    </>
  );
};
