export interface Cart {
  items: CartItem[];
  subtotal: number;
  numStudies: number;
  discounts: CartDiscount[];
  total: number;
}

export interface CartItem {
  quantity: number;
  description: string;
  unitPrice: number;
  total: number;
}

export interface CartDiscount {
  description: string;
  total: number;
}

export interface CartResponse {
  items: CartItemResponse[];
  subtotal: number;
  num_studies: number;
  discounts: CartDiscountResponse[];
  total: number;
}

export interface CartItemResponse {
  quantity: number;
  description: string;
  unit_price: number;
  total: number;
}

export interface CartDiscountResponse {
  description: string;
  total: number;
}

export const responseToCart = (cartResp: CartResponse) => {
  const cart: Cart = {
    items: cartResp.items.map(cartItemResp => responseToCartItem(cartItemResp)),
    subtotal: cartResp.subtotal,
    numStudies: cartResp.num_studies,
    discounts: cartResp.discounts.map(cartDiscountResp =>
      responseToCartDiscount(cartDiscountResp)
    ),
    total: cartResp.total,
  };
  return cart;
};

export const responseToCartItem = (cartItemResp: CartItemResponse) => {
  const cartItem: CartItem = {
    quantity: cartItemResp.quantity,
    description: cartItemResp.description,
    unitPrice: cartItemResp.unit_price,
    total: cartItemResp.total,
  };
  return cartItem;
};

export const responseToCartDiscount = (
  cartDiscountResp: CartDiscountResponse
) => {
  const cartDiscount: CartDiscount = {
    description: cartDiscountResp.description,
    total: cartDiscountResp.total,
  };
  return cartDiscount;
};
