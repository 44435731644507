import {ReactNode, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {HiOutlineCheck} from 'react-icons/hi';

import {Modal} from '../../core/layout/modal';
import {Dataset, requestDatasetMetadata} from '../../models/dataset';
import {trackEvent} from '../../utils/tracking';
import {useAxios} from 'src/utils/http';

export const DatasetRequestMetadataModal = ({
  datasetId,
  numStudies,
  onRequestClose,
  onSuccess,
  isOpen = true,
}: {
  datasetId: number;
  numStudies: number;
  onRequestClose?: () => void;
  isOpen?: boolean;
  onSuccess?: (dataset: Dataset) => void;
}) => {
  const queryClient = useQueryClient();
  const http = useAxios();

  const requestDatasetMetadataMut = useMutation(
    ({datasetId}: {datasetId: number}) =>
      requestDatasetMetadata(http, datasetId),
    {
      onSuccess: dataset => {
        queryClient.invalidateQueries(['dataset'], {exact: true});
        queryClient.invalidateQueries(['dataset', dataset.id]);

        onSuccess && onSuccess(dataset);
      },
    }
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="max-w-lg">
      <div className="flex flex-col gap-y-4">
        <div className="text-lg mb-3">Request metadata &amp; sample DICOMs</div>
        <div className="text-sm text-gray-500 mb-1">
          You are about to request metadata for{' '}
          {numStudies.toLocaleString('en-US')} DICOMs and{' '}
          {Math.min(numStudies, 5).toLocaleString('en-US')} DICOM samples.
          <span className="text-sm text-red-600">
            {' '}
            Once you submit the request, you will no longer be able to add more
            studies to this dataset. We recommend requesting ~4x the number of
            studies you need.
          </span>
        </div>

        <div className="text-sm text-gray-500 mb-3">
          In about 14 days, you can expect to see the metadata imported into the
          dataset, and we will send you a link to download the DICOM samples.
        </div>

        <div className="flex flex-row-reverse gap-x-3">
          <button
            className="btn btn-primary"
            data-cy="confirmRequestMetadata"
            onClick={() => {
              requestDatasetMetadataMut.mutate({datasetId});
            }}
            disabled={requestDatasetMetadataMut.isLoading}
          >
            Confirm Request
          </button>
          <button
            className="btn btn-white"
            data-cy="cancelRequestMetadata"
            onClick={() => onRequestClose && onRequestClose()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const DatasetRequestMetadataConfirmedModal = ({
  onRequestClose,
  isOpen = true,
}: {
  onRequestClose?: () => void;
  isOpen?: boolean;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      hideExitButton
      className="max-w-sm"
    >
      <div className="flex flex-col gap-y-2">
        <div className="mb-3 self-center rounded-full bg-emerald-100 w-12 h-12 flex items-center justify-center">
          <HiOutlineCheck className="w-6 h-6 text-emerald-600" />
        </div>
        <div className="text-lg self-center">Request confirmed</div>
        <div className="text-sm text-center text-gray-500 mb-4">
          A confirmation has been emailed to you. You will receive another email
          when the metadata and sample DICOMs are ready to view.
        </div>
        <div className="flex flex-row-reverse">
          <button
            data-cy="returnAfterSuccessfulMetadataRequest"
            className="btn btn-primary w-full"
            onClick={() => onRequestClose && onRequestClose()}
          >
            Return
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const DatasetRequestMetadataButton = ({
  datasetId,
  numStudies,
  className = 'btn btn-primary',
  children = 'Request metadata & samples',
  onClick,
  disabled,
  onSuccess,
}: {
  datasetId: number;
  numStudies: number;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  onSuccess?: (dataset: Dataset) => void;
}) => {
  const [requestModalOpen, requestModalOpenChange] = useState(false);

  return (
    <>
      <button
        className={className}
        disabled={disabled}
        data-cy="requestMetadataButton"
        onClick={() => {
          requestModalOpenChange(true);

          trackEvent('CLICK_REQUEST_DICOM_BTN', {
            datasetId,
          });

          onClick && onClick();
        }}
      >
        {children}
      </button>

      <DatasetRequestMetadataModal
        datasetId={datasetId}
        numStudies={numStudies}
        isOpen={requestModalOpen}
        onRequestClose={() => requestModalOpenChange(false)}
        onSuccess={dataset => {
          requestModalOpenChange(false);
          onSuccess && onSuccess(dataset);
        }}
      />
    </>
  );
};
