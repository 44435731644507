import {useState, useEffect, useRef, ReactText, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import {useQuery, useQueryClient} from 'react-query';
import {SearchIcon} from '@heroicons/react/solid';
import {HiOutlineArrowRight} from 'react-icons/hi';

import {Loading} from '../../../core/components/loading';
import {
  emptyPatientSearchQuery,
  SearchForm,
} from '../../../components/search-form';
import {
  useReportSelection,
  ReportSelectionEventType,
  isAnyReportSelected,
} from '../../../hooks/report-selection-provider';
import {Pagination} from '../../../core/components/pagination';
import {
  useURLQuery,
  decodeURLObject,
  encodeURLObject,
} from '../../../utils/router-helper';
import {Dropdown} from '../../../core/components/dropdown';
import {trackEvent} from '../../../utils/tracking';
import {Modal} from '../../../core/layout/modal';
import {quickToast, ToastMessage} from '../../../core/components/toast';
import {
  SearchQuery,
  fetchPatientSearchResults,
  PatientSearchPatient,
} from '../../../models/search';
import {Report} from '../../../models/report';
import {SearchResultsTour} from '../../../core/components/tour/search-results-tour';
import {getAvailableTags} from '../../../models/tags';
import {TagDropdown} from '../../../components/tags';
import {ApplicationShell} from '../../../core/layout/application-shell';
import {CompactSearchQuery} from '../../../components/search-form';
import {Card} from '../../../core/layout/card';
import {formatNumber} from '../../../utils/strings';
import {SearchResultsNotFoundTips} from '../../../components/search-results/search-results-not-found-tips';
import {PatientSearchResultsTable} from '../../../components/search-results/patient-search-results-table';
import {handleLegacySearchQuery} from '../../../utils/search-helper';
import {AddToDatasetDropdown} from '../../../components/dataset';
import {useAuth} from '../../../hooks/auth';
import {useAxios} from 'src/utils/http';

export const resultsPerPage = 100;

export const patientSearchResultToReports = (
  patientSearch: PatientSearchPatient[]
): Report[] => {
  return _.flatMap(patientSearch, p =>
    _.concat(p.studies, p.additionalStudies)
  );
};

export const PatientSearchResultsPage = () => {
  const {authState} = useAuth();
  const navigate = useNavigate();
  const urlQuery = useURLQuery();
  const api = useAxios();
  const queryClient = useQueryClient();

  const [searchForm, searchFormChange] = useState<SearchQuery>(
    emptyPatientSearchQuery
  );
  const [searchFormModalOpen, searchFormModalOpenChange] =
    useState<boolean>(false);
  const [searchQuery, searchQueryChange] = useState<SearchQuery>();
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const [selectDropdownOpened, selectDropdownOpenedChange] = useState(false);
  const demoToast = useRef<ReactText>();
  const [demoToastCount, demoToastCountChange] = useState(2); // # of times left until toast shown, -1 for dismissed
  const [resultsTourActive, resultsTourActiveChange] = useState(false);

  const studiesSelected = isAnyReportSelected(reportSelectionState);

  // Update if URL updated
  useEffect(() => {
    const queryFromUrl = handleLegacySearchQuery(
      decodeURLObject(urlQuery.q ?? undefined)
    );
    if (!_.isEqual(searchQuery, queryFromUrl)) {
      searchQueryChange(queryFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery]);

  const page: number =
    _.toInteger(urlQuery.page) > 0 ? _.toInteger(urlQuery.page) : 1;

  const {data: tags, isLoading: tagsLoading} = useQuery(
    ['tags'],
    () => getAvailableTags(api),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const userTags = useMemo(() => {
    if (tags) {
      return tags.filter(tag => tag.scope === 'user');
    }
    return [];
  }, [tags]);

  const {
    data: result,
    isLoading: resultIsLoading,
    isError: resultIsError,
  } = useQuery(
    ['patient_search', JSON.stringify(searchQuery), page],
    () => fetchPatientSearchResults(api, searchQuery!, page),
    {
      staleTime: Infinity,
      enabled: !_.isNil(searchQuery),
      onSuccess: data => {
        if (data.totalStudies < 100 && demoToastCount >= 0) {
          let leftUntilToast = demoToastCount;
          if (leftUntilToast > 0) {
            leftUntilToast--;
            demoToastCountChange(leftUntilToast);
          }
          if (leftUntilToast === 0) {
            demoToast.current = quickToast({
              title: 'Not enough results?',
              children: (
                <>
                  <span>
                    We might be able to give you some tips to optimize your
                    search
                  </span>
                  <div className="mt-2 space-x-2">
                    <a
                      href="https://calendly.com/alinelutz/30min"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-primary inline-block"
                    >
                      Schedule a demo
                    </a>

                    <button
                      className="btn btn-sm btn-white inline-block"
                      onClick={() => {
                        demoToastCountChange(-1);
                        toast.dismiss(demoToast.current);
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </>
              ),
            });
          }
        }
      },
      retry: (failureCount, err) => {
        const {response} = err;
        const {status} = response;
        if ([400, 401, 422].includes(status)) {
          return false;
        }
        return failureCount <= 2;
      },
      onError: (err: never) => {
        const {response} = err;
        const {status, data} = response || {};

        let errMessage = 'Something went wrong, please try again later';
        let errBody = <></>;
        switch (status) {
          case 400:
          case 401:
            errMessage = (data as {message: string})?.message ?? errMessage;
            break;
          case 422:
            errMessage = 'Invalid search link';
            errBody = (
              <>
                Please conduct a{' '}
                <Link
                  to={{
                    pathname: '/',
                  }}
                  className="text-primary hover:text-primary-active"
                >
                  new search
                </Link>
              </>
            );
            break;
          case 504:
            errMessage =
              'Search timed out. Please refine your search and try again';
            break;
        }
        toast(
          <ToastMessage title={errMessage} icon="error">
            {errBody}
          </ToastMessage>
        );
      },
    }
  );

  const reports = patientSearchResultToReports(result?.searchResults ?? []);

  const allSelected = !!reportSelectionState.add.query;
  const pageSelected = allSelected
    ? !_.some(reports, report =>
        reportSelectionState.remove.ids.has(report.studyId)
      )
    : _.every(reports, report =>
        reportSelectionState.add.ids.has(report.studyId)
      );

  const pageSomeSelected = pageSelected
    ? false
    : allSelected
    ? !_.every(reports, report =>
        reportSelectionState.remove.ids.has(report.studyId)
      )
    : _.some(reports, report =>
        reportSelectionState.add.ids.has(report.studyId)
      );

  // Parse query from URL after loading
  useEffect(() => {
    if (!_.isNil(searchQuery)) {
      searchFormChange(_.cloneDeep(searchQuery));
    }
  }, [searchQuery]);

  useEffect(() => {
    // Prefetch previous/next pages
    if (!_.isNil(result)) {
      if (page < result.totalPages) {
        queryClient.prefetchQuery(
          ['patient_search', JSON.stringify(searchQuery), page + 1],
          () => fetchPatientSearchResults(api, searchQuery!, page + 1),
          {staleTime: Infinity}
        );
      }

      if (page > 1) {
        queryClient.prefetchQuery(
          ['patient_search', JSON.stringify(searchQuery), page - 1],
          () => fetchPatientSearchResults(api, searchQuery!, page - 1),
          {staleTime: Infinity}
        );
      }
    }

    // Reset report selection if dataset changed
    if (
      _.isNil(reportSelectionState.id) ||
      reportSelectionState.id !== JSON.stringify(searchQuery)
    ) {
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {id: JSON.stringify(searchQuery)},
      });
    }
  }, [
    navigate,
    page,
    queryClient,
    reportSelectionDispatch,
    reportSelectionState.id,
    result,
    searchForm,
    searchQuery,
  ]);

  const numSelected =
    result && allSelected
      ? result.totalStudies +
        result.totalAdditionalStudies -
        (reportSelectionState.remove.ids.size || 0)
      : reportSelectionState.add.ids.size || 0;

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Search Results</title>
      </Helmet>

      <ApplicationShell
        bgcolor="white"
        contained={false}
        navbar={
          <div className="navbar">
            {searchQuery && (
              <button
                onClick={() => searchFormModalOpenChange(true)}
                className="text-input items-center w-1/2 overflow-x-scroll text-left whitespace-nowrap"
                data-cy="navbar__CompactSearchQuery__editSearchBtn"
              >
                <SearchIcon className="text-gray-400 w-6 h-6 mr-2 inline-block" />
                <CompactSearchQuery
                  searchQuery={searchQuery}
                  className="whitespace-nowrap"
                />
              </button>
            )}
            <div className="ml-auto text-sm text-gray-700">
              Having trouble finding data?
              <a
                className="link ml-1"
                href="https://calendly.com/d/dr3-5n8-mjd/segmed-demo-request"
                target="_blank"
                rel="noreferrer"
              >
                Schedule a help session
                <HiOutlineArrowRight className="inline-block ml-1" />
              </a>
            </div>
          </div>
        }
        noPadding
      >
        {searchQuery && (
          <div>
            {resultIsLoading ? (
              <Loading />
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 w-full gap-x-4 gap-y-4 p-6 bg-bgcolor border-b">
                  <Card>
                    <div className="text-gray-500 text-xs uppercase mb-2">
                      Results Count
                    </div>
                    <div className="grid grid-cols-2">
                      <div>
                        <span className="text-2xl font-semibold">
                          {_.isNumber(result?.totalStudies) &&
                            _.isNumber(result?.totalAdditionalStudies) &&
                            formatNumber(
                              result!.totalStudies +
                                result!.totalAdditionalStudies
                            )}
                        </span>{' '}
                        <span className="text-gray-500 text-sm">studies</span>
                      </div>
                      <div className="gap-x-2">
                        <span className="text-2xl font-semibold">
                          {formatNumber(result?.totalPatients)}
                        </span>{' '}
                        <span className="text-gray-500 text-sm">patients</span>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div className="text-gray-500 text-xs uppercase mb-2">
                      Additional Patient Studies
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={searchQuery.additionalPatientStudies}
                        id="additionalPatientStudies"
                        name="additionalPatientStudies"
                        className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        onChange={e => {
                          const checked = e.target.checked;
                          trackEvent(
                            checked
                              ? 'SEARCH_SELECT_ADDITIONAL_STUDIES_TOGGLE'
                              : 'SEARCH_UNSELECT_ADDITIONAL_STUDIES_TOGGLE'
                          );
                          if (
                            searchQuery.additionalPatientStudies !== checked
                          ) {
                            navigate({
                              pathname: '/patient_search/results',
                              search: queryString.stringify({
                                q: encodeURLObject({
                                  ...searchQuery,
                                  additionalPatientStudies: checked,
                                }),
                                page: page,
                              }),
                            });
                          }
                        }}
                      />
                      <label
                        htmlFor="additionalPatientStudies"
                        className="ml-2 text-gray-500 text-xs select-none"
                      >
                        Show more patient studies with the queried modality and
                        body area, but not the keywords. These are indicated
                        with <span className="text-yellow-500">•</span>
                      </label>
                    </div>
                  </Card>

                  <Card>
                    <div className="text-gray-500 text-xs uppercase mb-2">
                      {"Where's the rest of the metadata?"}
                    </div>
                    <div className="text-gray-500 text-xs">
                      Remaining metadata and image samples can be requested once
                      a dataset is created.
                    </div>
                  </Card>
                </div>

                <div>
                  <div className="flex justify-between items-center my-4 px-6">
                    <div className="space-x-3 flex items-center">
                      {result &&
                        patientSearchResultToReports(result.searchResults)
                          .length > 0 && (
                          <>
                            <input
                              type="checkbox"
                              data-tour="results-select-all"
                              className="checkbox-input"
                              checked={pageSelected}
                              ref={el =>
                                el && (el.indeterminate = pageSomeSelected)
                              }
                              data-cy="Results_selectAllInput"
                              onChange={e => {
                                const checked = e.target.checked;

                                trackEvent(
                                  checked
                                    ? 'REPORT_SELECT_PAGE'
                                    : 'REPORT_UNSELECT_PAGE'
                                );

                                reportSelectionDispatch({
                                  type: e.target.checked
                                    ? ReportSelectionEventType.SELECT
                                    : ReportSelectionEventType.UNSELECT,
                                  payload: _.map(
                                    patientSearchResultToReports(
                                      result.searchResults
                                    ),
                                    report => report.studyId
                                  ),
                                });
                              }}
                            />

                            <div className="inline-block">
                              <Dropdown
                                text={
                                  numSelected > 0
                                    ? `${numSelected} Studies Selected`
                                    : 'Select'
                                }
                                buttonStyle="btn-link"
                                buttonClassName="btn-xs"
                                open={selectDropdownOpened}
                                onToggle={opened => {
                                  selectDropdownOpenedChange(opened);
                                }}
                              >
                                <div className="w-48 rounded-md py-1 bg-white z-30 max-h-72">
                                  <button
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer w-full text-left"
                                    onClick={() => {
                                      selectDropdownOpenedChange(false);

                                      trackEvent(
                                        allSelected
                                          ? 'REPORT_UNSELECT_ALL'
                                          : 'REPORT_SELECT_ALL'
                                      );

                                      reportSelectionDispatch({
                                        type: allSelected
                                          ? ReportSelectionEventType.UNSELECT_ALL
                                          : ReportSelectionEventType.SELECT_ALL,
                                        payload: searchQuery,
                                      });
                                    }}
                                  >
                                    All{' '}
                                    {result.totalStudies +
                                      result.totalAdditionalStudies}{' '}
                                    results
                                  </button>
                                  <button
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer w-full text-left"
                                    onClick={() => {
                                      selectDropdownOpenedChange(false);

                                      trackEvent(
                                        !pageSelected
                                          ? 'REPORT_SELECT_PAGE'
                                          : 'REPORT_UNSELECT_PAGE'
                                      );

                                      reportSelectionDispatch({
                                        type: !pageSelected
                                          ? ReportSelectionEventType.SELECT
                                          : ReportSelectionEventType.UNSELECT,
                                        payload: _.map(
                                          patientSearchResultToReports(
                                            result.searchResults
                                          ),
                                          report => report.studyId
                                        ),
                                      });
                                    }}
                                  >
                                    {
                                      patientSearchResultToReports(
                                        result.searchResults
                                      ).length
                                    }{' '}
                                    results on page
                                  </button>
                                </div>
                              </Dropdown>
                            </div>
                            <SearchResultsTour
                              tourActive={resultsTourActive}
                              tourActiveChange={resultsTourActiveChange}
                            />
                          </>
                        )}
                      <div
                        className="flex flex-row gap-x-4"
                        data-cy="Results_datasetsDropdown"
                      >
                        <AddToDatasetDropdown
                          disabled={!studiesSelected}
                          reportSelectionState={reportSelectionState}
                        />
                        <TagDropdown
                          tags={userTags}
                          studyIDs={Array.from(reportSelectionState.add.ids)}
                          query={reportSelectionState.add.query}
                          align="left"
                          disabled={!studiesSelected || tagsLoading}
                        />
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/datasets"
                        data-tour="results-view-datasets"
                        className="btn btn-xs btn-white"
                        onClick={() => trackEvent('CLICK_VIEW_DATASETS_BTN')}
                      >
                        View Datasets
                      </Link>
                    </div>
                  </div>

                  {result &&
                  patientSearchResultToReports(result.searchResults).length >
                    0 ? (
                    <>
                      <PatientSearchResultsTable
                        searchResult={result}
                        searchQuery={searchQuery}
                        allSelected={allSelected}
                      />

                      <Pagination
                        max={result.totalPages || 1}
                        current={result.page}
                        linkFunc={num => {
                          trackEvent('SEARCH_PAGE_CHANGE', {
                            searchQuery,
                            page: num,
                          });
                          navigate({
                            pathname: '/patient_search/results',
                            search: queryString.stringify({
                              q: encodeURLObject(searchQuery),
                              page: num,
                            }),
                          });
                        }}
                      />
                    </>
                  ) : (
                    <div className="px-6">
                      <SearchResultsNotFoundTips />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </ApplicationShell>

      <Modal
        isOpen={searchFormModalOpen}
        onRequestClose={() => {
          searchFormModalOpenChange(false);
          searchFormChange(searchQuery!);
        }}
        className="w-screen max-h-full overflow-y-scroll"
        shouldCloseOnOverlayClick={false}
      >
        <SearchForm
          query={searchForm}
          queryChange={q => searchFormChange(q)}
          searchDisabled={_.isEqual(searchForm, searchQuery)}
          onSearch={query => {
            searchFormModalOpenChange(false);
            navigate({
              pathname: '/patient_search/results',
              search: queryString.stringify({
                q: encodeURLObject(query),
              }),
            });
            return false;
          }}
          compactable={!resultIsError}
          isPatientSearch
          patientFilter={authState.profile?.admin}
        />
      </Modal>
    </>
  );
};
