import {useAuth0} from '@auth0/auth0-react';
import {Helmet} from 'react-helmet';

export const KillSwitchPage = () => {
  const {logout} = useAuth0();

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Maintenance</title>
      </Helmet>

      <div>
        <button
          onClick={() => {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }}
          className="float float-right btn btn-sm btn-white m-5"
        >
          Log out
        </button>
        <div className="flex items-stretch min-h-screen">
          <div className="w-full flex justify-center items-center">
            <div className="text-4xl font-medium">
              Openda is currently under maintenance. Please try again later
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
