/* eslint-disable security/detect-object-injection */
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {DateTime} from 'luxon';
import {FileRejection, useDropzone} from 'react-dropzone';
import {HiOutlineTrash, HiOutlineXCircle} from 'react-icons/hi';
import {CancelTokenSource} from 'axios';
import {toast} from 'react-toastify';
import {useQuery} from 'react-query';
import {Modal} from '../../core/layout/modal';

import {s3} from '../../utils/s3';
import {cancelToken} from '../../utils/cancelToken';
import {formatBytes} from '../../utils/strings';
import {
  updateUploadedFileStatus,
  deleteUploadedFile,
  FileUploadLocation,
  getPresignedUrls,
  PresignedURLPart,
  FileUploadPresignedUrl,
  FileUploadCompletedPart,
  completeMultipartUpload,
  abortMultipartUpload,
} from '../../models/fileUpload';
import {ToastMessage} from '../../core/components/toast';
import {useAxios} from 'src/utils/http';

export const FileUpload = () => {
  interface FileState {
    uflId?: number;
    name: string;
    fileType?: string;
    size: number;
    uploadID?: string;
    completedParts?: FileUploadCompletedPart[];
    status: 'ongoing' | 'uploaded' | 'deleted';
    uploadedAt?: DateTime;
    cancelToken?: CancelTokenSource;
    error?: string;
    progress?: Record<number, number>;
  }

  //file extension types
  interface fileTypeMap {
    [key: string]: string;
  }

  const [files, setFiles] = useState<FileState[]>([]);
  const [deleteModalOpen, deleteModalOpenChange] = useState(false);
  const [modalFile, modalFileChange] = useState<[FileState, number]>();
  const http = useAxios();

  const {data: pastUploadedFiles} = useQuery(
    ['pastUploadedFiles'],
    () =>
      updateUploadedFileStatus(http).then(fileUploadLocations => {
        const uploadedFiles: FileState[] = fileUploadLocations.map(
          (fileUploadLocation: FileUploadLocation) => {
            return {
              uflId: fileUploadLocation.uflId,
              name: fileUploadLocation.filename,
              fileType: getFileType(fileUploadLocation.filename),
              size: fileUploadLocation.size,
              status: 'uploaded',
              uploadedAt: fileUploadLocation.updatedAt,
            };
          }
        );
        return uploadedFiles;
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    if (pastUploadedFiles) {
      setFiles([...pastUploadedFiles]);
    }
  }, [pastUploadedFiles]);

  //helper functions

  const openDeleteModal = (file: FileState, fileIndex: number) => {
    modalFileChange([file, fileIndex]);
    deleteModalOpenChange(true);
  };

  const closeDeleteModal = () => {
    deleteModalOpenChange(false);
    modalFileChange(undefined);
  };

  const renderDeleteModal = (modalFile: [FileState, number]) => {
    return (
      <div>
        <div className="text-lg mb-3">Delete File</div>
        <div className="text-sm text-gray-500 mb-3">
          This file will be permanently deleted.
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteFile(modalFile[0], modalFile[1]);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const allowedFileTypes: fileTypeMap = {
    dicom: 'DICOM',
    pdf: 'PDF',
    txt: 'TXT',
    text: 'TEXT',
    rtf: 'RTF',
    zip: 'ZIP',
    csv: 'CSV',
    dcm: 'DCM',
    docm: 'DOCM',
    docx: 'DOCX',
    doc: 'DOC',
    xls: 'XLS',
    xlsm: 'XLSM',
    xlsb: 'XLSB',
    xlsx: 'XLSX',
    pptx: 'PPTX',
    pptm: 'PPTM',
    ppt: 'PPT',
    img: 'IMG',
    png: 'PNG',
    jpg: 'JPG',
    jpeg: 'JPEG',
    tif: 'TIF',
    tiff: 'TIFF',
    bmp: 'BMP',
    pages: 'PAGES',
    numbers: 'NUMBERS',
  };

  const allowedFileExtensions = Object.keys(allowedFileTypes)
    .map(ext => `.${ext}`)
    .join(',');

  const getFileType = (fileName: string) => {
    const fileExt = getFileExt(fileName);
    return allowedFileTypes[fileExt];
  };

  const getFileExt = (fileName: string) => {
    return fileName.split('.').pop()?.toLowerCase() ?? '';
  };

  const safeFileName = (fileName: string) => {
    return fileName
      .toLowerCase()
      .trim()
      .replace(/[!@#$%*^()\]]|"<>]/g, '')
      .replace(/[ &_=+:()\]]|"<>]/g, '-')
      .replace(/[--]+/g, '-');
  };

  const getStatus = (file: FileState) => {
    if (file.error) {
      return file.error;
    } else if (file.status === 'uploaded') {
      // Format dateTime as time if recent, date if older than 12 hours
      return `uploaded ${
        Math.abs(file.uploadedAt!.diff(DateTime.now(), 'hours').hours) < 12
          ? file.uploadedAt?.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
          : file.uploadedAt?.toLocaleString(DateTime.DATE_MED)
      }`;
    } else if (file.status === 'ongoing') {
      return (
        (file.progress
          ? Math.floor((_.sum(Object.values(file.progress)) * 100) / file.size)
          : 0) + '%'
      );
    } else {
      return 'calculating';
    }
  };

  const cancelUpload = (fileIndex: number) => {
    const newFiles = [...files];

    newFiles[fileIndex].error = 'upload cancelled';
    newFiles[fileIndex].cancelToken!.cancel('upload cancelled');

    // abort multipart
    if (newFiles[fileIndex].uploadID) {
      abortMultipartUpload(http, newFiles[fileIndex].uploadID!);
    }

    setFiles([...newFiles]);
  };

  const deleteFile = (file: FileState, fileIndex: number) => {
    const newFiles = files;

    if (file.uflId) {
      const index = files.findIndex(fileState => {
        return fileState.uflId === file.uflId;
      });

      deleteUploadedFile(http, [file.uflId])
        .then(() => {
          newFiles[index].status = 'deleted';
          setFiles([...newFiles]);
          toast(
            <ToastMessage title="File successfully deleted" icon="success" />
          );
        })
        .catch(() => {
          //set error state
          newFiles[index].error = 'error deleting file';
          setFiles([...newFiles]);
          toast(<ToastMessage title="Error deleting file" icon="error" />);
        });
    } else {
      // if file is not uploaded delete from frontend
      newFiles[fileIndex].status = 'deleted';
      setFiles([...newFiles]);
      toast(<ToastMessage title="File successfully deleted" icon="success" />);
    }

    closeDeleteModal();
  };

  const renderCancelButton = (fileIndex: number) => {
    return (
      <div className="justify-self-end text-gray-500 flex-row flex">
        <button className="bg-white p-1 rounded-full hover:text-gray-700 focus:outline-none">
          <HiOutlineXCircle
            className="h-6 w-6 hover:text-gray-700"
            onMouseDown={() => cancelUpload(fileIndex)}
          />
        </button>
      </div>
    );
  };

  const renderDeleteButton = (file: FileState, fileIndex: number) => {
    return (
      <button className="bg-white p-1 rounded-full hover:text-gray-700 focus:outline-none">
        <HiOutlineTrash
          className="h-6 w-6"
          onMouseDown={() => {
            openDeleteModal(file, fileIndex);
          }}
        />
      </button>
    );
  };

  const renderDisplayButtons = (file: FileState, fileIndex: number) => {
    if (file.error) {
      return renderDeleteButton(file, fileIndex);
    } else if (file.status === 'ongoing') {
      return renderCancelButton(fileIndex);
    } else {
      return renderDeleteButton(file, fileIndex);
    }
  };

  const handleUploadProgress = (
    event: ProgressEvent,
    fileIndex: number,
    partNum?: number
  ) => {
    const bytesComplete = event.loaded;

    //update state with progress
    setFiles(latestFilesState => {
      const newFiles = [...latestFilesState];

      if (!newFiles[fileIndex].progress) {
        newFiles[fileIndex].progress = {};
      }

      if (
        newFiles[fileIndex].progress &&
        newFiles[fileIndex].progress![partNum ?? 0] !== event.total
      ) {
        newFiles[fileIndex].progress![partNum ?? 0] = bytesComplete;
        return [...newFiles];
      }

      return latestFilesState;
    });
  };

  //function to upload a file with single part
  const uploadFile = ({
    presignedUrl,
    uflId,
    file,
    cancelToken,
    fileIndex,
  }: {
    presignedUrl: string;
    uflId: number;
    file: File;
    cancelToken?: CancelTokenSource;
    fileIndex: number;
  }) => {
    //upload to bucket using presigned url
    s3.put(presignedUrl, file, {
      cancelToken: cancelToken?.token,

      onUploadProgress: _.throttle(
        event => handleUploadProgress(event, fileIndex),
        100,
        {leading: true, trailing: true}
      ),
    })
      .then(() => {
        //set uploaded state based on response
        setFiles((latestFilesState: FileState[]) => {
          const newFiles = latestFilesState;

          newFiles[fileIndex].status = 'uploaded';
          newFiles[fileIndex].uflId = uflId;
          newFiles[fileIndex].uploadedAt = DateTime.now();

          return [...newFiles];
        });
        // update status in backend table
        updateUploadedFileStatus(http);
        toast(
          <ToastMessage
            title="File uploaded successfully. We will review the uploaded files and get back to you within 5 business days."
            icon="success"
          />
        );
      })
      .catch(err => {
        if (err.message !== 'upload cancelled') {
          const message =
            err?.response?.data?.message ?? 'Error uploading files';
          toast(<ToastMessage title={message} icon="error" />);
        }
        setFiles((latestFilesState: FileState[]) => {
          const newFiles = latestFilesState;

          //only update state if error is different than cancelled
          if (latestFilesState[fileIndex].error !== 'upload cancelled') {
            newFiles[fileIndex].error = 'upload error';

            return [...newFiles];
          }
          return latestFilesState;
        });
      });
  };

  //function to upload file with multipart
  const uploadFileMultipart = ({
    presignedUrlParts,
    uflId,
    file,
    cancelToken,
    fileIndex,
  }: {
    presignedUrlParts: PresignedURLPart[];
    uflId: number;
    file: File;
    cancelToken?: CancelTokenSource;
    fileIndex: number;
  }) => {
    let start = 0;
    for (let i = 0; i < presignedUrlParts.length; i++) {
      //grab a chunk
      const presignedPart = presignedUrlParts[i];
      const end = start + presignedPart.partSize;
      let fileChunk = file.slice(start, end);
      if (i === presignedUrlParts.length - 1) {
        fileChunk = file.slice(start);
      }

      setFiles(latestFilesState => {
        const newFiles = [...latestFilesState];

        if (newFiles[fileIndex].uploadID !== presignedPart.uploadID) {
          newFiles[fileIndex].uploadID = presignedPart.uploadID;

          return [...newFiles];
        }
        return latestFilesState;
      });

      // upload chunk
      s3.put(presignedPart.presignedURL, fileChunk, {
        cancelToken: cancelToken?.token,

        onUploadProgress: _.throttle(
          event =>
            handleUploadProgress(event, fileIndex, presignedPart.partNumber),
          300,
          {leading: true, trailing: true}
        ),
      })
        .then(res => {
          const etag = _.get(res.headers, 'etag');

          // add completed part
          setFiles((latestFilesState: FileState[]) => {
            const newFiles = [...latestFilesState];

            if (!newFiles[fileIndex].completedParts) {
              newFiles[fileIndex].completedParts = [];
            }

            // add completed part if not already there
            if (
              !newFiles[fileIndex].completedParts?.find(
                part => part.etag === etag
              )
            ) {
              newFiles[fileIndex].completedParts?.push({
                etag: etag,
                partNumber: presignedPart.partNumber,
              });
            }

            if (
              newFiles[fileIndex].completedParts?.length ===
                presignedUrlParts.length &&
              newFiles[fileIndex].status === 'ongoing'
            ) {
              // All parts are uploaded
              newFiles[fileIndex].status = 'uploaded';
              newFiles[fileIndex].uflId = uflId;
              newFiles[fileIndex].uploadedAt = DateTime.now();

              // complete multipart upload
              completeMultipartUpload(
                http,
                presignedUrlParts[0].uploadID,
                newFiles[fileIndex].completedParts!
              )
                .then(() => {
                  toast(
                    <ToastMessage
                      title="File uploaded successfully. We will review the uploaded files and get back to you within 5 business days."
                      icon="success"
                    />
                  );
                })
                .catch(err => {
                  const message =
                    err?.response?.data?.message ?? 'Error uploading files';
                  toast(<ToastMessage title={message} icon="error" />);
                });
            }

            return [...newFiles];
          });
        })
        .catch(err => {
          if (err.message !== 'upload cancelled') {
            const message =
              err?.response?.data?.message ?? 'Error uploading files';
            toast(<ToastMessage title={message} icon="error" />);
          }
          setFiles((latestFilesState: FileState[]) => {
            const newFiles = latestFilesState;

            // only update state if error is different than cancelled
            if (latestFilesState[fileIndex].error !== 'upload cancelled') {
              newFiles[fileIndex].error = 'upload error';

              return [...newFiles];
            }
            return latestFilesState;
          });
        });

      start = end;
    }
  };

  // file validation
  const fileValidator = (file: File) => {
    // check if allowed type
    const fileExt = getFileExt(file.name);

    if (!allowedFileTypes[fileExt]) {
      return {
        code: 'unsupported file type',
        message: 'unsupported file type',
      };
    }

    const fileSizeLimit = 25 * 1024 * 1024 * 1024; // 25gb
    //check if allowed size
    if (file.size > fileSizeLimit) {
      return {
        code: 'file size too large',
        message: 'file size too large',
      };
    }

    return null;
  };

  //drag and drop upload component
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const filesToAdd: FileState[] = [];
    const filesToUpload: File[] = [];

    //only accept 10 files or less at once
    if (acceptedFiles.length + fileRejections.length < 11) {
      //accepted files that passed validation
      acceptedFiles.forEach((file: File) => {
        //file info to add to state
        filesToAdd.push({
          name: safeFileName(file.name),
          fileType: getFileType(file.name),
          size: file.size,
          status: 'ongoing',
          cancelToken: cancelToken.source(),
        });

        //actual files to upload
        filesToUpload.push(file);
      });

      const fileReqs = acceptedFiles.map(file => {
        return {
          filename: file.name,
          size: file.size,
        };
      });

      const currentLastIndex = 0 + files.length;

      if (fileReqs.length > 0) {
        getPresignedUrls(http, fileReqs)
          .then((presignedUrls: FileUploadPresignedUrl[]) => {
            filesToUpload.forEach((file, fileIndex) => {
              // choose upload method single or multi part
              if (presignedUrls[fileIndex].presignedURLParts) {
                // Multi Part
                uploadFileMultipart({
                  presignedUrlParts:
                    presignedUrls[fileIndex].presignedURLParts!,
                  uflId: presignedUrls[fileIndex].fileUploadLocation.uflId,
                  file,
                  cancelToken: filesToAdd[fileIndex].cancelToken,
                  fileIndex: fileIndex + currentLastIndex,
                });
              } else if (presignedUrls[fileIndex].presignedURL) {
                // Single Part
                uploadFile({
                  presignedUrl: presignedUrls[fileIndex].presignedURL!,
                  uflId: presignedUrls[fileIndex].fileUploadLocation.uflId,
                  file,
                  cancelToken: filesToAdd[fileIndex].cancelToken,
                  fileIndex: fileIndex + currentLastIndex,
                });
              }
            });
          })
          .catch(err => {
            const message =
              err?.response?.data?.message ?? 'Error getting presigned URLs';
            toast(<ToastMessage title={message} icon="error" />);
            setFiles(latestFilesState => {
              const newFiles = latestFilesState;

              newFiles.forEach(file => {
                if (file.progress === undefined && !file.error) {
                  file.error = 'upload error';
                }
              });
              return [...newFiles];
            });
          });
      }

      //rejected files that failed validation
      fileRejections.forEach((file: FileRejection) => {
        filesToAdd.push({
          name: safeFileName(file.file.name),
          fileType: getFileType(file.file.name),
          size: file.file.size,
          status: 'ongoing',
          error: file.errors[0].code,
        });
      });

      //add all files to state
      setFiles([...files, ...filesToAdd]);
    } else {
      toast(
        <ToastMessage
          title="Upload Error. Attempted to upload too many files at once. Please try again with 10 files or less."
          icon="error"
        />
      );
    }
  };

  const {getRootProps, getInputProps} = useDropzone({
    validator: fileValidator,
    onDrop,
  });

  const renderUploadItem = (file: FileState, fileIndex: number) => {
    return (
      <li className="grid grid-cols-12 justify-items-start items-center bg-white py-5">
        <div
          className="text-base leading-6 font-medium text-gray-900 w-full truncate col-span-4"
          title={file.name}
        >
          {file.name}
        </div>
        <div
          className={cx(
            'inline-flex items-center rounded text-sm leading-5 font-medium px-2 py-0.5 col-span-2',
            {
              'bg-yellow-100 text-yellow-800':
                file.fileType === 'TXT' || file.fileType === 'TEXT',
              'bg-emerald-100 text-emerald-800':
                file.fileType === 'DICOM' || file.fileType === 'DCM',
              'bg-indigo-100 text-indigo-800': file.fileType === 'ZIP',
              'bg-pink-100 text-pink-800': file.fileType === 'PDF',
              'bg-orange-100 text-orange-800':
                file.fileType &&
                !['TXT', 'TEXT', 'DCM', 'DICOM', 'ZIP', 'PDF'].includes(
                  file.fileType
                ),
              invisible: file.fileType === null,
            }
          )}
        >
          {file.fileType}
        </div>
        <div className="text-base leading-6 font-normal text-gray-500 col-span-2">
          {formatBytes(file.size)}
        </div>
        <div
          className={cx('text-base leading-6 w-full truncate col-span-3', {
            'text-red-700 font-medium': file.error,
            'text-gray-500 font-normal': !file.error,
          })}
        >
          {getStatus(file)}
        </div>
        <div className="justify-self-end text-gray-500 flex-row flex">
          {renderDisplayButtons(file, fileIndex)}
        </div>
      </li>
    );
  };

  return (
    <>
      <section className=" hover:text-gray-900">
        <div
          {...getRootProps({
            className:
              'border-2 border-dashed rounded-2xl border-gray-300 bg-gray-50 h-60 my-8 text-gray-500 flex items-center justify-center hover:border-gray-400 hover:text-gray-900',
          })}
        >
          <input {...getInputProps()} accept={allowedFileExtensions} />
          <div className="flex text-center flex-col h-4/5 justify-items-center justify-center space-y-4 items-center text-lg leading-7 font-normal focus:bg-red-500">
            <div>
              {/* heroicon: HiOutlineUpload, using SVG format to adjust stroke width */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
            </div>
            <p>Drag and drop files here</p>
          </div>
        </div>
      </section>
      <ul className="grid auto-cols-auto gap-y-0.5 bg-gray-200">
        {files
          .map((file, index) => {
            if (file.status === 'deleted') {
              return null;
            }
            return (
              <React.Fragment key={index}>
                {renderUploadItem(file, index)}
              </React.Fragment>
            );
          })
          .reverse()}
      </ul>
      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={() => closeDeleteModal()}
        className="w-96 max-w-lg"
      >
        {modalFile && renderDeleteModal(modalFile)}
      </Modal>
    </>
  );
};
