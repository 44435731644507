import {AxiosInstance} from 'axios';

// Function to disable external user logins
export const disableExternalUserLogins = (http: AxiosInstance) => {
  return http.post('/v1/admin/kill_switch/disable_login');
};

// Function to enable external user logins
export const enableExternalUserLogins = (http: AxiosInstance) => {
  return http.post('/v1/admin/kill_switch/enable_login');
};

// Function to check if external user logins are disabled
export const checkExternalUserLoginStatus = (http: AxiosInstance) => {
  return http.get('/v1/kill_switch/status');
};
