import {useMemo, Fragment, useEffect, useState} from 'react';
// React Table Imports
import {ReactTable} from '../../core/components/react-table';
import {Pagination} from '../../core/components/pagination';
import {MenuDropdownSingle} from '../../core/components/menu';
import {SelectCustomNumberReportsModal} from '../../components/minerva-search-results/SelectCustomNumberReportsModal';

import {
  ReportSelectionEventType,
  useReportSelection,
} from '../../hooks/report-selection-provider';

import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  useRowSelect,
} from 'react-table';
import cx from 'classnames';
import _ from 'lodash';

import {ESColumn} from '../../models/terminal';

type RequireStudyId = {
  study_id: string;
};

export const TerminalTable = ({
  columns,
  data,
  selectEnabled = false,
}: {
  columns: Array<Column<ESColumn>>;
  data: Array<{}>;
  selectEnabled?: boolean;
}) => {
  const memoRows = useMemo(() => data, [data]);
  const memoColumns = useMemo(() => {
    return columns as Column<{}>[];
  }, [columns]);

  const {reportSelectionDispatch} = useReportSelection();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    selectedFlatRows,
    state: {pageIndex},
    gotoPage,
    toggleAllRowsSelected,
    toggleRowSelected,
    rows,
  } = useTable(
    {
      columns: memoColumns,
      data: memoRows,
      disableMultiSort: true,
      disableSortRemove: true,
      disableSortBy: true,
      initialState: {
        pageSize: 100,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push((columns, {instance}) => [
        // adding a column of checkboxes
        {
          id: 'selection',
          // select all checkbox
          Header: ({getToggleAllPageRowsSelectedProps, filteredRows, page}) => {
            const {indeterminate: pageIndeterminate, ...pageRestProps} =
              getToggleAllPageRowsSelectedProps();
            return (
              <div className="flex items-center">
                <input
                  id="toggleAllPageRowsSelected"
                  type="checkbox"
                  className="checkbox-input"
                  ref={el =>
                    el && (el.indeterminate = pageIndeterminate ?? false)
                  }
                  {...pageRestProps}
                />
                <div>
                  <MenuDropdownSingle
                    buttonText=""
                    useDefaultButtonClass={false}
                    buttonClassName="px-2"
                    align="left"
                    menuButtonDisabled={filteredRows.length === 0}
                    menuItems={[
                      {
                        itemText: () => (
                          <div>Select all {page.length} on page</div>
                        ),
                        onClick: () =>
                          document
                            .getElementById('toggleAllPageRowsSelected')
                            ?.click(),
                      },
                      {
                        itemText: () => <div>Select top 1000 reports</div>,
                        onClick: () => {
                          instance.toggleAllRowsSelected(true);
                        },
                      },
                      {
                        itemText: () => <div>Select custom #</div>,
                        onClick: () => {
                          setCustomReportSelectionModalOpen(true);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            );
          },
          // individual row checkboxes
          Cell: ({row}) => {
            const {indeterminate: pageIndeterminate, ...pageRestProps} =
              row.getToggleRowSelectedProps();
            return (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  ref={el =>
                    el && (el.indeterminate = pageIndeterminate ?? false)
                  }
                  {...pageRestProps}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const [customReportSelectionModalOpen, setCustomReportSelectionModalOpen] =
    useState<boolean>(false);

  const selectReports = (numStudies: number) => {
    if (!isNaN(numStudies) && numStudies > 0) {
      toggleAllRowsSelected(false);
      rows.slice(0, numStudies).forEach(row => {
        toggleRowSelected(row.id, true);
      });
    }
  };

  useEffect(() => {
    const selectedStudyIDs = _.filter(
      selectedFlatRows.map(row => (row.original as RequireStudyId).study_id)
    );

    reportSelectionDispatch({
      type: ReportSelectionEventType.RESET,
      payload: {
        id: 'terminal',
        add: {ids: new Set(selectedStudyIDs)},
      },
    });
  }, [reportSelectionDispatch, selectedFlatRows]);

  return selectEnabled ? (
    <>
      <div
        className={cx(
          'shadow overflow-x-scroll border-b border-gray-200 sm:rounded-lg'
        )}
      >
        <table
          {...getTableProps({
            className: 'min-w-full divide-y divide-gray-200',
          })}
        >
          <thead className="bg-gray-50 divide-y divide-gray-200">
            {headerGroups.map(headerGroup => {
              const {key, ...headerGroupProps} =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...headerGroupProps}>
                  {headerGroup.headers.map(column => {
                    const {key, ...headerProps} = column.getHeaderProps({
                      className:
                        'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap',
                    });
                    return (
                      <th key={key} {...headerProps}>
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody
            {...getTableBodyProps({
              className: 'bg-white divide-y divide-gray-200',
            })}
          >
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      const {key, ...cellProps} = cell.getCellProps({
                        className: 'px-6 py-4 whitespace-nowrap text-sm',
                      });
                      return (
                        <td key={key} {...cellProps}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        max={pageCount}
        current={pageIndex + 1}
        linkFunc={num => {
          gotoPage(num - 1);
        }}
      />
      {customReportSelectionModalOpen && (
        <SelectCustomNumberReportsModal
          isOpen={customReportSelectionModalOpen}
          closeModal={() => setCustomReportSelectionModalOpen(false)}
          selectReports={(num: number) => {
            selectReports(num);
            setCustomReportSelectionModalOpen(false);
          }}
          filters={{}}
        />
      )}
    </>
  ) : (
    <ReactTable sortDisabled={true} tableColumns={columns} tableData={data} />
  );
};
